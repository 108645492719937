import Grid from "@material-ui/core/Grid";
import get from "lodash/get";
import React, { Component } from "react";
import { instance } from "../../../../../Actions/AxiosInstance";
import DynamicForm from "../../../../../Component/DynamicForm";
import { textinputconverter } from "../../../../../Component/DynamicForm/Fields/Text";
import { ErrorMessages } from "../../../../../Constants";

export default class Statutorydetails extends Component {
  state = {
    fieldarr: [],
    subscriptionTypeList: [],
    datareceived: false,
  };

  componentDidMount() {
    this.GetForm();

    this.props.onRef({
      onCancel: this.props.onCancel,
      onPrev: this.props.onPrevClick,
      onNext: this._onsubmit,
      nextButtonLabel: "Save",
    });
  }

  GetSubscriptionType = () =>
    instance.get("superuser/getmastercode/SubscriptionType");

  GetForm = async () => {
    const { postData } = this.props;
    // console.log('this.props.postData: ', this.props.postData);
    const resultsubscription = await this.GetSubscriptionType();
    const subscriptionTypeList = [];
    subscriptionTypeList.push(
      { displayname: "Select Subscription Type", displayvalue: "" },
      ...resultsubscription.data.result,
    );

    this.setState({ subscriptionTypeList });

    const URL = postData.businessunitid
      ? `account/incorporation/${get(this.props, "subscriptionid", "")}/${
          postData.businessunitid
        }`
      : `account/incorporation/${get(this.props, "subscriptionid", "")}`;
    instance
      .get(URL)
      .then((result) => {
        const data = get(result, "data.result[0]", {});
        const statutorydetaildata = {
          subscriptionname: get(data, "subscriptionname", ""),
          incorporationdate: get(data, "incorporationdate", ""),
          subscriptiontypeid: get(data, "subscriptiontypeid", null),
          statutorydetail: get(data, "statutorydetail", []),
        };
        this.setState({ statutorydetaildata, datareceived: true });
        this.props.updatePostData({
          ...this.props.postData,
          isseparateentity: true,
          statutorydetail: get(data, "statutorydetail", []),
        });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
      });
  };

  OnFieldChange2 = ({ statutoryid }, value) =>
    this.props.updatePostData({
      ...get(this.props, "postData", {}),
      statutorydetail: get(this.props, "postData.statutorydetail", []).map(
        (x) => ({
          ...x,
          statutoryvalue:
            x.statutoryid == statutoryid ? value : x.statutoryvalue,
        }),
      ),
    });

  _onsubmit = () => {
    const { statutorydetail } = this.props.postData;
    if (!statutorydetail) {
      this.props.enqueueSnackbar(ErrorMessages.E1220, { variant: "error" });
      return;
    }
    this.props._submit();
  };

  render() {
    const {
      postData: { statutorydetail = [] },
    } = this.props;
    const { datareceived } = this.state;
    return (
      datareceived && (
        <div>
          {statutorydetail.slice(0, statutorydetail.length).map((option) => (
            <Grid key="key" item md={6} sm={6} className="width40">
              <DynamicForm
                fields={textinputconverter({
                  value: option.statutoryvalue,
                  label: `${option.statutoryname} (Optional)`,
                })}
                onChange={(e) => this.OnFieldChange2(option, e)}
              />
            </Grid>
          ))}
          <div className="margin-top-20" />
        </div>
      )
    );
  }
}
