import React from "react";
import Select from "react-select";
import "../../Assets/SCSS/abstracts/inputs.scss";

export const DropDown = (props) => (
  <div className="form-dropdown">
    <Select {...props} />
  </div>
);

export default DropDown;
