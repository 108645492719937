import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import React from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import Helmet from "react-helmet";
import PermissionDeniedComp from "Component/PermissionDeniedComp/PermissionDeniedComp";
import { instance } from "../../../../../Actions/AxiosInstance";
import headersList from "../../../../../Constants/headersList";
import { CreateComp, List, TDTypeConsts } from "../../commons";
import { getTeamList, updateRespectiveList } from "../actions";
import ActionConstants from "../constants";
import { getBusinessConfig } from "../selectors";
import Details from "./Details";
import "../styles/index.css";
import { CheckActionPermission } from "../../../../../Actions/commonAction";
import { ChangeLanguage } from "../../../../../Actions/LanguageAction";

import teamConcept from "../../../../../Assets/Images/team.svg";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginRight: "10px",
  },
  typography: {},
});

const POSTDATA = {
  description: null,
  teamheadid: null,
  teamheadname: null,
  teamid: null,
  teamname: "",
};
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      postData: POSTDATA,
      permissions: { readOnly: false, editable: false },
    };
    this.headers = [
      {
        key: "teamname",
        name: <FormattedMessage id="teamname" defaultMessage="Team Name" />,
        type: TDTypeConsts.TEAM_NAME,
        style: { textAlign: "left" },
      },
      {
        key: "addedbyimagepath",
        name: <FormattedMessage id="LbCreatedBy" defaultMessage="Created By" />,
        type: TDTypeConsts.CREATED_BY,
      },
      {
        key: "assignedEmployeeList",
        name: (
          <FormattedMessage
            id="LbAssignedEmployee"
            defaultMessage="Assigned Employee"
          />
        ),
        type: TDTypeConsts.ASSIGNED_USER,
      },
      {
        key: "teamheadname",
        name: <FormattedMessage id="LbTeamLead" defaultMessage="Team Lead" />,
        type: TDTypeConsts.OTHERS,
      },
      {
        key: "edit",
        name: "",
        type: TDTypeConsts.EDIT_WITHOUT_HEADER,
      },
      {
        key: "delete",
        name: "",
        type: TDTypeConsts.DELETE_WITHOUT_HEADER,
      },
    ];
    this.listLenght = 0;
    this.formConfig = null;

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.ChangeLanguage("team");
    const [isTrue, permissions] = CheckActionPermission("team");
    if (isTrue) {
      this.setState({ permissions }, () => {
        this.props.getTeamList();
        this.GetEmployeeList();
        if (!this.state.permissions.editable) {
          const index = this.headers.findIndex(
            (x) => x.type == TDTypeConsts.EDIT_WITHOUT_HEADER,
          );
          if (index != -1) {
            this.headers.splice(index, 1);
          }
        }
        if (!this.state.permissions.editable) {
          const index = this.headers.findIndex(
            (x) => x.type == TDTypeConsts.DELETE_WITHOUT_HEADER,
          );
          if (index != -1) {
            this.headers.splice(index, 1);
          }
        }
      });
    } else {
      this.setState({ permissiondenied: true });
    }
  }

  GetEmployeeList() {
    const data = {
      accountid: this.state.accountid,
      statusid: null,
      subscriptionid: this.state.subscriptionid,
      featurekey: "team",
    };
    const URL = `subscription/getemployeelist`;
    instance
      .post(URL, data)
      .then((result) => {
        let employeeList = [];
        result.data.result.length > 0
          ? (employeeList = result.data.result)
          : (employeeList = []);
        this.setState({ employeeList });
      })
      .catch((error) => {
        const CommonError = error.response
          ? error.response.data.error.message
          : error.message;
        this.props.enqueueSnackbar(CommonError, { variant: "error" });
      });
  }

  getText() {
    return (
      <span>
        <FormattedMessage
          // id={"teamcreate"}
          id="teamConcept"
          defaultMessage={
            "Create Teams to link a set of employees into a clearly identifiable group working towards a common goal. Using teams can be ideal if you have group of employees from different departments working on a common project."
          }
        />
        <br></br>
        <br></br>
        <FormattedMessage
          // id={"teamcreate"}
          id="teamConceptSub"
          defaultMessage={
            "Additionally, these teams will come handy as a quick-filter when you're working on shift planners, assignment of policies, and more."
          }
        />
      </span>
    );
  }

  _renderCreate = () => (
    <CreateComp
      text={this.getText()}
      // heading={'Concept of Team'}
      heading={
        <FormattedMessage
          id={"conceptteamcreate"}
          defaultMessage="Concept of Team"
        />
      }
      onCreate={this._onCreate}
      imageSrc={teamConcept}
      permissions={this.state.permissions}
    />
  );

  showSnackbar = (msg, variant, autoHideDuration) => {
    this.props.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: autoHideDuration || 1000,
    });
  };

  _onCreate = () => {
    this.setState({ postData: POSTDATA });
    this._setEditPage();
  };

  _setEditPage = () => {
    const { match } = this.props;
    this.props.history.push(`${match.path}/create`);
  };

  onEdit = (item) => {
    instance.get(`subscription/team/${item.teamid}`).then((response) => {
      this.setState({ postData: response.data.result[0] }, () =>
        this._setEditPage(),
      );
    });
  };

  onDelete = (item) => {
    const { teamList } = this.props;
    const { list } = teamList;
    const id = item.teamid;
    const index = list.indexOf(item);
    instance.delete(`subscription/team/${id}`).then(
      () => {
        list.splice(index, 1);
        this.showSnackbar("Team successfully deleted.", "success");
        this.props.updateRespectiveList(ActionConstants.TEAM, list);
        // have to reRender as custom action will not trigger re-render (reason typeToReducer)
        this.setState({ reRender: true }, () => this.props.getTeamList());
      },
      (error) => {
        const msg =
          error.response.data.error.message || "Error while deleting Team.";
        this.showSnackbar(msg, "error", 2000);
      },
    );
  };

  _renderList = () => {
    const { teamList } = this.props;
    this.formConfig = null;
    return (
      <List
        list={teamList.list}
        headers={this.headers}
        onCreate={this._onCreate}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        module={ActionConstants.TEAM}
        goTo={this.props.goTo}
        permissions={this.state.permissions}
      />
    );
  };

  showLoader() {
    return <div className="pre-loader">Loading...</div>;
  }

  _renderComponent = () => {
    const { teamList } = this.props;
    const language = this.props.Language.team;
    if (teamList.IsPending) {
      return this.showLoader();
    }
    return (
      <IntlProvider locale="en" messages={language}>
        <div>
          {teamList.list && teamList.list.length == 0 && this._renderCreate()}
          {teamList.list && teamList.list.length > 0 && this._renderList()}
        </div>
      </IntlProvider>
    );
  };

  submit(data) {
    const { match } = this.props;
    instance
      .post("subscription/team", data)
      .then(() => {
        this.props.enqueueSnackbar("Saved successfully", {
          variant: "success",
        });
        // this.props.getProbationList();
        this.props.getTeamList();
        this.props.history.push(`${match.path}`);
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
      });
  }

  getTabBasedForm = () => {
    const { postData, allData } = this.state;
    return (
      <div>
        <Details
          postData={postData}
          submit={this.submit}
          allData={allData}
          employeeList={this.state.employeeList}
          {...this.props}
        />
      </div>
    );
  };

  _renderForm = () => {
    const language = this.props.Language.department;
    return (
      <IntlProvider locale="en" messages={language}>
        <div className="config-table">{this.getTabBasedForm()}</div>
      </IntlProvider>
    );
  };

  render() {
    const { classes, match } = this.props;
    const language = this.props.Language.team;
    return (
      <IntlProvider locale="en" messages={language}>
        <main className="content-outer-wrapper">
          <Helmet>
            <title>{headersList.Config.Team.header}</title>
          </Helmet>
          {this.state.permissiondenied ? (
            <div className="position-rel">
              <PermissionDeniedComp />
            </div>
          ) : (
            <div>
              <div className={classes.root}>
                <Grid container className="business-config-layout-container">
                  <Grid item xs={12} md={12} sm={12}>
                    <Switch>
                      <Route
                        exact
                        path={`${match.path}/create`}
                        render={this._renderForm}
                      />
                      <Route
                        exact
                        path={`${match.path}`}
                        render={this._renderComponent}
                      />
                      <Redirect from={`${match.path}`} to="" />
                    </Switch>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </main>
      </IntlProvider>
    );
  }
}

Index.propTypes = {};
const mapStateToProps = (state) => ({
  Language: state.LanguageAction.Language,
  teamList: getBusinessConfig(state, ActionConstants.TEAM),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTeamList,
      updateRespectiveList,
      ChangeLanguage: (data) => ChangeLanguage(data),
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withSnackbar(Index)));
