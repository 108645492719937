/* eslint-disable no-multi-assign */
import { MuiThemeProvider } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import { PersistGate } from "redux-persist/integration/react";
import muitheme from "./Assets/SCSS/external/muitheme";
import "./Assets/SCSS/index.scss";
import Configs from "./Constants/Configs";
import SnackbarWrapper from "./SnackbarWrapper";
import store from "./store";
import * as serviceWorker from './registerServiceWorker';
import { IntlProvider } from "react-intl";

// remove consoles in production
if (Configs.MODE === "PROD") {
  Sentry.init({
    dsn: "https://77aa47fce9634212b6d39c5502577540@o1347778.ingest.sentry.io/6626695",
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });  
} else {
  Sentry.init({
    dsn: "https://77aa47fce9634212b6d39c5502577540@o1347778.ingest.sentry.io/6626695",
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });  
  console.warn = console.error = () => { };
}

/**
 * Set up a store provider
 * Add Mui theme
 */
ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={muitheme}>
      <IntlProvider locale="en" >
        <PersistGate loading={"Loading...!"} persistor={persistStore(store)}>
          <SnackbarWrapper />
        </PersistGate>
      </IntlProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root"),
);

serviceWorker.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload(false);
      // registration.unregister();
    }
  },
  onSuccess: (registration) => {
    console.info("service worker on success state", registration);
  },
})

/**
// import registerServiceWorker, { unregister } from "./registerServiceWorker";

// import { StripeProvider } from "react-stripe-elements";
// import LogRocket from 'logrocket';
// LogRocket.init('xc0tpk/sumhr-dev');
// LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
//   name: 'Durvankur Potnis',
//   email: 'durvankur@sumhr.com',
//   // Add your own custom user variables here, ie:
//   subscriptionType: 'pro'
// });

// unregister();

after class----------------

// registerServiceWorker();
*/