const DEPARTMENT_NAME = "DEPARTMENT_NAME";
const OTHERS = "OTHERS";
const COMMONNAME = "COMMONNAME";
const CONVERSATION = "CONVERSATION";
const PRIORITYSUBJECT = "PRIORITYSUBJECT";
const TICKET_OWNER = "TICKET_OWNER";
const CREATED_BY = "CREATED_BY";
const ASSIGNED_USER = "ASSIGNED_USER";
const ASSIGNED_USER_LIST = "ASSIGNED_USER_LIST";
const DEPARTMENT_DESCRIPTION = "DEPARTMENT_DESCRIPTION";
const COMPETANCY_DESCRIPTION = "COMPETANCY_DESCRIPTION";
const TAGS = "TAGS";
const STATUS_ACTIVE_INACTIVE = "STATUS_ACTIVE_INACTIVE";
const EDIT = "EDIT";
const DELETE = "DELETE";
const GRADE_NAME = "GRADE_NAME";
const BUSINESS_UNIT_NAME = "BUSINESS_UNIT_NAME";
const GRADE_RANK = "GRADE_RANK";
const POLICY_NAME = "POLICY_NAME";
const LEAVE_POLICY_NAME = "LEAVE_POLICY_NAME";
const LEAVE_POLICY_DATE = "LEAVE_POLICY_DATE";
const POLICY_VERSION_NAME = "POLICY_VERSION_NAME";
const LEAVE_VERSION_DATE = "LEAVE_VERSION_DATE";
const IS_ACTIVE = "IS_ACTIVE";
const VERSIONS = "VERSIONS";
const SHIFT_TIME = "SHIFT_TIME";
const SHIFT_NAME = "SHIFT_NAME";
const SHIFT_COLOR = "SHIFT_COLOR";
const POLICY_STATUS = "POLICY_STATUS";
const EXTRA_TIME_STATUS = "EXTRA_TIME_STATUS";
const EXTRA_TIME_PAY_STATUS = "EXTRA_TIME_PAY_STATUS";
const EXTRA_TIME_NAME = "EXTRA_TIME_NAME";
const TRACKING_DECIVE = "TRACKING_DECIVE";
const BREAK_TRACK = "BREAK_TRACK";
const EDIT_POLICY = "EDIT_POLICY";
const EDIT_NAME = "EDIT_NAME";
const EDIT_SHIFT = "EDIT_SHIFT";
const SHIFTPLANNER_NAME = "SHIFTPLANNER_NAME";
const SHIFT_VERSIONS = "SHIFT_VERSIONS";
const BANK_NAME = "BANK_NAME";
const ACCOUNT_TYPE = "ACCOUNT_TYPE";
const ACCOUNT_NUMBER = "ACCOUNT_NUMBER";
const IFSC_CODE = "IFSC_CODE";
const STATE = "STATE";
const LEAVETYPES = "LEAVETYPES";
const LINKED_USER = "LINKED_USER";
const SA_NAME = "SA_NAME";
const CREATED_DATE = "CREATED_DATE";
const AUTHORITIES = "AUTHORITIES";
const AUTHORITY_TYPE = "AUTHORITY_TYPE";
const FALLBACK_OF_DEFAULT = "FALLBACK_OF_DEFAULT";
const EFFECTIVE_FROM_TO = "EFFECTIVE_FROM_TO";
const OPTIONAL_HOLIDAY_NAME = "OPTIONAL_HOLIDAY_NAME";
const OPTIONAL_HOLIDAY_TYPE = "OPTIONAL_HOLIDAY_TYPE";
const HOLIDAY_SELECTED = "HOLIDAY_SELECTED";
const OPTIONAL_HOLIDAY_DATE = "OPTIONAL_HOLIDAY_DATE";
const DOCS = "DOCS";
const EXPENSE_POLICY_NAME = "EXPENSE_POLICY_NAME";
const EXPENSE_MONTHLYLIMIT = "EXPENSE_MONTHLYLIMIT";
const COMPANY_NAME = "COMPANY_NAME";
const CLIENT_NAME = "CLIENT_NAME";
const DESIGNATION = "DESIGNATION";
const EDIT_WITHOUT_HEADER = "EDIT_WITHOUT_HEADER";
const EDIT_ASSIGNMENT_WITHOUT_HEADER = "EDIT_ASSIGNMENT_WITHOUT_HEADER";
const DELETE_WITHOUT_HEADER = "DELETE_WITHOUT_HEADER";
const DELETE_WITHOUT_HEADER1 = "DELETE_WITHOUT_HEADER1";
const DELETE_WITHOUT_HEADER_FOR_FBP = "DELETE_WITHOUT_HEADER_FOR_FBP";
const STATE_ACTIVE_INACTIVE = "STATE_ACTIVE_INACTIVE";
const CLICKABLE_NUMBER = "CLICKABLE_NUMBER";
const DOCUMENT_PREVIEW = "DOCUMENT_PREVIEW";
const MORE_OPTIONS = "MORE_OPTIONS";
const LEAVE_MORE_OPTIONS = "LEAVE_MORE_OPTIONS";
const LOGS = "LOGS";
const DATA_PREVIEW = "DATA_PREVIEW";
const SIGNATORY_NAME = "SIGNATORY_NAME";
const ATIVEINACTIVE = "ATIVEINACTIVE";
const LETTER_REQ = "LETTER_REQ";
const SENDER_BY = "SENDER_BY";
const ASSIGNED_GROUPS = "ASSIGNED_GROUPS";
const NAME_CLICKABLE = "NAME_CLICKABLE";
const EXCEPTION_STATUS = "EXCEPTION_STATUS";
const CHECKLIST_STATUS = "CHECKLIST_STATUS";
const CHECKLIST_ACTIVITIY = "CHECKLIST_ACTIVITIY";
const PROFILE = "PROFILE";
const ASSIGNED_TO = "ASSIGNED_TO";
const PROFILELINK = "PROFILELINK";
const ANSWER_TYPE = "ANSWER_TYPE";
const ASSIGNED_PIPELINE = "ASSIGNED_PIPELINE";
const ASSET_CATEGORY_NAME = "ASSET_CATEGORY_NAME";

const SAVE = "SAVE";
const CHECKBOX = "CHECKBOX";
const VARIABLE_STATUS = "VARIABLE_STATUS";
const SALARYCYCLE = "SALARYCYCLE";
const ASSET_TYPE = "ASSET_TYPE";
const ASSIGNED_OWNERS = "ASSIGNED_OWNERS";

export default {
  DEPARTMENT_NAME,
  SENDER_BY,
  DEPARTMENT_DESCRIPTION,
  COMPETANCY_DESCRIPTION,
  TAGS,
  STATUS_ACTIVE_INACTIVE,
  OTHERS,
  COMMONNAME,
  CONVERSATION,
  PRIORITYSUBJECT,
  TICKET_OWNER,
  ASSET_TYPE,
  LETTER_REQ,
  CREATED_BY,
  ASSIGNED_USER,
  ASSIGNED_USER_LIST,
  ASSIGNED_PIPELINE,
  EDIT,
  DELETE,
  GRADE_NAME,
  BUSINESS_UNIT_NAME,
  GRADE_RANK,
  LINKED_USER,
  POLICY_NAME,
  LEAVE_POLICY_NAME,
  LEAVE_POLICY_DATE,
  POLICY_VERSION_NAME,
  LEAVE_VERSION_DATE,
  IS_ACTIVE,
  VERSIONS,
  SHIFT_NAME,
  SHIFT_TIME,
  SHIFT_COLOR,
  POLICY_STATUS,
  EXTRA_TIME_STATUS,
  EXTRA_TIME_PAY_STATUS,
  TRACKING_DECIVE,
  BREAK_TRACK,
  EDIT_POLICY,
  EDIT_NAME,
  EDIT_SHIFT,
  SHIFT_VERSIONS,
  SHIFTPLANNER_NAME,
  EXTRA_TIME_NAME,
  BANK_NAME,
  ACCOUNT_TYPE,
  ACCOUNT_NUMBER,
  IFSC_CODE,
  LEAVETYPES,
  STATE,
  CREATED_DATE,
  SA_NAME,
  AUTHORITIES,
  AUTHORITY_TYPE,
  FALLBACK_OF_DEFAULT,
  EFFECTIVE_FROM_TO,
  OPTIONAL_HOLIDAY_NAME,
  OPTIONAL_HOLIDAY_DATE,
  OPTIONAL_HOLIDAY_TYPE,
  HOLIDAY_SELECTED,
  DOCS,
  EXPENSE_MONTHLYLIMIT,
  EXPENSE_POLICY_NAME,
  COMPANY_NAME,
  CLIENT_NAME,
  DESIGNATION,
  EDIT_WITHOUT_HEADER,
  EDIT_ASSIGNMENT_WITHOUT_HEADER,
  DELETE_WITHOUT_HEADER,
  DELETE_WITHOUT_HEADER1,
  DELETE_WITHOUT_HEADER_FOR_FBP,
  STATE_ACTIVE_INACTIVE,
  CLICKABLE_NUMBER,
  DOCUMENT_PREVIEW,
  MORE_OPTIONS,
  LEAVE_MORE_OPTIONS,
  LOGS,
  DATA_PREVIEW,
  SIGNATORY_NAME,
  ATIVEINACTIVE,
  ASSIGNED_GROUPS,
  NAME_CLICKABLE,
  EXCEPTION_STATUS,
  CHECKLIST_STATUS,
  CHECKLIST_ACTIVITIY,
  VARIABLE_STATUS,
  PROFILE,
  ASSIGNED_TO,
  PROFILELINK,
  SAVE,
  CHECKBOX,
  SALARYCYCLE,
  ANSWER_TYPE,
  ASSET_CATEGORY_NAME,
  ASSIGNED_OWNERS,
};
