const headersList = {
  MyDesk: {
    page: "MyDesk",
    header: "My Desk / sumHR",
  },
  Calendar: {
    page: "Calendar",
    header: "Calendar / sumHR",
  },
  Profile: {
    page: "Profile",
    header: "Profile / sumHR",
  },
  HRIS: {
    // used in start side
    page: "HRIS",
    header: "HRIS / start / sumHR",
  },
  Asset: {
    // used in start side
    page: "Asset",
    header: "Asset / start / sumHR",
  },
  Dashboard: {
    page: "Dashboard",
    header: "Dashboard / sumHR",
  },
  Attendance: {
    // used in start side
    Dashboard: {
      page: "Dashboard",
      header: "Dashboard / start / sumHR",
    },
    StartAttendence: {
      page: "Attendance",
      header: "Attendance / start / sumHR",
    },
    Biometric: {
      page: "Biometric",
      header: "Biometric / start / sumHR",
    },
    Attendence: {
      page: "Attendance",
      header: "Attendance / sumHR",
    },
  },
  Leave: {
    // used in start side
    Dashboard: {
      page: "Dashboard",
      header: "Dashboard / start / sumHR",
    },
    StartLeaves: {
      page: "Leaves",
      header: "Leaves / start / sumHR",
    },
    Leaves: {
      page: "Leaves",
      header: "Leaves / sumHR",
    },
  },
  Drive: {
    // used in start side
    StartDrive: {
      page: "Drive",
      header: "Drive / start / sumHR",
    },
    Drive: {
      page: "Drive",
      header: "Drive / sumHR",
    },
  },
  Expense: {
    // used in start side
    StartExpense: {
      page: "Expense",
      header: "Expense / start / sumHR",
    },
    Compensator: {
      page: "Compensator",
      header: "Compensator / start / sumHR",
    },
    Expense: {
      page: "Expense",
      header: "Expense / sumHR",
    },
  },
  Letters: {
    // used in start side
    Letters: {
      page: "Letters",
      header: "Letters / sumHR",
    },
    CreateLetters: {
      page: "Create Letters",
      header: "Create Letters / start / sumHR",
    },
    Drafts: {
      page: "Drafts",
      header: "Drafts / start / sumHR",
    },
    SentLetters: {
      page: "Sent Letters",
      header: "Sent Letters / start / sumHR",
    },
    PostOffice: {
      page: "Post Office",
      header: "Post Office / start / sumHR",
    },
  },
  StartPayroll: {
    // used in start side
    PayrollDashboard: {
      page: "Payroll Dashboard",
      header: "Payroll Dashboard / start / sumHR",
    },
    VariablePayments: {
      page: "Variable Payments",
      header: "Variable Payments / start / sumHR",
    },
    SalaryAdvance: {
      page: "Salary Advance",
      header: "Salary Advance / start / sumHR",
    },
    Loans: {
      page: "Loans",
      header: "Loans / start / sumHR",
    },
    ExtraPayments: {
      page: "Extra Payments",
      header: "Extra Payments / start / sumHR",
    },
    Employee_VPF: {
      page: "Employee VPF",
      header: "Employee VPF / start / sumHR",
    },
    ExtraDeductions: {
      page: "Extra Deductions",
      header: "Extra Deductions / start / sumHR",
    },
    PayRollExceptions: {
      page: "PayRoll Exceptions",
      header: "PayRoll Exceptions / start / sumHR",
    },
    EmployeePayrollDetails: {
      page: "Employee Payroll Details",
      header: "Employee Payroll Details / start / sumHR",
    },
    Non_payrollIncome: {
      page: "Non-payroll Income",
      header: "Non-payroll Income / start / sumHR",
    },
  },
  Perform: {
    // used in start side
    ManageForms: {
      page: "Manage Forms",
      header: "Manage Forms / start / sumHR",
    },
    EmployeeReviews: {
      page: "Employee Reviews",
      header: "Employee Reviews / start / sumHR",
    },
  },
  Holiday: {
    page: "Holiday",
    header: "Holiday / sumHR",
  },
  CheckList: {
    page: "Checklist",
    header: "Checklist / sumHR",
  },
  PolicyCenter: {
    page: "Policy Center",
    header: "Policy Center / sumHR",
  },
  Payroll: {
    PaySlip: {
      page: "Payslip",
      header: "Payslip / Payroll / sumHR",
    },
    Form16: {
      page: "Form16",
      header: "Form16 / Payroll / sumHR",
    },
    Package_Proration: {
      page: "Package & Proration",
      header: "Package&Proration / Payroll / sumHR",
    },
    Taxsheet: {
      page: "Taxsheet",
      header: "Taxsheet / Payroll / sumHR",
    },
    SalaryAdvance: {
      page: "Salary Advance",
      header: "Salary Advance / Payroll / sumHR",
    },
    Loan: {
      page: "Loan",
      header: "Loan / Payroll / sumHR",
    },
    ExtraPayment: {
      page: "Extra Payment",
      header: "Extra Payment / Payroll / sumHR",
    },
    ExtraDeduction: {
      page: "Extra Deduction",
      header: "Extra Deduction / Payroll / sumHR",
    },
    Flexible_Benifit_Plan: {
      page: "Flexible Benifit Plan",
      header: "Flexible Benifit Plan / Payroll / sumHR",
    },
    Payroll_Fnf: {
      page: "FnF policy",
      header: "FnF policy / Payroll / sumHR",
    },
    IT_Declaration: {
      page: "IT Declaration and Submission",
      header: "IT Declaration & Submission / Payroll / sumHR",
    },
    IncomeTax: {
      page: "Income Tax",
      header: "Income Tax / Payroll / sumHR",
    },
    Form12B: {
      page: "Form 12B",
      header: "Form 12B / Payroll / sumHR",
    },
    AnnualPayslip: {
      page: "Annual Payslip",
      header: "Annual Payslip / Payroll / sumHR",
    },
    Perquisite: {
      page: "Perquisite",
      header: "Perquisite / Payroll / sumHR",
    },
  },
  OKRGoals: {
    page: "OKR & Goals",
    header: "OKR & Goals / sumHR",
  },
  Recruit: {
    Pipeline: {
      page: "Pipeline",
      header: "Pipeline / Manage / sumHR",
    },
    TalentPool: {
      page: "Talent Pool",
      header: "Talent Pool / Manage / sumHR",
    },
    HiringPlan: {
      page: "Hiring Plan",
      header: "Hiring Plan / Manage / sumHR",
    },
    Requisition: {
      page: "Requisition",
      header: "Requisition / Manage / sumHR",
    },
  },

  // config side
  Config: {
    Dashboard: {
      page: "Dashboard",
      header: "Dashboard / Config / sumHR",
    },
    BusinessUnit: {
      page: "Business Unit",
      header: "Business Unit / Config / sumHR",
    },
    Department: {
      page: "Department",
      header: "Department / Config / sumHR",
    },
    Designation: {
      page: "Designation",
      header: "Designation / Config / sumHR",
    },
    Team: {
      page: "Team",
      header: "Team / Config / sumHR",
    },
    Grade: {
      page: "Grade",
      header: "Grade / Config / sumHR",
    },
    Employeeid: {
      page: "Employeeid",
      header: "Employeeid / Config / sumHR",
    },
    Probation: {
      page: "Probation",
      header: "Probation / Config / sumHR",
    },
    ApprovalWorkflow: {
      page: "Approval Workflow",
      header: "Approval Workflow / Config / sumHR",
    },
    DefaultProfilePrivacy: {
      page: "Default Profile Privacy",
      header: "Default Profile Privacy / Config / sumHR",
    },
    DefaultPermission: {
      page: "Default Permission",
      header: "Default Permission / Config / sumHR",
    },
    PermissionPolicy: {
      page: "Permission Policy",
      header: "Permission Policy / Config / sumHR",
    },
    ExitPolicy: {
      page: "Exit Policy",
      header: "Exit Policy / Config / sumHR",
    },
    ExitReasons: {
      page: "Exit Reasons",
      header: "Exit Reasons / Config / sumHR",
    },
    CustomData: {
      page: "Custom Data",
      header: "Custom Data / Config / sumHR",
    },
    Shift: {
      page: "Shift",
      header: "Shift / Config / sumHR",
    },
    ClockinMethod: {
      page: "Clock-in Method",
      header: "Clock-in Method / Config / sumHR",
    },
    AttendancePolicy: {
      page: "Attendance Policy",
      header: "Attendance Policy / Config / sumHR",
    },
    ExtraTime: {
      page: "Extra Time",
      header: "Extra Time / Config / sumHR",
    },
    AttendanceRequestCycle: {
      page: "Attendance Request Cycle",
      header: "Attenance Request Cycle / Config / sumHR",
    },
    WeeklyOff: {
      page: "Weekly Off",
      header: "Weekly Off / Config / sumHR",
    },
    LeaveType: {
      page: "Leave Type",
      header: "Leave Type / Config / sumHR",
    },
    LeavePolicy: {
      page: "Leave Policy",
      header: "Leave Policy / Config / sumHR",
    },
    HolidayPlan: {
      page: "Holiday Plan",
      header: "Holiday Plan / Config / sumHR",
    },
    LeaveCycleTransition: {
      page: "Leave Cycle Transition",
      header: "Leave Cycle Transition / Config / sumHR",
    },
    ExpenseCycle: {
      page: "Expense Cycle",
      header: "Expense Cycle / Config / sumHR",
    },
    Fuel_DistanceUnits: {
      page: "Fuel & Distance Units",
      header: "Fuel & Distance Units / Config / sumHR",
    },
    Compensator: {
      page: "Compensator",
      header: "Compensator / Config / sumHR",
    },
    ExpensePolicy: {
      page: "Expense Policy",
      header: "Expense Policy / Config / sumHR",
    },
    PayrollMethod: {
      page: "Payroll Method",
      header: "Payroll Method / Config / sumHR",
    },
    OverTimePaymentPolicy: {
      page: "Extra Time Payment Policy",
      header: "Over Time Payment Policy / Config / sumHR",
    },
    SalaryCycle: {
      page: "Salary Cycle",
      header: "Salary Cycle / Config / sumHR",
    },
    Misc: {
      RoundingOption: {
        page: "Rounding Option",
        header: "Rounding Option / Misc / Config / sumHR",
      },
      VariableComponentSettings: {
        page: "Variable Component Settings",
        header: "Variable Component Settings / Misc / Config / sumHR",
      },
      FlexibleBenifitPlan: {
        page: "Flexible Benifit Plan",
        header: "Flexible Benifit Plan / Misc / Config / sumHR",
      },
      SalarySlipDesign: {
        page: "Salary Slip Design",
        header: "Salary Slip Design / Misc / Config / sumHR",
      },
      LoanSettings: {
        page: "Loan Settings",
        header: "Loan Settings / Misc / Config / sumHR",
      },
      ITDeclaration: {
        page: "IT Declaration",
        header: "IT Declaration / Misc / Config / sumHR",
      },
      ITSubmission: {
        page: "IT Submission",
        header: "IT Submission / Misc / Config / sumHR",
      },
    },
    Component: {
      page: "Component",
      header: "Component / Config/ sumHR",
    },
    SalaryStructure: {
      page: "Salary Structure",
      header: "Salary Structure / Config / sumHR",
    },
    FlexibleBenifitPlan: {
      page: "Flexible Benifit Plan",
      header: "Flexible Benifit Plan /  Config / sumHR",
    },
    PayrollTag: {
      page: "Payroll Tag",
      header: "Payroll Tag / Config / sumHR",
    },
    PageLayout: {
      page: "Page Layout",
      header: "Page Layout / Config / sumHR",
    },
    Templates: {
      page: "Templates",
      header: "Templates / Config / sumHR",
    },
    AuthorizedSignatory: {
      page: "Authorized Signatory",
      header: "Authorized Signatory / Config / sumHR",
    },
    CheckList: {
      page: "Checklist",
      header: "Checklist / Config / sumHR",
    },
    CompanyPolicy: {
      page: "Company Policy",
      header: "Company Policy / Config / sumHR",
    },
    PerformanceCycle: {
      page: "Performance Cycle",
      header: "Performance Cycle / Config / sumHR",
    },
    Reminder: {
      page: "Reminder",
      header: "Reminder / Config / sumHR",
    },
    RatingScale: {
      page: "Rating Scale",
      header: "Rating Scale / Config / sumHR",
    },
    CompetencyRepository: {
      page: "Competency Repository",
      header: "Competency Repository / Config / sumHR",
    },
    ReviewStructure: {
      page: "Review Structure",
      header: "Review Structure / Config / sumHR",
    },
    ReviewForm: {
      page: "Review Form",
      header: "Review Form / Config / sumHR",
    },
    OKRConfig: {
      page: "OKR Config",
      header: "OKR Config / Config / sumHR",
    },
    MeasurementUnit: {
      page: "Measurement unit",
      header: "Measurement unit/ Config / sumHR",
    },
    RequestCenter: {
      page: "Request Center",
      header: "Request Center / Config / sumHR",
    },
    Assets: {
      page: "Add category",
      header: "Add category",
    },
    ATS: {
      Recruiters: {
        page: "Recruiters",
        header: "Recruiters / Config / sumHR",
      },
      CarrerPage: {
        page: "Career Page",
        header: "Career Page / Config / sumHR",
      },
      PipelineMailTemplate: {
        page: "PipelineMailTemplate",
        header: "Pipeline Email Templates / Config / sumHR",
      },
      Pipeline: {
        page: "Pipeline",
        header: "Pipeline / Config / sumHR",
      },
      ScreeningQuestions: {
        page: "Screening Questions",
        header: "Screening Questions / Config / sumHR",
      },
      JobTemplates: {
        page: "Job Templates",
        header: "Job Templates / Config / sumHR",
      },
      CandidateSources: {
        page: "Candidate Sources",
        header: "Candidate Sources / Config / sumHR",
      },
    },
  },

  // planner side
  Planner: {
    Planner: {
      page: "Planner",
      header: "Planner / sumHR",
    },
    ProbationPlanner: {
      page: "Probation Planner",
      header: "Probation Planner / sumHR",
    },
    DesignationPlanner: {
      page: "Designation Planner",
      header: "Designation Planner / sumHR",
    },
    ShiftPlanner: {
      page: "Shift Planner",
      header: "Shift Planner / sumHR",
    },
    ExtraTimePlanner: {
      page: "Extra Time Planner",
      header: "Extra Time Planner / sumHR",
    },
    LeavePolicyPlanner: {
      page: "Leave Policy Planner",
      header: "Leave Policy Planner / sumHR",
    },
    HolidayPlanner: {
      page: "Holiday Planner",
      header: "Holiday Planner / sumHR",
    },
    PayrollPlanner: {
      page: "PayrollPlanner",
      header: "PayrollPlanner / sumHR",
    },
  },

  // live data
  LiveData: {
    LiveData: {
      page: "Live Data",
      header: "Live Data / sumHR",
    },
    AttendanceRegularization: {
      page: "Attendance Regularization",
      header: "Attendance Regularization / sumHR",
    },
    LeaveCreditDebitAdjustment: {
      page: "Leave Credit & Debit Adjustment",
      header: "Leave Credit & Debit Adjustment / sumHR",
    },
    BulkLeaveApplication: {
      page: "Bulk Leave Application",
      header: "Bulk Leave Application / sumHR",
    },
    EmployeeDeviceMapping: {
      page: "Employee Device Mapping",
      header: "Employee Device Mapping / sumHR",
    },
    AddNewEmployee: {
      page: "Add New Employee",
      header: "Add New Employee / sumHR",
    },
    EmployeeID: {
      page: "Employee ID",
      header: "Employee ID / sumHR",
    },
    ExpensePolicy: {
      page: "Expense Policy",
      header: "Expense policy / sumHR",
    },
    ApprovalWorkflow: {
      page: "Approval Workflow",
      header: "Approval Workflow / sumHR",
    },
    ExitPolicy: {
      page: "Exit Policy",
      header: "Exit Policy / sumHR",
    },
    EmployeeWorkprofile: {
      page: "Employee Workprofile",
      header: "Employee Workprofile / sumHR",
    },
    Managers: {
      page: "Managers",
      header: "Managers / sumHR",
    },
    Team: {
      page: "Team",
      header: "Team / sumHR",
    },
    Grade: {
      page: "Grade",
      header: "Grade / sumHR",
    },
    Assignment: {
      page: "Assignment",
      header: "Assignment / sumHR",
    },
    DefaultPermission: {
      page: "Default Permission",
      header: "Default Permission / sumHR",
    },
    PermissionPolicy: {
      page: "Permission Policy",
      header: "Permission Policy / sumHR",
    },
    EmploymentType: {
      page: "Employment Type",
      header: "Employment Type / sumHR",
    },
    ReportingOffice: {
      page: "Reporting Office",
      header: "Reporting Office / sumHR",
    },
    SelfService: {
      page: "Self Service",
      header: "Self Service / sumHR",
    },
    PayrollTags: {
      page: "Payroll Tags",
      header: "Payroll Tags / sumHR",
    },
    EmployeeExit: {
      page: "Employee Exit",
      header: "Employee Exit / sumHR",
    },
    ExtraPayment: {
      page: "Extra Payment",
      header: "Extra Payment / sumHR",
    },
    ExtraDeduction: {
      page: "Extra Deduction",
      header: "Extra Deduction / sumHR",
    },
    BankDetails: {
      page: "Bank Details",
      header: "Bank Details / sumHR",
    },
    SalaryStructure: {
      page: "Salary Structure",
      header: "Salary Structure / sumHR",
    },
    Payslip: {
      page: "Payslip",
      header: "Payslip / sumHR",
    },
    Package_Proration: {
      page: "Proration Upload",
      header: "Proration Upload / sumHR",
    },
    Fnf: {
      page: "Fnf",
      header: "Fnf / sumHR",
    },
    Statutory: {
      page: "Statutory",
      header: "Statutory / sumHR",
    },
  },

  // others
  PerformReview: {
    page: "Perform Review",
    header: "Perform Review / sumHR",
  },
  ROBO: {
    page: "Report Robo",
    header: "Report Robo / sumHR",
  },
  ChangePassword: {
    page: "Change Password",
    header: "Change Password / sumHR",
  },
  RequestCenter: {
    page: "Request Center",
    header: "Request Center / sumHR",
  },
  InviteNewEmployee: {
    page: "Invite New Employee",
    header: "Invite New Employee / sumHR",
  },
  PersonalPrivacy: {
    page: "Personal Privacy",
    header: "Personal Privacy / sumHR",
  },
  Directory: {
    page: "Directory",
    header: "Directory / sumHR",
  },
  NotificationPreferences: {
    page: "Notification Preferences",
    header: "Notification Preferences / sumHR",
  },
  NotificationCenter: {
    page: "Notification Center",
    header: "Notification Center / sumHR",
  },
  Help: {
    page: "Help",
    header: "Help / sumHR",
  },
  Operation: {
    page: "Operation",
    header: "Operation / sumHR",
  },
};

export default headersList;
