import { applyMiddleware, createStore, compose } from "redux";
import { createLogger } from "redux-logger";
import { persistReducer } from "redux-persist";
import createEncryptor from "redux-persist-transform-encrypt";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import promise from "redux-promise-middleware";
// import LogRocket from 'logrocket';
import thunk from "redux-thunk";
import reducers from "./Reducers";
import Configs from "./Constants/Configs";

const middleware =
  Configs.MODE == "DEV"
    ? applyMiddleware(
        thunk,
        promise(),
        createLogger({ collapsed: true, duration: true }),

        // LogRocket.reduxMiddleware()
      )
    : applyMiddleware(
        thunk,
        promise(),
        // createLogger({ collapsed: true, duration: true }),
        // LogRocket.reduxMiddleware()
      );

const reducerObj = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    "LoaderReducer",
    "clockinReducer",
    "SaveEmployeeid",
    "planDuration",
    "ALL_SHIFT",
    "ATTENDANCE_POLICY",
    "SaveInProgressReducer",
    "DEVICE_POLICY",
    "DrawerReducer",
    "EXTRA_TIME",
    "HOLIDAY_PLAN",
    "InitAttendancePolicy",
    "InitLeavesPolicy",
    "LEAVES_POLICY",
    "NO_OF_EMPLOLYEES",
    "WEEKOFFTPLANNER",
    "approvalWorkflowReducer",
    "attendanceDeviceReducer",
    "businessConfig",
    "holidayPlanReducer",
    "extraTimeDataReducer",
    "profilePrivacyInfoReducer",
    "refreshProfileInfoReducer",
    "shiftPlannerReducer",
    "weekoffPlannerReducer",
    "PAIN_POINTS",
    "ProgressbarReducer",
    "SALARY_STURUCTURE",
    "helperTextReducer",
    "EMPREQUESTCENTERLIST",
    "PreviewReducer",
    "dynamicformReducer",
    "REQUESTCENTER",
  ],
};

// encrypt data in production
// if (Configs.MODE == "DEV") {
//   const encryptor = createEncryptor({
//     secretKey: Configs.REDUX_ENCRYPTOR_KEY,
//     onError: (error) => {
//       // Handle the error.
//       console.log("error: ", error);
//     },
//   });
//   reducerObj.transforms = [encryptor];
// }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  persistReducer(reducerObj, reducers),
  composeEnhancers(middleware),
);
