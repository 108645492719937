import React from "react";
import { getFormattedLogoLink } from "Utils";
import "./style/style.css";
import sumhrlogo from "./images/sumhr-logo.svg";
import hifive from "./images/undraw-high-five.svg";
import copyIcon from "./images/copy.svg";
import twitterIcon from "./images/twitter.svg";
import facebookIcon from "./images/facebook.svg";
import linkedinIcon from "./images/linkedin.svg";
import pinterestIcon from "./images/pinterest.svg";

class SuccessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobdetails: this.props.jobdetails,
      postdata: this.props.postdata,
    };
  }

  render() {
    const { jobdetails } = this.state;
    return (
      <div style={{ background: "rgb(249, 249, 249)" }}>
        <div>
          <div
            className="c-fluid-container"
            style={{ backgroundColor: "#fff", padding: "32px 0px 0px" }}
          >
            <div className="c-wrapper">
              <img
                style={{
                  maxHeight: 100,
                  maxWidth: 200,
                  objectFit: "contain",
                }}
                src={getFormattedLogoLink(this.props.subscriptiondetails.logo)}
                alt="icon"
              />
            </div>
          </div>
          <div
            className="c-fluid-container"
            style={{ backgroundColor: "#fff", padding: "32px 0px" }}
          >
            <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
              <div className="c-wrapper">
                <h1
                  style={{
                    color: "rgb(1, 7, 9)",
                    fontSize: 40,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  {jobdetails && jobdetails.designationname}
                </h1>
                <p
                  style={{
                    color: "rgb(84, 85, 86)",
                    fontSize: 24,
                    marginRight: 16,
                    fontWeight: "bold",
                    marginTop: 0,
                  }}
                >
                  {jobdetails.location && jobdetails.location.location} •{" "}
                  {jobdetails && jobdetails.designationname} •{" "}
                  {jobdetails && jobdetails.jobtype}
                </p>
              </div>
            </div>
          </div>
          <div className="c-wrapper">
            <div
              className="c-MuiGrid-root c-fluid-container c-MuiGrid-container"
              style={{ background: "rgb(249, 249, 249)", textAlign: "center" }}
            >
              <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                <div className="c-wrapper">
                  <p
                    style={{
                      color: "rgb(1, 7, 9)",
                      fontSize: 24,
                      fontWeight: "bold",
                      marginBbottom: 16,
                      marginTop: 0,
                    }}
                  >
                    Your application has been successfully submitted!
                  </p>
                  <p
                    style={{
                      color: "rgb(1, 7, 9)",
                      fontSize: 22,
                      margin: "24px auto 64px",
                    }}
                  >
                    Our team will reach out to you for further rounds of
                    screening. Please feel free to share this job posting in the
                    meanwhile!
                  </p>
                </div>
              </div>
              <div className="c-MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 MuiGrid-grid-md-6">
                <img src={hifive} />
              </div>
              <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-6">
                <div className="c-rounded-rectangle-div">
                  <p
                    className="c-large-text-dark-secondary"
                    style={{
                      textAlign: "left",
                      width: "90%",
                      display: "inline-block",
                    }}
                  >
                    {window.location.href}
                  </p>
                  {/* <img src={copyIcon} /> */}
                </div>
                <div style={{ marginTop: 24 }}>
                  <a
                    href={`https://twitter.com/intent/tweet?url=${window.location.origin}&text=`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={twitterIcon} style={{ marginRight: 16 }} />
                  </a>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebookIcon} style={{ marginRight: 16 }} />
                  </a>
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.origin}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedinIcon} style={{ marginRight: 16 }} />
                  </a>
                </div>
                <button
                  className="c-btn c-btn-primary-md-blue"
                  style={{ marginTop: 40 }}
                  type="button"
                  onClick={() => this.props.history.push("/career")}
                >
                  View all openings
                </button>
              </div>
            </div>
          </div>
          {/* <div
            className="c-wrapper"
            style={{
              background: "rgb(249, 249, 249)",
              padding: "88px 0",
              textAlign: "center",
            }}
          >
            <p className="c-normal-dark">Powered by</p>
            <img src={sumhrlogo} />
          </div> */}
        </div>
      </div>
    );
  }
}

export default SuccessPage;
