import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import React from "react";
import Helmet from "react-helmet";
import PermissionDeniedComp from "Component/PermissionDeniedComp/PermissionDeniedComp";
import { FormattedMessage, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import headersList from "../../../../../Constants/headersList";
import { instance } from "../../../../../Actions/AxiosInstance";
import { CreateComp, List, TDTypeConsts } from "../../commons";
import { getDepartmentList, updateRespectiveList } from "../actions";
import ActionConstants from "../constants";
import { getBusinessConfig } from "../selectors";
import "../styles/index.css";
import Details from "./Details";
import { CheckActionPermission } from "../../../../../Actions/commonAction";
import { ChangeLanguage } from "../../../../../Actions/LanguageAction";
import departmentConcept from "../../../../../Assets/Images/Group_chat.svg";
import DrawerHeader from "../../../../../Component/Headers/drawerHeader";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginRight: "10px",
  },
  typography: {},
});

const POSTDATA = {
  businessunitids: null,
  departmentemailid: null,
  departmentheadid: null,
  departmentid: null,
  departmentname: null,
  description: null,
  parentdepartmentid: null,
  olddepartmentheadid: null,
};
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      postData: POSTDATA,
      permissions: { readOnly: false, editable: false },
      allData: null,
      selectedBusinessUnit: null,
    };
    this.submit = this.submit.bind(this);
    this.headers = [
      {
        key: "departmentname",
        name: (
          <FormattedMessage
            id="LbDepartmentUnitName"
            defaultMessage="Department Unit Name"
          />
        ),
        type: TDTypeConsts.DEPARTMENT_NAME,
        style: { textAlign: "left" },
      },
      {
        key: "bucount",
        name: (
          <FormattedMessage
            id="__LbLinkedBusinessUnits"
            defaultMessage="Linked Business Units"
          />
        ),
        type: TDTypeConsts.CLICKABLE_NUMBER,
      },
      {
        key: "addedbyimagepath",
        name: <FormattedMessage id="LbCreatedBy" defaultMessage="Created By" />,
        type: TDTypeConsts.CREATED_BY,
      },
      {
        key: "assignedEmployeeList",
        name: (
          <FormattedMessage
            id="LbAssignedEmployee"
            defaultMessage="Assigned Employee"
          />
        ),
        type: TDTypeConsts.ASSIGNED_USER,
      },
      {
        key: "departmentheadname",
        name: (
          <FormattedMessage
            id="LbDepartmentUnitHead"
            defaultMessage="Department Unit Head"
          />
        ),
        type: TDTypeConsts.OTHERS,
      },
      {
        key: "edit",
        name: "",
        type: TDTypeConsts.EDIT_WITHOUT_HEADER,
      },
      {
        key: "delete",
        name: "",
        type: TDTypeConsts.DELETE_WITHOUT_HEADER,
      },
    ];
    this.listLenght = 0;
    this.doEditId = -1;
  }

  componentDidMount() {
    this.props.ChangeLanguage("department");
    const [isTrue, permissions] = CheckActionPermission("department");
    // const [isTrue, actionlistObject] = CheckActionPermission(this.props.history.location.pathname)
    if (isTrue) {
      this.setState({ permissions }, () => {
        this.props.getDepartmentList();
        this.getDepartmentData();
        if (!this.state.permissions.editable) {
          const index = this.headers.findIndex(
            (x) => x.type == TDTypeConsts.EDIT_WITHOUT_HEADER,
          );
          if (index != -1) {
            this.headers.splice(index, 1);
          }
        }
        if (!this.state.permissions.editable) {
          const index = this.headers.findIndex(
            (x) => x.type == TDTypeConsts.DELETE_WITHOUT_HEADER,
          );
          if (index != -1) {
            this.headers.splice(index, 1);
          }
        }
      });
    } else {
      this.setState({ permissiondenied: true });
    }
  }

  getDepartmentData() {
    const data = {
      featurekey: "department",
    };
    instance
      .post("subscription/initdepartment", data)
      .then((result) => {
        this.setState({
          allData: result.data.result,
        });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
      });
  }

  getText() {
    return (
      <span>
        <FormattedMessage
          id="departmentConcept"
          defaultMessage="Use Departments to segregate functional areas within your company, like Marketing, Sales, Engineer, et al."
        />
      </span>
    );
  }

  _renderCreate = () => (
    <CreateComp
      text={this.getText()}
      heading={
        <FormattedMessage
          id="createdepartmentconcept"
          defaultMessage="Concept of Department"
        />
      }
      onCreate={this._onCreate}
      imageSrc={departmentConcept}
      permissions={this.state.permissions}
    />
  );

  showSnackbar = (msg, variant, autoHideDuration) => {
    this.props.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: autoHideDuration || 1000,
    });
  };

  _onCreate = () => {
    this.setState({ postData: POSTDATA });
    this._setEditPage();
  };

  _setEditPage = () => {
    const { match } = this.props;
    this.props.history.push(`${match.path}/create`);
  };

  onEdit = (item) => {
    instance
      .get(`subscription/department/${item.departmentid}`)
      .then((response) => {
        const d = response.data.result[0];
        d.olddepartmentheadid = d.departmentheadid;
        this.setState({ postData: response.data.result[0] }, () =>
          this._setEditPage(),
        );
      });
  };

  onDelete = (item) => {
    const { departmentList } = this.props;
    const { list } = departmentList;
    const id = item.departmentid;
    const index = list.indexOf(item);
    instance.delete(`subscription/department/${id}`).then(
      (response) => {
        if (
          response.data.result &&
          response.data.result[0] &&
          !response.data.result[0].isexecuted
        ) {
          this.showSnackbar("Department can not be deleted.", "error", 2000);
        } else {
          list.splice(index, 1);
          this.showSnackbar("Department successfully deleted.", "success");
          this.props.updateRespectiveList(ActionConstants.DEPARTMENT, list);
          // have to reRender as custom action will not trigger re-render (reason typeToReducer)
        }

        this.setState({ reRender: true }, () => this.props.getDepartmentList());
      },
      (error) => {
        const msg =
          error.response.data.error.message ||
          "Error while deleting Department.";
        this.showSnackbar(msg, "error", 2000);
      },
    );
  };

  submit(data) {
    const { match } = this.props;
    instance
      .post("subscription/department", data)
      .then(() => {
        this.props.enqueueSnackbar("Saved successfully", {
          variant: "success",
        });
        // this.props.getProbationList();
        this.props.getDepartmentList();
        this.getDepartmentData();
        this.props.history.push(`${match.path}`);
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
      });
  }

  onClickableNumberClick = (row, key, rowIndex) => {
    console.log("row, key, rowIndex: ", row, key, rowIndex);
    instance.get(`subscription/allbussinessunit/${row.departmentid}`).then(
      (response) => {
        this.setState({ selectedBusinessUnit: response.data.result }, () =>
          this.props.getDepartmentList(),
        );
      },
      (error) => {
        const msg =
          error.response.data.error.message ||
          "Error while deleting Department.";
        this.showSnackbar(msg, "error", 2000);
      },
    );
  };

  _renderList = () => {
    const { departmentList } = this.props;
    this.doEditId = -1;
    return (
      <List
        list={departmentList.list}
        headers={this.headers}
        onCreate={this._onCreate}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        module={ActionConstants.DEPARTMENT}
        goTo={this.props.goTo}
        permissions={this.state.permissions}
        onClickableNumberClick={this.onClickableNumberClick}
      />
    );
  };

  showLoader() {
    return <div className="pre-loader">Loading...</div>;
  }

  _renderComponent = () => {
    const { departmentList } = this.props;
    if (departmentList.IsPending) {
      return this.showLoader();
    }
    const language = this.props.Language.department;
    return (
      <IntlProvider locale="en" messages={language}>
        <div>
          {departmentList.list &&
            departmentList.list.length == 0 &&
            this._renderCreate()}
          {departmentList.list &&
            departmentList.list.length > 0 &&
            this._renderList()}
        </div>
      </IntlProvider>
    );
  };

  getTabBasedForm = () => {
    const { postData, allData } = this.state;
    return (
      <div>
        <Details
          postData={postData}
          updatePostData={this.updatePostData}
          submit={this.submit}
          allData={allData}
          {...this.props}
        />
      </div>
    );
  };

  _renderForm = () => {
    const language = this.props.Language.department;
    return (
      <IntlProvider locale="en" messages={language}>
        <div className="config-table">{this.getTabBasedForm()}</div>
      </IntlProvider>
    );
  };

  render() {
    const { classes, match } = this.props;
    const { selectedBusinessUnit } = this.state;
    return (
      <IntlProvider locale="en" messages={"en"}>
        <main className="content-outer-wrapper">
          <Helmet>
            <title>{headersList.Config.Department.header}</title>
          </Helmet>
          {this.state.permissiondenied ? (
            <div className="position-rel">
              <PermissionDeniedComp />
            </div>
          ) : (
            <div>
              <Drawer
                anchor="right"
                open={selectedBusinessUnit}
                onClose={() => this.setState({ selectedBusinessUnit: "" })}
                classes={{
                  paper: "drawer-main",
                }}
              >
                <div className="planner-right-drawer">
                  <DrawerHeader
                    header="Business Units"
                    closeDrawer={() =>
                      this.setState({ selectedBusinessUnit: "" })
                    }
                  />
                  {selectedBusinessUnit &&
                    selectedBusinessUnit.map((bu) => (
                      <p className="sub-heading">
                        <strong>{bu.businessunitname}</strong>
                      </p>
                    ))}
                </div>
              </Drawer>
              <div>
                <div className={classes.root}>
                  <Grid container className="business-config-layout-container">
                    <Grid item xs={12} md={12} sm={12}>
                      <Switch>
                        <Route
                          exact
                          path={`${match.path}/create`}
                          render={this._renderForm}
                        />
                        <Route
                          exact
                          path={`${match.path}`}
                          render={this._renderComponent}
                        />
                        <Redirect from={`${match.path}`} to={`${match.path}`} />
                      </Switch>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </main>
      </IntlProvider>
    );
  }
}

Index.propTypes = {};
const mapStateToProps = (state) => ({
  Language: state.LanguageAction.Language,
  departmentList: getBusinessConfig(state, ActionConstants.DEPARTMENT),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDepartmentList,
      updateRespectiveList,
      ChangeLanguage: (data) => ChangeLanguage(data),
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withSnackbar(Index)));
