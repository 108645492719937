import get from "lodash/get";
import React, { Component } from "react";
import toLower from "lodash/toLower";
import DatePicker from "react-date-picker";
import "../DateRangeCx.scss";
import ClearIcon from "../../../Assets/Svg/clearIcon.svg";
import {
  getUserDateTimeFormat,
  convertToJSDateType,
} from "../../../Utils/dates";

const CALENDERS_DATEFORMATS = {
  "DD-MM-YYYY HH12:MI:SS AM": "d/MM/yyyy",
  "DD-MM-YYYY HH24:MI:SS": "d/MM/yyyy",
  "DD-MM-YY HH12:MI:SS AM": "d/MM/yy",
  "DD-MM-YY HH24:MI:SS": "d/MM/yy",
  "DD-MMM-YYYY HH12:MI:SS AM": "d/MMM/yyyy",
  "DD-MMM-YYYY HH24:MI:SS": "d/MMM/yyyy",
  "MMM DD, YYYY HH12:MI:SS AM": "MMM dd, yyyy",
  "MMM DD, YYYY HH24:MI:SS": "MMM d, yyyy",
  "YYYY-MM-DD HH": "yyyy/MM/d",
};

const getthisdateformat = (depth) =>
  depth == "decade"
    ? "yyyy"
    : depth == "year"
    ? "MMM yyyy"
    : CALENDERS_DATEFORMATS[getUserDateTimeFormat()];

export default class DatePickerComponent extends Component {
  state = {
    value: null,
  };

  componentWillMount = () =>
    this.setState({ ...this.convertProps(this.props) });

  componentWillReceiveProps = (props) => {
    console.log("props: ", props);
    this.setState({ ...this.convertProps(props) });
  };

  convertProps = (props) => ({
    ...props,
    hideClearIcon:
      props.hideClearIcon || get(props.field, "hideClearIcon", "") || null,
    value: props.value ? convertToJSDateType(props.value) : this.state.value,

    format:
      props.customFormat ||
      getthisdateformat(props.depth && toLower(props.depth)),
    // maxDate: (props.max && convertToJSDateType(props.max)) || null,
    // minDate: (props.min && convertToJSDateType(props.min)) || null,
    view: (props.start && toLower(props.start)) || null,
    depth: (props.depth && toLower(props.depth)) || null,
    className: props.className || null,
    disabled: props.disabled,
    maxDetail: (props.depth && toLower(props.depth)) || null,
    minDetail: (props.top && toLower(props.top)) || null,
  });

  onChange = (date) => {
    const { change, onChange, hideClearIcon, value } = this.props;

    const updateParent = () => {
      change && change({ value: date });
      onChange && onChange({ value: date });
    };

    if (hideClearIcon && !date) {
      // unnecessary event prevented, when clearIcon is hidden
      return;
    } else if (!date) {
      // parent has not passed value, so update state first -> then update parent
      this.setState({ value: date }, () => updateParent());
      return;
    }

    // update parent
    updateParent();
  };

  calenderOpen = () => {
    const { props } = this;
    this.setState({
      maxDate: (props.max && convertToJSDateType(props.max)) || null,
      minDate: (props.min && convertToJSDateType(props.min)) || null,
    });
  };

  calenderClose = () => {
    this.setState({
      maxDate: null,
      minDate: null,
    });
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   return this.state.value != nextState.value;
  // }

  render() {
    const {
      className = "",
      width = null,
      hideClearIcon,
      value,
      format,
      maxDate,
      minDate,
      view,
      depth,
      disabled,
      maxDetail,
      minDetail,
    } = this.state;
    // return null;
    return (
      <div className={className} style={{ width }}>
        <DatePicker
          className="calenderTop"
          // {...this.state}
          value={value || null}
          format={format || null}
          maxDate={maxDate || null}
          minDate={minDate || null}
          view={view || "month"} // "month", "year", "decade", "century"
          depth={depth || "month"} // "month", "year", "decade", "century"
          disabled={disabled || null}
          onCalendarOpen={this.calenderOpen}
          onCalendarClose={this.calenderClose}
          maxDetail={maxDetail || "month"} // "month", "year", "decade", "century"
          minDetail={minDetail || "decade"} // "month", "year", "decade", "century"
          onChange={this.onChange}
          clearIcon={
            value && !hideClearIcon ? (
              <img
                src={ClearIcon}
                className="react-time-picker__clear-button__icon react-time-picker__button__icon"
              />
            ) : (
              ""
            )
          }
        />
      </div>
    );
  }
}

// export const YearPicker = (props) => {
//   return <DatePicker onClickYear={props.onClickYear} maxDetail="decade" {...props} />;
// };

// export const MonthPicker = (props) => {
//   return <DatePicker onClickMonth={props.onClickMonth} maxDetail="year" {...props} />;
// };
