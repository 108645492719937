// eslint-disable-next-line import/prefer-default-export
export const FEATUREKEYS = {
  // hris
  hris: 100,
  employeedata: 101,
  profile: 111,
  personalProfilePrivacy: 112,
  inviteEmployee: 113,
  whitelistMobileDevice: 123, // <- 132,
  livedata: 103, //  <-  150
  livedata_newemployee: 151,
  livedata_employeePersonalProfile: 152,
  livedata_employeeWorkProfile: 153,
  livedata_team: 154,
  livedata_grade: 155,
  livedata_managers: 156,
  livedata_approvalWorkflow: 157,
  livedata_selfService: 158,
  livedata_employeeID: 159,
  livedata_employementType: 160,
  livedata_reportingOffice: 161,
  livedata_defaultPermission: 162,
  livedata_permissionPolicy: 163,
  livedata_exitPolicy: 164,
  livedata_exitemployee: 165,
  livedata_expensePolicy: 351,
  livedata_attendanceregularization: 251,
  livedata_leaveadjustment: 252,
  livedata_bulkleaveapplication: 253,
  livedata_employeeDeviceMapping: 254,
  livedata_payrolltags: 361,
  livedata_extrapayment: 364,
  livedata_extradeduction: 363,
  livedata_bankdetail: 362,
  livedata_salarystructure: 367,
  livedata_payslip: 365,
  livedata_proration: 366,
  livedata_fnf: 368,
  livedata_statutory: 166,
  // livedata_attendanceregularise: 9000,
  planner: 104, //  <-  180
  planner_probation: 181,
  planner_designation: 182,
  planner_shift: 281, // <- 183,
  planner_extratime: 282, // <- 184,
  planner_leavePolicy: 283, // <- 185,
  planner_holiday: 284, // <- 186,
  planner_payroll: 381,
  // track
  track: 200,
  attendance: 201,
  attendanceLogs: 211,
  clockinRequest: 212,
  workRequest: 213,
  bulkRegularize: 215, // <- 212,
  autoRegularize: 216, // <- 213,
  deleteAttendancelogs: 217, // <- 214,
  updateAttendancelogs: 214, // <- 215,
  exportAttendancelogs: 218, // <- 216,
  geoTracking: 219, // <- 217,
  biometric: 202,
  biometricDevice: 220, // <- 218,
  exportbiometricLogsData: 221, // <- 219,
  exportbiometricMappingData: 222, // <- 220,
  leave: 203,
  leaveBalanceAndHistory: 223, // <- 222,
  annualLeaveHistory: 226, // <- 223,
  leaveLedger: 224,
  applyLeave: 225,
  holiday: 227, // <- 221,
  employeeID: 1101, // <- 121,
  probation: 1102, // <- 122,
  approvalWorkflow: 1105, // <- 123,
  globalProfilePrivacy: 1106, // <- 124,
  defaultPermission: 1107, // <- 125,
  permissionPolicy: 1108, // <- 126,
  customData: 1109,
  commonAccess: 1115,
  companyData: 102,
  businessUnit: 1201, // <- 127,
  department: 1202, // <- 128,
  designation: 1203, // <- 129,
  team: 1204, // <- 130,
  grade: 1205, // <- 131,
  directory: 114,
  companyAttributes: 115,
  employeeAttributes: 116,
  exportDirectoryData: 117,
  notifyEmployees: 118,
  requestCenter: 119,
  reportRobo: 120,
  reportingHierarchy: 125, // <- 133,
  shift: 2101, // <- 226,
  attendancePolicy: 2102, // <- 227,
  extratime: 2103, // <- 228,
  clockinMethod: 2104, // <- 229,
  requestCycle: 2105, // <- 230,
  leavePlan: 2201, // <- 231,
  leavePolicy: 2202, // <- 232,
  weeklyOff: 2203, // <- 233,
  holidayConfig: 2204, // <- 234,
  transition: 2205, // <- 235,
  exitPolicy: 1103, // <- 134,
  exitReasons: 1104,
  initiateResignation: 124, // <- 135,
  // pay
  pay: 300,
  expense: 301,
  expenseType: 3101,
  expenseCycle: 3102,
  compensator: 3104,
  expenseFuel: 3105,
  expensecurrency: 3106,
  expensePolicy: 3103,
  employeeExpense: 311,
  expenseRequest: 312,
  advanceRequest: 313,
  adminDashboard: 314,
  payroll: 302,

  incometax: 321,
  pkgproration: 322,
  payslip: 323,
  taxsheet: 324,
  salaryadvance: 325,
  loan: 326,
  extrapayment: 327,
  extradeduction: 328,
  fbp: 329,
  itdecsub: 330,
  form12: 331,
  form16: 332,
  overtimepayment: 333,
  annualpayslip: 334,
  payrolldashboard: 341,
  runpayroll: 342,
  variable: 343,
  appsalaryadvance: 344,
  apploan: 345,
  appextrapayment: 346,
  appextradeduction: 347,
  exception: 348,
  employeepayroll: 349,

  payrollmethod: 3201,
  salarycycle: 3202,
  misc: 3203,
  salarycomponent: 3204,
  salarystructure: 3205,
  configfbp: 3206,
  configtag: 3207,
  bankdetails: 3209,
  signatory: 3208,
  fnfconfig: 3210,
  extrapaymentpolicy: 3211,

  // resolve
  resolve: 400,
  policyCenter: 401,
  configChecklistRoot: 402,
  checklist: 421,
  configChecklist: 4201,

  hrmsPolicyCenter: 412,
  employeePolicyCenter: 411,
  configPolicyCenter: 4101,

  // organize
  organise: 500,
  drive: 501,
  letters: 502,
  assetManager: 503,
  myDrive: 511,
  companyDrive: 512,
  uploadFile: 513,
  createfolder: 514,
  share: 515,
  pagelayout: 5201,
  templates: 5202,
  signatories: 5203,
  postoffice: 521,
  letterbox: 522,
  // Below Keys are created for new Navbar and sidebar menus
  more: -99,
  Home: -100,
  Policies: -101,
  dailyActivites: -102,
  Configuration: -103,
  // new keys
  // policyCenter: 9000,

  // Perform
  perform: 600,
  degree: 601,
  okr: 602,
  performcycle: 6101,
  remainder: 6102,
  ratingscale: 6103,
  competency: 6104,
  reviewstructure: 6105,
  reviewform: 6106,
  okrconfig: 6201,
  Successunit: 6202,
  zero: 611,
  epdegree: 612,
  managefrom: 613,
  employeereview: 614,
  employeeokr: 621,
  okradmin: 615,

  // account config
  accountManagement: 105,
  globalDefault: 1110,
  interfaceBranding: 1111,
  emailBranding: 1112,
  companyOffices: 1113,
  incorporationDetails: 1114,

  // recruit config
  recruit: 700,
  ATS: 701,
  recruiters: 7101,
  careerPage: 7102,
  pipelineEmailTemplates: 7103,
  pipeline: 7104,
  screeningQuestions: 7105,
  jobTemplates: 7106,
  candidateSources: 7107,
  hrops: 800,
  asset: 801,
  assetAssignment: 811,
  assetCategory: 8101,
  helpdeskEmployee: 821,
  helpdesk: 802,
  ticketCategory: 8201,
  cannedResponses: 8202,
};
