export const InitialData = {
  ...{
    leavepolicyid: null,
    leavepolicyname: null,
    description: null,
    isdraft: true,
    details: [
      {
        leavetypeid: 4,
        leavetypename: 'Unpaid Leave',
        leavecategoryid: 1,
        leavepolicydetailid: null,
      },
    ],
    policy: [],
    isviewmode: false,
  },
};
