import createMuiTheme from "@material-ui/core/styles/createTheme";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#166ab3" }, // Purple and green play nicely together.
    secondary: { main: "#000000" },
    background: { main: "#fffffff" }, // This is just green.A700 as hex.
  },
  typography: {
    fontFamily:
      '"Muli", "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        // backgroundColor: "#fff",
        backgroundColor: "#010709",
        height: 16,
        fontWeight: "bold",
        // color: "010709",
        color:"#fff",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
        // border: "1px solid #d9dadb",
      },
    },
    MuiInputBase: {
      // inputMarginDense:{
      //   padding:"11px 0px 10px 0px !important"
      // },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiOutlinedInput: {
      multiline: {
        padding: 10,
      },
      input: {
        padding: 10,
        // width: "96%",
      },
    },
  },
});

export default theme;
