import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = () => (<ContentLoader
    height={800}
    width={1280}
    speed={1}
    interval={1}
    primaryColor="#e8e8e8"
    secondaryColor="#f0f0f0"
  >
    <rect x="40" y="15" rx="10" ry="10" width="167" height="28" />
    <circle cx="292" cy="30" r="14" />
    <circle cx="372" cy="30" r="14" />
    <circle cx="451" cy="30" r="14" />
    <circle cx="531" cy="30" r="14" />
    <circle cx="611" cy="30" r="14" />
    <circle cx="691" cy="30" r="14" />
    <rect x="21" y="102" rx="10" ry="10" width="40" height="40" />
    <rect x="80" y="112" rx="10" ry="10" width="125" height="19" />
    <rect x="80" y="242" rx="10" ry="10" width="125" height="19" />
    <rect x="21" y="232" rx="10" ry="10" width="40" height="40" />
    <rect x="80" y="296" rx="10" ry="10" width="125" height="19" />
    <rect x="80" y="352" rx="10" ry="10" width="125" height="19" />
    <rect x="80" y="408" rx="10" ry="10" width="125" height="19" />
    <rect x="21" y="286" rx="10" ry="10" width="40" height="40" />
    <rect x="21" y="342" rx="10" ry="10" width="40" height="40" />
    <rect x="21" y="398" rx="10" ry="10" width="40" height="40" />
    <rect x="21" y="737" rx="10" ry="10" width="209" height="49" />
    <circle cx="43" cy="710" r="12" />
    <circle cx="98" cy="710" r="12" />
    <circle cx="153" cy="710" r="12" />
    <circle cx="208" cy="710" r="12" />
    <circle cx="1248" cy="30" r="16" />
    <circle cx="1197" cy="30" r="16" />
    <rect x="282" y="95" rx="10" ry="10" width="980" height="133" />
    <rect x="282" y="240" rx="10" ry="10" width="485" height="133" />
    <rect x="777" y="240" rx="10" ry="10" width="485" height="133" />
    <rect x="282" y="385" rx="10" ry="10" width="980" height="30" />
    <rect x="282" y="427" rx="10" ry="10" width="980" height="243" />
  </ContentLoader>
);

export default Loader;
