import { ReducersTypes } from '../Constants/ReducersTypes';

const INITIAL_STATE = {
  selectedFilters: [],
};

export default function FilterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
  case ReducersTypes.SETSELECTEDFILTERS:
    // console.log("action.payload");
    return { ...state, selectedFilters: action.payload };
  case ReducersTypes.REMOVESELECTEDFILTERS:
    return { ...state, selectedFilters: [] };
  default:
    return state;
  }
}
