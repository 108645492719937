import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { combineReducers } from "redux";
import PageNotFound from "../../../Component/NotFound/index";
import BusinessUnit from "./company/business-unit";
import Department from "./company/department";
import Designation from "./company/designation";
import Grade from "./company/grade";
import company from "./company/reducers";
import Team from "./company/team";
import ProbationPlan from "./employee/probation-plan";
import employee from "./employee/reducers";

export const businessConfigReducers = combineReducers({ company, employee });

const BusinessUnitRoutes = (props) => {
  const { match } = props;
  console.log('match: ', match);
  return (
    <Switch>
      <Route path={`${match.path}/designation`} component={Designation} />
      <Route path={`${match.path}/department`} component={Department} />
      <Route path={`${match.path}/team`} component={Team} />
      <Route path={`${match.path}/grade`} component={Grade} />
      <Route path={`${match.path}/business_unit`} component={BusinessUnit} />
      {/* <Route path={`${match.path}/probation`} component={ProbationPlan} /> */}
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default withRouter(BusinessUnitRoutes);

// export default BusinessUnitRoutes;
