import React from "react";
import TextField from "@material-ui/core/TextField";
import "../../Assets/SCSS/abstracts/inputs.scss";

export const MuiTextField = (props) => (
  <div className="dynamic-form-number-input">
    <TextField {...props}
      inputProps={{ min: props.field && props.field.min ? props.field.min : 0, max: props.field && props.field.max ? props.field.max : 0 }}
    />
  </div>
);

export default MuiTextField;
