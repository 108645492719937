import React from "react";
import TextField from "@material-ui/core/TextField";
import "../../Assets/SCSS/abstracts/inputs.scss";

export const MuiTextField = (props) => (
  <div className="dynamic-form-number-input">
    <TextField {...props} />
  </div>
);

export default MuiTextField;
