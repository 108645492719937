import Cookies from "universal-cookie";
import Configs from "../Constants/Configs";
import { getFormattedLogoLink } from "../Utils";
import { getSubdomain } from "./getters";

export const setAppFavicon = (favicon) => {
  // set app favicon
  const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = getFormattedLogoLink(favicon);
  document.getElementsByTagName("head")[0].appendChild(link);
};

export const backToHome = () => {
  const URL = `${window.location.origin}`;
  window.open(URL, "_self");
};

export function removeCookie(cname) {
  const cookies = new Cookies();
  return cookies.remove(cname) || null;
}

export function setCookie(cname, value) {
  const cookies = new Cookies();
  cookies.remove(cname);
  const URL = `.${Configs.DOMAIN}`;
  cookies.set("domain", URL, { path: "/" });
  cookies.set(cname, value, { path: "/", domain: URL, maxAge: 5 * 60 });
}

export function setFullStory(accountid) {
  if (!accountid || (Configs.MODE == "DEV" && !Configs.FULLSTORY_IDS.includes(accountid))) {
    return;
  }
  window._fs_host = "fullstory.com";
  window._fs_script = "edge.fullstory.com/s/fs.js";
  window._fs_org = "M5VDD";
  window._fs_namespace = "FS";
  (function (m, n, e, t, l, o, g, y) {
    if (e in m) {
      if (m.console && m.console.log) {
        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
      }
      return;
    }
    g = m[e] = function (a, b, s) {
      g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
    };
    g.q = [];
    o = n.createElement(t);
    o.async = 1;
    o.crossOrigin = "anonymous";
    o.src = `https://${window._fs_script}`;
    y = n.getElementsByTagName(t)[0];
    y.parentNode.insertBefore(o, y);
    g.identify = function (i, v, s) {
      g(l, { uid: i }, s);
      if (v) g(l, v, s);
    };
    g.setUserVars = function (v, s) {
      g(l, v, s);
    };
    g.event = function (i, v, s) {
      g("event", { n: i, p: v }, s);
    };
    g.shutdown = function () {
      g("rec", !1);
    };
    g.restart = function () {
      g("rec", !0);
    };
    g.log = function (a, b) {
      g("log", [a, b]);
    };
    g.consent = function (a) {
      g("consent", !arguments.length || a);
    };
    g.identifyAccount = function (i, v) {
      o = "account";
      v = v || {};
      v.acctId = i;
      g(o, v);
    };
    g.clearUserCookie = function () {};
  }(window, document, window._fs_namespace, "script", "user"));
}
