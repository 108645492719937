const TEAM = 'team';
const DEPARTMENT = 'department';
const DESIGNATION = 'designation';
const GRADE = 'grade';
const BUSINESS_UNIT = 'business_unit';

export default {
  TEAM,
  DEPARTMENT,
  DESIGNATION,
  GRADE,
  BUSINESS_UNIT,
};
