import React from "react";
import Radio from "@material-ui/core/Radio";
import "../../Assets/SCSS/abstracts/inputs.scss";

export const MuiTextField = (props) => (
  <div className="dymanic-form-radio">
    <Radio {...props} />
  </div>
);

export default MuiTextField;
