import { instance } from "../../../../../Actions/AxiosInstance";
import ActionConstants from "../constants";

export const getDesignationList = () => ({
  type: ActionConstants.DESIGNATION,
  payload: instance.get("subscription/alldesignationlist"),
});

export const getDepartmentList = () => ({
  type: ActionConstants.DEPARTMENT,
  payload: instance.get("subscription/alldepartmentlist"),
});

export const getTeamList = () => ({
  type: ActionConstants.TEAM,
  payload: instance.get("subscription/allteamlist"),
});

export const getBusinessUnitList = () => ({
  type: ActionConstants.BUSINESS_UNIT,
  payload: instance.get("subscription/allbusinessunitlist"),
});

export const updateRespectiveList = (action, data) => ({
  type: `${action}_UPDATE`,
  payload: data,
});

export const getGradeList = () => ({
  type: ActionConstants.GRADE,
  payload: instance.get("subscription/allgrade"),
});

export const addDepartment = (data) => ({
  type: ActionConstants.DEPARTMENT,
  payload: instance.post("superuser/getalllead", data),
});

export const addDesignation = (data) => ({
  type: ActionConstants.DESIGNATION,
  payload: instance.post("superuser/getalllead", data),
});
