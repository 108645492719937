import React from "react";
import "../../Assets/SCSS/components/Pageheader.scss";

const sectionHeaderExtraSmall = ({ header = "", classnames = "" }) => (
  <div className={`section-header-outer ${classnames}`}>
    <span className="section-header-extra-small">{header}</span>
  </div>
);

export default sectionHeaderExtraSmall;
