import get from "lodash/get";
import React from "react";
import Dropdown from "../../Material-ui/Dropdown";
import initjson from "../Data/initjson";
import KEYS from "../KEYS";

export const Component = (props) => {
  const [focused, setFocused] = React.useState(false);

  const { field, updateData } = props;
  const {
    value = "",
    iserr = function () {},
    placeholder = "Select",
    label = "Select",
    helperText = "",
    options = [],
    disabled = false,
  } = field;

  function handleChange(selectedOption) {
    updateData({ id: field.id, value: selectedOption.displayvalue });
  }

  const selectedOption =
    options && options.find((opt) => opt.displayvalue == value);

  return (
    <div>
      {label && <p className="input-label">{label}</p>}
      <Dropdown
        className="width100"
        getOptionLabel={(option) => option.displayname}
        getOptionValue={(option) => option.displayvalue}
        variant="outlined"
        options={options}
        onChange={handleChange}
        placeholder={placeholder}
        error={iserr({ fielddata: field })}
        helperText={focused && helperText}
        isDisabled={disabled}
        {...props}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={selectedOption || null}
      />
    </div>
  );
};

export default Component;

export const selectinputconverter = (field) => [
  {
    ...initjson,
    ...field,
    typeid: KEYS.Select,
  },
];
