import get from "lodash/get";
import typeToReducer from "type-to-reducer";
import { ReducersTypes } from "../Constants";
import { instance } from "./AxiosInstance";

const initialState = { IsFulfilled: false, IsError: false, Language: {} };

export const ChangeLanguage = (module) => ({
  type: ReducersTypes.LANGUAGE,
  payload: instance.get(`language/${module}/${localStorage.getItem("lang") ? localStorage.getItem("lang") : "english"}`),
});

// const Language = typeToReducer(
//   {
//     [ReducersTypes.LANGUAGE]: {
//       PENDING: () => ({
//         ...state
//       }),
//       FULFILLED: (state, action) => ({
//         ...state
//       }),
//       REJECTED: (state, action) => ({
//         ...state
//       })
//     }
//   },
//   state
// );

const Language = typeToReducer(
  {
    [ReducersTypes.LANGUAGE]: {
      PENDING: () => ({
        ...initialState,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        IsFulfilled: true,
        Language: action.payload.data.result,
      }),
      REJECTED: (state, action) => ({
        ...state,
        IsError: true,
        ErrorMessage: action.payload.response ? get(action.payload, "response.data.error.message", "") : action.payload.message,
      }),
    },
  },
  initialState,
);

export default Language;
