import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
import permissionDeniedImg from "Assets/Svg/permission_denied.svg";

export default function PermissionDeniedComp(props) {
  const { heading, subheading } = props;
  return (
    <Grid
      container
      className="empty-state-illustration"
      style={{
        marginTop: window.location.pathname == "/app/track/attendance" ? 0 : 50,
      }}
    >
      <Grid container className="padding0 text-center">
        <div className="width100">
          <img src={permissionDeniedImg} height="300" width="300" />

          <div className="headline-h3 margin-bottom-8 margin-top-24 text-align-center">
            Permission denied
          </div>
          <div className="normal-dark-secondary">
            You are not authorized to access this page
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

PermissionDeniedComp.propTypes = {
  text: PropTypes.string.isRequired,
};

PermissionDeniedComp.defaultProps = {};
