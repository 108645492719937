/* eslint-disable import/prefer-default-export */
import React from "react";
import { FormattedMessage } from "react-intl";

/**
 * Error messages.
 * @module Errors
 * 0000 - 1000: Common errors
 * 1000 - 5000: HRIS App errors
 * 8000 - 8990: Accounts App errors
 * 9000 - 9999: Superuser App erros
 * Note: increase with 10
 */

export const ErrorMessages = {
  E0000: "Saved successfully",
  E0010: "Please fill valid data",
  E0020: "Please fill all required fields",
  E0030: "Something went wrong...",
  E0040: "Request sent",
  E0050: "Data uploaded successfully",
  E0060: "Data upload failed",
  E0070: "Invalid file",
  E0080: "Exceeding maximum days limit",
  E0090: "Are you sure you want to logout?",
  E0100: "Link Expired",
  E0110: "Already exist",
  E0120: "Deleted successfully",
  E0130: "Please select all required fields",
  E0140: "Are you sure? You will lose all unsaved data, if you cancel.",
  // HRIS
  E1000: "Please enter policy name",
  E1020: "Similar request already added",
  E1030: "Out time can not be more than in time",
  E1040: "Start date and end date are compulsory",
  E1050: "Please select leave",
  E1060: "Exit reason is in use",
  E1070: "Are you sure you want to change the state?",
  E1080: "Policy not assigned",
  E1090: "Policy name already exist",
  E1200: "Policy is in use",
  E1210: "Employee exists with this data",
  E1230: "Please select leave policy",
  E1240: "The uploaded file is not valid for this leave policy",
  E1250: "This report cannot exceed more than 90 days",
  E1260: "Please assign shift for this date",
  E1270: "Please complete current task",
  E1280: "Please add comment",
  E1290: "Your log time is beyond shift time",
  E1300: "Please enter name",
  E1310: "Please enter business unit head",
  E1320: "Please enter statutory details",
  E1330: "Please enter early payroll days",
  E1340: "Please select device or device group",
  E1350: "Type missmatches",
  E1360: "Please select any time other than 12:00 AM",
  E1370: "Please answer all required questions",
  E1380: "Please fill custom effective paid days",
  E1390: "Please select at least one exclusion",
  E1400: "Please select at least one employee",
  E1410: "Let's configure payroll first",
  E1420: "Please select fallback action",
  E1430: "You can either keep accrural or variable",
  E1440: "Are you sure you want to delete?",
  E1450: "Please create basic pay salary component",
  E1460: "There are no components remaining to choose",
  E1470: "Please add at least one component with gross",
  E1480: "Please create at least one deduction component",
  E1490: "Data is similar to previous assignment",
  E1500: "Please select valid effective from date",
  E1510: "No logs found",
  E1520: "This report cannot exceed more than 365 days",
};

// **********
// Note: Please replace below sytax with all when we are going to use multiple languages
// <FormattedMessage id="#" defaultMessage="Saved successfully" />,
// **********

// // kept for referanace

// export const ErrorMessagesPrev = {
//   imgext: "png,jpg/jepg,svg only allowed",
//   logo: "png,jpg/jepg,svg only allowed",
//   favicon: "png,jpg/jepg,svg only allowed",
//   bgimage: "png,jpg/jepg,svg only allowed",
//   empty: "can not be empty",
//   officeaddressid: "select office",
//   genderid: "select gender",
//   textPattern: "can not accept only space ",
//   urlPattern: "invalid url",
//   Country: "Country should not be empty form constant",
//   companyname: "Companyname shoudnot be empty frm const",
//   emailid: "Are you sure you've entered a correct email? Please re check 🙏",
//   emailPattern: "Are you sure you've entered a correct email? Please re check 🙏",
//   password: "The password you used during signup goes here 🔑",
//   PasswordLength: "Minimum 8 characters, please 🙏",
//   mobilenumber: "mobile can not be empty 😔 ",
//   mobilenumberlength: "mobile should be less than 13 ",
//   Postal: "postal code should not be empty",
//   locationname: "locationname can not be empty",
//   firstname: "firstname can not be empty",
//   lastname: "lastname can not be empty",
//   addressline1: "addressline1 can not be empty",
//   doj: "invalid doj",
//   countryid: "please select country",
//   stateid: "please select state",
//   cityid: "please select city",
//   countrytimezoneid: "please select country time zone",
//   financialcycleid: "please select financial cycle",
//   leavecycleid: "please select leavecycle",
//   dateformatid: "please select dateformat",
//   subscriptionname: "subscriptionname can not be empty",
//   subdomain: " subdomain can not be empty",
//   header: " header can not be empty",
//   subheader: " subheader can not be empty",
//   sendername: " sendername can not be empty",
//   senderemail: " senderemail can not be empty",
//   replytoemail: " replytoemail can not be empty",
//   sendersignature: " sendersignature can not be empty",
// };
