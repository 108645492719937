import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import priorityHighIcon from "Assets/newicons/notify/priority/glyph/priority-high-glyph-16-px.svg";
import priorityLowIcon from "Assets/newicons/notify/priority/glyph/priority-low-glyph-16-px.svg";
import priorityMedIcon from "Assets/newicons/notify/priority/glyph/priority-med-glyph-16-px.svg";

// import AccountBalance from "@material-ui/icons/AccountBalance";
// import Done from "@material-ui/icons/Done";
import FourKIcon from "@material-ui/icons/FourK";
import SearchIcon from "@material-ui/icons/Search";
import classNames from "classnames";
import get from "lodash/get";
import Moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  setPreview,
  RightDrawer,
} from "../../../../../../../Actions/commonAction";
import biometric from "../../../../../../../Assets/Icons/Biometric/biometric-outline-16px.svg";
import desktop from "../../../../../../../Assets/Icons/Web/web-16px.svg";
import mobileIcon from "../../../../../../../Assets/Icons/Mobile/mobile-16px.svg";
import DefaultProfileImage from "../../../../../../../Assets/Images/profile_defaultImg.svg";
import teaBreak from "../../../../../../../Assets/newicons/object/tea-cup/outline/tea-cup-outline-16px.svg";
import checkRoundGreen from "../../../../../../../Assets/newicons/notify/check-round/glyph/check-round-outline-green-16px.svg";
import If from "../../../../../../../Component/Common/If";
import DrawerHeader from "../../../../../../../Component/Headers/drawerHeader";
import createstore from "../../../../../../../store";
import {
  getFormattedDate,
  getFormattedDateTime,
  getFormattedImageLink,
  getFormattedTime,
  getMonthName,
  getTime,
} from "../../../../../../../Utils";
import "../../../styles/index.css";
import TDTypeConsts from "./constants";
import "./index.style.css";
import Style from "./styles";
import previewIcon from "../../../../../../../Assets/newicons/user-interface/eye-show/outline/eye-show-outline-16px.svg";
import previewIconWhite from "../../../../../../../Assets/newicons/user-interface/eye-show/outline/eye-show-outline-16px-white.svg";
import uploadIcon from "../../../../../../../Assets/newicons/action/upload/outline/upload-outline-16px.svg";
import editIcon from "../../../../../../../Assets/newicons/action/edit/outline/edit-outline-16px.svg";
import duplicate from "../../../../../../../Assets/newicons/action/copy/outline/copy-outline-16-px.svg";
import deleteIcon from "../../../../../../../Assets/newicons/action/delete-bin/outline/delete-bin-outline-16px.svg";

import kebab from "../../../../../../../Assets/newicons/user-interface/kebab/glyph/kebab-glyph-16px.svg";
import tandoor from "../../../../../../../Assets/newicons/user-interface/tandoor/glyph/tandoor-glyph-16px.svg";
import yellowdot from "../../../../../../../Assets/Svg/yellow-dot.svg";
import bluedot from "../../../../../../../Assets/Svg/blue-dot.svg";
import reddot from "../../../../../../../Assets/Svg/red-dot.svg";
import greendot from "../../../../../../../Assets/Svg/greendot.svg";
import pending from "../../../../../../../Assets/Svg/pending.svg";
import { checkboxinputconverter } from "Component/DynamicForm/Fields/CheckBox";
import DynamicForm from "Component/DynamicForm";

const styles = () => Style;
class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionidList: "",
      shown: false,
      anchorEl: [],
      SelectedLeaveIndex: -1,
      open: false,
      draweropen: false,
      selectedUser: null,
      selectedPolicy: null,
      selectedDocUrl: "",
    };
    this.setSelectedPolicy = this.setSelectedPolicy.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.openDoc = this.openDoc.bind(this);
  }

  componentWillMount() {
    const { SubscriptionInfo } = createstore.getState();
    const DateFormat = get(
      SubscriptionInfo,
      "SubscriptionData.dateformatname",
      " ",
    );
    let ShortDateFormat = DateFormat.split(" ");
    ShortDateFormat = ShortDateFormat[0];
    this.setState({ ShortDateFormat });
  }

  // leaves policy status
  handleClick = (SelectedLeaveIndex, event) => {
    // let name = `anchorEl${SelectedLeaveIndex}`
    // this.setState({ anchorEl: event.currentTarget, SelectedLeaveIndex });
    // this.setState({ [name]: event.currentTarget });
    const { anchorEl } = this.state;
    anchorEl[SelectedLeaveIndex] = event.currentTarget;
    this.setState({ anchorEl });
  };

  handleClose = (SelectedLeaveIndex) => {
    const { anchorEl } = this.state;
    anchorEl[SelectedLeaveIndex] = null;
    this.setState({ anchorEl });
  };

  toggle() {
    this.setState({
      shown: !this.state.shown,
    });
  }

  onDelete = (index) => {
    const { onDelete } = this.props;
    onDelete(index);
  };

  onDelete1 = (index) => {
    const { onDelete1 } = this.props;
    onDelete1(index);
  };

  onMoreOptionClick = (e, row) => {
    const { onMoreOptionClick } = this.props;
    onMoreOptionClick(e, row);
  };

  toggleAssginedOwnerDrawer = (e, row) => {
    const { toggleAssginedOwnerDrawer } = this.props;
    toggleAssginedOwnerDrawer(e, row);
  };

  boolRenderMoreOptionInList = (module, row, isdate = null) => {
    let isRender = true;
    switch (module) {
      case "variable":
        isRender =
          isdate &&
          row.isactionallowed &&
          row.isrunpayroll &&
          [1, 3, 6].some((varStatusId) => row.variablestatusid == varStatusId);
        break;
      case "vpf":
        isRender = row.vpfstatus != 3 && row.isallowvpf == true;
        break;
      case "mail templates":
        isRender = row.isactive;
        break;
      case "job templates":
        isRender = row.isactive;
        break;
      case "requisition":
        isRender = row.isactive;
        break;
      default:
        break;
    }

    return isRender ? true : false;
  };

  onMoreleaveOptionClick = (e, row) => {
    const { onMoreleaveOptionClick } = this.props;
    onMoreleaveOptionClick(e, row);
  };

  onViewClick = (e, row) => {
    const { onViewClick } = this.props;
    onViewClick(e, row);
  };

  onRemindClick = (e, row) => {
    const { onRemindClick } = this.props;
    onRemindClick(e, row);
  };

  onLogsClick = (e, row) => {
    const { onLogsClick } = this.props;
    onLogsClick(e, row);
  };

  __renderLinkedUser = (e, row) => {
    const { __renderLinkedUser } = this.props;
    __renderLinkedUser(e, row);
  };

  _onVersionChange = (parentid) => {
    const { onVersionChange } = this.props;
    onVersionChange(parentid);
  };

  onEdit = (index, edit) => {
    const { onEdit } = this.props;
    onEdit(index, edit);
  };

  onEditAssignment = (index, edit) => {
    const { onEditAssignment } = this.props;
    onEditAssignment(index, edit);
  };

  onGroupClicked = (row) => {
    const { onGroupClicked } = this.props;
    onGroupClicked(row);
  };

  onVersionEdit = (index, edit) => {
    const { onVersionEdit } = this.props;
    onVersionEdit(index, edit);
  };

  searchChange = (event) => {
    const { onSearch } = this.props;
    if (onSearch) onSearch(event.target.value.toLowerCase());
  };

  goTo(empId) {
    const { goTo } = this.props;
    if (goTo) goTo(empId);
  }

  // Does the intial search for the total number of results....
  renderSearch = () => {
    const {
      classes,
      onCreate,
      list,
      module,
      showYearDropdown,
      yearList,
      holidayyear,
      headers,
      createbuttonName,
    } = this.props;
    return (
      <Grid
        container
        className={`${classes.tableSearch} table-search-container`}
        style={{ padding: "0px 0px 0px 0px" }}
      >
        <Grid
          container
          className="margin-bottom-16"
          style={
            this.props.module == "Leaves version policie"
              ? { position: "relative" }
              : {}
          }
        >
          <Grid item xs={12} md={4} sm={4}>
            {/* {!this.props.hidecount && (
              <Grid item style={{ paddingBottom: 5 }}>
                <Typography
                  component="p"

                  className={`${classes.noOfResults} noOfResults`}
                  style={{ marginRight: 25 }}
                >
                  {`Showing all ${list && list.length} ${module}s`}
                </Typography>
              </Grid>
            )} */}
            <div
              style={
                this.props.module == "Leaves version policie"
                  ? { position: "absolute", right: 220 }
                  : {}
              }
            >
              {this.props.module != "Signing Authoritie" &&
              this.props.module != "Optinal Holiday" &&
              this.props.module != "Mandatory Holiday" &&
              this.props.module != "Assets" &&
              this.props.module != "payroll draft" ? (
                <span>
                  <span
                    className="t-16"
                    style={{ color: "rgba(0,0,0,0.87)", marginRight: 4 }}
                  >
                    Sort By:
                  </span>
                  <Select
                    name="age"
                    displayEmpty
                    value={this.props.orderBy}
                    onChange={(e) => {
                      this._renderSort(e.target.value);
                    }}
                    className="text-color-grey select-sort"
                    variant="outlined"
                  >
                    <MenuItem className="sort-item" value={"sortableDate"}>
                      Created Date
                    </MenuItem>
                    {headers
                      .filter(
                        (x) =>
                          x.key != "edit" &&
                          x.key != "delete" &&
                          (this.props.module == "Payroll tag"
                            ? x.key != "tagdatatypename"
                            : true) &&
                          (this.props.module == "policy center"
                            ? x.key != "policydescription"
                            : true),
                      )
                      .map((header) => {
                        if (
                          header.key !== "addedbyimagepath" &&
                          header.key !== "assignedEmployeeList" &&
                          header.key !== "policydescription" &&
                          header.key !== "moreoptions"
                        ) {
                          return (
                            <MenuItem value={header.key}>
                              {get(header, "name", "-")}
                            </MenuItem>
                          ); // .props.defaultMessage
                        }
                        return null;
                      })}
                  </Select>
                </span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Grid
              container
              alignItems="flex-start"
              justify="flex-end"
              direction="row"
            >
              {showYearDropdown == true ? (
                <div className="margin-right-16 floatleft">
                  <select
                    className="table-date-dropdown width100"
                    onChange={(listItem) => this.props.updateYear(listItem)}
                    // value={holidayyear}
                  >
                    {yearList.map((listItem) => (
                      <option
                        key="key"
                        value={listItem.displayname}
                        selected={listItem.displayname == holidayyear}
                      >
                        {listItem.displayname}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                ""
              )}
              {this.props.module != "Optional Holiday" &&
              this.props.module != "Mandatory Holiday" &&
              this.props.module != "payroll draft" ? (
                <React.Fragment>
                  <div className="floatleft margin-right-16">
                    <FormControl
                      className={`${classes.search} searchBox`}
                      style={
                        this.props.module == "Leaves version policie"
                          ? { position: "absolute", right: 0, width: 180 }
                          : this.props.module == "Assets"
                          ? { position: "Relative", right: 240 }
                          : {}
                      }
                    >
                      <Input
                        style={{ height: 35 }}
                        onChange={this.searchChange}
                        id="input-with-icon-adornment"
                        placeholder="Search"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon color="disabled" />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <Grid item>
              {this.props.permissions &&
              this.props.permissions.editable &&
              this.props.permissions.editable &&
              onCreate ? (
                <Button
                  variant="contained"
                  color="primary"
                  className="btn btn-primary-md-blue width100"
                  onClick={onCreate}
                >
                  {createbuttonName || (
                    <FormattedMessage id="BtCreate" defaultMessage="create" />
                  )}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          {!this.props.hidecount && (
            <Grid item style={{ paddingBottom: 5 }}>
              <Typography
                component="p"
                className={`${classes.noOfResults} noOfResults`}
                style={{ marginRight: 25 }}
              >
                {`Showing all ${list && list.length} ${module}s`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  getPlaceHolder(data, classes, appendText) {
    return (
      <Typography
        component="p"
        className={classNames("bank-details-viewpage t-14", classes)}
        // style={{ textAlign: "left" }}
      >
        {data} {appendText}
      </Typography>
    );
  }

  getPlaceHolderHolidaydate(data, classes, appendText) {
    return (
      <Typography
        component="p"
        className={classNames(
          "bank-details-viewpage t-14 text-align-center",
          classes,
        )}
        // style={{ fontSize: 14, paddingTop: 10, textAlign: "center" }}
      >
        {data} {appendText}
      </Typography>
    );
  }

  DisplayHolidaySelected(data, classes, style) {
    let status;
    data == true ? (status = "Yes") : (status = "No");
    return (
      <Typography
        component="p"
        className={classNames(
          "bank-details-viewpage text-align-center",
          classes,
        )}
        style={{ ...style, textAlign: "center" }}
      >
        <span style={{ fontWeight: status == "Yes" && "bold" }}>{status}</span>
      </Typography>
    );
  }

  getPlaceHolderFallback(data, classes, appendText, style) {
    return (
      <React.Fragment>
        {data.map((date) => {
          if (date != "") {
            return (
              <Typography
                component="p"
                className={classNames("bank-details-viewpage", classes)}
                style={style}
              >
                {date} {appendText}
              </Typography>
            );
          }
          return null;
        })}
      </React.Fragment>
    );
  }

  getPlaceHolderEffectivefrom(data, classes, appendText, style) {
    let dates = [];
    let fromdate = "";
    let todate = "";

    return (
      // <Typography component="p" className={classNames("bank-details-viewpage", classes)} style={style}>
      <React.Fragment>
        {data.map((date) => {
          if (date != "") {
            dates = date.split(" - ");
            fromdate = this.getDate(dates[0]);
            if (dates[1] != "Present" && dates[1] != "") {
              todate = this.getDate(dates[1]);
            } else {
              todate = dates[1];
            }

            return (
              <Typography
                component="p"
                className={classNames("bank-details-viewpage", classes)}
                style={style}
              >
                {fromdate} - {todate} {appendText}
              </Typography>
            );
          }
          return null;
        })}
      </React.Fragment>
    );
  }

  getPlaceHolderDefaultEffectivefrom(data, classes, appendText, style) {
    let dates = [];
    let fromdate = "";
    let todate = "";
    dates = data.split(" - ");
    fromdate = this.getDate(dates[0]);
    if (dates[1] != "Present" && dates[1] != "") {
      todate = this.getDate(dates[1]);
    } else {
      todate = dates[1];
    }

    return (
      <Typography
        component="p"
        className={classNames("bank-details-viewpage", classes)}
        style={style}
      >
        {fromdate} - {todate} {appendText}
      </Typography>
    );
  }

  getPlaceHolderWithPrefixIcon(data, classes) {
    return (
      <Typography component="p" className={classes}>
        {/* {Icon} {data} */}
      </Typography>
    );
  }

  _renderSort = (key) => {
    this.props.handleSort(key);
  };

  createSortHandler = (property) => (event) => {
    this.props.handleSort(property, event);
  };

  getDate(dataString) {
    return getFormattedDateTime(dataString);
  }

  _renderTableHeaders = (headers) => {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow className={classes.listHead}>
          {headers.map((header) => (
            <TableCell
              key={header.key}
              style={header.style}
              {...header.extraProps}
            >
              <span
                className={`table-header ${
                  header.key === "policystatus" ? "paddingleft27" : ""
                }`}
              >
                {header.name}
              </span>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  _renderDesignationTD = (row, key) => {
    const { classes } = this.props;
    return (
      <div>
        {this.getPlaceHolder(row[key], classes.name)}
        {this.getPlaceHolder(this.getDate(row.addeddate), classes.date)}
        <Typography
          component="p"
          className={`${classes.date} ${classes.paddingTop7}`}
        >
          <FourKIcon className={classes.iconAndText} /> Engineering
        </Typography>
      </div>
    );
  };

  _renderDepartmentTD = (row, key) => {
    const { classes } = this.props;
    const Icon = <FourKIcon className={classes.iconAndText} />;
    return (
      <div>
        {this.getPlaceHolder(row[key], classes.name)}
        {this.getPlaceHolder("26 NOV 2016", classes.date)}
        {this.getPlaceHolderWithPrefixIcon(
          "Mumbai",
          `${classes.date} ${classes.paddingTop7}`,
          Icon,
        )}
      </div>
    );
  };

  _renderBusinessUnitTD = (row, key) => {
    const { classes } = this.props;
    // const Icon = <AccountBalance className={classes.iconAndText} />;

    return (
      <div>
        {this.getPlaceHolder(row[key], classes.name)}
        {this.getPlaceHolder(this.getDate(row.addeddate), classes.date)}
        {/* {this.getPlaceHolderWithPrefixIcon(
          row[extraKey],
          `${classes.date} ${classes.paddingTop7}`,
          Icon,
        )} */}
      </div>
    );
  };

  _renderClickableName = (row, key) => {
    const { classes, onNameClick } = this.props;
    // const Icon = <AccountBalance className={classes.iconAndText} />;

    return (
      <div className="cursour-pointer" onClick={() => onNameClick(row)}>
        {this.getPlaceHolder(row[key], classes.name)}
        {/* {this.getPlaceHolder(this.getDate(row.addeddate), classes.date)} */}
        {row.isadhoc == true && (
          <p
            style={{
              padding: "2px 8px",
              borderRadius: 4,
              backgroundColor: "#f5e9fe",
              color: "#921eed",
              margin: 0,
              marginTop: 8,
              fontSize: 12,
              display: "inline",
            }}
          >
            Modified
          </p>
        )}
      </div>
    );
  };

  _renderGradeTD = (row, key) => {
    const { classes } = this.props;
    return (
      <div className={`${classes.listPadding} ${classes.flex}`}>
        <div className={classes.gradeRank}>
          {this.getPlaceHolder(row.graderank, classes.gradeName)}
        </div>
        <div style={{ flexGrow: 5 }}>
          {this.getPlaceHolder(row[key], classes.name)}
          {this.getPlaceHolder(
            this.getDate(row.addeddate),
            `${classes.date} ${classes.paddingTop10}`,
          )}
        </div>
      </div>
    );
  };

  HelperSliderFlag(side, flag, text, configid, holidayyear, row) {
    // console.log("configid: ", configid);
    let OpenFlag = false;
    if (flag == true) {
      OpenFlag = true;
      this.setState({ OpenFlag });
    } else {
      OpenFlag = false;
      this.setState({ OpenFlag });
    }
    this.props.RightDrawer({
      drawerdata: text,
      configid:
        this.props.module == "expense policie"
          ? row.expensepolicyid
          : this.props.module == "signatories-recepients"
          ? row.letterid
          : configid,
      holidayyear,
      open: side,
      side: flag,
      row,
    });
    // this.props.RightDrawer({ drawerdata: this.state.EmpList, open: flag });
  }

  _renderCreatedByTD = (row, key, extraKey) => {
    const { classes } = this.props;
    const path = row[key];
    // if(!path) return;
    return (
      <Grid container alignItems="center">
        <Tooltip
          title={row[extraKey] || row.fullname || row.employeename}
          aria-label={row[extraKey] || row.fullname || row.employeename}
          placement="top"
        >
          <Link
            className="active-color image-center"
            target="_blank"
            rel="noopener noreferrer"
            to={`/profile/${row.addeddbyid || row.employeeid}`}
          >
            <Avatar
              alt={row[extraKey]}
              src={getFormattedImageLink(path) || DefaultProfileImage}
              className={classes.avatar}
            />
          </Link>
        </Tooltip>
      </Grid>
    );
  };

  _renderEmpProfile = (row, key, extraKey) => {
    const { classes } = this.props;
    const path = row[extraKey];
    // if(!path) return;
    return (
      <div className="display-flex">
        <div className="margin-right-8">
          <Tooltip
            title={row.employeecode}
            aria-label={row.employeecode}
            placement="top"
          >
            <Link
              className="active-color image-center"
              target="_blank"
              rel="noopener noreferrer"
              to={`/profile/${row.addeddbyid || row.employeeid}`}
            >
              <Avatar
                alt={row.fullname}
                src={getFormattedImageLink(path) || DefaultProfileImage}
                className={classes.avatar}
              />
            </Link>
          </Tooltip>
        </div>
        <div style={{ paddingTop: 12 }}>{row.fullname}</div>
      </div>
    );
  };

  _renderAssignedTo = (row, key, extraKey) => (
    <div>
      {row.assignedto ? (
        <Tooltip title={row.assignedto} placement="top">
          <div className="display-flex" style={{ alignItems: "center" }}>
            <img
              src={getFormattedImageLink(row.imagepath) || DefaultProfileImage}
              height="24"
              width="24"
              style={{ borderRadius: 40 }}
            ></img>
          </div>
        </Tooltip>
      ) : (
        <div className="ellipsis width70">No employee assigned</div>
      )}
    </div>
  );

  _renderTicketOwner = (row, key) => (
    <div>
      <Tooltip title={row[key][0].fullname} placement="top">
        <div className="display-flex" style={{ alignItems: "center" }}>
          <img
            src={
              getFormattedImageLink(row[key][0].imagepath) ||
              DefaultProfileImage
            }
            height="24"
            width="24"
            style={{ borderRadius: 40 }}
          ></img>
        </div>
      </Tooltip>
    </div>
  );

  _renderEmpProfileLink = (row, key, extraKey) => {
    const { classes } = this.props;
    const path = row[extraKey];
    const selectedvalue = false;
    return (
      <div className="display-flex" style={{ padding: "4px 0px" }}>
        <div style={{ width: 24, height: 24, marginRight: 8 }}>
          <DynamicForm
            fields={checkboxinputconverter({
              value: row.selectedvalue,
              placeholder: "",
              label: "",
              disabled:
                this.props.modules == "Non-payrol income" &&
                !row.ispayslipgenerated
                  ? false
                  : row.isactionallowed
                  ? false
                  : true,
            })}
            onChange={() => {
              this.props.checkChange(row, key, row.selectedvalue);
            }}
          />
        </div>
        <div className="margin-right-8">
          <Tooltip
            title={row.employeecode}
            aria-label={row.employeecode}
            placement="left"
          >
            <Link
              className="active-color image-center"
              target="_blank"
              rel="noopener noreferrer"
              to={`/profile/${row.addeddbyid || row.employeeid}`}
            >
              <div style={{ marginTop: 3 }}>
                {this.getPlaceHolder(row[key], classes.name)}
              </div>
            </Link>
          </Tooltip>
        </div>
      </div>
    );
  };

  _renderSentByTD = (row, key, extraKey) => {
    const { classes } = this.props;
    const path = row[key];
    // if(!path) return;
    return (
      <Grid container alignItems="center">
        <Tooltip
          title={row[extraKey]}
          aria-label={row[extraKey]}
          placement="top"
        >
          <Link
            className="active-color image-center"
            target="_blank"
            rel="noopener noreferrer"
            to={`/profile/${row.senderid}`}
          >
            <Avatar
              alt={row[extraKey]}
              src={getFormattedImageLink(path) || DefaultProfileImage}
              className={classes.avatar}
            />
          </Link>
        </Tooltip>
      </Grid>
    );
  };

  _renderAssignedByTD = (row, key) => {
    const moduletype = this.props.module;
    const { holidayyear } = this.props;
    let count = 0;
    if (row[key] && row[key].length > 0 && row[key][0].fullcount) {
      // console.log("here", row, key);
      count = this.getCount(row[key][0].fullcount);

      return (
        <Grid container justify="center" alignItems="center">
          <div className="avtar-thumb-wrapper-small">
            {row[key] &&
              row[key].slice(0, 3).map((item) => (
                // if(item.imagepath)
                <Tooltip
                  key="key"
                  title={item.employeename || item.fullname}
                  aria-label={item.employeename || item.fullname}
                >
                  <div
                    className={count > 0 ? "avtar-thumb-blur" : "avtar-thumb"}
                  >
                    <Link
                      className="active-color"
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/profile/${item.employeeid}`}
                    >
                      {/* <Avatar
                        key={item.employeeid}
                        alt={item.employeename}
                        src={getFormattedImageLink(item.imagepath) || DefaultProfileImage}
                        className={`${classes.avatar} avatar-wrapper`}
                      /> */}
                      <div className="avatar-wrapper-small">
                        {!item.imagepath || item.imagepath == null ? (
                          <img
                            src={DefaultProfileImage}
                            height="40"
                            className="team-avatar"
                          />
                        ) : (
                          <img
                            src={getFormattedImageLink(item.imagepath)}
                            height="40"
                            className="team-avatar"
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                </Tooltip>
              ))}
          </div>
          {/* {count>0 && <Avatar className={classes.countAvatar+' td-avatar'}>+{count}</Avatar>} */}
          {count > 0 && (
            <span
              className="additional-count"
              onClick={this.HelperSliderFlag.bind(
                this,
                "right",
                true,
                moduletype,
                row[key][0],
                holidayyear,
                row,
              )}
            >
              <span>
                <div className="total-count-small">+{count}</div>
              </span>
            </span>
          )}
        </Grid>
      );
    } else if (!row[key] || row[key].length == 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: 46 }}
        >
          {this.props.module == "reviewform" && !row.isdraft
            ? "-"
            : "No Employee Assigned"}
        </Grid>
      );
    }
  };

  _renderAssignedNoClickTD = (row, key) => {
    const moduletype = this.props.module;
    const { holidayyear } = this.props;
    let count = 0;
    if (row[key] && row[key].length > 0 && row[key][0].fullcount) {
      // console.log("here", row, key);
      count = this.getCount(row[key][0].fullcount);

      return (
        <Grid container justify="center" alignItems="center">
          <div className="avtar-thumb-wrapper-small">
            {row[key] &&
              row[key].slice(0, 3).map((item) => (
                // if(item.imagepath)
                <Tooltip
                  key="key"
                  title={item.employeename || item.fullname}
                  aria-label={item.employeename || item.fullname}
                >
                  <div
                    className={count > 0 ? "avtar-thumb-blur" : "avtar-thumb"}
                  >
                    <Link
                      className="active-color"
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/profile/${item.employeeid}`}
                    >
                      {/* <Avatar
                        key={item.employeeid}
                        alt={item.employeename}
                        src={getFormattedImageLink(item.imagepath) || DefaultProfileImage}
                        className={`${classes.avatar} avatar-wrapper`}
                      /> */}
                      <div className="avatar-wrapper-small">
                        {!item.imagepath || item.imagepath == null ? (
                          <img
                            src={DefaultProfileImage}
                            height="40"
                            className="team-avatar"
                          />
                        ) : (
                          <img
                            src={getFormattedImageLink(item.imagepath)}
                            height="40"
                            className="team-avatar"
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                </Tooltip>
              ))}
          </div>
          {/* {count>0 && <Avatar className={classes.countAvatar+' td-avatar'}>+{count}</Avatar>} */}
          {count > 0 && (
            <span
              className="additional-count"
              // onClick={this.HelperSliderFlag.bind(
              //   this,
              //   "right",
              //   true,
              //   moduletype,
              //   row[key][0],
              //   holidayyear,
              //   row,
              // )}
            >
              <span>
                <div className="total-count-small">+{count}</div>
              </span>
            </span>
          )}
        </Grid>
      );
    } else if (!row[key] || row[key].length == 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: 46 }}
        >
          {this.props.module == "reviewform" && !row.isdraft
            ? "-"
            : "No Employee Assigned"}
        </Grid>
      );
    }
  };

  _renderAssignedGroupsTD = (row, key) => {
    let count = 0;
    if (row[key] && row[key].length > 0) {
      count = this.getCount(row[key].length);

      return (
        <Grid container onClick={() => this.onGroupClicked(row)}>
          <div className="">
            {row[key] &&
              row[key].map((item) => (
                // <Tooltip
                //   key="key"
                //   title={item.grouptypename || item.fullname}
                //   aria-label={item.grouptypename || item.fullname}
                // >
                <div
                  style={{
                    background: "#edf5fd",
                    padding: "2px 8px",
                    borderRadius: 30,
                    display: "inline-block",
                    marginRight: 8,
                    marginTop: 8,
                  }}
                >
                  <span style={{ color: "#057cff" }}>{item.grouptypename}</span>
                  {/* <Link
                      className="active-color"
                      target="_blank"
                      rel="noopener noreferrer"
                      // to={`/profile/${item.employeeid}`}
                    >
                      <div className="avatar-wrapper-small">
                        {!item.imagepath || item.imagepath == null ? (
                          <img
                            src={DefaultProfileImage}
                            height="40"
                            className="team-avatar"
                          />
                        ) : (
                          <img
                            src={getFormattedImageLink(item.imagepath)}
                            height="40"
                            className="team-avatar"
                          />
                        )}
                      </div>
                    </Link>  */}
                </div>
                // </Tooltip>
              ))}
          </div>

          {/* {count > 0 && (
            <span
              className="additional-count"
              onClick={this.HelperSliderFlag.bind(
                this,
                "right",
                true,
                moduletype,
                row[key][0],
                holidayyear,
                row,
              )}
            >
              <span>
                <div className="total-count-small">+{count}</div>
              </span>
            </span>
          )} */}
        </Grid>
      );
    } else if (!row[key] || row[key].length == 0) {
      return (
        <Grid container justify="center" alignItems="center">
          No Employee Assigned
        </Grid>
      );
    }
  };

  getCount = (count) => {
    if (count >= 3) {
      return count - 3;
    }
    return 0;
  };

  _renderDefaultTD = (row, key, appendText, style) => {
    const { classes } = this.props;
    return this.getPlaceHolder(
      row[key],
      `${classes.date} ${classes.centerAlight}`,
      appendText,
      style,
    );
  };

  _renderCommonNameTD = (row, key) => {
    const { classes, module } = this.props;
    return (
      <Tooltip title={row[key]} placement="top">
        <p className="small-dark-secondary ellipsis width90 padding-top-8 padding-bottom-8">
          {" "}
          {row[key]}
        </p>
      </Tooltip>
    );
  };

  _renderConversationTD = (row, key) => {
    const { classes, module } = this.props;
    return (
      <div>
        {module == "Canned response" ? (
          <span
            className="small-dark-secondary last-conversation ellipsis width50 padding-top-8 padding-bottom-8"
            dangerouslySetInnerHTML={{
              __html: row[key],
            }}
            style={{ height: 20, display: "block" }}
          ></span>
        ) : (
          <Tooltip title={row[key]} placement="top">
            <span
              className="small-dark-secondary last-conversation ellipsis width50 padding-top-8 padding-bottom-8"
              dangerouslySetInnerHTML={{
                __html: row[key],
              }}
            ></span>
          </Tooltip>
        )}
      </div>
    );
  };

  _renderPrioritySubjetTD = (row, key, key1, key2) => {
    const { classes } = this.props;
    return (
      <Tooltip title={`${row[key2]} / ${row[key]}`} placement="top">
        <div className="display-flex">
          <img
            src={
              row[key1] && row[key1] == 1
                ? priorityHighIcon
                : row[key1] == 2
                ? priorityMedIcon
                : priorityLowIcon
            }
          ></img>
          <p className="small-dark-secondary margin-left-8 ellipsis width50 padding-top-8 padding-bottom-8">
            {row[key]}
          </p>
        </div>
      </Tooltip>
    );
  };

  getUnique = (arr) => {
    const comp = "pipelineid";
    const unique = arr
      .map((e) => e[comp])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  };

  _renderAssignedPipelineTD = (row, key) => {
    let count = 0;
    if (row[key] && row[key].length > 0) {
      count = this.getCount(row[key].length);
      const uniquepipeline = this.getUnique(row[key]);
      console.log("uniquepipeline: ", uniquepipeline);

      return (
        <div className="pipelineassigneddata">
          {uniquepipeline &&
            uniquepipeline.map((item) => (
              <span className="small-dark-secondary pipelineassigneddata-span ">
                {item.pipelinename}
              </span>
            ))}
        </div>
      );
    } else if (!row[key] || row[key].length == 0) {
      return (
        <Grid container justify="center" alignItems="center">
          No pipeline assigned
        </Grid>
      );
    }
  };

  _renderDefaultCycle = (row, key, appendText, style) => {
    const { classes } = this.props;
    return (
      <Typography
        component="p"
        className={classNames("bank-details-viewpage t-14", classes)}
        // style={{ fontSize: 14, paddingTop: 10 }}
      >
        {getMonthName(parseInt(row[key].split("-")[0]))}{" "}
        {row[key].split("-")[1]}
      </Typography>
    );
  };

  _renderFallBack = (row, key, appendText, style) => {
    const { classes } = this.props;
    if (row[key] == "Default") {
      return <span>-</span>;
    }
    const fallback = row[key].split(";");
    return this.getPlaceHolderFallback(
      fallback,
      `${classes.date} ${classes.centerAlight}`,
      appendText,
      style,
    );
  };

  _renderEffectivefrom = (row, key, appendText, style) => {
    const { classes } = this.props;
    if (row.authoritystatus == "Default") {
      return this.getPlaceHolderDefaultEffectivefrom(
        row[key],
        `${classes.date} ${classes.centerAlight}`,
        appendText,
        style,
      );
    }
    // return this.getPlaceHolder(row[key], classes.date + ' ' + classes.centerAlight, appendText, style);
    // let fallback=JSON.parse(row.fallback);
    const fallback = row[key].split(";");
    return this.getPlaceHolderEffectivefrom(
      fallback,
      `${classes.date} ${classes.centerAlight}`,
      appendText,
      style,
    );
  };

  _renderEditTD = (row) => {
    const { classes, permissions } = this.props;
    return (
      <span
        className={`${classes.actionBtnContainer} action-btn`}
        onClick={() => permissions && permissions.editable && this.onEdit(row)}
      >
        {/* <EditIcon className={classes.actionBtns} /> */}
        <svg
          className={
            permissions && permissions.editable
              ? "cursour-pointer table-action-icon vertical-middle"
              : "table-action-icon vertical-middle"
          }
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
        </svg>
      </span>
    );
  };

  _renderEditWithoutHeaderTD = (row) => {
    const { classes, modules } = this.props;
    const checkshow = () =>
      modules == "extrapayment" ? row.isactionallowed : !row.hideedit;
    return (
      <span>
        {checkshow() && (
          <span
            className={`${classes.actionBtnContainer} action-btn`}
            onClick={() => this.onEdit(row)}
          >
            {/* <EditIcon className={classes.actionBtns} /> */}
            {
              <img
                src={editIcon}
                className="cursour-pointer vertical-middle"
              ></img>
            }
          </span>
        )}
      </span>
    );
  };

  _renderEditAssignmentWithoutHeaderTD = (row) => {
    // console.log("row: ", row);
    const { classes, module } = this.props;
    // const checkshow = () =>
    //   !row.iseditable &&
    //   row.payrolltypeid != 3 &&
    //   row.isduplicate &&
    //   row.assignedemployeelist.length > 0;
    const checkshow = () =>
      (module == "Salary structure" &&
        !row.iseditable &&
        row.payrolltypeid != 3 &&
        row.isduplicate &&
        row.assignedemployeelist.length > 0) ||
      (module != "Salary structure" && true);
    return (
      <span>
        {checkshow() && (
          <Tooltip
            className="cursour-pointer "
            title={
              module != "Salary structure"
                ? "Duplicate in draft"
                : "Edit & Duplicate"
            }
            placement="top"
            classes={{
              tooltip: classes.customWidth,
            }}
          >
            <span
              className={`${classes.actionBtnContainer} action-btn`}
              onClick={() => this.onEditAssignment(row)}
            >
              {/* <EditIcon className={classes.actionBtns} /> */}
              {
                <img
                  src={duplicate}
                  className="cursour-pointer vertical-middle"
                ></img>
              }
            </span>
          </Tooltip>
        )}
      </span>
    );
  };

  _renderDeleteTD = (row) => {
    const { classes } = this.props;
    return (
      <span
        className={`${classes.actionBtnContainer} action-btn`}
        onClick={() => this.onDelete(row)}
      >
        {/* <DeleteIcon className={classes.actionBtns} /> */}
        <svg
          className="cursour-pointer table-action-icon vertical-middle"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4h-3.5z" />
        </svg>
      </span>
    );
  };

  _renderDeleteWithoutHeaderTD = (row) => {
    // console.log("row: ", row);
    const { classes, modules } = this.props;
    // console.log("module: ", modules);
    const checkshow = () =>
      modules == "extrapayment" ? row.isactionallowed : !row.hidedelete;
    // console.log("checkshow: ", checkshow());
    return (
      <span>
        {checkshow() && (
          <span
            className={`${classes.actionBtnContainer} action-btn`}
            onClick={() => this.onDelete(row)}
          >
            {/* <DeleteIcon className={classes.actionBtns} /> */}
            {
              <img
                src={deleteIcon}
                className="cursour-pointer vertical-middle"
              ></img>
            }
          </span>
        )}
      </span>
    );
  };

  _renderDeleteWithoutHeaderforexceptionTD = (row) => {
    const { classes, modules, module } = this.props;
    const checkshow = () =>
      module == "Salary exception" ? row.exceptionstatusid != 99 : true;

    return (
      <span>
        {checkshow() && (
          <span
            className={`${classes.actionBtnContainer} action-btn`}
            onClick={() => this.onDelete1(row)}
          >
            {/* <DeleteIcon className={classes.actionBtns} /> */}
            {
              <img
                src={deleteIcon}
                className="cursour-pointer vertical-middle"
              ></img>
            }
          </span>
        )}
      </span>
    );
  };

  _renderDeleteWithoutHeaderForFBPTD = (row) => {
    const { classes } = this.props;
    return (
      <span
        className={`${classes.actionBtnContainer} action-btn`}
        onClick={() => this.onDelete(row)}
      >
        {/* <DeleteIcon className={classes.actionBtns} /> */}
        {row.assignemployee.length === 0 && (
          <img
            src={deleteIcon}
            className="cursour-pointer vertical-middle"
          ></img>
        )}
      </span>
    );
  };

  _renderMoreOptionTD = (row) => {
    const { classes, modules, actionabledate, activevariabledays } = this.props;
    const actiondate =
      actionabledate &&
      activevariabledays &&
      moment(actionabledate, "YYYY-MM-DD")
        .subtract("days", activevariabledays)
        .format("YYYY-MM-DD");
    const isdate =
      actiondate && actiondate <= moment(new Date()).format("YYYY-MM-DD");
    // console.log("isdate: ", isdate, actionabledate, actiondate);
    // console.log("row.isactionallowed: ", row.isactionallowed);
    return (
      <span>
        {this.boolRenderMoreOptionInList(modules, row, isdate) && (
          <span
            style={{ cursor: "pointer" }}
            className={`${classes.actionBtnContainer} action-btn`}
            onClick={(e) => this.onMoreOptionClick(e, row)}
          >
            <img src={kebab}></img>
          </span>
        )}
      </span>
    );
  };

  _renderleaveMoreOptionTD = (row) => {
    const { classes, modules, actionabledate, activevariabledays } = this.props;
    const actiondate =
      actionabledate &&
      activevariabledays &&
      moment(actionabledate, "YYYY-MM-DD")
        .subtract("days", activevariabledays)
        .format("YYYY-MM-DD");
    const isdate =
      actiondate && actiondate <= moment(new Date()).format("YYYY-MM-DD");
    // console.log("isdate: ", isdate, actionabledate, actiondate);
    // console.log("row.isactionallowed: ", row.isactionallowed);
    return (
      <div
        style={{
          cursor: "pointer",
          alignItems: "center",
          padding: "8px 16px",
          width: 74,
        }}
        className="display-flex rounded-rectangle-div"
        onClick={(e) => this.onMoreleaveOptionClick(e, row)}
      >
        <span className="small-dark" style={{ marginRight: 8 }}>
          {" "}
          Actions{" "}
        </span>
        <img src={tandoor}></img>
      </div>
    );
  };

  _renderLogsTD = (row) => {
    const { classes, modules, actionabledate, activevariabledays } = this.props;
    const actiondate =
      actionabledate &&
      activevariabledays &&
      moment(actionabledate, "YYYY-MM-DD")
        .subtract("days", activevariabledays)
        .format("YYYY-MM-DD");
    const isdate =
      actiondate && actiondate <= moment(new Date()).format("YYYY-MM-DD");
    // console.log("isdate: ", isdate, actionabledate, actiondate);
    // console.log("row.isactionallowed: ", row.isactionallowed);
    return (
      <span>
        {/* {((modules == "variable" &&
          isdate &&
          row.isactionallowed &&
          row.isrunpayroll &&
          (row.variablestatusid == 1 ||
            row.variablestatusid == 3 ||
            row.variablestatusid == 6)) ||
          (modules != "variable" && true)) && ( */}
        <span
          style={{ cursor: "pointer" }}
          className={`${classes.actionBtnContainer} action-btn view-more-link`}
          onClick={(e) => this.onLogsClick(e, row)}
        >
          Logs
        </span>
        {/* )} */}
      </span>
    );
  };

  _renderVersionChange = (row, key) => (
    <span
      className="cursour-pointer"
      onClick={() => this._onVersionChange(row.parentid)}
    >
      {row[key]}
    </span>
  );

  _renderPolicyStatus = (row) => (
    <div className={"no-padding"} style={{ textAlign: "left", marginTop: 2 }}>
      {row.isabsenteeismrule ? (
        <div>
          <img className="floatleft margin-right-8" src={checkRoundGreen}></img>
          <p className="small-dark-secondary">Absenteeism</p>
        </div>
      ) : (
        ""
      )}
      {row.ispunctualityrule ? (
        <div>
          <img className="floatleft margin-right-8" src={checkRoundGreen}></img>
          <p className="small-dark-secondary">Punctuality</p>
        </div>
      ) : (
        ""
      )}
      {row.istimeatworkrule ? (
        <div>
          <img className="floatleft margin-right-8" src={checkRoundGreen}></img>
          <p className="small-dark-secondary">Time At Work</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );

  _renderExtraTimeStatus = (row) => (
    <div
      className={"no-padding"}
      style={{ textAlign: "left", marginLeft: 70, marginTop: 2 }}
    >
      {row.working &&
      row.working[0] &&
      (row.working[0].extratimetypeid == 1 ||
        row.working[0].isworkingbenefit) ? (
        <div>
          <img className="floatleft margin-right-8" src={checkRoundGreen}></img>
          <p className="small-dark-secondary">Working Day</p>
        </div>
      ) : (
        ""
      )}
      {row.working &&
      row.working[1] &&
      (row.working[0].extratimetypeid == 2 ||
        row.working[1].isworkingbenefit) ? (
        <div>
          <img className="floatleft margin-right-8" src={checkRoundGreen}></img>
          <p className="small-dark-secondary">Non-Working Day</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );

  _renderExtraTimePayStatus = (row) => (
    <div
      className={"no-padding"}
      style={{ textAlign: "left", marginLeft: 70, marginTop: 2 }}
    >
      {row.working &&
      Array.isArray(row.working) &&
      row.working.some(
        (ext) => ext.extratimetypeid == 1 && ext.isworkingbenefit,
      ) ? (
        <div>
          <img className="floatleft margin-right-8" src={checkRoundGreen}></img>
          <p className="small-dark-secondary">Working Day</p>
        </div>
      ) : (
        ""
      )}

      {row.working &&
      Array.isArray(row.working) &&
      row.working.some(
        (ext) => ext.extratimetypeid == 2 && ext.isworkingbenefit,
      ) ? (
        <div>
          <img className="floatleft margin-right-8" src={checkRoundGreen}></img>
          <p className="small-dark-secondary">Non-Working Day</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );

  _renderTeamTD = (row, key) => {
    const { classes } = this.props;
    return (
      <div>
        {this.getPlaceHolder(row[key], classes.name)}
        {row.addeddate &&
          this.getPlaceHolder(this.getDate(row.addeddate), `${classes.date}`)}
      </div>
    );
  };

  _renderDepartmentDescriptionTD = (row, key) => {
    const { classes } = this.props;
    return (
      <div style={{ textAlign: "justify" }}>
        <Typography
          component="p"
          className={classNames("bank-details-viewpage  t-14", classes)}
          style={{
            textAlign: "justify",
            color: "rgba(1, 7, 9, 0.70)",
          }}
        >
          {row[key]}
        </Typography>
      </div>
    );
  };

  _renderCompetancyDescriptionTD = (row, key) => {
    const { classes, module, modules } = this.props;
    // console.log('modules: ', modules);
    // console.log('module: ', module);
    return (
      <div>
        <Tooltip
          title={row[key]}
          placement="top"
          classes={{
            tooltip: classes.customWidth,
          }}
        >
          <p
            className="small-dark-secondary ellipsis"
            style={{
              textAlign: "justify",
              color: "rgba(1, 7, 9, 0.70)",

              width:
                module == "checklist"
                  ? 180
                  : module == "ticket category"
                  ? 180
                  : module == "Assets"
                  ? 150
                  : 360,
            }}
          >
            {row[key]}
          </p>
        </Tooltip>
      </div>
    );
  };

  _renderTagsTD = (row, key) => (
    // console.log('row[key]: ', row[key]);
    <div className="display-flex" style={{ alignItems: "center" }}>
      <p
        className="small-dark-secondary"
        style={{
          textAlign: "justify",
          color: "rgba(1, 7, 9, 0.70)",
          textOverflow: "ellipsis",
          // width: 360,
        }}
      >
        {row[key] && row[key].join(", ")}
      </p>
    </div>
  );

  _renderShitNameColor = (row, key) => {
    const { classes } = this.props;
    return (
      <div>
        <div>
          <div className="shiftcolor" style={{ background: row.shiftcolour }} />
          {this.getPlaceHolder(row[key], classes.name)}
          {this.getPlaceHolder(
            this.getDate(row.addeddate),
            `${classes.date} ${classes.paddingTop10}`,
          )}
        </div>
      </div>
    );
  };

  _renderExpenseData = (row, key) => {
    const { classes } = this.props;
    return (
      <div className={`list-tabel${classes.listPadding}`}>
        {this.getPlaceHolder(row[key], classes.name)}
      </div>
    );
  };

  _renderExpenseData2 = (row, key) => {
    const { classes } = this.props;
    return (
      <div
        className={`list-tabel${classes.listPadding}`}
        style={{ textAlign: "center" }}
      >
        {row[key]}
      </div>
    );
  };

  _renderShitTime = (row, key) => {
    const shifttiming = JSON.parse(row[key]);
    return (
      <div>
        <ul className="shift-timing-list">
          <div
            style={{
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {shifttiming.map((val) => {
              const times = val.shifttiming.split("-");
              return (
                <React.Fragment>
                  <li>
                    {getTime(times[0])} to {getTime(times[1])}
                  </li>
                </React.Fragment>
              );
            })}
          </div>
        </ul>
      </div>
    );
  };

  _renderGradeRankTD = (row, key) => {
    const { classes } = this.props;
    return this.getPlaceHolder(row[key], classes.gradeRankText);
  };

  _renderAssignedOwnerTD = (row, key) => (
    <Tooltip title={row[key]} placement="top">
      <p
        className="small-blue ellipsis width90 padding-top-8 padding-bottom-8 cursor-pointer"
        onClick={(e) => this.toggleAssginedOwnerDrawer(e, row)}
      >
        {" "}
        {row[key]}
      </p>
    </Tooltip>
  );

  _renderTDBasedonType = (header, index, row, rowIndex) => {
    switch (header.type) {
      case TDTypeConsts.OPTIONAL_HOLIDAY_NAME:
        return this._renderOptionalHolidayName(row, header.key, true);

      case TDTypeConsts.OPTIONAL_HOLIDAY_DATE:
        return this._renderOptionalHolidayDate(row, header.key);

      case TDTypeConsts.HOLIDAY_SELECTED:
        return this._renderHolidaySelected(row, header.key);

      case TDTypeConsts.BUSINESS_UNIT_NAME:
        return this._renderBusinessUnitTD(row, header.key, "cityname");
      case TDTypeConsts.NAME_CLICKABLE:
        return this._renderClickableName(row, header.key);

      case TDTypeConsts.TEAM_NAME:
      case TDTypeConsts.GRADE_NAME:
      case TDTypeConsts.DEPARTMENT_NAME:
      case TDTypeConsts.DESIGNATION_NAME:
      case TDTypeConsts.SHIFTPLANNER_NAME:
      case TDTypeConsts.DEVICE_NAME:
        return this._renderTeamTD(row, header.key);

      // case TDTypeConsts.SHIFT_COLOR:
      case TDTypeConsts.SHIFT_NAME:
        return this._renderShitNameColor(row, header.key);
      case TDTypeConsts.DEPARTMENT_DESCRIPTION:
        return this._renderDepartmentDescriptionTD(row, header.key);
      case TDTypeConsts.COMPETANCY_DESCRIPTION:
        return this._renderCompetancyDescriptionTD(row, header.key);
      case TDTypeConsts.TAGS:
        return this._renderTagsTD(row, header.key);

      case TDTypeConsts.SHIFT_TIME:
        return this._renderShitTime(row, header.key);

      case TDTypeConsts.GRADE_RANK:
        return this._renderGradeRankTD(row, header.key);

      case TDTypeConsts.CREATED_BY:
        return this._renderCreatedByTD(row, header.key, "addedbyname");

      case TDTypeConsts.PROFILE:
        return this._renderEmpProfile(row, header.key, "imagepath");

      case TDTypeConsts.ASSIGNED_TO:
        return this._renderAssignedTo(row, header.key);

      case TDTypeConsts.TICKET_OWNER:
        return this._renderTicketOwner(row, header.key);

      case TDTypeConsts.PROFILELINK:
        return this._renderEmpProfileLink(row, header.key, "imagepath");

      case TDTypeConsts.SENDER_BY:
        return this._renderSentByTD(row, header.key, "sendername");

      case TDTypeConsts.ASSIGNED_USER:
        return this._renderAssignedByTD(row, header.key);

      case TDTypeConsts.ASSET_TYPE:
        return this.renderAssetType(row, header.key);

      case TDTypeConsts.ASSIGNED_USER_LIST:
        return this._renderAssignedNoClickTD(row, header.key);

      case TDTypeConsts.ASSIGNED_PIPELINE:
        return this._renderAssignedPipelineTD(row, header.key);

      case TDTypeConsts.ASSIGNED_GROUPS:
        return this._renderAssignedGroupsTD(row, header.key);

      case TDTypeConsts.CONVERSATION:
        return this._renderConversationTD(row, header.key);

      case TDTypeConsts.PRIORITYSUBJECT:
        return this._renderPrioritySubjetTD(
          row,
          header.key,
          header.key1,
          header.key2,
        );

      case TDTypeConsts.OTHERS:
        return this._renderDefaultTD(
          row,
          header.key,
          header.appendText,
          header.rowStyle,
        );

      case TDTypeConsts.COMMONNAME:
        return this._renderCommonNameTD(
          row,
          header.key,
          header.appendText,
          header.rowStyle,
        );

      case TDTypeConsts.SALARYCYCLE:
        return this._renderDefaultCycle(
          row,
          header.key,
          header.appendText,
          header.rowStyle,
        );

      case TDTypeConsts.EDIT:
        return this._renderEditTD(row);

      case TDTypeConsts.EDIT_WITHOUT_HEADER:
        return this._renderEditWithoutHeaderTD(row);

      case TDTypeConsts.EDIT_ASSIGNMENT_WITHOUT_HEADER:
        return this._renderEditAssignmentWithoutHeaderTD(row);

      case TDTypeConsts.DELETE:
        return this._renderDeleteTD(row);

      case TDTypeConsts.DELETE_WITHOUT_HEADER:
        return this._renderDeleteWithoutHeaderTD(row);
      case TDTypeConsts.DELETE_WITHOUT_HEADER1:
        return this._renderDeleteWithoutHeaderforexceptionTD(row);

      case TDTypeConsts.DELETE_WITHOUT_HEADER_FOR_FBP:
        return this._renderDeleteWithoutHeaderForFBPTD(row);

      case TDTypeConsts.MORE_OPTIONS:
        return this._renderMoreOptionTD(row);

      case TDTypeConsts.LEAVE_MORE_OPTIONS:
        return this._renderleaveMoreOptionTD(row);
      case TDTypeConsts.LOGS:
        return this._renderLogsTD(row);

      case TDTypeConsts.VERSIONS:
        return this._renderVersionChange(row, header.key);

      case TDTypeConsts.SHIFT_VERSIONS:
        return this._renderVersionChange(row, header.key);

      case TDTypeConsts.POLICY_STATUS:
        return this._renderPolicyStatus(row);

      case TDTypeConsts.EXTRA_TIME_STATUS:
        return this._renderExtraTimeStatus(row);

      case TDTypeConsts.EXTRA_TIME_PAY_STATUS:
        return this._renderExtraTimePayStatus(row);

      case TDTypeConsts.TRACKING_DECIVE:
        return this._renderTrackingDevice(row, header.key, "trackingdevice");

      case TDTypeConsts.BREAK_TRACK:
        return this._renderBreakTracking(row, header.key, "isbreakon");

      case TDTypeConsts.EDIT_NAME:
        return this._renderEditPolicy(row, header.key, true);

      case TDTypeConsts.BANK_NAME:
        return this._renderBankDetails(row, header.key);

      case TDTypeConsts.ACCOUNT_TYPE:
        return this._renderDefaultTD(row, header.key);

      case TDTypeConsts.ACCOUNT_NUMBER:
        return this._renderDefaultTD(row, header.key);

      case TDTypeConsts.IFSC_CODE:
        return this._renderDefaultTD(row, header.key);

      case TDTypeConsts.STATE:
        return this._renderDefaultTD(row, header.key);

      case TDTypeConsts.CREATED_DATE:
        return this._renderCreatedDate(row, header.key);

      case TDTypeConsts.SA_NAME:
        return this._renderSigningAuthorityName(row, header.key);

      case TDTypeConsts.AUTHORITIES:
        return this._renderDefaultTD(row, header.key);

      case TDTypeConsts.AUTHORITY_TYPE:
        return this._renderDefaultTD(row, header.key);

      case TDTypeConsts.FALLBACK_OF_DEFAULT:
        return this._renderFallBack(row, header.key);

      case TDTypeConsts.EFFECTIVE_FROM_TO:
        return this._renderEffectivefrom(row, header.key);

      case TDTypeConsts.POLICY_NAME:
      case TDTypeConsts.EXTRA_TIME_NAME:
        return this._renderEditPolicy(row, header.key, false);

      case TDTypeConsts.LEAVE_POLICY_NAME:
        return this._renderleaveEditPolicy(row, header.key, false);

      case TDTypeConsts.LEAVE_POLICY_DATE:
        return this._renderleavePolicydate(row, header.key, false);

      case TDTypeConsts.DOCUMENT_PREVIEW:
        return this._renderDocumentPreview(row, header.key);

      case TDTypeConsts.POLICY_VERSION_NAME:
        return this._renderEditVersionPolicy(row, header.key, false);

      case TDTypeConsts.LEAVE_VERSION_DATE:
        return this._renderDateVersionPolicy(row, header.key, false);

      case TDTypeConsts.EDIT_SHIFT:
        return this._renderEditShift(row, header.key, true);

      case TDTypeConsts.LEAVETYPES:
        return this._renderLeavesType(row, header.key, rowIndex);

      case TDTypeConsts.LINKED_USER:
        return this._renderLinkedUser(
          row,
          header.key,
          rowIndex,
          header.appendText,
          header.rowStyle,
        );

      case TDTypeConsts.DOCS:
        return this._renderDocs(row, header.key, rowIndex);

      case TDTypeConsts.EXPENSE_POLICY_NAME:
        return this._renderExpenseData(row, header.key, rowIndex);

      case TDTypeConsts.EXPENSE_MONTHLYLIMIT:
        return this._renderExpenseData2(row, header.key, rowIndex);

      case TDTypeConsts.STATE_ACTIVE_INACTIVE:
        return this._renderStateActiveInactive(row, header.key, rowIndex);

      case TDTypeConsts.STATUS_ACTIVE_INACTIVE:
        return this._renderStatusActiveInactive(row, header.key, rowIndex);

      case TDTypeConsts.CLICKABLE_NUMBER:
        return this._renderClickableNumber(row, header.key, rowIndex);

      case TDTypeConsts.DATA_PREVIEW:
        return this._renderDataPreviewTD(row, header.key);

      case TDTypeConsts.SIGNATORY_NAME:
        return this._renderSignatoryName(row, header.key);

      case TDTypeConsts.ATIVEINACTIVE:
        return this.renderActiveInactive(row, header.key, rowIndex);

      case TDTypeConsts.EXCEPTION_STATUS:
        return this.renderExceptionStatus(row, header.key, rowIndex);

      case TDTypeConsts.CHECKLIST_STATUS:
        return this.renderChecklistStatus(row, header.key, rowIndex);

      case TDTypeConsts.CHECKLIST_ACTIVITIY:
        return this.renderChecklistActivity(row, header.key, rowIndex);

      case TDTypeConsts.VARIABLE_STATUS:
        return this.renderVariableStatus(row, header.key, rowIndex);

      case TDTypeConsts.LETTER_REQ:
        return this.renderLetterImage(row, header.key, rowIndex);

      case TDTypeConsts.SAVE:
        return this.renderSaveChange(row, header.key, rowIndex);

      case TDTypeConsts.CHECKBOX:
        return this.renderCheckbox(row, header.key, rowIndex);

      case TDTypeConsts.ANSWER_TYPE:
        return this.renderAnswerType(row, header.key, rowIndex);

      case TDTypeConsts.ASSIGNED_OWNERS:
        return this._renderAssignedOwnerTD(row, header.key);
      default:
        return null;
    }
  };

  renderSaveChange = (row, key, index) => (
    <div>
      <Button
        className="btn btn-primary-md-blue"
        onClick={() => this.props.onSave(row, key, index)}
      >
        Save
      </Button>
    </div>
  );

  renderCheckbox = (row, key, index) => (
    <div style={{ display: "table", margin: "0 auto" }}>
      <DynamicForm
        fields={checkboxinputconverter({
          value: row[key],
          placeholder: "",
          label: "",
        })}
        onChange={(value) => {
          this.props.checkChange(row, key, index, value);
        }}
      />
    </div>
  );

  renderExceptionStatus = (row, key, rowIndex) => {
    const x = row[key];
    const name =
      x == 2
        ? "Postponed"
        : x == 1
        ? "Onhold"
        : x == 3
        ? "Excluded"
        : x == 99
        ? "Included"
        : "-";
    return (
      <div>
        {x ? (
          <span>
            <span>
              {x == 2 ? (
                <span className="margin-right-8">
                  <img src={bluedot} />
                </span>
              ) : x == 1 ? (
                <span className="margin-right-8">
                  <img src={yellowdot} />
                </span>
              ) : x == 3 ? (
                <span className="margin-right-8">
                  <img src={reddot} />
                </span>
              ) : x == 99 ? (
                <span className="margin-right-8">
                  <img src={greendot} />
                </span>
              ) : (
                ""
              )}
            </span>
            <span>{name}</span>
          </span>
        ) : (
          ""
        )}
      </div>
    );
  };

  renderAssetType = (row, key, rowIndex) => {
    const x = row[key];
    const type = x == 1 ? "Physical" : x == 2 ? "Digital" : "-";
    return <div>{x ? <div>{type}</div> : ""}</div>;
  };

  renderChecklistStatus = (row, key, rowIndex) => {
    const x = row[key];
    return (
      <div>
        {x ? (
          <div>
            <span
              style={{
                // width: 80,
                padding: "1px 4px",
                fontSize: 12,
                borderRadius: 4,
                width: 64,
                display: "block",
                color:
                  x == "On Track"
                    ? "#008985"
                    : x == "Over Due"
                    ? "#f60038"
                    : x == "Due Soon"
                    ? "#f86b07"
                    : "#00FF00",
                backgroundColor:
                  x == "On Track"
                    ? "#dcf2f1"
                    : x == "Over Due"
                    ? "#ffe2e5"
                    : x == "Due Soon"
                    ? "#fef1d9"
                    : "#00FF00",
              }}
            >
              {x}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  renderChecklistActivity = (row, key, rowIndex) => {
    const x = row[key];
    const y = [row];
    // console.log(' y: ', y);
    return (
      <div>
        {x ? (
          <div className="text-align-left">
            <div className="showviewbtn">
              <p
                className="small-blue t-bold"
                style={{ padding: "8px 8px" }}
                onClick={(e) => this.onViewClick(e, row)}
              >
                View
              </p>
              {((y && y[0] && y[0].status && y[0].status == "Over Due") ||
                (y && y[0] && y[0].status && y[0].status == "Due Soon")) && (
                <p
                  className="small-blue t-bold"
                  style={{ padding: "8px 8px" }}
                  onClick={(e) => this.onRemindClick(e, row)}
                >
                  Remind
                </p>
              )}
            </div>
            <span
              style={{
                width: 80,
              }}
              className="viewbtn"
            >
              {x}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  renderVariableStatus = (row, key, rowIndex) => {
    const x = row[key];
    const name =
      x == 1
        ? "Pending"
        : x == 2
        ? "Approved"
        : x == 6
        ? "Postponed"
        : x == 3
        ? "On hold"
        : x == 4
        ? "Deny"
        : "In transit";
    return (
      <div>
        {x ? (
          <span>
            <span>
              {x == 2 ? (
                <span className="margin-right-8">
                  <img src={greendot} />
                </span>
              ) : x == 1 ? (
                <span className="margin-right-8">
                  <img src={pending} />
                </span>
              ) : x == 6 ? (
                <span className="margin-right-8">
                  <img src={yellowdot} />
                </span>
              ) : x == 3 ? (
                <span className="margin-right-8">
                  <img src={bluedot} />
                </span>
              ) : x == 4 ? (
                <span className="margin-right-8">
                  <img src={reddot} />
                </span>
              ) : (
                <span className="margin-right-8">
                  <img src={yellowdot} />
                </span>
              )}
            </span>
            <span>{name}</span>
          </span>
        ) : (
          ""
        )}
      </div>
    );
  };

  renderLetterImage = (row, key, rowIndex) => {
    const x = row[key];
    const name =
      x == 1
        ? "Available in letter box"
        : x == 2
        ? "Available on request"
        : "Auto triggered";
    return (
      <div>
        <Tooltip title={name}>
          <span>
            {x == 1 ? (
              "📬"
            ) : x == 2 ? (
              <span style={{ fontSize: 28 }}>🛎</span>
            ) : (
              "⚡️"
            )}
          </span>
        </Tooltip>
      </div>
    );
  };

  _renderStateActiveInactive = (row) => {
    const { onStateStatusChange } = this.props;
    return (
      <div>
        <Switch
          checked={row.isactive}
          onChange={(e) => onStateStatusChange(e.target.checked, row)}
          value={row.isactive}
          // name={column}
          color="primary"
        />
      </div>
    );
  };

  _renderStatusActiveInactive = (row) => (
    // console.log('row: ', row);
    <div>
      <p className={row.isactive ? "small-green-dark" : "small-red-dark"}>
        {row.isactive ? "Active" : "Inactive"}
      </p>
    </div>
  );

  renderActiveInactive = (row, key) => (
    <div>
      <Select
        onChange={(e) => this.props.changeStatus(row, key, e.target.value)}
        className={row[key] && "select-active"}
        disableUnderline
        value={row[key] ? 1 : 2}
      >
        <MenuItem value={1}>
          <span style={{ color: "#1e8ced", fontWeight: "bold" }}>Active</span>
        </MenuItem>
        <MenuItem value={2}>
          <span style={{ color: "black", fontWeight: "bold" }}>Inactive</span>
        </MenuItem>
      </Select>
    </div>
  );

  _renderClickableNumber = (row, key, rowIndex) => {
    const { onClickableNumberClick } = this.props;
    return (
      <div
        onClick={() => onClickableNumberClick(row, key, rowIndex)}
        className="cursour-pointer"
      >
        {row[key]}
      </div>
    );
  };

  _renderNoRecord = () => {
    const { headers } = this.props;
    return (
      <TableRow>
        <TableCell colSpan={headers.length}>No record found</TableCell>
      </TableRow>
    );
  };

  handleDraftClick(row) {}
  // Table Body Code to be refactor

  _renderTableBody = (row, rowIndex) => {
    const { headers } = this.props;
    return (
      <TableRow
        key={row.id}
        className={this.props.module == "empchecklist" ? "listhover" : ""}
      >
        {headers.map((header, index) => (
          <TableCell key={header.key} style={header.style}>
            {this._renderTDBasedonType(header, index, row, rowIndex)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  _renderTrackingDevice = (row, key, extraKey) => (
    <Grid container>
      <div align="center" className="width100">
        {row.isbiometricdevice ? (
          <img
            alt={row[extraKey]}
            src={biometric}
            className="margin-right-16"
          />
        ) : (
          ""
        )}
        {row.iswebdevice ? (
          <img alt={row[extraKey]} src={desktop} className="margin-right-16" />
        ) : (
          ""
        )}
        {row.ismobiledevice ? <img alt={row[extraKey]} src={mobileIcon} /> : ""}
      </div>
    </Grid>
  );

  _renderBreakTracking = (row, key, extraKey) => (
    <Grid container>
      <div align="center" className="width100">
        {row[key] ? <img alt={row[extraKey]} src={teaBreak} /> : ""}
      </div>
    </Grid>
  );

  _renderEditPolicy = (row, key, edit) => {
    const { classes } = this.props;
    return (
      <div onClick={() => this.onEdit(row, edit)} className="cursour-pointer">
        {this.getPlaceHolder(row[key], classes.name)}
        {this.getPlaceHolder(this.getDate(row.addeddate), `${classes.date}`)}
      </div>
    );
  };

  _renderleaveEditPolicy = (row, key, edit) => {
    const { classes } = this.props;
    return (
      <div className="">
        {this.getPlaceHolder(row[key], classes.name)}
        {/* {this.getPlaceHolder(this.getDate(row.addeddate), `${classes.date}`)} */}
      </div>
    );
  };

  _renderleavePolicydate = (row, key, edit) => {
    const { classes } = this.props;
    return (
      <div>
        {/* {this.getPlaceHolder(row[key], classes.name)} */}
        {this.getPlaceHolder(this.getDate(row.addeddate), `${classes.date}`)}
      </div>
    );
  };

  openDoc(row) {
    // this.props.setPreview({ show: true, type: "doc", data: getFormattedImageLink(url) });
    this.props.onPreview(row);
    // this.setState({ showDoc: true, selectedDocUrl: url });
  }

  _renderDocumentPreview = (row, key) => (
    <div
      onClick={() => this.openDoc(row)}
      className="cursour-pointer action-btn"
    >
      {this.getPlaceHolder(row[key])}
      <img src={previewIcon} className="vertical-middle"></img>
    </div>
  );

  _renderDataPreviewTD = (row, key, edit) => (
    <div className="list-data-preview">
      {row.signaturepath ? (
        <div
          onClick={() =>
            this.props.setPreview({
              show: true,
              type: "doc",
              data: getFormattedImageLink(row[key]),
            })
          }
        >
          <img
            className="list-doc-img vertical-center"
            style={{ height: 40, width: 40, borderRadius: 4 }}
            src={getFormattedImageLink(row.signaturepath)}
          ></img>
          {/* <div className="zoom-icon vertical-center">
              <img src={previewIconWhite}></img>
            </div> */}
        </div>
      ) : (
        <div
          style={{
            height: 40,
            width: 40,
            borderRadius: 4,
            background: "#edf5fd",
            margin: "0 auto",
          }}
        >
          <img src={uploadIcon} className="vertical-center"></img>
        </div>
      )}
    </div>
  );

  _renderEditVersionPolicy = (row, key, edit) => {
    const { classes } = this.props;
    return (
      <div
        onClick={() => this.onVersionEdit(row, edit)}
        className="cursour-pointer"
      >
        {this.getPlaceHolder(row[key], classes.name)}
        {/* {this.getPlaceHolder(
          this.getDate(row.addeddate),
          `${classes.date} ${classes.paddingTop10}`,
        )} */}
      </div>
    );
  };

  _renderDateVersionPolicy = (row, key, edit) => {
    const { classes } = this.props;
    return (
      <div
      // onClick={() => this.onVersionEdit(row, edit)}
      // className="cursour-pointer"
      >
        {/* {this.getPlaceHolder(row[key], classes.name)} */}
        {this.getPlaceHolder(
          this.getDate(row.updateddate),
          `${classes.date} ${classes.paddingTop10} ${classes.centerAlight}`,
        )}
      </div>
    );
  };

  _renderBankDetails = (row, key) => {
    const { classes } = this.props;
    return (
      <div onClick={() => this.onEdit(row)} className="cursour-pointer">
        {this.getPlaceHolder(row[key], classes.name)}
      </div>
    );
  };

  _renderSigningAuthorityName = (row, key) => {
    const { classes } = this.props;
    return <div>{this.getPlaceHolder(row[key], classes.name)}</div>;
  };

  _renderSignatoryName = (row, key) => {
    const { classes } = this.props;
    return <div>{this.getPlaceHolder(row[key], classes.name)}</div>;
  };

  _renderCreatedDate = (row, key, appendText, style) => {
    const { classes } = this.props;
    return this.getPlaceHolder(
      this.getDate(row[key]),
      `${classes.date} ${classes.centerAlight}`,
      appendText,
      style,
    );
  };

  _renderSigningAuthorityDetails = (row, key) => {
    const { classes } = this.props;
    return (
      <div onClick={() => this.onEdit(row)} className="cursour-pointer">
        {this.getPlaceHolder(row[key], classes.name)}
      </div>
    );
  };

  _renderEditShift = (row, key, edit) => {
    const { classes } = this.props;
    return (
      <div
        onClick={() => this.onEdit(row, edit)}
        className="cursour-pointer position-rel"
        style={{ height: 40 }}
      >
        <div className="shiftcolor" style={{ background: row.shiftcolour }} />
        {this.getPlaceHolder(row[key], classes.shiftname)}
        {this.getPlaceHolder(this.getDate(row.addeddate), `${classes.date}`)}
      </div>
    );
  };

  _renderOptionalHolidayName = (row, key) => {
    const { classes } = this.props;
    return (
      <div style={{ padding: "11px 0" }}>
        {this.getPlaceHolder(row[key], classes.name)}
        {/* {this.getPlaceHolder(this.getDate(row['addeddate']), (classes.date + ' ' + classes.paddingTop10))} */}
      </div>
    );
  };

  _renderOptionalHolidayDate = (row, key) => {
    const { classes } = this.props;
    return (
      <div>
        {this.getPlaceHolderHolidaydate(
          getFormattedDate(row[key]),
          `${classes.date}`,
        )}
      </div>
    );
  };

  _renderHolidaySelected = (row, key) => {
    const { classes } = this.props;
    return (
      <div className="cursour-pointer">
        {this.DisplayHolidaySelected(row[key], `${classes.date}`)}
      </div>
    );
  };

  closeDrawer() {
    this.setState({ open: false, selectedPolicy: null });
  }

  setSelectedPolicy(policy) {
    console.log("policy: ", policy);
    this.setState({ selectedPolicy: policy, open: true });
  }

  // setLinkedUser(user) {
  //   this.setState({ draweropen: true, selectedUser: user });
  // }

  _renderDocs = (row) => (
    <div style={{ fontWeight: "100", textAlign: "left", width: "300px" }}>
      {get(row, "policydocs", []).map((x) => (
        <div className="ellipsis"> {x.url} </div>
      ))}
    </div>
  );

  _renderLeavesType = (row, key, rowIndex) => {
    const { list } = this.props;
    const versionCount = get(list, `[${rowIndex}][${key}].length`, "");
    // const open = Boolean(anchorEl[rowIndex]);
    return (
      <div>
        <If condition={versionCount > 0}>
          <div
            className="cursour-pointer"
            onClick={() => this.setSelectedPolicy(list[rowIndex])}
            style={{ pointerEvents: "none" }}
          >
            <span onClick={() => this.setSelectedPolicy(list[rowIndex])}>
              {get(list, `[${rowIndex}][${key}].length`, "")}
            </span>
          </div>
        </If>
        <If
          condition={versionCount == 0}
          onClick={() => this.setSelectedPolicy(list[rowIndex])}
        >
          <div className="hide-event">
            <span onClick={() => this.setSelectedPolicy(list[rowIndex])}>
              {get(list, `[${rowIndex}][${key}].length`, "")}
            </span>
          </div>
        </If>
      </div>
    );
  };

  _renderLinkedUser = (row, key, rowIndex, appendText) => {
    // const { list } = this.props;
    const { classes } = this.props;
    return (
      <div>
        <div
          className="cursour-pointer"
          onClick={(e) => this.__renderLinkedUser(e, row)}
        >
          <Typography
            style={{ color: "currentColor" }}
            component="p"
            className={classNames("bank-details-viewpage t-14", classes)}
          >
            {row[key]} {appendText}
          </Typography>
        </div>
      </div>
    );
  };

  renderAnswerType = (row, key, rowIndex) => {
    const x = row[key];
    const name =
      x == 1
        ? "Text"
        : x == 2
        ? "Numeric"
        : x == 3
        ? "Date"
        : x == 4
        ? "Multi-choice"
        : "Multi-select";
    return <div>{x ? <span>{name}</span> : ""}</div>;
  };

  render() {
    const { selectedPolicy, open } = this.state;
    const { classes, list, headers, orderBy, order, showOnlyList } = this.props; // module, header
    // const language = this.props.Language[Modules[value]];
    return (
      <Grid container className="config-table">
        <Grid item xs={12} md={12} sm={12}>
          {!showOnlyList && this.renderSearch()}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          className="list-table"
          style={
            this.props.module == "Leaves version policie"
              ? { marginTop: 72 }
              : {}
          }
        >
          <div>
            <Table>
              {/* {this._renderDropDown(headers, orderBy, order)} */}
              {this._renderTableHeaders(headers, orderBy, order)}
              <TableBody showrowhover="true" className="shift">
                {list && list.map(this._renderTableBody)}
                {list && list.length === 0 && this._renderNoRecord()}
              </TableBody>
            </Table>
          </div>
        </Grid>
        <Drawer
          className={classes.drawer}
          classes={{
            paper: classNames(
              classes.drawerPaper,
              "planner-drawer padding20 padding-top-10",
            ),
          }}
          anchor="right"
          open={open}
          onClose={this.closeDrawer}
        >
          {/* <div className="padding-bottom-10 text-align-center">
            <span style={{ fontWeight: "bold", fontSize: 14 }}>
              Policy Name: <strong className="text-color-blue">{get(selectedPolicy, "leavepolicyname", "")}</strong>
            </span>
          </div> */}
          <DrawerHeader
            header={
              <span>
                Policy Name: &nbsp;
                <strong className="text-color-blue">
                  {get(selectedPolicy, "leavepolicyname", "")}
                </strong>
              </span>
            }
            isbackarrow={false}
            closeDrawer={this.closeDrawer}
          />

          <Table>
            <div>
              <TableHead className="inline-table width100">
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      color: "rgba(0,0,0,0.87)",
                      background: "#edf5fd",
                      padding: "4px 78px 4px 24px",
                    }}
                  >
                    Leave Name
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      color: "rgba(0,0,0,0.87)",
                      background: "#edf5fd",
                      textAlign: "center",
                    }}
                  >
                    Previous Version(s)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="inline-table width100">
                {get(selectedPolicy, "leavepolicyjson", []).map((option) => (
                  <TableRow>
                    <TableCell
                      className="cursor-pointer drawer-leave-name"
                      style={{ width: "52%" }}
                    >
                      <span
                        onClick={() => {
                          this.props.onLeavePlanSelect(
                            selectedPolicy,
                            option,
                            false,
                          );
                        }}
                      >
                        {option.leavename}
                      </span>
                    </TableCell>

                    <If condition={option.versioncount > 0}>
                      <TableCell className="cursor-pointer text-center">
                        <span
                          onClick={() =>
                            this.props._renderVersionList(
                              selectedPolicy,
                              option,
                            )
                          }
                        >
                          <span>{option.versioncount}</span>
                        </span>
                      </TableCell>
                    </If>
                    <If condition={option.versioncount == 0}>
                      <TableCell className="hide-event text-center">
                        <span
                          onClick={() =>
                            this.props._renderVersionList(
                              selectedPolicy,
                              option,
                            )
                          }
                        >
                          <span>{option.versioncount}</span>
                        </span>
                      </TableCell>
                    </If>
                  </TableRow>
                ))}
              </TableBody>
            </div>
          </Table>
        </Drawer>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      RightDrawer: (data) => RightDrawer(data),
      setPreview,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(withStyles(styles)(List));
