import React from "react";
import Grid from "@material-ui/core/Grid";
import mobileviewimage from "Assets/Svg/mobile-view.svg";
import mobileviewandridIcon from "Assets/Svg/getitongoogleplay.svg";
import mobileviewiosIcon from "Assets/Svg/appstore.svg";
import "./index.css";

const MobileView = () => (
  <div
    className="root-display-mobile"
    // className={
    //   window.location.hostname.includes("signup") && window.innerWidth < 768
    //     ? "display-none"
    //     : "root-display-mobile"
    // }

    // style={{
    //   display:
    //     window.innerWidth > 768 && window.location.hostname === "/signup" &&
    //       ? "none"
    //       : "block",
    // }}

    // style={
    //   window.location.hostname === "signup.localhost"
    //     ? { display: "none" }
    //     : { display: "block" }
    // }
  >
    <div
      className="mobileview-left-content position-rel"
      style={{ height: "100vh" }}
    >
      {/* <div className="marginTop40 inline-block">
        <div className="mobileview-android-icon floatleft">
          <a href="https://play.google.com/store/apps/details?id=io.hrms.sumhr">
            <img
              src={mobileviewandridIcon}
              alt="android-icon"
              height="64"
            ></img>
          </a>
        </div>
        <div className="mobileview-ios-icon floatleft marginLeft40">
          <a href="https://itunes.apple.com/us/app/sumhr/id1437519246?mt=8">
            <img src={mobileviewiosIcon} alt="ios-icon" height="64"></img>
          </a>
        </div>
      </div> */}
      <Grid container className="vertical-center" style={{ height: 402 }}>
        <Grid item sm={12} md={12} className="width100">
          <img src={mobileviewimage} alt="mobileviewimage"></img>
          <p className="small-dark t-bold margin-bottom-32 margin-top-32">
            Going mobile and on the go? <br></br> Get our dedicated app!
          </p>
          <a href="https://play.google.com/store/apps/details?id=io.hrms.sumhr">
            <img
              src={mobileviewandridIcon}
              className="display-block"
              style={{ margin: "auto auto 16px auto", textAlign: "center" }}
            ></img>
          </a>
          <a href="https://apps.apple.com/us/app/sumhr-all-in-one-hr-platform/id1500895469">
            <img
              src={mobileviewiosIcon}
              className="margin-bottom-32 display-block text-align-center"
            ></img>
          </a>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default MobileView;
