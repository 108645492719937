import get from "lodash/get";
import createstore from "../../../store";
import { ReducersTypes, ErrorMessages } from "../../../Constants";

export const fetchAllFormsFunction = () => {
  const store = createstore.getState();
  return get(store, "dynamicformReducer.forms", []);
};

export const fetchMyFormFunction = (formKey) => {
  const store = createstore.getState();
  const res = get(store, "dynamicformReducer.forms", []).find(
    (form) => form.formKey === formKey,
  );
  return res;
};

// sets showAllErr flag in selected dynamic form
export const showAllErrorsFunction = (formKey) => {
  console.log("showAllErrorsFunction");
  let myForm;

  const forms = fetchAllFormsFunction();
  const formIndex =
    forms && forms.findIndex((form) => form.formKey === formKey);
  if (formIndex != -1) {
    myForm = forms[formIndex];
    myForm.fields =
      myForm.fields.map((field) => ({
        ...field,
        showAllErr: true,
      }));
    forms[formIndex] = myForm;
  }
  createstore.dispatch({
    type: ReducersTypes.DYNAMIC_FORM,
    payload: { forms },
  });
  // also returns value if required
  return (myForm && myForm.fields) || [];
};

export const validateMyFormFunction = ({ formKey, enqueueSnackbar }) => {
  console.log("formKey: ", formKey);
  const fields = showAllErrorsFunction(formKey);
  if (fields) {
    let error = "";
    fields.forEach((field) => {
      console.log("field: ", field);
      if (field.iserr({ fielddata: field }) && !error) {
        error =
          formKey == "bulRegularizeShiftForm"
            ? null
            : field.errorText || ErrorMessages.E0020;
        console.log("error: ", error);
        if (error) {
          enqueueSnackbar &&
            enqueueSnackbar(error, {
              variant: "error",
            });
        }
        showAllErrorsFunction(formKey);
      }
    });
    return { error, fields };
  }
  return { error: ErrorMessages.E0030 };
};

export const deleteMyFormFunction = (formKey) => {
  const store = createstore.getState();
  const forms = get(store, "dynamicformReducer.forms", []).filter(
    (form) => form.formKey !== formKey,
  );
  createstore.dispatch({
    type: ReducersTypes.DYNAMIC_FORM,
    payload: { forms },
  });
};
