import React from "react";
import { LottiePlayer as Lottie } from "Library/lottie-player";

const lottieObj = {
  loop: true,
  autoplay: true,
  animationData: require("../../Assets/lottie-jsons/404animation.json"),
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

class PageNotFound extends React.Component {
  render() {
    return (
      <div className="innerwrapper">
        {/* <div align="center" className="padding-top-60 align-center">
                <img src={SumhrLogo} alt="logo" />
            </div> */}
        <div>
          <div className="pagenotfound">
            <Lottie options={lottieObj} height={"80%"} width={"100%"} />
          </div>
          {/* <img src={PageNotFoundImg} className="pagenotfound"/> */}
          <div className="pagenotfoundmessage">
            <h3>Oops!!</h3>
            <p>We can’t seem to find the page you are looking for…</p>
            <p>
              <strong>Error Code: 404</strong>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
