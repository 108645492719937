import Paper from "@material-ui/core/Paper";
import noUrlImg from "Assets/Svg/no-url-found.svg";
import crossIcon from "Assets/Svg/wrong-cancel-stop-cross.svg";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Configs from "Constants/Configs";

class TypoError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log(this.props.history);
  }

  render() {
    return (
      <Grid
        container
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid item sm={12} md={4} className="text-center">
          <p
            className="large-text-dark-secondary t-bold margin-bottom-12"
            style={{ color: "#000", fontSize: 25 }}
          >
            Whoops, looks like a typo!
          </p>
          <p
            className="small-dark-secondary  margin-bottom-24"
            style={{ color: "#000" }}
          >
            The URL you have entered is incorrect
          </p>
          <img src={noUrlImg} className="margin-bottom-32"></img>
          <div
            className="display-flex margin-bottom-24"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <img src={crossIcon} className="margin-right-8"></img>
            <p
              className="normal-dark-secondary"
              style={{ color: "#1e8ced", fontSize: 19 }}
            >
              <span style={{ borderBottom: "solid 3px #f60038" }}>
                {this.props.subdomain}
              </span>
              .{Configs.DOMAIN}
            </p>
          </div>

          <p
            className="normal-dark-secondary margin-bottom-12"
            style={{ color: "#000" }}
          >
            Make sure there are no spelling mistakes
          </p>

          <p
            className="normal-dark-secondary margin-bottom-12"
            style={{ color: "#000" }}
          >
            For any assistance please contact{" "}
            <a href="help@sumhr.com" target="_blank" className="active-color">
              help@sumhr.com
            </a>
          </p>
        </Grid>
      </Grid>
    );
  }
}

export default TypoError;
