import React from 'react';
import Lottie from "react-lottie-player";

export function LottiePlayer(params) {
    return <Lottie
    loop
    play
    animationData={params.options.animationData}
    style={{ width: params.width, height: params.height }}
    rendererSettings={params.options.rendererSettings}
    />
}