import React from "react";
import "../../Assets/SCSS/abstracts/inputs.scss";

export const DateTimePicker = (props) => (
  <div className="date-time-picker">

  </div>
);

export default DateTimePicker;
