export default {
  Text: 1,
  Date: 2,
  DateTime: 3,
  Float: 4,
  Time: 5,
  Select: 6,
  TextArea: 7,
  Integer: 8,
  Radio: 9,
  CheckBox: 10,
  MultiSelect: 11,
  ImageInput: 12,
  GroupSelect: 13,
};
