import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import Button from "@material-ui/core/Button";
import TextField from "../../../../../Component/Material-ui/TextField";
import TextArea from "../../../../../Component/Material-ui/TextArea";

export default class NameDesc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notUnique: false,
      shonameerr: false,
    };
  }

  checkUnique() {}

  _onChange = (key, value) => {
    const { postData } = this.props;
    postData[key] = value;
    if (key == "probationperiodname" && value) {
      this.setState({ shonameerr: false });
    }
    this.props.updatePostData(postData);
  };

  _onsubmit = () => {
    const { postData, setActiveTabValue } = this.props;
    if (postData.probationperiodname) {
      setActiveTabValue(1);
    } else {
      this.setState({ shonameerr: true });
    }
  };

  render() {
    return (
      <div className="stepper-scroller padding-top-0">
        <div className="stepper-scroller-content">
          <div className="padding-bottom-30 width100">
            <div className="device-wrapper">
              <p className="form-input-label">
                <FormattedMessage id="LbPolicyName" defaultMessage="Policy Name" />
              </p>
              <div>
                {/* <input
                    type="text"
                    className="form-contryol"
                    autoComplete="off"
                    autoFocus
                    maxLength={50}
                    placeholder="Policy Name"
                    onChange={(e) => this._onChange("probationperiodname", e.target.value)}
                    value={this.props.postData.probationperiodname}
                    onBlur={this.checkUnique.bind(this, false)}
                  /> */}

                <TextField
                  className="expense-cycle-input width100"
                  margin="dense"
                  variant="outlined"
                  placeholder="Policy Name"
                  onChange={(e) => this._onChange("probationperiodname", e.target.value)}
                  value={this.props.postData.probationperiodname}
                  onBlur={this.checkUnique.bind(this, false)}
                  type="text"
                  autoFocus
                  maxLength="100"
                  autoComplete="off"
                />
                {this.state.shonameerr ? <span className="caption-error">*Please add name</span> : null}
              </div>
            </div>
            <div className="padding-bottom-30 device-wrapper">
              <p className="form-input-label">
                <FormattedMessage id="LbDesciption" defaultMessage="Lets write a brief description about this policy" />
              </p>
              <div>
                {/* <textarea
                    className="input-area-section"
                    placeholder="Description"
                    maxLength="200"
                    onChange={(e) => this._onChange("description", e.target.value)}
                    value={this.props.postData.description}
                  /> */}
                <TextArea
                  className="width100"
                  maxLength="200"
                  variant="outlined"
                  placeholder="Description "
                  multiline
                  onChange={(e) => this._onChange("description", e.target.value)}
                  value={this.props.postData.description}
                />
              </div>
            </div>
            <Button onClick={this._onsubmit} variant="contained" color="primary" size="large">
              <FormattedMessage id="BtNext" defaultMessage="save & next" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
