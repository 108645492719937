import React, { Component } from "react";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import CheckImg from "../../../../../Assets/Images/aaa.png";
import InputValues from "../../../../../Component/InputValues";

export default class Details extends Component {
  _onChange = (key, value) => {
    const { postData } = this.props;
    postData[key] = value;
    if (key == "isnotifyemployee" && !value) {
      postData.isnotifyoncompletion = false;
      postData.isnotifyonextention = false;
      postData.isnotifyonconfirmed = false;
      postData.notifypriordays = 0;
    }
    if (key == "isnotifyoncompletion" && !value) {
      postData.notifypriordays = 0;
    }
    this.props.updatePostData(postData);
  };

  _onsubmit = () => {
    this.props._submit(POSTDATA);
  };

  render() {
    const {
      probationdays,
      isearlyconfirmedallowed,
      isautoconfirmed,
      isnotifyemployee,
      notifypriordays,
      isnotifyoncompletion,
      isnotifyonextention,
      isnotifyonconfirmed,
    } = this.props.postData;
    return (
      <div>
        {/** numnof days */}
        <div>
          <p className="depedent-select-list-head margin-bottom-0 ">
            What will be the initial Probation Duration for this Plan?
          </p>
          <div className="probation-days">
            <InputValues
              min="0"
              max="365"
              field="wfhlimit"
              onChange={(e) => this._onChange("probationdays", e.target.value)}
              value={probationdays}
              defaultvalue={0}
            />{" "}
            days
          </div>
        </div>

        <div className="margin-top-20" />
        {/** Is early employee confirmation allowed? */}
        <div className="width50">
          <p className="depedent-select-list-head ">Is early employee confirmation allowed?</p>
          <div
            className="depedent-select-list"
            onClick={() => this._onChange("isearlyconfirmedallowed", true)}
            aria-expanded={isearlyconfirmedallowed}
          >
            <span>Yes</span>
            {isearlyconfirmedallowed ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
          <div
            className="depedent-select-list"
            onClick={() => this._onChange("isearlyconfirmedallowed", false)}
            aria-expanded={!isearlyconfirmedallowed}
          >
            <span>No</span>
            {!isearlyconfirmedallowed ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
        </div>

        <div className="margin-top-20" />
        {/** isautoconfirmed */}
        <div className="width50">
          <p className="depedent-select-list-head ">
            Do you want employee&apos;s status to automatically change from ON-Probation to Confirmed
            when their Probation period is over?
          </p>
          <div
            className="depedent-select-list"
            onClick={() => this._onChange("isautoconfirmed", true)}
            aria-expanded={isautoconfirmed}
          >
            <span>Yes</span>
            {isautoconfirmed ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
          <div
            className="depedent-select-list"
            onClick={() => this._onChange("isautoconfirmed", false)}
            aria-expanded={!isautoconfirmed}
          >
            <span>No</span>
            {!isautoconfirmed ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
        </div>

        <div className="margin-top-20" />
        {/** isnotfemployee */}
        <div className="margin-top-20 width50">
          <p className="depedent-select-list-head ">Do you want to notify employee?</p>
          <div
            className="depedent-select-list"
            onClick={() => this._onChange("isnotifyemployee", true)}
            aria-expanded={isnotifyemployee}
          >
            <span>Yes</span>
            {isnotifyemployee ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
          <div className="et-level2 width100 margin0">
            <Collapse
              in={isnotifyemployee}
              timeout={2}
              unmountOnExit
              className="padding20 overflow-initial"
            >
              <p className="depedent-select-list-head">Select from below options</p>
              <div>
                <div
                  className="depedent-select-list"
                  aria-expanded={true}
                  onClick={() => this._onChange("isnotifyoncompletion", !isnotifyoncompletion)}
                >
                  <span>Notify days before completion</span>
                  {isnotifyoncompletion ? (
                    <span className="floatright selected-box">
                      <svg width="24" height="24" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                      </svg>
                    </span>
                  ) : (
                    <span className="not-selected-box" />
                  )}
                </div>
                <div className="et-level3">
                  <Collapse
                    in={isnotifyoncompletion}
                    timeout={2}
                    unmountOnExit
                    className="padding30 overflow-initial"
                  >
                    <InputValues
                      min="0"
                      max="30"
                      // field="notifypriordays"
                      onChange={(e) => this._onChange("notifypriordays", e.target.value)}
                      // onChange={this.handleChange.bind(this, "notifypriordays")}
                      value={notifypriordays == null ? 0 : notifypriordays}
                      defaultvalue={0}
                    />{" "}
                    days
                  </Collapse>
                </div>
                <div
                  className="depedent-select-list"
                  aria-expanded={true}
                  onClick={() => this._onChange("isnotifyonextention", !isnotifyonextention)}
                >
                  <span>On probation extension</span>
                  {isnotifyonextention ? (
                    <span className="floatright selected-box">
                      <svg width="24" height="24" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                      </svg>
                    </span>
                  ) : (
                    <span className="not-selected-box" />
                  )}
                </div>
                <div
                  className="depedent-select-list"
                  aria-expanded={true}
                  onClick={() => this._onChange("isnotifyonconfirmed", !isnotifyonconfirmed)}
                >
                  <span>On confirmation</span>
                  {isnotifyonconfirmed ? (
                    <span className="floatright selected-box">
                      <svg width="24" height="24" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                      </svg>
                    </span>
                  ) : (
                    <span className="not-selected-box" />
                  )}
                </div>
              </div>
            </Collapse>
          </div>
          <div
            className="depedent-select-list"
            onClick={() => this._onChange("isnotifyemployee", false)}
            aria-expanded={!isnotifyemployee}
          >
            <span>No</span>
            {!isnotifyemployee ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
        </div>

        <div className="margin-top-20" />
        <Button onClick={this._onsubmit} variant="contained" color="primary" size="large">
          Save
        </Button>
      </div>
    );
  }
}

const POSTDATA = {
  probationperiodname: "",
  description: "",
  probationdays: 0,
  isearlyconfirmedallowed: true,
  isautoconfirmed: true,
  isnotifyemployee: true,
  notifypriordays: 0,
  isnotifyoncompletion: false,
  isnotifyonextention: false,
  isnotifyonconfirmed: false,
};
