import { ReducersTypes } from '../../../Constants/ReducersTypes';

const initialState = {
  data: 'here1111',
  currentStep: 0,
  showOnboardingModal: false,
  configStatus: {
    branding: false,
    globaldefault: false,
    officelocation: false,
  },
};

export const OnboardingReducer = (state = initialState, action) => {
  switch (action.type) {
  case ReducersTypes.SET_ONBOARDING_DATA: {
    return { ...state, data: 'udpated' };
  }
  case ReducersTypes.SET_CURRENT_ONBOARDING_STEP: {
    return { ...state, currentStep: action.payload };
  }
  case ReducersTypes.SHOW_ONBOARDING: {
    return { ...state, showOnboardingModal: action.payload };
  }
  case ReducersTypes.SET_ONB_CONFIG_STATUS: {
    console.log('action.payload: ', action.payload);
    return { ...state, configStatus: action.payload };
  }
  case ReducersTypes.RESET_ONBOARDING_DATA: {
    return { ...state };
  }
  default: {
    return state;
  }
  }
};
