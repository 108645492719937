import React from "react";
import Float from "../../Material-ui/Float";
import KEYS from "../KEYS";
import initjson from "../Data/initjson";

export default function FloatComp(props) {
  const [focused, setFocused] = React.useState(false);
  const { field, updateData } = props;
  const {
    value = "",
    iserr = function () {},
    placeholder = "",
    label = "",
    helperText = "",
    className = "",
    disabled = false,
    max = null,
  } = field;

  function handleChange(event) {
    if (event.target.value.includes("-") || parseInt(event.target.value) < 0 || (max && parseFloat(event.target.value) > max)) {
      updateData({ id: field.id, value: "" });
    } else {
      updateData({ id: field.id, value: event.target.value });
    }
  }

  return (
    <div>
      <p className="input-label">{label}</p>
      <Float
        type="number"
        className={`width100 ${className}`}
        variant="outlined"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        error={iserr({ fielddata: field })}
        helperText={focused ? helperText : ""}
        {...props}
        max={max}
        disabled={disabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </div>
  );
}

export const floatinputconverter = (field) => [
  {
    ...initjson,
    ...field,
    typeid: KEYS.Float,
  },
];
