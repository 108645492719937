import Configs from "Constants/Configs";
import get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { downloadFile } from "../../Actions/AxiosInstance";
import { setPreview as setPreviewAction } from "../../Actions/commonAction";
import downloadIcon from "../../Assets/newicons/action/download/outline/download-outline-16px.svg";
import "../../Assets/SCSS/components/Thumbnail.scss";
import cancelWhiteIcon from "../../Assets/Svg/cancel-white.svg";
// import { getFormattedImageLink } from "../../Utils";
import documentIcon from "../../Assets/Svg/document-icon.svg";
import viewIcon from "../../Assets/Svg/view-icon.svg";
import { getUrlExtension } from "../../Handlers/getters";

const getThumbnailFromExt = ({ ext, url }) => {
  switch (ext) {
    case "jpg":
    case "jpeg":
    case "jpe":
    case "jif":
    case "jfi":
    case "png":
    case "jfif":
    case "PNG":
      return <ImgThumbnail url={url} />;
    case "pdf":
      return <PdfThumbnail url={url} />;
    default:
      return <OtherThumbnail url={url} />;
  }
};

const getFormattedImageLink = (link) => {
  const ext1 = getUrlExtension(link);
  // const ext1 = ext || getUrlExtension(docUrl);
  switch (ext1) {
    case "jpg":
    case "jpeg":
    case "png":
    case "jfif":
    case "PNG":
    case "jpe":
    case "jif":
    case "jfi":
      return `${Configs.STORE_URL}${link}`;
    case "pdf":
    case "ppt":
    case "pptx":
    case "doc":
    case "docx":
    case "txt":
    case "text":
    case "rtf":
    case "odt":
      return documentIcon;
    default:
      return null;
  }
};

const openDoc = (url, props, name) => {
  const ext = getUrlExtension(url);
  console.log("ext: ", ext);
  if (
    ext == "jpeg" ||
    ext == "jpg" ||
    ext == "png" ||
    ext == "tif" ||
    ext == "jpeg" ||
    ext == "gif" ||
    ext == "jfif" ||
    ext == "PNG" ||
    ext == "jpe" ||
    ext == "jif" ||
    ext == "jfi"
  ) {
    props.setPreview({
      show: true,
      type: "doc",
      data: getFormattedImageLink(url),
    });
  } else {
    // this.props.downloadFile(getFormattedImageLink(url), name);
    downloadFile(url, name);
  }
};

//  const downloadDoc = (url, name) => {
//   downloadFile(url, name);
// };

const ThumbnailSmall = (props) => {
  const URL = get(props, "doc.url", "");
  const ext = getUrlExtension(URL);
  const iconwrapper =
    !(ext == "jpg" || ext == "jpg" || ext == "png") && "top-140";
  return (
    <div className="add-attachment">
      <div style={{ height: "100%" }}>
        <img
          src={getFormattedImageLink(URL)}
          className="expense-attachment-img"
        />
        {props.canDelete && (
          <img
            src={cancelWhiteIcon}
            className="remove-attachment-icon"
            onClick={() => props.onDeleteAttachment()}
          />
        )}
        <div className="view-icon-bg" onClick={() => openDoc(URL, props)}>
          {ext == "jpeg" ||
          ext == "jpg" ||
          ext == "png" ||
          ext == "tif" ||
          ext == "jpeg" ||
          ext == "gif" ||
          ext == "jfif" ||
          ext == "PNG" ||
          ext == "jpe" ||
          ext == "jif" ||
          ext == "jfi" ? (
            <img src={viewIcon} className="view-icon" />
          ) : (
            <img src={downloadIcon} className="view-icon" />
          )}
        </div>
      </div>
      {/* <ToolTip title="Insert attachment" placement="right">
                                    <Add className="add-attachement-icon" />
                                  </ToolTip> */}
    </div>
  );
};

const ImgThumbnail = ({ url }) => (
  <img src={url} className="thumbnail-big" alt="img" />
);

const PdfThumbnail = ({ url }) => (
  <div className="thumbnail-big text-align-center">
    <i className="material-icons thumbnail-big-pdficon">picture_as_pdf</i>
  </div>
);

const OtherThumbnail = ({ url }) => (
  <div className="thumbnail-big text-align-center">
    <i className="material-icons thumbnail-big-othersicon">attachment</i>
  </div>
);

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setPreview: setPreviewAction }, dispatch);
}

export default connect(null, mapDispatchToProps)(ThumbnailSmall);
