import get from "lodash/get";
import { ApiReducerType, FEATUREKEYS, ReducersTypes } from "../Constants";
import createstore from "../store";
import { instance } from "./AxiosInstance";

export const setOpenPageHelp = (data) => ({
  type: ReducersTypes.OPEN_PAGE_HELP,
  payload: data,
});

export const setPageHelptext = (data) => ({
  type: ReducersTypes.SET_PAGE_HELPTEXT,
  payload: data,
});

export const setOpenFormHelp = (data) => ({
  type: ReducersTypes.OPEN_FORM_HELP,
  payload: data,
});

export const setHelperText = (data) => ({
  type: ReducersTypes.SETHELPERTEXT,
  payload: data,
});

export const setHelperTexDrawerStatus = (data) => ({
  type: ReducersTypes.HELPER_TEXT_DRAWER_STATUS,
  payload: data,
});

export const RightDrawer = (data) => ({
  type: ReducersTypes.RIGHTDRAWER,
  payload: data,
});

export const setPreview = (data) => ({
  type: ReducersTypes.PREVIEW_DOCUMENT,
  payload: data,
});

export const removePreview = () => ({
  type: ReducersTypes.PREVIEW_DOCUMENT,
  payload: {
    show: false,
    type: "",
    data: "",
    name: "",
    isbase64: false,
    ext: "",
  },
});

export const setDynamicForm = (data) => ({
  type: ReducersTypes.DYNAMIC_FORM,
  payload: data,
});

export const addDynamicForm = (data) => ({
  type: ReducersTypes.ADD_DYNAMIC_FORM,
  payload: data,
});

export const refreshProfileInfo = (data) => ({
  type: ReducersTypes.REFRESHPROFILEINFO,
  payload: data,
});

export const profilePrivacyInfo = (data) => ({
  type: ReducersTypes.PROFILEPRIVACYINFO,
  payload: data,
});

export const SetRequestCenter = (data) => ({
  type: `${ReducersTypes.REQUESTCENTER}_UPDATED`,
  payload: data,
});

export const EmployeeRequestList = (data) => ({
  type: ReducersTypes.EMPREQUESTCENTERLIST,
  payload: instance.post("requestcenter/emprequestlist", data),
});

export const ApprovalWorkflow = (data) => ({
  type: ReducersTypes.APPROVALWORKFLOW,
  payload: data,
});

export const ExtraTimeData = (data) => ({
  type: ReducersTypes.EXTRATIMEDATA,
  payload: data,
});

export const AttendancePolicyData = (data) => ({
  type: ReducersTypes.ATTENDANCEPOLICY,
  payload: data,
});

export const CALPROGRESS = (data) => ({
  type: ReducersTypes.CALPROGRESS,
  payload: data,
});

export const CommonReducerAction = (type, data) => ({
  type,
  payload: data,
});

export const CommonApiAction = (type, method, url, data) => ({
  type,
  payload: instance[method](url, data || {}),
});

export const GetAllAttendancePolicy = (url) => ({
  type: ApiReducerType.ATTENDANCE_POLICY,
  payload: instance.get(url),
});

export const GetAllExtraTime = (URL) => ({
  type: ApiReducerType.EXTRA_TIME,
  payload: instance.get(URL),
});

// export const GetAllExtraTime = (status) => ({
//     type: ApiReducerType.EXTRA_TIME,
//     payload: instance.get(`/attendance/allextratimepolicybysubid/${status ? status : 1}`)
// })

export const GetAllShift = (URL) => ({
  type: ApiReducerType.ALL_SHIFT,
  payload: instance.get(URL),
  // payload: instance.get(`attendance/shiftbysubscriptionid/${status ? status : 1}`)
});

export const ShiftPlanner = (data) => ({
  type: ReducersTypes.SHIFTPLANNER,
  payload: data,
});

export const ShowFeature = (featurekey) => {
  const { AuthenticationAction } = createstore.getState();
  const result = AuthenticationAction.login.features.find(
    (x) => x.featureid == FEATUREKEYS[featurekey],
  );
  if (result && result != -1 && result.permissions) {
    return true;
  }
  return false;
};

export const GetFeature = (featurekey) => {
  const { AuthenticationAction } = createstore.getState();
  const result = get(AuthenticationAction, "login.features", []).find(
    (x) => x.featureid == FEATUREKEYS[featurekey],
  );
  if (result && result != -1 && result.permissions) {
    return result;
  }
  return null;
};

export function CheckEditPermission(featurekey) {
  const featureData = GetFeature(featurekey);
  if (featureData) {
    return get(featureData, "permissions.editable", false);
  }
  return false;
}

export function CheckViewPermission(featurekey) {
  const featureData = GetFeature(featurekey);
  if (featureData) {
    return get(featureData, "permissions.readOnly", false);
  }
  return false;
}

export const CheckActionPermission = (featurekey) => {
  const { AuthenticationAction } = createstore.getState();
  const result = AuthenticationAction.login.features.find(
    (x) => x.featureid == FEATUREKEYS[featurekey],
  );
  if (result && result != -1 && result.permissions) {
    return [
      true,
      {
        readOnly: get(result, "permissions.readOnly", false),
        editable: get(result, "permissions.editable", false),
      },
    ];
  }
  return [false, { readOnly: false, editable: false }];
};

export const getFormattedPermissions = (list) => {
  console.log("getFormattedPermissions list", list);
  const result = list.map((x) => ({
    ...x,
    permissions: {
      readOnly: x.actionid == 2,
      editable: x.actionid != 2,
    },
    isconfigmode: x.featureid > 999,
  }));
  console.log("getFormattedPermissions result", result);
  return result;
};

/* prev checkactionpermission
export const CheckActionPermission = (URL, actionid = null) => {
    let AuthenticationAction = createstore.getState().AuthenticationAction
    let actionlistObject = AuthenticationAction.login.menuaction.find(x => x.routing == URL)

    if (actionlistObject) {
        if (actionlistObject.actionidlist.includes(actionid ? actionid : 2)) {
            console.log([true, actionlistObject])
            return [true, actionlistObject]
        } else {
            return [false]
        }
    } else {
        return [false]
    }
}
*/

export const AttendanceDevice = (data) => ({
  type: ReducersTypes.ATTENDANCEDEVICE,
  payload: data,
});

export const GetAllDevicePolicy = (URL) => ({
  type: ApiReducerType.DEVICE_POLICY,
  payload: instance.get(URL),
});

export const GetAllHolidayPlan = (URL, data) => ({
  type: ApiReducerType.HOLIDAY_PLAN,
  payload: instance.post(URL, data),
});

export const HolidayPlanInfo = (data) => ({
  type: ReducersTypes.HOLIDAYPLANINFO,
  payload: data,
});

export const WeekoffPlanner = (data) => ({
  type: ReducersTypes.WEEKOFFPOLICY,
  payload: data,
});

export const reactVersion = (data) => ({
  type: ReducersTypes.REACTVERSION,
  payload: data,
});

export const PushNotificationActive = (data) => ({
  type: ReducersTypes.PUSH_NOTIFICATION_ACTIVE,
  payload: data,
});

export const SetSelection = (data) => ({ type: ReducersTypes.OPENSELECTION, payload: data });

export const SetAppMode = (data) => ({ type: ReducersTypes.APP_MODE, payload: data });

export const SetDrawerID = (data) => ({
  type: ReducersTypes.SELECTED_DRAWER_ID,
  payload: data,
});

export const SetMenuList = (data) => ({
  type: ReducersTypes.MENU_LIST,
  payload: data,
});

export const SetMenuData = (data) => ({
  type: ReducersTypes.MENU_DATA,
  payload: data,
});

export const SetClickedBreadCrum = (data) => ({
  type: ReducersTypes.CURRENT_BREADCRUM,
  payload: data,
});

export const SetOpenLeftDrawer = (data) => ({
  type: ReducersTypes.OPEN_LEFT_DRAWER,
  payload: data,
});
export const SetAllApps = (data) => ({
  type: ReducersTypes.OPEN_ALL_APPS,
  payload: data,
});
export const SalaryStructureInfo = (data) => ({
  type: ReducersTypes.SALARYSTURUCTURE,
  payload: data,
});

export const onBoardingDone = (data) => ({
  type: ReducersTypes.UPDATEONBOARDINGSTATUS,
  payload: data,
});

export const NoOfEmployees = () => ({
  type: ApiReducerType.NO_OF_EMPLOLYEES,
  payload: instance.get("superuser/getmastercode/HRMemberCountType"),
});

export const PainPoints = () => ({
  type: ApiReducerType.PAIN_POINTS,
  payload: instance.get("superuser/getmastercode/PainType"),
});

export const reloadPopup = (e) => ({
  type: ReducersTypes.SHOWRELOADPOPUP,
  payload: e,
});

export const clockin = (e) => ({
  type: ReducersTypes.SETCLOCKINDATA,
  payload: e,
});

export const duration = (e) => ({
  type: ReducersTypes.DURATION,
  payload: e,
});

export const SaveEmployeeid = (data) => ({
  type: ReducersTypes.SETEMPLOYEEID,
  payload: data,
});
