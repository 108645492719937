import cloneDeep from "lodash/cloneDeep";
import { extraTimeJson } from "../Module/Subscription/Track/Attendance/ExtraTime/extraTimeJson";
import { InitialData, AttendanceInitialData } from "../Module/Subscription/Track/Attendance/Policy/InitialData";
import { InitialData as LeavesInitialData } from "../Module/Subscription/Track/Leaves/Policy/InitialData/InitialData";
import { ReducersTypes } from "../Constants";
import Configs from "Constants/Configs";

// export const helperTextReducer = (state = { helperText: '' }, action) => {
//     switch (action.type) {
//         case ReducersTypes.SETHELPERRATION:TEXT:
//             return { ...state, helperText: action.payload };
//         default:
//             return state;
//     }
// }

export const helperTextReducer = (
  state = {
    helperText: "",
    open: false,
    openFormHelp: false,
    openPageHelp: true,
    pageHelpText: "",
  },
  action,
) => {
  switch (action.type) {
    case ReducersTypes.SETHELPERTEXT:
      return { ...state, ...action.payload };
    case ReducersTypes.HELPER_TEXT_DRAWER_STATUS:
      return { ...state, open: action.payload };
    case ReducersTypes.OPEN_FORM_HELP:
      return { ...state, openFormHelp: action.payload };
    case ReducersTypes.OPEN_PAGE_HELP:
      return { ...state, openPageHelp: action.payload };
    case ReducersTypes.SET_PAGE_HELPTEXT:
      return { ...state, pageHelpText: action.payload };
    default:
      return state;
  }
};

export const DrawerReducer = (
  state = {
    drawerdata: "",
    configid: [],
    holidayyear: "",
    open: "",
    side: false,
    empid: "",
  },
  action,
) => {
  switch (action.type) {
    case ReducersTypes.RIGHTDRAWER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const PreviewReducer = (
  state = {
    show: false,
    type: "", // doc, policy, info
    data: "",
    name: "",
    isbase64: false,
    ext: "",
  },
  action,
) => {
  switch (action.type) {
    case ReducersTypes.PREVIEW_DOCUMENT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const dynamicformReducer = (
  state = {
    forms: [],
    count: 0,
  },
  action,
) => {
  switch (action.type) {
    case ReducersTypes.DYNAMIC_FORM:
      return { ...state, forms: action.payload.forms };
    case ReducersTypes.ADD_DYNAMIC_FORM:
      return { ...state, forms: [...state.forms, ...action.payload.forms], count: state.count + 1 };
    default:
      return state;
  }
};

export const ProgressbarReducer = (
  state = {
    skills: "",
    lifeevent: "",
    lang: "",
    address: "",
    contact: "",
    personal: "",
    dates: "",
    dependents: "",
    medical: "",
    identity: "",
    banking: "",
    about: "",
    interest: "",
    music: "",
    movies: "",
    books: "",
  },
  action,
) => {
  switch (action.type) {
    case ReducersTypes.CALPROGRESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const refreshProfileInfoReducer = (state = { doRefresh: false }, action) => {
  switch (action.type) {
    case ReducersTypes.REFRESHPROFILEINFO:
      return { ...state, doRefresh: action.payload };

    default:
      return state;
  }
};

export const refreshPagePopupReducer = (state = {}, action) => {
  switch (action.type) {
    case ReducersTypes.SHOWRELOADPOPUP: {
      action.payload.returnvalue = "eee";
      return state;
    }

    default:
      return state;
  }
};

export const profilePrivacyInfoReducer = (state = { privacyData: [] }, action) => {
  switch (action.type) {
    case ReducersTypes.PROFILEPRIVACYINFO:
      return { ...state, privacyData: action.payload };
    default:
      return state;
  }
};

export const approvalWorkflowReducer = (state = { approvalworkflowdetail: [] }, action) => {
  switch (action.type) {
    case ReducersTypes.APPROVALWORKFLOW:
      return { ...state, ...action.payload };
    case ReducersTypes.CLEARAPPROVALWORKFLOW:
      return {};
    default:
      return state;
  }
};

export const extraTimeDataReducer = (state = extraTimeJson, action) => {
  switch (action.type) {
    case ReducersTypes.EXTRATIMEDATA:
      return { ...state, ...action.payload };
    case ReducersTypes.CLEAREXTRATIMEDATA: {
      const data = extraTimeJson;
      delete data.extratime;
      const extratime = [
        {
          extratimeid: 0,
          isworkingbenefit: false,
          benefittypeid: 0,
          requiredhour: "00:00:00",
          maxearninghour: "00:00:00",
          creditcompoff: 0,
          maxearningcompoff: 0,
          isaccumulation: false,
          accumulationmonths: 0,
          isonweekoff: false,
          isonholiday: false,
          isonoptionalholiday: false,
          isconsiderbuffertime: false,
          buffertime: "00:00:00",
        },
        {
          extratimeid: 0,
          isworkingbenefit: false,
          benefittypeid: 0,
          requiredhour: "00:00:00",
          maxearninghour: "00:00:00",
          creditcompoff: 0,
          maxearningcompoff: 0,
          isaccumulation: false,
          accumulationmonths: 0,
          isonweekoff: true,
          isonholiday: true,
          isonoptionalholiday: true,
          isconsiderbuffertime: false,
          buffertime: "00:00:00",
        },
      ];
      return { ...data, extratime };
    }
    default:
      return state;
  }
};

export const attendanceDeviceReducer = (state = { allowedweddeviceip: [] }, action) => {
  switch (action.type) {
    case ReducersTypes.ATTENDANCEDEVICE: {
      return { ...state, ...action.payload };
    }
    case ReducersTypes.CLEARATTENDANCEDEVICE:
      return {};
    default:
      return state;
  }
};

export const InitAttendancePolicy = (state = InitialData, action) => {
  switch (action.type) {
    case ReducersTypes.ATTENDANCEPOLICY:
      return { ...state, ...action.payload };
    case ReducersTypes.INITATTENDANCEPOLICY:
      return AttendanceInitialData;
    default:
      return state;
  }
};
export const InitExitPolicy = (state = InitialData, action) => {
  switch (action.type) {
    case ReducersTypes.EXITREASONSPOLICY:
      return { ...state, ...action.payload };
    // case ReducersTypes.INITREASONSEXITPOLICY:
    //   return AttendanceInitialData;
    //   break;
    default:
      return state;
  }
};

export const holidayPlanReducer = (state = { holiday: [] }, action) => {
  switch (action.type) {
    case ReducersTypes.HOLIDAYPLANINFO:
      return { ...state, ...action.payload };
    case ReducersTypes.CLEARHOLIDAYPLANINFO:
      return {};
    default:
      return state;
  }
};
export const shiftPlannerReducer = (state = { shifttiming: [] }, action) => {
  switch (action.type) {
    case ReducersTypes.SHIFTPLANNER:
      return { ...state, ...action.payload };
    case ReducersTypes.CLEARSHIFTPLANNER:
      return {};
    default:
      return state;
  }
};
export const weekoffPlannerReducer = (state = { weekoffdetail: [], weekoffdays: [] }, action) => {
  switch (action.type) {
    case ReducersTypes.WEEKOFFPOLICY:
      return { ...state, ...action.payload };
    case ReducersTypes.CLEARWEEKOFF:
      return {};
    default:
      return state;
  }
};

export const reactVersion = (state = { version: Configs.APP_VERSION }, action) => {
  switch (action.type) {
    case ReducersTypes.REACTVERSION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const InitLeavesPolicy = (state = LeavesInitialData, action) => {
  switch (action.type) {
    case ReducersTypes.LEAVESPOLICY: {
      return { ...state, ...action.payload };
    }
    case ReducersTypes.INITLEAVESPOLICY:
      return {
        leavepolicyid: null,
        leavepolicyname: null,
        description: null,
        isdraft: true,
        details: [
          {
            leavetypeid: 4,
            leavetypename: "Unpaid Leave",
            leavecategoryid: 1,
            leavepolicydetailid: null,
          },
        ],
        policy: [],
        isviewmode: false,
      };
    default:
      return state;
  }
};

export const salaryStructureReducer = (state = { holiday: [] }, action) => {
  switch (action.type) {
    case ReducersTypes.SALARYSTURUCTURE:
      return { ...state, ...action.payload };
    case ReducersTypes.CLEARSALARYSTURUCTURE:
      return {};
    default:
      return state;
  }
};

export const SaveInProgressReducer = (state = { issaveinprogress: false }, action) => {
  switch (action.type) {
    case ReducersTypes.SETSAVEPROGRESS:
      return { ...state, issaveinprogress: action.payload };
    case ReducersTypes.REMOVESAVEPROGRESS:
      return { ...state, issaveinprogress: false };
    default:
      return state;
  }
};

export const SaveEmployeeid = (state = { employeeid: "" }, action) => {
  switch (action.type) {
    case ReducersTypes.SETEMPLOYEEID:
      return { ...state, employeeid: action.payload };
    default:
      return state;
  }
};

export const LoaderReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case ReducersTypes.LOADINGPENDING:
      return { ...state, loading: true };
    case ReducersTypes.LOADINGDONE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const clockinReducer = (state = { clockin: [] }, action) => {
  switch (action.type) {
    case ReducersTypes.SETCLOCKINDATA:
      return { ...state, clockin: action.payload };
    default:
      return state;
  }
};

export const planDuration = (state = { duration: [] }, action) => {
  switch (action.type) {
    case ReducersTypes.DURATION:
      return { ...state, duration: action.payload };
    default:
      return state;
  }
};

const initialState = {
  allMenu: [],
  globalSelection: "",
  selectedDrawerId: "-100",
  menuList: [],
  pushnotificationactive: false,
  menuData: [],
  clickedBreadCrumOption: "",
  openLeftDrawer: true,
  appMode: "normal",
};

export const FCMReducer = (
  state = {
    devicetokenid: null,
    type: null,
    values: [],
    notification: {},
  },
  action,
) => {
  switch (action.type) {
    case ReducersTypes.FCM:
      return {
        ...state,
        devicetokenid: action.payload && action.payload.devicetokenid ? action.payload.devicetokenid : state.devicetokenid,
        notification: action.payload && action.payload.notification ? action.payload.notification : {},
        type: action.payload && action.payload.data ? action.payload.data.key : null,
        values: action.payload && action.payload.data ? action.payload.data.values : [],
      };
    default:
      return state;
  }
};

export const selectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReducersTypes.OPENSELECTION:
      return { ...state, globalSelection: action.payload, selectedDrawerId: "" };
    case ReducersTypes.SELECTED_DRAWER_ID:
      return { ...state, selectedDrawerId: action.payload == 9000 ? "" : action.payload, globalSelection: "" };
    case ReducersTypes.MENU_LIST:
      return { ...state, menuList: cloneDeep(action.payload) };
    case ReducersTypes.OPEN_LEFT_DRAWER:
      return { ...state, openLeftDrawer: action.payload };
    case ReducersTypes.OPEN_ALL_APPS:
      return { ...state, isallappsopen: action.payload };
    case ReducersTypes.MENU_DATA:
      return { ...state, menuData: action.payload };
    case ReducersTypes.PUSH_NOTIFICATION_ACTIVE:
      return { ...state, pushnotificationactive: action.payload };
    case ReducersTypes.CURRENT_BREADCRUM:
      return { ...state, clickedBreadCrumOption: action.payload };
    case ReducersTypes.APP_MODE:
      return { ...state, appMode: action.payload };
    case "CLEARSELECTION":
      return {
        ...state,
        globalSelection: "",
      };
    default:
      return state;
  }
};

export default {
  helperTextReducer,
  DrawerReducer,
  PreviewReducer,
  dynamicformReducer,
  ProgressbarReducer,
  refreshProfileInfoReducer,
  profilePrivacyInfoReducer,
  approvalWorkflowReducer,
  extraTimeDataReducer,
  attendanceDeviceReducer,
  InitAttendancePolicy,
  holidayPlanReducer,
  shiftPlannerReducer,
  InitLeavesPolicy,
  weekoffPlannerReducer,
  selectionReducer,
  FCMReducer,
  refreshPagePopupReducer,
  SaveInProgressReducer,
  LoaderReducer,
  SaveEmployeeid,
  clockinReducer,
  planDuration,
  reactVersion,
};
