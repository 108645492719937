import React, { Component } from "react";
import Collapse from "@material-ui/core/Collapse";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { instance } from "../../../../../Actions/AxiosInstance";
import CheckImg from "../../../../../Assets/Images/aaa.png";

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: null,
      teamheadid: null,
      teamheadname: "",
      teamid: null,
      teamname: "",
      isteam: true,
      selectedEmp: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
    this.updateDropdown = this.updateDropdown.bind(this);
    this.SearchEmployee = this.SearchEmployee.bind(this);
  }

  componentDidMount() {
    const { postData, employeeList } = this.props;
    const emp =
      employeeList &&
      employeeList.find(
        (emp1) => postData && emp1.displayvalue == postData.teamheadid,
      );
    this.setState({
      description: null,
      teamheadid: postData && postData.teamheadid,
      teamheadname: postData && postData.teamheadname,
      teamid: postData && postData.teamid,
      teamname: postData && postData.teamname,
      isteam: postData && postData.teamid ? !!postData.teamheadid : true,
      selectedEmp: emp,
    });
  }

  SearchEmployee(search) {
    if (search.length < 3) return;
    if (
      (!this.state.prevSearch || this.state.prevSearch != search) &&
      search.length >= 3
    ) {
      const URL = `/subscription/employeelist`;
      const data = {
        searchfor: search,
        statusid: 1,
        featurekey: "team",
      };
      instance
        .post(URL, data)
        .then((result) => {
          this.setState({ emplist: result.data.result });
        })
        .catch((error) => {
          this.props.enqueueSnackbar(
            error.response ? error.response.data.error.message : error.message,
            { variant: "error" },
          );
        });
    }
  }

  onChange(key, value) {
    switch (key) {
      case "isteam": {
        if (value) {
          this.setState({
            [key]: value,
          });
        } else {
          this.setState({
            [key]: value,
            teamheadid: null,
            teamheadname: null,
            selectedEmp: null,
          });
        }
        break;
      }
      case "teamname": {
        this.setState({
          [key]: value.target.value,
        });
        break;
      }
      default:
        break;
    }
  }

  updateDropdown(key, selectedValues) {
    if (key == "teamheadid") {
      this.setState({
        teamheadname: selectedValues && selectedValues.displayname,
        teamheadid: selectedValues && selectedValues.displayvalue,
        selectedEmp: selectedValues,
      });
    }
  }

  onsubmit() {
    const { teamname, teamid, teamheadid, isteam } = this.state;
    if (!teamname || teamname == "") {
      this.props.enqueueSnackbar("Please enter team name", {
        variant: "error",
      });
      return;
    } else if (isteam) {
      if (!teamheadid) {
        this.props.enqueueSnackbar("Please select team head", {
          variant: "error",
        });
        return;
      }
    }

    const data = {
      teamheadid,
      teamname,
      teamid,
      description: null,
    };
    this.props.submit(data);
  }

  render() {
    const { isteam } = this.state;
    return (
      <div>
        <div className="width50">
          <p className="form-sub-header ">Would you like to call this Team?</p>
          <div>
            <p className="form-input-label">Team Name</p>
            <TextField
              id="outlined-dense"
              margin="dense"
              variant="outlined"
              style={{ width: "100%" }}
              placeholder="Enter team name"
              onChange={(e) => this.onChange("teamname", e)}
              value={this.state.teamname}
              maxLength="100"
              className="form-text-input"
            />
          </div>
        </div>

        <div className="margin-top-20 width50">
          <p className="form-input-label">Do you want to assign a Team Lead?</p>
          <div
            className="depedent-select-list"
            onClick={() => this.onChange("isteam", true)}
            aria-expanded={isteam}
          >
            <span>Yes</span>
            {isteam ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
          <div className="et-level2 width100 margin0">
            <Collapse
              in={isteam}
              timeout={2}
              unmountOnExit
              className="padding20 overflow-initial"
            >
              <p className="depedent-select-list-head">Select team lead</p>
              <Select
                className="selectsearch inlindClass width70"
                onChange={(e) => this.updateDropdown("teamheadid", e)}
                options={this.props.employeeList}
                getOptionLabel={(option) => option.displayname}
                getOptionValue={(option) => option.displayvalue}
                value={this.state.selectedEmp}
                placeholder="Select Employee"
                isClearable={true}
              />
            </Collapse>
          </div>
          <div
            className="depedent-select-list"
            onClick={() => this.onChange("isteam", false)}
            aria-expanded={!isteam}
          >
            <span>No</span>
            {!isteam ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
        </div>

        <div className="margin-top-20" />
        <Button
          onClick={this.onsubmit}
          variant="contained"
          color="primary"
          size="large"
        >
          Save
        </Button>
      </div>
    );
  }
}
