import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { withSnackbar } from "notistack";
import React from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { instance } from "../../../../../Actions/AxiosInstance";
import { CheckActionPermission } from "../../../../../Actions/commonAction";
import { ChangeLanguage } from "../../../../../Actions/LanguageAction";
import probationConcept from "../../../../../Assets/Images/learning.svg";
import { CreateComp, List, TDTypeConsts } from "../../commons";
import "../../company/styles/index.css";
import { getProbationList, updateRespectiveList } from "../actions";
import ActionConstants from "../constants";
import { getBusinessConfig } from "../selectors";
import Details from "./Details";
import NameDesc from "./NameDesc";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginRight: "10px",
  },
  typography: {},
});

class ProbationPlanIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      activeTabValue: 0, // 0
      showBorder: true,
      permissions: { readOnly: false, editable: false },
      postData: POSTDATA,
    };
    this.allTabData = {};
    this.currentData = null;
    this.updatePostData = this.updatePostData.bind(this);
    this.headers = [
      {
        key: "probationperiodname",
        name: (
          <FormattedMessage
            id="LbProbationPlanName"
            defaultMessage="Probation Plan Name"
          />
        ),
        type: TDTypeConsts.GRADE_NAME,
        style: { textAlign: "left" },
      },
      {
        key: "addedbyimagepath",
        name: <FormattedMessage id="LbCreatedBy" defaultMessage="Created By" />,
        type: TDTypeConsts.CREATED_BY,
      },
      {
        key: "assignedEmployeeList",
        name: (
          <FormattedMessage
            id="LbAssignedEmployee"
            defaultMessage="Assigned Employee"
          />
        ),
        type: TDTypeConsts.ASSIGNED_USER /* style: { padding: '24' } */,
      },
      {
        key: "probationdays",
        name: (
          <FormattedMessage
            id="LbProbationconfirmation"
            defaultMessage="Probation Duration"
          />
        ),
        type: TDTypeConsts.OTHERS,
        appendText: "Days",
        rowStyle: { fontSize: "29.3px" },
      },
      {
        key: "edit",
        name: "",
        type: TDTypeConsts.EDIT_WITHOUT_HEADER,
      },
      {
        key: "delete",
        name: "",
        type: TDTypeConsts.DELETE_WITHOUT_HEADER,
      },
    ];
    this.doEditId = -1;
  }

  componentDidMount() {
    this.props.ChangeLanguage("probationplan");
    // const [isTrue, actionlistObject] = CheckActionPermission(this.props.history.location.pathname)

    const [isTrue, permissions] = CheckActionPermission("probation");
    if (isTrue) {
      this.setState({ permissions }, () => {
        this.props.getProbationList();
        if (!this.state.permissions.editable) {
          const index = this.headers.findIndex(
            (x) => x.type == TDTypeConsts.EDIT_WITHOUT_HEADER,
          );
          if (index != -1) {
            this.headers.splice(index, 1);
          }
        }
        if (!this.state.permissions.editable) {
          const index = this.headers.findIndex(
            (x) => x.type == TDTypeConsts.DELETE_WITHOUT_HEADER,
          );
          if (index != -1) {
            this.headers.splice(index, 1);
          }
          // this.headers
          // this._renderComponent()
        }
      });
    } else {
      // this.props.history.push('404')
    }
  }

  getText() {
    return (
      <span>
        <FormattedMessage
          id="probationConcept"
          defaultMessage="A probation plan lets you assign flexible and automatic workflows that help streamline the probation process. Probation plans will keep all stakeholders (employees, managers & HR team) in-sync with the ongoing and upcoming probation confirmations in the company."
        />
      </span>
    );
  }

  _renderCreate = () => (
    <CreateComp
      text={this.getText()}
      heading={
        <FormattedMessage
          id="LbProbationConcept"
          defaultMessage="Concept of Probation Plan"
        />
      }
      onCreate={this._onCreate}
      imageSrc={probationConcept}
      permissions={this.state.permissions}
    />
  );

  showSnackbar = (msg, variant, autoHideDuration) => {
    this.props.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: autoHideDuration || 1000,
    });
  };

  _onCreate = () => {
    const POSTDATA = {
      probationperiodname: "",
      description: "",
      probationdays: 0,
      isearlyconfirmedallowed: true,
      isautoconfirmed: true,
      isnotifyemployee: true,
      notifypriordays: 0,
      isnotifyoncompletion: false,
      isnotifyonextention: false,
      isnotifyonconfirmed: false,
    };
    this.setState({ postData: POSTDATA, activeTabValue: 0 });
    this._setEditPage();
  };

  _setEditPage = () => {
    console.log(this.props);
    const { match } = this.props;
    this.props.history.push(`${match.path}/create`);
  };

  onEdit = (item) => {
    instance
      .get(`subscription/probationperiod/${item.probationperiodid}`)
      .then((response) => {
        this.setState(
          { postData: response.data.result[0], activeTabValue: 0 },
          () => this._setEditPage(),
        );
      });
    console.log(this.state.postData);
  };

  onDelete = (item) => {
    const { probationList } = this.props;
    const { list } = probationList;
    const id = item.probationperiodid;
    const index = list.indexOf(item);
    instance.delete(`subscription/probationperiod/${id}`).then(
      () => {
        list.splice(index, 1);
        this.showSnackbar("Probation Plan successfully deleted.", "success");
        this.props.updateRespectiveList(ActionConstants.PROBATION_PLAN, list);
        // have to reRender as custom action will not trigger re-render (reason typeToReducer)
        this.setState({ reRender: true }, () => this.props.getProbationList());
      },
      (error) => {
        const msg =
          error.response.data.error.message ||
          "Error while deleting Probation Plan.";
        this.showSnackbar(msg, "error", 2000);
      },
    );
  };

  _renderList = () => {
    const { probationList } = this.props;
    this.doEditId = -1;
    return (
      <div>
        <List
          list={probationList.list}
          headers={this.headers}
          onCreate={this._onCreate}
          onEdit={this.onEdit}
          onDelete={this.onDelete}
          module={"probation plan"}
          goTo={this.props.goTo}
          permissions={this.state.permissions}
        />
      </div>
    );
  };

  changeProbationActiveTab = () => {
    this.setState({ activeTabValue: 0 });
  };

  _renderComponent = () => {
    const language = this.props.Language.probationplan;
    const { probationList } = this.props;
    if (probationList.IsPending) {
      return this.showLoader();
    }
    return (
      <IntlProvider locale="en" messages={language}>
        <div className="content-outer-wrapper">
          {probationList.list &&
            probationList.list.length == 0 &&
            this._renderCreate()}
          {probationList.list &&
            probationList.list.length > 0 &&
            this._renderList()}
        </div>
      </IntlProvider>
    );
  };

  handleTabChange = (e, activeTabValue) => {
    this.setState({ activeTabValue });
  };

  setActiveTabValue = (value) => {
    this.setState({ activeTabValue: value });
  };

  updatePostData(data) {
    this.setState({ postData: data });
  }

  _submit = () => {
    const POSTDATA = {
      probationperiodname: "",
      description: "",
      probationdays: 0,
      isearlyconfirmedallowed: true,
      isautoconfirmed: true,
      isnotifyemployee: true,
      notifypriordays: 0,
      isnotifyoncompletion: false,
      isnotifyonextention: false,
      isnotifyonconfirmed: false,
    };
    const data = {};
    Object.keys(this.state.postData).forEach((key) => {
      data[key] = this.state.postData[key] || null;
      if (key == "probationdays") {
        data[key] = this.state.postData[key] || 0;
      }
      if (key == "notifypriordays") {
        data[key] = this.state.postData[key] || 0;
      }
    });
    if (data.probationperiodname == null) {
      this.props.enqueueSnackbar("Please enter policyname", {
        variant: "error",
      });
    }
    if (data.isnotifyemployee) {
      if (
        data.isnotifyonconfirmed == null &&
        data.isnotifyonextention == null &&
        data.isnotifyoncompletion == null
      ) {
        this.props.enqueueSnackbar(
          "Please select how you want to notify employee",
          { variant: "error" },
        );
        return;
      }
      // else if (data.isnotifyoncompletion && data.notifypriordays === null) {
      //   this.props.enqueueSnackbar("Please enter prior notification days", {
      //     variant: "error"
      //   });
      //   return;
      // }
    }

    if (
      data.isnotifyoncompletion &&
      (!data.notifypriordays || data.notifypriordays == "0")
    ) {
      this.props.enqueueSnackbar("Please select notify days", {
        variant: "error",
      });
      return;
    }

    const { match } = this.props;
    instance
      .post("subscription/insertupdateprobationperiod", data)
      .then(() => {
        this.props.enqueueSnackbar("Saved successfully", {
          variant: "success",
        });
        console.log("Data reset ");
        this.setState({
          postData: POSTDATA,
        });
        this.props.getProbationList();
        this.props.history.push(`${match.path}`);
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
      });
  };

  _renderTabBasedForm = () => {
    const { activeTabValue, postData } = this.state;
    return (
      <div className="content-outer-wrapper-with-tab">
        <div style={{ display: activeTabValue != 0 ? "none" : "block" }}>
          <NameDesc
            postData={postData}
            updatePostData={this.updatePostData}
            setActiveTabValue={this.setActiveTabValue}
          />
        </div>
        <div style={{ display: activeTabValue != 1 ? "none" : "block" }}>
          <Details
            postData={postData}
            updatePostData={this.updatePostData}
            _submit={this._submit}
          />
        </div>
      </div>
    );
  };

  getDataOnChange = (data) => {
    this.allTabData = { ...this.allTabData, ...data };
  };

  showLoader() {
    return <div className="pre-loader">Loading...</div>;
  }

  // getEmployeeDetails = (id) => instance.get(`subscription/designation/${id}`).then(
  //     (response) => response.data && response.data.result && response.data.result[0],
  //   );

  getEmployeeDetails = () => {
    const { accountid, subscriptionid } = this.props;
    const data = {
      accountid,
      statusid: 1,
      subscriptionid,
      featureky: "probation",
    };
    const URL = `subscription/getemployeelist`;
    // let URL = `subscription/getemployeelist/${this.state.accountid}/${this.state.subscriptionid}/1`
    return instance
      .post(URL, data)
      .then((response) => response.data && response.data.result);
  };

  getDetailsById = (id) =>
    instance
      .get(`subscription/probationperiod/${id}`)
      .then(
        (response) =>
          response.data && response.data.result && response.data.result[0],
      );

  getFormComponent = () => {
    this.getEmployeeDetails().then((employeeData) => {
      if (this.doEditId > -1) {
        this.getDetailsById(this.doEditId).then((data) => {
          this.initialForm(data, employeeData);
        });
      } else {
        this.initialForm(null, employeeData);
      }
    });
  };

  initialForm = (data, employeeData) => {
    this.allTabData = data;
    this.currentData = { initialData: data, employeeData };
    this.setState({ reRender: true });
  };

  _renderForm = () => {
    const language = this.props.Language.probationplan;
    return (
      <IntlProvider locale="en" messages={language}>
        <div className="config-table">{this._renderTabBasedForm()}</div>
      </IntlProvider>
    );
  };

  componentWillUnmount() {
    // this.setState({ activeTabValue: 0 });
  }

  render() {
    const { match } = this.props;
    const { activeTabValue } = this.state;
    const language = this.props.Language.probationplan;
    return (
      <IntlProvider locale="en" messages={language}>
        <div className="innerwrapper-full-width">
          <div>
            {this.props.location.pathname !==
              "/config/hris/company/probation" && (
              <div className="common-menubar-header">
                <Tabs
                  value={activeTabValue}
                  onChange={this.handleTabChange.bind(this)}
                  indicatorColor="#fff"
                  textColor="primary"
                >
                  <Tab
                    label={
                      <FormattedMessage
                        id="TabProbationName"
                        defaultMessage="Name & Descpriton"
                      />
                    }
                    className={`tabheading ${
                      activeTabValue == 0 ? "active-tab1" : "inactive-tab1"
                    } `}
                  />
                  <Tab
                    label={
                      <FormattedMessage
                        id="TabProbationDetails"
                        defaultMessage="Probation Details"
                      />
                    }
                    className={`tabheading ${
                      activeTabValue == 1 ? "active-tab1" : "inactive-tab1"
                    } `}
                  />
                </Tabs>
                <div className="tab-border" />
              </div>
            )}
          </div>
          <main>
            <div>
              <Grid
                container
                className="business-config-layout-container probation-plan-page"
              >
                <Grid item xs={12} md={12} sm={12}>
                  <Switch>
                    <Route
                      exact
                      path={`${match.path}`}
                      component={this._renderComponent}
                    />
                    <Route
                      exact
                      path={`${match.path}/create`}
                      component={this._renderForm}
                    />
                  </Switch>
                </Grid>
              </Grid>
            </div>
          </main>
        </div>
      </IntlProvider>
    );
  }
}

ProbationPlanIndex.propTypes = {};
const mapStateToProps = (state) => ({
  Language: state.LanguageAction.Language,
  probationList: getBusinessConfig(state, ActionConstants.PROBATION_PLAN),
  subscriptionid: state.AuthenticationAction.login.subscriptionid,
  accountid: state.AuthenticationAction.login.accountid,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProbationList,
      updateRespectiveList,
      ChangeLanguage: (data) => ChangeLanguage(data),
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withSnackbar(ProbationPlanIndex)));

const POSTDATA = {
  probationperiodname: "",
  description: "",
  probationdays: 0,
  isearlyconfirmedallowed: true,
  isautoconfirmed: true,
  isnotifyemployee: true,
  notifypriordays: 0,
  isnotifyoncompletion: false,
  isnotifyonextention: false,
  isnotifyonconfirmed: false,
};
