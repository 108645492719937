import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { FormattedMessage } from "react-intl";
import "../../styles/index.css";

export default function CreateComp(props) {
  const { text, heading, onCreate, imageSrc, permissions } = props;
  return (
    <Grid container>
      <Grid container className="config-landing-content-container">
        <Grid item xs={12} md={6} sm={12}>
          <div className="text-align-left">
            <Typography gutterBottom variant="title" component="h5">
              {heading}
            </Typography>
            <Typography component="p" className="p">
              {text}
            </Typography>
            {permissions.editable ? (
              <Button variant="contained" color="primary" className="createbutton" onClick={onCreate}>
                <FormattedMessage id="BtCreate" defaultMessage="create" />
              </Button>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={12} className="bg-color-white">
          <div className="right">
            {/* Section for image */}
            {/* Style it as per requirement */}
            {imageSrc && <img src={imageSrc} className="width80" />}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
