import React from 'react';
import TextField from "@material-ui/core/TextField";
import '../../Assets/SCSS/components/MuiTextArea.scss';

export const TextArea = (props) => (
    <div className="mui-textarea">
      <TextField {...props} />
    </div>
  );

export default TextArea;
