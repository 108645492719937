export const ReducersTypes = {
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  UPDATEONBOARDINGSTATUS: "UPDATEONBOARDINGSTATUS",
  FORGETPASSWORD: "FORGET_PASSWORD",
  LANGUAGE: "LANGUAGE",
  SUBSCRIPTIONINFO: "SUBSCRIPTIONINFO",
  SETHELPERTEXT: "SETHELPERTEXT",
  HELPER_TEXT_DRAWER_STATUS: "HELPER_TEXT_DRAWER_STATUS",
  OPEN_FORM_HELP: "OPEN_FORM_HELP",
  OPEN_PAGE_HELP: "OPEN_PAGE_HELP",
  SET_PAGE_HELPTEXT: "SET_PAGE_HELPTEXT",
  REFRESHPROFILEINFO: "REFRESHPROFILEINFO",
  PROFILEPRIVACYINFO: "PROFILEPRIVACYINFO",
  REQUESTCENTER: "REQUESTCENTER",
  EMPREQUESTCENTERLIST: "EMPREQUESTCENTERLIST",
  RIGHTDRAWER: "RIGHTDRAWER",
  APPROVALWORKFLOW: "APPROVALWORKFLOW",
  EXTRATIMEDATA: "EXTRATIMEDATA",
  CALPROGRESS: "CALPROGRESS",
  CLEARAPPROVALWORKFLOW: "CLEARAPPROVALWORKFLOW",
  ATTENDANCEDEVICE: "ATTENDANCEDEVICE",
  CLEARATTENDANCEDEVICE: "CLEARATTENDANCEDEVICE",
  CLEARREDUCER: "CLEARREDUCER",
  CLEAREXTRATIMEDATA: "CLEAREXTRATIMEDATA",
  ATTENDANCEPOLICY: "ATTENDANCEPOLICY",
  INITATTENDANCEPOLICY: "INITATTENDANCEPOLICY",
  HOLIDAYPLANINFO: "HOLIDAYPLANINFO",
  CLEARHOLIDAYPLANINFO: "CLEARHOLIDAYPLANINFO",
  SHIFTPLANNER: "SHIFTPLANNER",
  CLEARSHIFTPLANNER: "CLEARSHIFTPLANNER",
  LEAVESPOLICY: "LEAVESPOLICY",
  INITLEAVESPOLICY: "INITLEAVESPOLICY",
  CLEARWEEKOFF: "CLEARWEEKOFF",
  WEEKOFFPOLICY: "WEEKOFFPOLICY",
  REACTVERSION: "REACTVERSION",
  OPENSELECTION: "OPENSELECTION",
  SELECTED_DRAWER_ID: "SELECTED_DRAWER_ID",
  MENU_LIST: "MENU_LIST",
  MENU_DATA: "MENU_DATA",
  CURRENT_BREADCRUM: "CURRENT_BREADCRUM",
  SET_ONBOARDING_DATA: "SET_ONBOARDING_DATA",
  RESET_ONBOARDING_DATA: "RESET_ONBOARDING_DATA",
  SET_CURRENT_ONBOARDING_STEP: "SET_CURRENT_ONBOARDING_STEP",
  SHOW_ONBOARDING: "SHOW_ONBOARDING",
  SET_ONB_CONFIG_STATUS: "SET_ONB_CONFIG_STATUS",
  OPEN_LEFT_DRAWER: "OPEN_LEFT_DRAWER",
  SALARYSTURUCTURE: "SALARYSTURUCTURE",
  CLEARSALARYSTURUCTURE: "CLEARSALARYSTURUCTURE",
  FCM: "FCM",
  SETSELECTEDFILTERS: "SETSELECTEDFILTERS",
  REMOVESELECTEDFILTERS: "REMOVESELECTEDFILTERS",
  APP_MODE: "APP_MODE",
  SHOWRELOADPOPUP: "SHOWRELOADPOPUP",
  REMOVERELOADPOPUP: "REMOVERELOADPOPUP",
  EXITREASONSPOLICY: "EXITREASONSPOLICY",
  OPEN_ALL_APPS: "OPEN_ALL_APPS",
  PUSH_NOTIFICATION_ACTIVE: "PUSH_NOTIFICATION_ACTIVE",
  PREVIEW_DOCUMENT: "PREVIEW_DOCUMENT",
  DYNAMIC_FORM: "DYNAMIC_FORM",
  ADD_DYNAMIC_FORM: "ADD_DYNAMIC_FORM",
  SETSAVEPROGRESS: "SETSAVEPROGRESS",
  SETEMPLOYEEID: "SETEMPLOYEEID",
  REMOVESAVEPROGRESS: "REMOVESAVEPROGRESS",
  LOADINGPENDING: "LOADINGPENDING",
  LOADINGDONE: "LOADINGDONE",
  SETCLOCKINDATA: "SETCLOCKINDATA",
  DURATION: "DURATION",
};

export const ApiReducerType = {
  ATTENDANCE_POLICY: "ATTENDANCE_POLICY",
  EXTRA_TIME: "EXTRA_TIME",
  ALL_SHIFT: "ALL_SHIFT",
  DEVICE_POLICY: "DEVICE_POLICY",
  HOLIDAY_PLAN: "HOLIDAY_PLAN",
  LEAVES_POLICY: "LEAVES_POLICY",
  WEEKOFFTPLANNER: "WEEKOFFTPLANNER",
  SALARY_STURUCTURE: "SALARY_STURUCTURE",
  NO_OF_EMPLOLYEES: "NO_OF_EMPLOLYEES",
  PAIN_POINTS: "PAIN_POINTS",
};
