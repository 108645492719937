import typeToReducer from "type-to-reducer";
import { ReducersTypes } from "../Constants";

const initState = {
  IsPending: true,
  IsFulfilled: false,
  IsError: false,
  Data: [],
};

const RequestCenterReducer = typeToReducer(
  {
    [ReducersTypes.REQUESTCENTER]: {
      PENDING: () => ({
        ...initState,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        IsFulfilled: true,
        IsPending: false,
        Data: action.payload.data.result,
      }),
      UPDATED: (state, action) => ({
        ...state,
        IsFulfilled: true,
        IsPending: false,
        Data: action.payload,
      }),
      REJECTED: (state, action) => ({
        ...state,
        IsError: true,
        IsPending: false,
        ErrorMessage: action.payload.response ? action.payload.response.data.error.message : action.payload.message,
      }),
    },
  },
  initState,
);

export default RequestCenterReducer;
