import React from "react";
import TextField from "../../Material-ui/TextField";
import initjson from "../Data/initjson";
import KEYS from "../KEYS";

export default function TextComp(props) {
  const [focused, setFocused] = React.useState(false);
  const { field, updateData, onBlur } = props;
  const {
    value = "",
    iserr = function () {},
    placeholder = "",
    label = "",
    helperText = "",
    className = "",
    disabled = false,
    maxLength = null,
  } = field;
  function handleChange(event) {
    updateData({ id: field.id, value: event.target.value });
  }
  // function blurEvent(event) {
  //   const value1 = event.target.value;
  //   console.log('value1: ', value1);
  //   onBlur && onBlur(value1);
  // }
  return (
    <div>
      <p className="input-label">{label}</p>
      <TextField
        type="text"
        className={`width100 ${className}`}
        variant="outlined"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        error={iserr({ fielddata: field })}
        helperText={focused ? helperText : ""}
        disabled={disabled}
        inputProps={{
          maxLength,
          // onBlur: blurEvent,
        }}
        // onBlur={blurEvent}
        {...props}
        onFocus={() => setFocused(true)}
        // onBlur={() => setFocused(false)}
        autoFocus
      />
    </div>
  );
}

export const textinputconverter = (field) => [
  {
    ...initjson,
    ...field,
    typeid: KEYS.Text,
  },
];
