import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import React from "react";
import PermissionDeniedComp from "Component/PermissionDeniedComp/PermissionDeniedComp";

// eslint-disable-next-line import/no-unresolved
import Helmet from "react-helmet";
import headersList from "Constants/headersList";
import get from "lodash/get";
import { FormattedMessage, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { instance } from "../../../../../Actions/AxiosInstance";
import { CheckActionPermission } from "../../../../../Actions/commonAction";
import { ChangeLanguage } from "../../../../../Actions/LanguageAction";
import buConcept from "../../../../../Assets/Svg/bunitcreate.svg";
import Stepper from "../../../../../Component/Stepper";
import { List, TDTypeConsts } from "../../commons";
import { getBusinessUnitList, updateRespectiveList } from "../actions";
import ActionConstants from "../constants";
import { getBusinessConfig } from "../selectors";
import "../styles/index.css";
import Form from "./form";
import Statutorydetails from "./Statutorydetails";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginRight: "10px",
  },
  typography: {},
});
class BusinessUnit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      permissions: { readOnly: false, editable: false },
      postData: BUSINESS_DATA,
      initData: null,
      activeTabValue: 0,
      AllStep: [],
      completed: false,
      activeStep: 0,
      isentity: false,
    };
    this._submit = this._submit.bind(this);
    this.headers = [
      {
        key: "businessunitname",
        name: (
          <FormattedMessage
            id="businessunitname"
            defaultMessage="Business Unit Name"
          />
        ),
        type: TDTypeConsts.BUSINESS_UNIT_NAME,
        style: { textAlign: "left" },
      },
      {
        key: "addedbyimagepath",
        name: <FormattedMessage id="LbCreatedBy" defaultMessage="Created By" />,
        type: TDTypeConsts.CREATED_BY,
      },
      {
        key: "assignedEmployeeList",
        name: (
          <FormattedMessage
            id="LbAssignedEmployee"
            defaultMessage="Assigned Employee"
          />
        ),
        type: TDTypeConsts.ASSIGNED_USER,
      },
      {
        key: "businessunitlocation",
        name: <FormattedMessage id="LbLocation" defaultMessage="Location" />,
        type: TDTypeConsts.OTHERS,
      },
      {
        key: "businessunitheadname",
        name: (
          <FormattedMessage
            id="LbBusinessUnitHead"
            defaultMessage="Business Unit Head"
          />
        ),
        type: TDTypeConsts.OTHERS,
      },
      {
        key: "edit",
        name: "",
        type: TDTypeConsts.EDIT_WITHOUT_HEADER,
      },
      {
        key: "delete",
        name: "",
        type: TDTypeConsts.DELETE_WITHOUT_HEADER,
      },
    ];
    this.listLenght = 0;
    this.updatePostData = this.updatePostData.bind(this);
    this.updateSteps = this.updateSteps.bind(this);
    this.setStep = this.setStep.bind(this);
  }

  componentDidMount() {
    this.props.ChangeLanguage("businessunit");
    // const [isTrue, actionlistObject] = CheckActionPermission(this.props.history.location.pathname)
    if (this.props.history.location.pathname.includes("create")) {
      this.onCancel();
    }

    const [isTrue, permissions] = CheckActionPermission("businessUnit");
    if (isTrue) {
      this.setState({ permissions }, () => {
        this.updateSteps(true);
        this.props.getBusinessUnitList();
        // if (!this.state.permissions.editable) {
        //   const index = this.headers.findIndex(
        //     (x) => x.type == TDTypeConsts.EDIT_WITHOUT_HEADER,
        //   );
        //   if (index != -1) {
        //     this.headers.splice(index, 1);
        //   }
        // }
        if (!this.state.permissions.editable) {
          const index = this.headers.findIndex(
            (x) => x.type == TDTypeConsts.DELETE_WITHOUT_HEADER,
          );
          if (index != -1) {
            this.headers.splice(index, 1);
          }
        }
      });
    } else {
      this.setState({ permissiondenied: true });
    }
    this.getInitData();
  }

  updateSteps(flag) {
    const { match } = this.props;
    const { postData, initData } = this.state;
    const { currentEditData, subscriptionid, businessUnitList } = this.props;
    let { isentity } = this.state;
    isentity =
      isentity ||
      get(businessUnitList, "list", []).find((x) => x.isseparateentity);
    const AllStep = [
      {
        component: (
          <Form
            onRef={(ref) => this.setState({ child0: ref })}
            isedit={true}
            initData={initData}
            postData={postData}
            updatePostData={this.updatePostData}
            _submit={this._submit}
            {...this.props}
            isentity={false}
            subscriptionid={subscriptionid}
            currentEditData={currentEditData}
            onNextClick={this.onNextClick}
            setStep={this.setStep}
            onCancel={this.onCancel}
          />
        ),
        iserror: false,
        label: "Define",
        step: 1,
      },
    ];
    // isentity
    //   && AllStep.push({
    //     component: (
    //       <Statutorydetails
    //         onRef={(ref) => this.setState({ child1: ref })}
    //         isedit={true}
    //         initData={initData}
    //         postData={postData}
    //         updatePostData={this.updatePostData}
    //         _submit={this._submit}
    //         {...this.props}
    //         isentity={isentity}
    //         subscriptionid={subscriptionid}
    //         onPrevClick={this.onPrevClick}
    //         onCancel={this.onCancel}
    //       />
    //     ),
    //     iserror: false,
    //     label: "Statutory",
    //     step: 2,
    //   });

    this.setState({ AllStep }, () => {
      if (!flag) {
        this.props.history.push(`${match.path}/create`);
      }
    });
  }

  onCancel = () => {
    this.props.history.push("./list");
  };

  setStep(step, completed) {
    this.child.setState({ activeStep: step, completed });
    this.setState({ activeStep: step, completed });
  }

  getText() {
    return (
      <span>
        <FormattedMessage
          id="businessUnitConcept"
          defaultMessage="You can create Business Units to create distinction between important manageable business-centres in your company. Using Business Units is ideal for a company which has multiple brands or operates in different sectors but wants to manage HR for all of them from within one company."
        />
      </span>
    );
  }

  handleChange = (e) => {
    this.setState({
      isentity: e.target.value == "true" ? true : false,
    });
  };

  _renderCreate = () => {
    const { permissions } = this.state;
    console.log("permissions: ", permissions);
    return (
      <div className="content-outer-wrapper">
        <Grid container className="height100">
          <Grid item xs={12} md={12} sm={12}>
            <div className="display-table height100 width100">
              <div className="display-table-cell vertical-middle">
                <div className="text-center">
                  <img src={buConcept} width="25%"></img>
                </div>
                <div className="page-header-name text-center">
                  {"Create your first business unit"}
                </div>
                {/* <div
                  className="rounded-rectangle-paper page-subheader-name text-center horizontal-middle margin-auto margin-bottom-24"
                  style={{ padding: "16px 16px 8px 16px", display: "table" }}
                >
                  <span className="display-block">
                    Are your business units independently incorporated entities?
                  </span>
                  <span>
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={this.state.isentity}
                      onChange={this.handleChange}
                      row
                      style={{ display: "unset", borderRadius: 4 }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </span>
                </div> */}
                <div className="text-center">
                  {permissions.editable && (
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ width: 140 }}
                      onClick={this._onFirstCreate}
                    >
                      Create
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  showSnackbar = (msg, variant, autoHideDuration) => {
    this.props.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: autoHideDuration || 1000,
    });
  };

  getInitData = () => {
    instance.get("subscription/initbusinessunit").then((resp) => {
      if (resp.data.result) {
        const initData = resp.data.result;
        initData.OfficeAddress.unshift({
          displayname: "None",
          displayvalue: "",
        });
        this.setState({ initData });
      }
    });
  };

  _onCreate = () => {
    this.setState({ postData: BUSINESS_DATA }, () => this._setEditPage());
  };

  _onFirstCreate = () => {
    const BUSINESS_DATA = {
      businessunitname: "",
      officeaddressid: null,
      businessunitheadid: null,
      oldbusinessunitheadid: null,
      businessunitid: null,
      statutorydetail: [],
    };
    // instance
    //   .put("subscription/updatebusinessentity", {
    //     isseparatebusinessentity: this.state.isentity,
    //   })
    //   .then(() => {
    console.log("BUSINESS_DATA: ", BUSINESS_DATA);
    this.setState({ postData: BUSINESS_DATA }, () => this._setEditPage());
    // })
    // .catch((error) => {
    //   this.props.enqueueSnackbar(error.response.data.error.message, {
    //     variant: "error",
    //   });
    // });
  };

  onEdit = (item) => {
    const d = item;
    d.oldbusinessunitheadid = item.businessunitheadid;
    this.setState({ postData: item, isentity: item.isseparateentity }, () => {
      this._setEditPage();
    });
  };

  _setEditPage = () => {
    this.updateSteps();
  };

  onDelete = (item) => {
    const { businessUnitList } = this.props;
    const { list } = businessUnitList;
    const id = item.businessunitid;
    const index = list.indexOf(item);

    instance.delete(`subscription/businessunit/${id}`).then(
      () => {
        list.splice(index, 1);
        this.showSnackbar("Business unit successfully deleted.", "success");
        this.props.closeSnackbar();
        this.props.updateRespectiveList(ActionConstants.BUSINESS_UNIT, list);
        // have to reRender as custom action will not trigger re-render (reason typeToReducer)
        this.setState({ reRender: true }, () => {
          this.props.getBusinessUnitList();
        });
      },
      (error) => {
        const msg = get(
          error,
          "response.data.error.message",
          "Error while deleting Business Unit.",
        );
        this.showSnackbar(msg, "error", 2000);
      },
    );
  };

  validateForm = () => false;

  _submit = () => {
    const { postData } = this.state;
    const data = postData;

    // if (this.validateForm()) {
    //   return;
    // }
    instance
      .post("subscription/businessunit", data)
      .then(() => {
        this.props.history.push(`${this.props.match.path}`);
        this.props.getBusinessUnitList();
        this.setState({
          postData: BUSINESS_DATA,
        });
        this.props.enqueueSnackbar("Save successfully", {
          variant: "success",
        });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.response.data.error.message, {
          variant: "error",
        });
      });
  };

  _renderList = () => {
    const { businessUnitList } = this.props;
    console.log("businessUnitList: ", businessUnitList);
    return (
      <List
        list={businessUnitList.list}
        headers={this.headers}
        onCreate={this._onCreate}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        module={"Business Unit"}
        goTo={this.props.goTo}
        permissions={this.state.permissions}
      />
    );
  };

  updatePostData(data) {
    this.setState({ postData: data }, () => this.updateSteps());
  }

  showLoader() {
    return <div className="pre-loader">Loading...</div>;
  }

  _renderComponent = () => {
    const {
      businessUnitList,
      location: { pathname },
      Language: { businessunit = {} },
    } = this.props;
    if (businessUnitList.IsPending) {
      return this.showLoader();
    }
    const checkpath = pathname.includes("/config/hris/company/business_unit");
    const language = businessunit;
    return (
      <IntlProvider locale="en" messages={language}>
        <div>
          {businessUnitList.list &&
            businessUnitList.list.length == 0 &&
            checkpath &&
            this._renderCreate()}
          {businessUnitList.list &&
            businessUnitList.list.length > 0 &&
            checkpath &&
            this._renderList()}
        </div>
      </IntlProvider>
    );
  };

  onPrevClick = () => {
    this.child.handleBack();
  };

  render() {
    const {
      classes,
      match,
      Language: { businessunit = null },
    } = this.props;
    const { child0, child1 } = this.state;
    return (
      <IntlProvider locale="en" messages={businessunit}>
        <main className="content-outer-wrapper">
          <Helmet>
            <title>{headersList.Config.BusinessUnit.header}</title>
          </Helmet>
          {this.state.permissiondenied ? (
            <div className="position-rel">
              <PermissionDeniedComp />
            </div>
          ) : (
            <div>
              {this.props.location.pathname ===
              "/config/hris/company/business_unit/create" ? (
                <div>
                  <Stepper
                    AllStep={this.state.AllStep}
                    onRef={(ref) => (this.child = ref)}
                    activeStep={this.state.activeStep}
                    setStep={this.setStep}
                    completed={this.state.completed}
                    childs={[child0, child1]}
                    permissions={this.state.permissions}
                  />
                </div>
              ) : (
                <Grid
                  container
                  className="business-config-layout-container probation-plan-page"
                >
                  <Grid item xs={12} md={12} sm={12}>
                    <Switch>
                      {/* <Route path={`${match.path}/create`} render={this._renderForm} /> */}
                      <Route
                        path={`${match.path}`}
                        render={this._renderComponent}
                      />
                      <Redirect from={`${match.path}`} to={`${match.path}`} />
                    </Switch>
                  </Grid>
                </Grid>
              )}
            </div>
          )}
        </main>
      </IntlProvider>
    );
  }
}

BusinessUnit.propTypes = {};
const mapStateToProps = (state) => ({
  Language: state.LanguageAction.Language,
  businessUnitList: getBusinessConfig(state, ActionConstants.BUSINESS_UNIT),
  subscriptionid: state.AuthenticationAction.login.subscriptionid,
  SubscriptionInfo: state.SubscriptionInfo,
  AuthenticationAction: state.AuthenticationAction,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBusinessUnitList,
      updateRespectiveList,
      ChangeLanguage: (data) => ChangeLanguage(data),
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withSnackbar(BusinessUnit)));

const BUSINESS_DATA = {
  businessunitname: "",
  officeaddressid: null,
  businessunitheadid: null,
  businessunitid: null,
  isseparateentity: false,
  oldbusinessunitheadid: null,
  statutorydetail: [],
};
