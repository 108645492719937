import { instance } from "../../../../../Actions/AxiosInstance";
import ActionConstants from "../constants";

export const getProbationList = () => ({
  type: ActionConstants.PROBATION_PLAN,
  payload: instance.get("subscription/allprobationperiod"),
});

export const updateRespectiveList = (action, data) => ({
  type: `${action}_UPDATE`,
  payload: data,
});
