import React from "react";
import "../../Assets/SCSS/abstracts/inputs.scss";
import { DatePickerComponent } from "../Custom";

export const DateComponent = (props) => (
  <div className="dynamic-form-date">
    <DatePickerComponent {...props} />
  </div>
);

export default DateComponent;
