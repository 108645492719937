import get from "lodash/get";
import axios from "axios";
import typeToReducer from "type-to-reducer";
import publicIp from "public-ip";
import { detect } from "detect-browser";
import { getOSName } from "Handlers/getters";
import Configs from "../Constants/Configs";
import createstore from "../store";
import { ReducersTypes } from "../Constants";
import { instance, GLOBAL_TOKEN } from "./AxiosInstance";
import { backToHome } from "../Handlers/setters";


const browser = detect();

export const Login = (data) => ({
  type: ReducersTypes.LOGIN,
  payload: instance.post("account/login", data),
});

export async function LogMeOut(userid) {
  const doRequiredLogoutOperations = () => {
    if (userid) {
      localStorage.setItem("loggedout", userid);
    }
    setTimeout(() => {
      localStorage.clear();
      backToHome();
    }, 250);
  };

  const ConvertHeadersForBackend1 = (headers) => {
    headers.Authorization = GLOBAL_TOKEN;
    headers["Content-Type"] = "application/json";
    return headers;
  };

  const instance1 = axios.create({
    baseURL: Configs.API_URL,
    transformRequest: [
      // eslint-disable-next-line no-unused-vars
      (data, headers) => {
        headers = ConvertHeadersForBackend1(headers);
        return JSON.stringify(data);
      },
    ],
  });
  const store = createstore.getState();
  const { AuthenticationAction } = store;
  const ip = await publicIp.v4();
  await instance1
    .post("subscription/logout", {
      "userid": AuthenticationAction.login && AuthenticationAction.login.userid,
      "accountid": AuthenticationAction.login && AuthenticationAction.login.accountid,
      "subscriptionid": AuthenticationAction.login && AuthenticationAction.login.subscriptionid,
      "timezoneid": AuthenticationAction.login && AuthenticationAction.login.timezoneid,
      "logininfoid": AuthenticationAction.login && AuthenticationAction.login.logininfoid,
      "devicetokenid": null,
      "ipaddress": ip,
      "systemdetail": getOSName(),
      "browserdetail": browser.name,
      "logintype": 2,
      "deviceid": null,
     })
    .then(() => {
      doRequiredLogoutOperations();
    })
    .catch(() => {
      doRequiredLogoutOperations();
    });
}

export const SubscriptionLogin = (data) => ({
  type: ReducersTypes.LOGIN,
  payload: instance.post("subscription/passwordlogin", data),
});

export const SuperLogin = (data) => ({
  type: ReducersTypes.LOGIN,
  payload: instance.post("superuser/login", {
    ...data,
    email: data.username || null,
    username: data.username || null,
    password: data.password || null,
    subscriptionid: data.subscriptionid || null,
    logintype: data.logintype || null,
    browserdetail: data.systemdetail || null,
    systemdetail: data.systemdetail || null,
    devicetokenid: data.devicetokenid || null,
  }),
});

export const VerifyAccountApi = (url) => ({
  type: ReducersTypes.LOGIN,
  payload: instance.get(url),
});

export const updateAuthentication = (type, data) =>
  // console.log("type, data: ", type, { ...data });
  ({
    type,
    payload: data,
  });
const initialState = { IsFulfilled: false, IsError: false };

const Authentication = typeToReducer(
  {
    [ReducersTypes.LOGIN]: {
      PENDING: () => ({
        ...initialState,
      }),
      FULFILLED: (state, action) => {
        window.localStorage.setItem("loggedin", get(action.payload, "data.result[0].userid", ""));
        return {
          ...state,
          IsFulfilled: true,
          login: action.payload.data.result[0],
        };
      },
      UPDATED: (state, action) => ({
        ...state,
        login: action.payload,
      }),
      UPDATED_WITH_ISFULFILLED: (state, action) => ({
        ...state,
        IsFulfilled: action.payload.IsFulfilled,
        login: action.payload.data,
      }),
      REJECTED: (state, action) => ({
        ...initialState,
        IsError: true,
        ErrorMessage: action.payload.response ? action.payload.response.data.error.message : action.payload.message,
      }),
    },
  },
  initialState,
);

export default Authentication;
