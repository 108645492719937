import React from "react";
import "./style/style.css";
import Paper from "@material-ui/core/Paper";
import { textinputconverter } from "Component/DynamicForm/Fields/Text";
import { dateinputconverter } from "Component/DynamicForm/Fields/Date";
import { integerinputconverter } from "Component/DynamicForm/Fields/Integer";
import { checkboxinputconverter } from "Component/DynamicForm/Fields/CheckBox";
import DynamicForm from "Component/DynamicForm";
import { getFormattedLogoLink } from "Utils";
import { radioinputconverter } from "Component/DynamicForm/Fields/Radio";
import sumhrlogo from "./images/sumhr-logo.svg";
import { instance, careerpageinstance } from "../../../Actions/AxiosInstance";
import leftarrow from "./images/line-arrow-left-outline-16px.svg";

class ScreeningQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobdetails: this.props.jobdetails,
      postdata: this.props.postdata,
      companyquestiondata: [],
      functionalquestiondata: [],
      questiondata: [],
      selectedquestioninputoptionids: null,
    };
    this.submit = this.submit.bind(this);
    this.getQuestion = this.getQuestion.bind(this);
    this.updatePostData = this.updatePostData.bind(this);
  }

  componentDidMount() {
    this.getQuestion();
  }

  updatePostData(
    key,
    value,
    index,
    id,
    inputoptionid,
    inputvaluetypeid = null,
  ) {
    const { companyquestiondata, functionalquestiondata } = this.state;
    const { candiatequestionfeedback } = this.state.postdata;
    if (id === 1) {
      if (key == "requisitionjobquestioninputoptionids") {
        if (inputvaluetypeid == 5) {
          if (value) {
            companyquestiondata[index][key] = companyquestiondata[index][key]
              ? companyquestiondata[index][key]
              : [];
            companyquestiondata[index][key].push(inputoptionid);
          } else {
            companyquestiondata[index][key] = companyquestiondata[index][
              key
            ].filter((input) => input != inputoptionid);
          }
        } else if (inputvaluetypeid == 4) {
          companyquestiondata[index][key] = [inputoptionid];
        }
      } else {
        companyquestiondata[index][key] = value;
      }

      this.setState({
        companyquestiondata,
        candiatequestionfeedback,
      });
    }
    if (id === 2) {
      if (key == "requisitionjobquestioninputoptionids") {
        if (inputvaluetypeid == 5) {
          if (value) {
            functionalquestiondata[index][key] = functionalquestiondata[index][
              key
            ]
              ? functionalquestiondata[index][key]
              : [];
            functionalquestiondata[index][key].push(inputoptionid);
          } else {
            functionalquestiondata[index][key] = functionalquestiondata[index][
              key
            ].filter((input) => input != inputoptionid);
          }
        } else if (inputvaluetypeid == 4) {
          functionalquestiondata[index][key] = [inputoptionid];
        }
      } else {
        functionalquestiondata[index][key] = value;
      }

      this.setState({
        functionalquestiondata,
        candiatequestionfeedback,
      });
    }
  }

  renderActivity = (data, index, id) => (
    <Paper style={{ padding: 16, position: "relative" }}>
      <div>
        <div className="floatleft"></div>
        {/* <div className="floatleft">
      <span>
        <IconLabel
          label={data.title}
          img={taskIcon}
          className="margin-bottom-8"
        />
        <IconLabel
          label="Due in 3 days"
          img={calenderIcon}
          className="margin-bottom-8"
        />
        <IconLabel
          label="Nikhil and 3 others"
          img={dependentIcon}
          className="margin-bottom-8"
        />
      </span>
    </div> */}
        <p>{data && data.questiontext} </p>
        <div className="clear-float"></div>
      </div>

      {data && (
        <div>
          {data && data.inputvaluetypeid && data.inputvaluetypeid == 1 ? (
            <DynamicForm
              fields={textinputconverter({
                value: data && data.feedback,
                placeholder: "",
              })}
              onChange={(e) => this.updatePostData("feedback", e, index, id)}
            />
          ) : data && data.inputvaluetypeid && data.inputvaluetypeid == 2 ? (
            <DynamicForm
              fields={integerinputconverter({
                value: data.feedback || null,
                placeholder: "",
                min: 0,
                max: 1000,
              })}
              onChange={(e) => this.updatePostData("feedback", e, index, id)}
            />
          ) : data && data.inputvaluetypeid && data.inputvaluetypeid == 3 ? (
            <DynamicForm
              fields={dateinputconverter({
                value: data.feedback || null,
                placeholder: "",
                min: data.isexpiryfield ? new Date() : null,
              })}
              onChange={(e) => this.updatePostData("feedback", e, index, id)}
            />
          ) : data && data.inputvaluetypeid && data.inputvaluetypeid == 4 ? (
            data.requisitionjobquestioninputoption &&
            data.requisitionjobquestioninputoption.map((y) => (
              <div>
                <DynamicForm
                  fclassName="width100"
                  fields={radioinputconverter({
                    value:
                      (data.requisitionjobquestioninputoptionids &&
                        data.requisitionjobquestioninputoptionids.includes(
                          y.questioninputoptionid,
                        )) ||
                      false,
                    placeholder: "",
                    label: y.optionname,
                  })}
                  onChange={(e) =>
                    this.updatePostData(
                      "requisitionjobquestioninputoptionids",
                      e,
                      index,
                      id,
                      y.questioninputoptionid,
                      data.inputvaluetypeid,
                    )
                  }
                />
              </div>
            ))
          ) : (
            data &&
            data.requisitionjobquestioninputoption &&
            data.requisitionjobquestioninputoption.map((y) => (
              <div>
                <DynamicForm
                  fields={checkboxinputconverter({
                    value:
                      data.requisitionjobquestioninputoptionids &&
                      data.requisitionjobquestioninputoptionids.includes(
                        y.questioninputoptionid,
                      ),
                    placeholder: "",
                    label: y.optionname,
                  })}
                  onChange={(e) =>
                    this.updatePostData(
                      "requisitionjobquestioninputoptionids",
                      e,
                      index,
                      id,
                      y.questioninputoptionid,
                      data.inputvaluetypeid,
                    )
                  }
                />
              </div>
            ))
          )}
        </div>
      )}

      {/* <div style={{ position: "absolute", right: 16, top: 16 }}>
    <img
      src={kebabIcon}
      className="floatright"
      onClick={(event) => this.moreOptionsClick(event, data)}
    ></img>
  </div> */}
      {/* <Popover
    open={this.state.moreOptions}
    anchorEl={this.state.moreOptions_anchorEl}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    onClick={() => this.setState({ moreOptions: false })}
    className="margin-top-8"
  >
    <Paper style={{ width: 200 }}>
      <div>
        <MenuList>
          <ListItem
            button
            className="padding-right-0 padding-top-4 padding-bottom-4 cursor-pointer"
            onClick={this.onEdit}
          >
            <ListItemIcon>
              <img src={editIcon}></img>
            </ListItemIcon>
            <ListItemText style={{ padding: 0 }}>
              <span className="small-dark-secondary">Edit</span>
            </ListItemText>
          </ListItem>

          <ListItem
            button
            className="padding-right-0 padding-top-4 padding-bottom-4 cursor-pointer"
            onClick={this.onDelete}
          >
            <ListItemIcon>
              <img src={deleteIcon}></img>
            </ListItemIcon>
            <ListItemText style={{ padding: 0 }}>
              <span className="small-dark-secondary">Delete</span>
            </ListItemText>
          </ListItem>
        </MenuList>
      </div>
    </Paper>
  </Popover> */}
    </Paper>
  );

  getQuestion() {
    const { jobdetails } = this.state;
    careerpageinstance
      .get(`recruit/getrequisitionjobquestion/${jobdetails.requisitionjobid}`)
      .then((result) => {
        console.log("result: ", result);
        const companyquestiondata =
          result.data.result[0].requisitionjobquestion.filter(
            (x) => x.questioncategoryid == 1,
          );
        const functionalquestiondata =
          result.data.result[0].requisitionjobquestion.filter(
            (x) => x.questioncategoryid == 2,
          );

        this.setState({
          companyquestiondata,
          functionalquestiondata,
        });
      })
      .catch((error) => {});
  }

  submit() {
    const {
      companyquestiondata,
      functionalquestiondata,
      postdata,
      jobdetails,
    } = this.state;
    postdata.requisitionjobid = jobdetails.requisitionjobid;
    postdata.requisitionid = jobdetails.requisitionid;
    companyquestiondata.map((x) => {
      const inputoption =
        Array.isArray(x.requisitionjobquestioninputoptionids) &&
        x.requisitionjobquestioninputoptionids.length
          ? x.requisitionjobquestioninputoptionids.join(",")
          : null;
      postdata.candiatequestionfeedback.push({
        requisitionid: jobdetails.requisitionid || null,
        requisitionjobid: jobdetails.requisitionjobid,
        requisitionjobquestionid: x.requisitionjobquestionid,
        feedback: x.feedback,
        requisitionjobquestioninputoptionids: inputoption,
        candiatequestionfeedbackid: postdata.candiatequestionfeedbackid || null,
        candidateid: postdata.candidateid || null,
      });
    });

    postdata.source = this.props.sourcename.source
      ? this.props.sourcename.source
      : null;

    functionalquestiondata.map((x) => {
      const inputoption =
        Array.isArray(x.requisitionjobquestioninputoptionids) &&
        x.requisitionjobquestioninputoptionids.length
          ? x.requisitionjobquestioninputoptionids.join(",")
          : null;

      postdata.candiatequestionfeedback.push({
        requisitionid: postdata.requisitionid || null,
        requisitionjobid: postdata.requisitionjobid,
        requisitionjobquestionid: x.requisitionjobquestionid,
        feedback: x.feedback,
        requisitionjobquestioninputoptionids: inputoption,
        candiatequestionfeedbackid: postdata.candiatequestionfeedbackid || null,
        candidateid: postdata.candidateid || null,
      });
    });

    careerpageinstance
      .post("recruit/configcandidateapplication", postdata)
      .then((result) => {
        this.props.onSuccesspage();
        this.setState({
          CandidateDetails: result.data.result,
          // name: result.data.result.name,
        });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
        postdata.candiatequestionfeedback = [];
      });
  }

  render() {
    const { jobdetails } = this.state;
    return (
      <div>
        <div
          className="c-fluid-container"
          style={{ backgroundColor: "#fff", padding: "32px 0px 0px" }}
        >
          <div className="c-wrapper">
            <div className="c-margin-bottom-32">
              <img
                style={{
                  maxHeight: 100,
                  maxWidth: 200,
                  objectFit: "contain",
                }}
                src={getFormattedLogoLink(this.props.subscriptiondetails.logo)}
                alt="icon"
              />
            </div>
          </div>
        </div>
        <div
          className="c-fluid-container"
          style={{ backgroundColor: "#fff", padding: "32px 0px 32px 0px" }}
        >
          <div className="c-wrapper">
            <h1
              style={{
                color: "rgb(1, 7, 9)",
                fontSize: 40,
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              {jobdetails && jobdetails.designationname}
            </h1>
            <p
              style={{
                color: "rgb(84, 85, 86)",
                fontSize: 24,
                marginRight: 16,
                fontWeight: "bold",
                marginTop: 0,
              }}
            >
              {jobdetails &&
                jobdetails.location &&
                jobdetails.location.location}{" "}
              • {jobdetails && jobdetails.designationname} •{" "}
              {jobdetails && jobdetails.jobtype}
            </p>
          </div>
        </div>
        <div className="c-wrapper">
          <div
            className="c-fluid-container width100"
            style={{ background: "rgb(249, 249, 249)" }}
          >
            <div className="c-display-flex">
              <p
                style={{
                  color: "rgb(1, 7, 9)",
                  fontSize: 24,
                  marginBottom: 32,
                  fontWeight: "bold",
                  marginTop: 0,
                }}
              >
                Step 3: Screening questions
              </p>
            </div>
            <div>
              <div
                className="dashed-divider1 position-rel text-center"
                style={{ marginTop: 40 }}
              >
                <span
                  style={{
                    background: "#f9f9f9",
                    position: "relative",
                    top: -14,
                    padding: "0px 24px",
                  }}
                  className="extra-small-dark-secondary"
                >
                  Company and culture questions
                </span>
              </div>

              {this.state.companyquestiondata &&
                this.state.companyquestiondata.length > 0 &&
                this.state.companyquestiondata.map((x, index) =>
                  this.renderActivity(x, index, 1),
                )}

              {/* {this.state.companyquestiondata &&
                this.state.companyquestiondata.filter(
                  (x) => x.questioncategoryid == 1,
                ).length > 0 && ( */}
              <div
                className="dashed-divider1 position-rel text-center"
                style={{ marginTop: 40 }}
              >
                <span
                  style={{
                    background: "#f9f9f9",
                    position: "relative",
                    top: -14,
                    padding: "0px 24px",
                  }}
                  className="extra-small-dark-secondary"
                >
                  Functional and role questions
                </span>
              </div>
              {/* )} */}
              {this.state.functionalquestiondata &&
                this.state.functionalquestiondata.length > 0 &&
                this.state.functionalquestiondata.map((x, index) =>
                  this.renderActivity(x, index, 2),
                )}
            </div>
            <div className="c-margin-top-24" style={{ marginTop: 24 }}>
              <div
                style={{ float: "left", marginBottom: 16 }}
                onClick={() => this.props.onDocumentupload()}
              >
                <span className="c-margin-right-16 c-cursor-pointer">
                  <img src={leftarrow} />
                </span>
                <span
                  className="c-normal-dark-secondary c-t-bold"
                  style={{ marginLeft: 16 }}
                >
                  Document upload
                </span>
                <div className="c-clear-float"></div>
              </div>
              <div style={{ float: "right" }}>
                <button
                  className="c-btn c-btn-primary-md-blue"
                  type="button"
                  onClick={this.submit}
                >
                  Submit application
                </button>
              </div>
              <div className="c-clear-float" style={{ clear: "both" }}></div>
            </div>
          </div>
        </div>
        {/* <div className="c-wrapper">
          <div
            className="c-fluid-container width100"
            style={{ background: "rgb(249, 249, 249)" }}
          >
            <p className="c-normal-dark">Powered by</p>
            <img src={sumhrlogo} />
          </div>
        </div> */}
      </div>
    );
  }
}

export default ScreeningQuestion;
