import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import ToolTip from "@material-ui/core/Tooltip";
import addIcon from "Assets/newicons/action/add-round/glyph/add-round-filled-blue-16px.svg";
import checkRoundGreen from "Assets/newicons/notify/check-round/glyph/check-round-outline-green-16px.svg";
import DynamicForm from "Component/DynamicForm";
import { textinputconverter } from "Component/DynamicForm/Fields/Text";
import { textareainputconverter } from "Component/DynamicForm/Fields/Textarea";
import React, { Component } from "react";
import ReactQuill from "react-quill";

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

export const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
  { value: "purple", label: "Purple", color: "#5243AA" },
  { value: "red", label: "Red", color: "#FF5630", isFixed: true },
  { value: "orange", label: "Orange", color: "#FF8B00" },
  { value: "yellow", label: "Yellow", color: "#FFC400" },
  { value: "green", label: "Green", color: "#36B37E" },
  { value: "forest", label: "Forest", color: "#00875A" },
  { value: "slate", label: "Slate", color: "#253858" },
  { value: "silver", label: "Silver", color: "#666666" },
];

export default class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postData: this.props.postData,
      designationid: null,
      jobmission: "",
      isedit: false,
      rolesummary: "",
      departmentids: null,
      issubdepartment: true,
      skillarray: this.props.postData.jobdetail.skill || [],
      backupskill: null,
      selectedDepartment: null,
      jobresponsibilities: "",
      options:
        props.allTags &&
        props.allTags.map((tag) => ({
          value: tag.value,
          label: tag.label,
        })),
      allTags: [],
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
    this.updateDropdown = this.updateDropdown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  componentDidMount() {
    this.props.onRef({
      onCancel: this.props.onCancel,
      onNext: this.onsubmit,
      nextButtonLabel: "Save",
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      options: props.allTags.map((tag) => ({
        value: tag.value,
        label: tag.label,
      })),
    });
  }

  handleChange(newValue) {
    if (newValue && newValue.length < 6) {
      this.setState({ value: newValue });
    }
  }

  handleCreate(inputValue) {
    this.setState({ isLoading: true });
    setTimeout(() => {
      const { options, value } = this.state;
      const newOption = { value: inputValue, label: inputValue };
      const x = [...options, newOption];
      const y = [...value, newOption];
      this.setState({
        isLoading: false,
        options: x.length > 5 ? [...options] : x,
        value: y.length > 5 ? [...value] : y,
      });
    }, 1000);
  }

  handleBlur(key, value) {
    const { skillarray, backupskill, postData } = this.state;
    if (value && value.length > 50) {
      this.props.enqueueSnackbar(
        "maximum limit should not be greater than 50",
        { variant: "error" },
      );
      return;
    } else if (value) {
      skillarray.push(backupskill);
      // skill.push(backupskill);
      this.setState({
        skillarray,
        backupskill: "",
        [key]: "",
        // skill,
      });
    }
  }

  handleDelete(index) {
    const { skillarray } = this.state;
    const { skill } = this.state.postData.jobdetail;
    skillarray.slice(index, 1);
    skill.splice(index, 1);
    this.setState({
      skillarray,
      skill,
    });
  }

  onChange(key, value) {
    const { jobdetail, skillarray } = this.state.postData;
    if (key == "backupskill") {
      // if (value.length <= 40) {
      this.setState({
        [key]: value,
        isedit: true,
      });
      // }
    } else {
      jobdetail[key] = value;
      this.setState({
        jobdetail,
      });
    }
  }

  updateDropdown(key, selectedValues) {
    if (key == "departmentids") {
      this.setState({
        selectedDepartment: selectedValues,
        [key]:
          selectedValues && selectedValues.map((value) => value.displayvalue),
      });
    }
  }

  onsubmit() {
    const { postData, skillarray } = this.state;
    const { jobdetail } = this.state.postData;
    jobdetail.skill = skillarray;
    // jobdetail.responsibility = jobdetail.responsibility.toString()
    // .replace(/(<([^>]+)>)/gi, "");
    this.setState({ jobdetail });
    // const data = {
    //   designationname,
    //   designationid,
    //   departmentids,
    //   description: null,
    // };
    this.props.submit(postData);
    // this.props.onNextClick();
  }

  render() {
    const { jobdetail } = this.state.postData;
    let { allTags } = this.props;
    allTags =
      allTags &&
      allTags.map((tag) => ({
        value: tag.value,
        label: tag.label,
      }));
    return (
      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <p className="normal-dark-secondary margin-bottom-8">Job mission</p>
          <DynamicForm
            fields={textareainputconverter({
              value: (jobdetail && jobdetail.mission) || null,
              placeholder: "Enter the job mission",
              label: "",
            })}
            onChange={(e) => {
              this.onChange("mission", e);
            }}
          />
          <p className="normal-dark-secondary margin-bottom-8">Role summary</p>
          <DynamicForm
            fields={textareainputconverter({
              value: (jobdetail && jobdetail.rolesummary) || null,
              placeholder: "Enter a role summary for this job",
              label: "",
            })}
            onChange={(e) => {
              this.onChange("rolesummary", e);
            }}
          />
          <p className="normal-dark-secondary margin-bottom-8">
            Mention the job responsibilities
          </p>
          <div className="margin-top-8 margin-bottom-8">
            <ReactQuill
              theme="snow"
              value={(jobdetail && jobdetail.responsibility) || null}
              modules={this.state.modules}
              formats={this.state.formats}
              onChange={(e) => jobdetail && this.onChange("responsibility", e)}
            />
          </div>
        </Grid>
        <Grid item sm={12} md={6} style={{ position: "relative" }}>
          <p className="normal-dark-secondary margin-bottom-8">
            Add skills required for this designation
          </p>
          {/* <CreatableSelect
            isClearable
            isDisabled={isLoading}
            isLoading={isLoading}
            onChange={this.handleChange}
            onCreateOption={this.handleCreate}
            options={colourOptions}
            value={value}
            isMulti
            placeholder="Enter a skill"
          /> */}
          <div
            className="position-rel"
            style={{
              // bottom: 0,
              left: 0,
              width: "calc(100% - 12px)",
              // padding: "8px 24px",
              // marginLeft: 12,
            }}
          >
            <DynamicForm
              fields={textinputconverter({
                value: this.state.backupskill,
                placeholder: "Enter a skill(maximum limit is 50 character)",
                label: "",
              })}
              onChange={(e) => {
                this.onChange("backupskill", e);
              }}
            />
            <div
              style={{ position: "absolute", right: 14, top: 12 }}
              onClick={() => {
                this.handleBlur("backupskill", this.state.backupskill);
              }}
            >
              {this.state.isedit ? (
                <img src={checkRoundGreen}></img>
              ) : (
                <img src={addIcon}></img>
              )}
            </div>
          </div>

          {this.state.skillarray &&
            this.state.skillarray.length > 0 &&
            this.state.skillarray.map((x, index) => (
              <ToolTip title={x} placement="top">
                <Chip
                  label={x}
                  onDelete={() => this.handleDelete(index)}
                  color="primary"
                  className="ellipsis"
                  variant="outlined"
                  style={{ marginRight: 16, marginBottom: 8, width: 200 }}
                />
              </ToolTip>
            ))}

          {/* <Chip
        icon={<FaceIcon />}
        label="Deletable secondary"
        onDelete={handleDelete}
        color="secondary"
        variant="outlined"
      /> */}
        </Grid>
      </Grid>
    );
  }
}
