import { ReducerTypes } from './constant';
// import {Exitreasons} from "./ExitActions";

export const ExitReasonsReducer = (state = {
  subscriptionexitreasonid: null,
  reasontypeid: null,
  reason: '',
  subscriptionid: null,
  isactive: null,
}, action) => {
  switch (action.type) {
  case ReducerTypes.EXITREASONSPOLICY:
    return { ...state, ...action.payload };
  default:
    return state;
  }
};
