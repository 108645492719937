import React from "react";
import "./style/style.css";
import {
  instance,
  careerpageinstance,
  extInstanceMultipart,
} from "Actions/AxiosInstance";
import Dropzone from "react-dropzone";
// import { instanceMultipart } from "Actions/AxiosInstance";
import Thumbnail from "Component/Thumbnail/ThumbnailSmall";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import { bindActionCreators } from "redux";
// import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import Select from "react-select";
import { getFormattedLogoLink } from "Utils";
import Paper from "@material-ui/core/Paper";
import mailIcon from "Assets/newicons/user-interface/mail/outline/mail-outline-16px.svg";
import mobileIcon from "Assets/newicons/user-interface/mobile/outline/mobile-outline-16px.svg";
import phoneIcon from "Assets/newicons/services/phone-bill/outline/phone-bill-outline-16px.svg";
import githubIcon from "Assets/Svg/github-24-px.svg";
import dribbbleIcon from "Assets/Svg/dribbble-24-px.svg";
import behanceIcon from "Assets/Svg/behance-24-px.svg";
import linkedinIcon from "Assets/Svg/linkedin-24-px.svg";
import facebookIcon from "Assets/Svg/facebooklogo.svg";
import blogIcon from "Assets/Svg/blogs.svg";
import slackIcon from "Assets/Svg/slack.svg";
import skypIcon from "Assets/Svg/skype.svg";
import qouraIcon from "Assets/Svg/quara.svg";
import pinterestIcon from "Assets/Svg/pintrest.svg";
import twitterIcon from "Assets/Svg/twitter.svg";
import whatsAppIcon from "Assets/Svg/watsapp.svg";
import hangoutIcon from "Assets/Svg/hangout.svg";
import sumhrlogo from "./images/sumhr-logo.svg";
import ScreeningQuestion from "./ScreeningQuestion";
import SuccessPage from "./successPage";
import uploadicon from "./images/upload-outline-16px.svg";
import DocumentUpload from "./DocumentUpload";

const styles = () => ({
  drawerPaper: {
    width: "30%",
  },
});

const POSTDATA = {
  name: null,
  emailid: null,
  callingcode: null,
  mobilenumber: null,
  pincode: null,
  city: null,
  state: null,
  country: null,
  requisitionjobid: null,
  jobemailid: null,
  joburl: null,
  advanceskill: null,
  noticeperiod: null,
  expectedsalary: null,
  profilepicpath: null,
  elementryskill: null,
  advancelanguage: null,
  elementrylanguage: null,
  workinglanguage: null,
  resumepath: null,
  coverletterpath: null,
  sourcetypeid: null,
  candidateeducation: [],
  candidateexperience: [],
  candidatecontact: [],
  candiatequestionfeedback: [],
  source: null,
  isEmailtrue: false,
};

class PersonalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showform: false,
      jobdetails: this.props.jobdetails,
      documentuploadsection: false,
      contactType: [],
      screeningquestion: false,
      onsuccess: false,
      errormessage: "",
      CandidateDetails: [],
      postdata: { ...POSTDATA },
      contactInit: {
        contacttypeid: null,
        contacttagid: 2,
        contacttypevalue: null,
        candidatecontactid: null,
        candidateid: null,
        contacttype: null,
      },
    };
    this.submit = this.submit.bind(this);
    this.onDocumentupload = this.onDocumentupload.bind(this);
    this.onScreeningquestion = this.onScreeningquestion.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onPersonaldetail = this.onPersonaldetail.bind(this);
    this.onSuccesspage = this.onSuccesspage.bind(this);
    this.uploadDoc = this.uploadDoc.bind(this);
  }

  submit() {
    this.props.changeForm("isUpload");
  }

  componentDidMount() {
    this.GetContactType();
  }

  handleChange(name, value) {
    const { postdata, contactInit } = this.state;
    if (name == "contacttypevalue" || name == "contacttypeid") {
      if (name == "contacttypeid") {
        contactInit[name] = value && value.displayvalue;
        contactInit.contacttype = value;
      } else {
        contactInit[name] = value;
      }
      this.setState({
        contactInit,
      });
    } else {
      postdata[name] = value;
      this.setState({
        postdata,
      });
    }
  }

  handleblur(name, value) {
    if (name == "name") {
      if (value == "") {
        this.setState({ isinvalidename: true });
      } else {
        this.setState({ isinvalidename: false });
      }
    } else if (name == "callingcode") {
      if (value == "") {
        this.setState({ iscallingcode: true });
      } else {
        this.setState({ iscallingcode: false });
      }
    } else if (name == "mobilenumber") {
      if (value == "") {
        this.setState({ ismobno: true });
      } else {
        this.setState({ ismobno: false });
      }
    } else if (name == "city") {
      if (value == "") {
        this.setState({ isinvalidcity: true });
      } else {
        this.setState({ isinvalidcity: false });
      }
    } else if (name == "noticeperiod") {
      if (value == "") {
        this.setState({ isinvalidenoticeperiod: true });
      } else {
        this.setState({ isinvalidenoticeperiod: false });
      }
    } else if (name == "expectedsalary") {
      if (value == "") {
        this.setState({ isinvalideexpectedsalary: true });
      } else {
        this.setState({ isinvalideexpectedsalary: false });
      }
    } else if (name == "advanceskill") {
      if (value == "") {
        this.setState({ isinvalideadvanceskill: true });
      } else {
        this.setState({ isinvalideadvanceskill: false });
      }
    } else if (name == "elementryskill") {
      if (value == "") {
        this.setState({ isinvalidelementryskill: true });
      } else {
        this.setState({ isinvalidelementryskill: false });
      }
    } else if (name == "contacttypevalue") {
      if (value == "") {
        this.setState({ isinvalidcontacttypevalue: true });
      } else {
        this.setState({ isinvalidcontacttypevalue: false });
      }
    } else {
    }
  }

  // validateEmail(value, index) {
  //   const { postdata } = this.state;
  //   const currentemail = value;
  //   if (currentemail != "") {
  //     if (
  //       /^\w+([\.-]?\w+)*([+]{0,1}\w+){0,1}@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(
  //         currentemail,
  //       )
  //     ) {
  //       // const x = postdata.find((email, index1) => index != index1 && email.emailid == currentemail);
  //       const x = postdata.emailid == currentemail;
  //       if (x) {
  //         this.props.enqueueSnackbar("Please enter unique email ID", {
  //           variant: "error",
  //         });
  //         postdata.isEmailtrue = false;
  //       } else {
  //         postdata.isEmailtrue = true;
  //       }
  //       this.setState({ postdata });
  //     } else {
  //       postdata.isEmailtrue = false;
  //       this.setState({ postdata });
  //       this.props.enqueueSnackbar("Please enter valid email ID", {
  //         variant: "error",
  //       });
  //     }
  //   } else {
  //     this.props.enqueueSnackbar("Please fill all the required fileds", {
  //       variant: "error",
  //     });
  //   }
  // }

  validateEmail(value) {
    // const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const mailformat =
      /^\w+([.-]?\w+)*([+]{0,1}\w+){0,1}@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    if (value.match(mailformat)) {
      // alert("Valid email address!");
      // // document.form1.text1.focus();
      // this.props.enqueueSnackbar("Please enter valid email ID", {
      //   variant: "error",
      // });
      this.setState({ isinvalidemailid: false });
      return true;
    } else {
      // alert("You have entered an invalid email address!");
      // // document.form1.text1.focus();
      // this.props.enqueueSnackbar("Please enter valid email address!", {
      //   variant: "error",
      // });
      this.setState({ isinvalidemailid: true });
      return false;
    }
  }

  addContact = () => {
    const { postdata, contactInit } = this.state;

    if (!contactInit.contacttypeid) {
      this.props.enqueueSnackbar("Please select contact type", {
        variant: "error",
      });
    } else if (!contactInit.contacttypevalue) {
      this.props.enqueueSnackbar("Please enter contact details", {
        variant: "error",
      });
    } else {
      delete contactInit.contacttype;
      postdata.candidatecontact.push(contactInit);
      this.setState({
        postdata,
        contactInit: {
          contacttypeid: null,
          contacttagid: 2,
          contacttypevalue: "",
          candidatecontactid: null,
          candidateid: null,
          contacttype: null,
        },
        showform: false,
      });
    }
  };

  async uploadDoc(key, files) {
    const { postdata } = this.state;
    console.log("files: ", files);
    // const { resumepath, coverletterpath } = this.state;
    const formData = new FormData();
    formData.append("file", files[0]);
    // if (files.length == 0) {
    //   this.props.enqueueSnackbar("Please select Valid image. (Maxsize : 2MB)", {
    //     variant: "error",
    //   });
    //   return false;
    // }
    extInstanceMultipart
      .post(
        `/drive/uploaddocument`, // 8 is recruitproduct appid
        formData,
      )
      .then((resp) => {
        console.log("resp.data.result: ", resp);
        // if (id == 2) {
        postdata[key] = resp.data.result[0].imagepath;
        //   sectiondetail.documenttypeid =
        //     getDocTypeid[resp.data.result[0].extension];
        //   this.setState({
        //     sectiondetail,
        //   });
        // }
        // if (id == 1) {
        // [key] = resp.data.result[0].imagepath;
        // sectiondetail.documenttypeid =
        //   getDocTypeid[resp.data.result[0].extension];
        // postData.sectiondetail.push(sectiondetail);
        this.setState({
          postdata,
        });
        // }
      });
  }

  onScreeningquestion() {
    this.setState({
      screeningquestion: true,
      documentuploadsection: false,
    });
  }

  onDocumentupload() {
    const { postdata } = this.state;

    // if (
    //   !postdata.name ||
    //   !postdata.emailid ||
    //   !postdata.callingcode ||
    //   !postdata.mobilenumber ||
    //   !postdata.city ||
    //   !postdata.noticeperiod ||
    //   !postdata.expectedsalary ||
    //   !postdata.advanceskill ||
    //   !postdata.elementryskill ||
    //  // !postdata.candidatecontact
    // ) {
    //   this.props.enqueueSnackbar("Please fill all required fields", {
    //     variant: "error",
    //   });
    // }
    if (!postdata.name) {
      this.props.enqueueSnackbar("Please enter name", {
        variant: "error",
      });
      return;
    } else if (!postdata.emailid) {
      this.props.enqueueSnackbar("Please enter email id", {
        variant: "error",
      });
      return;
    } else if (this.state.isinvalidemailid) {
      this.props.enqueueSnackbar("Please enter valid email id", {
        variant: "error",
      });
      return;
    } else if (!postdata.callingcode) {
      this.props.enqueueSnackbar("Please enter calling code", {
        variant: "error",
      });
      return;
    } else if (!postdata.mobilenumber) {
      this.props.enqueueSnackbar("Please enter mobile number", {
        variant: "error",
      });
      return;
    } else if (!postdata.city) {
      this.props.enqueueSnackbar("Please enter city", {
        variant: "error",
      });
      return;
    } else if (!postdata.noticeperiod) {
      this.props.enqueueSnackbar("Please enter notice period", {
        variant: "error",
      });
      return;
    } else if (!postdata.expectedsalary) {
      this.props.enqueueSnackbar("Please enter expected salary", {
        variant: "error",
      });
      return;
    } else if (!postdata.advanceskill) {
      this.props.enqueueSnackbar("Please enter advance skill", {
        variant: "error",
      });
      return;
    } else if (!postdata.elementryskill) {
      this.props.enqueueSnackbar("Please enter elementry skill", {
        variant: "error",
      });
      return;
    } else {
      this.setState({
        documentuploadsection: true,
        screeningquestion: false,
        onsuccess: false,
      });
    }
  }

  onPersonaldetail() {
    this.setState({
      documentuploadsection: false,
      screeningquestion: false,
      onsuccess: false,
    });
  }

  onSuccesspage() {
    this.setState({
      documentuploadsection: false,
      screeningquestion: false,
      onsuccess: true,
    });
  }

  GetContactType() {
    careerpageinstance
      .get("superuser/getmastercode/ContactType")
      .then((result) => {
        const contactType = [];
        contactType.push(...result.data.result);
        this.setState({ contactType });
      })
      .catch((error) => {
        const CommonError = error.response
          ? error.response.data.error.message
          : error.message;
        this.props.enqueueSnackbar(CommonError, { variant: "error" });
      });
  }

  render() {
    const { postdata, jobdetails } = this.state;
    return (
      <div>
        {this.state.documentuploadsection == true ? (
          <DocumentUpload
            {...this.state}
            {...this.props}
            postdata={this.state.postdata}
            onScreeningquestion={this.onScreeningquestion}
            onDocumentupload={this.onDocumentupload}
            onPersonaldetail={this.onPersonaldetail}
            jobdetails={this.state.jobdetails}
          />
        ) : this.state.screeningquestion == true ? (
          <ScreeningQuestion
            {...this.state}
            {...this.props}
            postdata={this.state.postdata}
            onScreeningquestion={this.onScreeningquestion}
            onDocumentupload={this.onDocumentupload}
            onPersonaldetail={this.onPersonaldetail}
            onSuccesspage={this.onSuccesspage}
            jobdetails={this.state.jobdetails}
          />
        ) : this.state.onsuccess == true ? (
          <SuccessPage
            {...this.state}
            {...this.props}
            postdata={this.state.postdata}
            onScreeningquestion={this.onScreeningquestion}
            onDocumentupload={this.onDocumentupload}
            onPersonaldetail={this.onPersonaldetail}
          />
        ) : (
          <div style={{ background: "rgb(249, 249, 249)" }}>
            <div
              className="c-fluid-container"
              style={{
                backgroundColor: "#fff",
                width: "100%",
                padding: "32px 0px 0px 0px",
              }}
            >
              <div className="c-wrapper">
                <img
                  style={{
                    maxHeight: 100,
                    maxWidth: 200,
                    objectFit: "contain",
                  }}
                  src={getFormattedLogoLink(
                    this.props.subscriptiondetails.logo,
                  )}
                  alt="icon"
                />
              </div>
            </div>
            <div style={{ backgroundColor: "#fff" }}>
              <div className="c-wrapper">
                <div className="c-MuiGrid-root c-fluid-container c-MuiGrid-container">
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-7">
                    <h1
                      style={{
                        color: "rgb(1, 7, 9)",
                        fontSize: 40,
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      {jobdetails && jobdetails.designationname}
                    </h1>
                    <p
                      style={{
                        color: "rgb(84, 85, 86)",
                        fontSize: 24,
                        marginRight: 16,
                        fontWeight: "bold",
                        marginTop: 0,
                      }}
                    >
                      {jobdetails &&
                        jobdetails.location &&
                        jobdetails.location.location}{" "}
                      • {jobdetails && jobdetails.designationname} •{" "}
                      {jobdetails && jobdetails.jobtype}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
            //  className="c-fluid-container"
            >
              <div className="c-wrapper">
                <p
                  style={{
                    color: "rgb(1, 7, 9)",
                    fontSize: 24,
                    marginBottom: 32,
                    fontWeight: "bold",
                  }}
                >
                  Submit your application
                </p>
                <form
                // onSubmit={this.mySubmitHandler}
                // onSubmit={this.configCandidateDetails}
                >
                  <div>
                    <p className="c-large-text-dark-seondary c-t-bold c-margin-bottom-24">
                      Personal Information
                    </p>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                        <p className="c-normal-dark-secondary c-margin-top-8">
                          Profile picture
                        </p>
                      </div>
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                        <div style={{ marginBottom: 16 }}>
                          {postdata.profilepicpath == null ? (
                            <Dropzone
                              onDrop={(e) =>
                                this.uploadDoc("profilepicpath", e)
                              }
                              multiple={false}
                              accept="image/*"
                              maxSize={2000000}
                              className="height100 drag-drop"
                            >
                              <div className="c-uploader-wrapper cursor-pointer">
                                <div
                                  className="vertical-center"
                                  style={{ height: 20 }}
                                >
                                  <img
                                    src={uploadicon}
                                    className="vertical-middle"
                                  ></img>
                                  <span className="margin-left-16 small-blue">
                                    Click here to upload
                                  </span>
                                </div>
                              </div>
                            </Dropzone>
                          ) : (
                            <div className="about-us-file-uploader">
                              <Thumbnail
                                key="key"
                                onDelete={() => this.onDeleteDoc("uploadimg")}
                                onEdit={(e) =>
                                  this.uploadDoc("profilepicpath", e)
                                }
                                filetypes="image/*"
                                doc={{ url: postdata.profilepicpath }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                        <p className="c-normal-dark-secondary c-margin-top-8">
                          Full name *
                        </p>
                      </div>
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                        <div
                          className="c-form-element"
                          style={{ marginBottom: 12 }}
                        >
                          <input
                            type="text"
                            name="name"
                            value={postdata.name}
                            onChange={(event) =>
                              this.handleChange("name", event.target.value)
                            }
                            onBlur={(e) =>
                              this.handleblur("name", e.target.value)
                            }
                            style={{ marginBottom: 4 }}
                          />
                          {this.state.isinvalidename && (
                            <p className="small-red-dark margin-bottom-8">
                              Please enter full name!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                        <p className="c-normal-dark-secondary c-margin-top-8">
                          Email *
                        </p>
                      </div>
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                        <div
                          className="c-form-element"
                          style={{ marginBottom: 12 }}
                        >
                          <input
                            type="email"
                            name="email"
                            value={postdata.emailid}
                            onChange={(event) =>
                              this.handleChange("emailid", event.target.value)
                            }
                            onBlur={(e) => this.validateEmail(e.target.value)}
                            style={{ marginBottom: 4 }}
                          />
                          {this.state.isinvalidemailid && (
                            <p className="small-red-dark margin-bottom-8">
                              Please enter valid email address!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                        <p className="c-normal-dark-secondary c-margin-top-8">
                          Phone *
                        </p>
                      </div>
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                        <div
                          className="c-form-element"
                          style={{ display: "flex", marginBottom: 12 }}
                        >
                          <span style={{ width: 60 }}>
                            <input
                              type="text"
                              name="callingcode"
                              value={postdata.callingcode}
                              onChange={(event) =>
                                this.handleChange(
                                  "callingcode",
                                  event.target.value,
                                )
                              }
                              onBlur={(e) =>
                                this.handleblur("callingcode", e.target.value)
                              }
                              style={{ marginBottom: 4 }}
                            />
                          </span>
                          <span>
                            <input
                              type="number"
                              name="mobilenumber"
                              value={postdata.mobilenumber}
                              onChange={(event) =>
                                this.handleChange(
                                  "mobilenumber",
                                  event.target.value,
                                )
                              }
                              onBlur={(e) =>
                                this.handleblur("mobilenumber", e.target.value)
                              }
                              style={{ marginBottom: 4 }}
                            />
                          </span>
                        </div>
                        {this.state.iscallingcode && (
                          <p className="small-red-dark margin-bottom-8">
                            Please enter calling code!
                          </p>
                        )}
                        {this.state.ismobno && (
                          <p className="small-red-dark margin-bottom-8">
                            Please enter mobile number!
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                        <p className="c-normal-dark-secondary c-margin-top-8">
                          Current city *
                        </p>
                      </div>
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                        <div
                          className="c-form-element"
                          style={{ marginBottom: 12 }}
                        >
                          <input
                            type="text"
                            name="city"
                            value={postdata.city}
                            onChange={(event) =>
                              this.handleChange("city", event.target.value)
                            }
                            onBlur={(e) =>
                              this.handleblur("city", e.target.value)
                            }
                            style={{ marginBottom: 4 }}
                          />
                          {this.state.isinvalidcity && (
                            <p className="small-red-dark margin-bottom-8">
                              Please enter city name!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                        <p className="c-normal-dark-secondary c-margin-top-8">
                          Notice period *
                        </p>
                      </div>
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                        <div
                          className="c-form-element"
                          style={{ marginBottom: 12 }}
                        >
                          <input
                            type="number"
                            name="noticeperiod"
                            value={postdata.noticeperiod}
                            onChange={(event) =>
                              this.handleChange(
                                "noticeperiod",
                                event.target.value,
                              )
                            }
                            onBlur={(e) =>
                              this.handleblur("noticeperiod", e.target.value)
                            }
                            style={{ marginBottom: 4 }}
                          />
                          {this.state.isinvalidenoticeperiod && (
                            <p className="small-red-dark margin-bottom-8">
                              Please enter notice period!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                        <p className="c-normal-dark-secondary c-margin-top-8">
                          Expected salary *
                        </p>
                      </div>
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                        <div
                          className="c-form-element"
                          style={{ marginBottom: 12 }}
                        >
                          <input
                            type="number"
                            name="expectedsalary"
                            value={postdata.expectedsalary}
                            onChange={(event) =>
                              this.handleChange(
                                "expectedsalary",
                                event.target.value,
                              )
                            }
                            onBlur={(e) =>
                              this.handleblur("expectedsalary", e.target.value)
                            }
                            style={{ marginBottom: 4 }}
                          />
                          {this.state.isinvalideexpectedsalary && (
                            <p className="small-red-dark margin-bottom-8">
                              Please enter expected salary!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div>
                <p className="c-large-text-dark-seondary c-t-bold c-margin-bottom-24">
                  Education
                </p>
                <div className="c-MuiGrid-root c-MuiGrid-container">
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                    <p className="c-normal-dark-secondary c-margin-top-8">
                      Most relevant *
                    </p>
                  </div>
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-margin-bottom-16 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                        <div className="c-width100">
                          <div>
                            <p className="c-input-label">Course type</p>
                            <div className="c-form-element">
                               <select
                                id="cars"
                                value={this.state.mycar}
                                name="cars"
                                style={{ marginBottom: 16 }}
                                onChange={(event) =>
                                  this.handleChange("selectedFuelType", event)
                                }
                              >
                                <option value="All locations">Product</option>
                                <option value="saab">Saab 95</option>
                                <option value="mercedes">Mercedes SLK</option>
                                <option value="audi">Audi TT</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-margin-bottom-16 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                        <div className="c-width100">
                          <div>
                            <p className="c-input-label">Institute name</p>
                            <div className="c-form-element">
                              <input type="text" value="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-margin-bottom-16 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                        <div className="c-width100">
                          <div>
                            <p className="c-input-label"> Course name</p>
                            <div className="c-form-element">
                              <input type="text" value="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-margin-bottom-16 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                        <div className="c-width100">
                          <div>
                            <p className="c-input-label">Graduating year</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p
                  className="normal-blue c-t-bold"
                  style={{ cursor: "pointer" }}
                >
                  + New education
                </p>
              </div>
              <div>
                <p className="c-large-text-dark-seondary c-t-bold c-margin-bottom-24">
                  Work experience
                </p>
                <div className="c-MuiGrid-root c-MuiGrid-container">
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                    <p className="c-normal-dark-secondary c-margin-top-8">
                      Most relevant *
                    </p>
                  </div>
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-margin-bottom-16 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                        <div className="c-width100">
                          <div>
                            <p className="c-input-label">Title</p>
                            <div className="c-form-element">
                              <input type="text" value="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-margin-bottom-16 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                        <div className="c-width100">
                          <div>
                            <p className="c-input-label">Company name</p>
                            <div className="c-form-element">
                              <input type="text" value="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-margin-bottom-16 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                        <div className="c-width100">
                          <div>
                            <p className="c-input-label">Employment type</p>
                            <div className="c-form-element">
                              <select
                                id="cars"
                                name="cars"
                                style={{ marginBottom: 16 }}
                              >
                                <option value="All locations">Product</option>
                                <option value="saab">Saab 95</option>
                                <option value="mercedes">Mercedes SLK</option>
                                <option value="audi">Audi TT</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-margin-bottom-16 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                        <div className="c-width100">
                          <div>
                            <p className="c-input-label">Location</p>
                            <div className="c-form-element">
                              <input type="text" value="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="c-floatleft">
                        <p className="c-input-label">Start date</p>
                        <div className="c-form-element">
                          <input
                            type="text"
                            id="start-date"
                            className="salsa-calendar-input"
                            autoComplete="off"
                            name="startdate"
                            value=""
                          />
                        </div>
                      </span>
                      <span className="c-floatleft">
                        <p className="c-input-label">End date</p>
                        <div className="c-form-element">
                          <input
                            type="text"
                            id="end-date"
                            className="salsa-calendar-input"
                            autoComplete="off"
                            name="startdate"
                            value=""
                          />
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <p
                  className="normal-blue cursor-pointer c-t-bold"
                  style={{ cursor: "pointer" }}
                >
                  + New work experience
                </p>
              </div>
              <div>
                <p className="c-large-text-dark-seondary c-t-bold c-margin-bottom-24">
                  Languages
                </p>
                <div className="c-MuiGrid-root c-MuiGrid-container">
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                    <p className="c-normal-dark-secondary c-margin-top-8">
                      Advanced proficiency
                    </p>
                  </div>
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                    <div className="c-form-element">
                      <textarea
                        type="text"
                        name="city"
                        value={this.state.advancelanguage}
                        onChange={(event) =>
                          this.handleChange(
                            "advancelanguage",
                            event.target.value,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="c-MuiGrid-root c-MuiGrid-container">
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                    <p className="c-normal-dark-secondary c-margin-top-8">
                      Working proficiency
                    </p>
                  </div>
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                    <div className="c-form-element">
                      <textarea
                        type="text"
                        name="city"
                        value={this.state.workinglanguage}
                        onChange={(event) =>
                          this.handleChange(
                            "workinglanguage",
                            event.target.value,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="c-MuiGrid-root c-MuiGrid-container">
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                    <p className="c-normal-dark-secondary c-margin-top-8">
                      Elementary proficiency
                    </p>
                  </div>
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                    <div className="c-form-element">
                      <textarea
                        type="text"
                        name="city"
                        value={this.state.elementrylanguage}
                        onChange={(event) =>
                          this.handleChange(
                            "elementrylanguage",
                            event.target.value,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
               */}
                  <div>
                    <p className="c-large-text-dark-seondary c-t-bold c-margin-bottom-24">
                      Skills
                    </p>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                        <p className="c-normal-dark-secondary c-margin-top-8">
                          Advanced skill *
                        </p>
                      </div>
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                        <div
                          className="c-form-element"
                          style={{ marginBottom: 12 }}
                        >
                          <textarea
                            type="text"
                            name="advanceskill"
                            value={postdata.advanceskill}
                            onChange={(event) =>
                              this.handleChange(
                                "advanceskill",
                                event.target.value,
                              )
                            }
                            onBlur={(e) =>
                              this.handleblur("advanceskill", e.target.value)
                            }
                            style={{ marginBottom: 4 }}
                          />
                          {this.state.isinvalideadvanceskill && (
                            <p className="small-red-dark margin-bottom-8">
                              Please enter advance skill!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                        <p className="c-normal-dark-secondary c-margin-top-8">
                          Elementry skill *
                        </p>
                      </div>
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                        <div
                          className="c-form-element"
                          style={{ marginBottom: 12 }}
                        >
                          <textarea
                            type="text"
                            name="elementryskill"
                            value={postdata.elementryskill}
                            onChange={(event) =>
                              this.handleChange(
                                "elementryskill",
                                event.target.value,
                              )
                            }
                            onBlur={(e) =>
                              this.handleblur("elementryskill", e.target.value)
                            }
                            style={{ marginBottom: 4 }}
                          />
                          {this.state.isinvalidelementryskill && (
                            <p className="small-red-dark margin-bottom-8">
                              Please enter elementry skill!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <p className="c-large-text-dark-seondary c-t-bold c-margin-bottom-24">
                      Contact details
                    </p>
                    <div className="c-MuiGrid-root c-MuiGrid-container">
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 MuiGrid-grid-md-3">
                        <p className="c-normal-dark-secondary c-margin-top-8">
                          Contact type
                        </p>
                      </div>
                      <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                        <div>
                          {postdata.candidatecontact.length > 0 && (
                            <div>
                              <p className="small-dark-secondary margin-bottom-16">
                                All contact details
                              </p>
                              <Paper className="rounded-rectangle-paper padding16 margin-bottom-16 padding-bottom-8">
                                {postdata.candidatecontact &&
                                  postdata.candidatecontact.map((item) => (
                                    <div
                                      className="display-flex margin-bottom-8"
                                      style={{ alignItems: "center" }}
                                    >
                                      {item.contacttypeid && (
                                        <img
                                          height="16"
                                          src={
                                            item.contacttypeid == 1
                                              ? mailIcon
                                              : item.contacttypeid == 2
                                              ? mobileIcon
                                              : item.contacttypeid == 3
                                              ? phoneIcon
                                              : item.contacttypeid == 4
                                              ? skypIcon
                                              : item.contacttypeid == 5
                                              ? hangoutIcon
                                              : item.contacttypeid == 6
                                              ? slackIcon
                                              : item.contacttypeid == 8
                                              ? facebookIcon
                                              : item.contacttypeid == 9
                                              ? twitterIcon
                                              : item.contacttypeid == 10
                                              ? linkedinIcon
                                              : item.contacttypeid == 11
                                              ? behanceIcon
                                              : item.contacttypeid == 12
                                              ? dribbbleIcon
                                              : item.contacttypeid == 13
                                              ? pinterestIcon
                                              : item.contacttypeid == 14
                                              ? githubIcon
                                              : item.contacttypeid == 15
                                              ? qouraIcon
                                              : item.contacttypeid == 16
                                              ? blogIcon
                                              : item.contacttypeid == 17
                                              ? whatsAppIcon
                                              : ""
                                          }
                                          style={{ marginRight: 8 }}
                                        ></img>
                                      )}
                                      <p className="small-dark-secondary">
                                        {item.contacttypevalue}
                                      </p>
                                    </div>
                                  ))}
                              </Paper>
                            </div>
                          )}
                          {!this.state.showform && (
                            <p
                              className="normal-blue c-t-bold"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.setState({ showform: true })}
                            >
                              {postdata.candidatecontact.length > 0
                                ? `+ Add more`
                                : `Add contact`}
                            </p>
                          )}
                          {this.state.showform && (
                            <div>
                              <div className="margin-bottom-16">
                                <Select
                                  className="selectsearch ContactType1"
                                  onChange={(selectedid) => {
                                    this.handleChange(
                                      "contacttypeid",
                                      selectedid,
                                    );
                                  }}
                                  options={this.state.contactType}
                                  getOptionLabel={(option) =>
                                    option.displayname
                                  }
                                  getOptionValue={(option) =>
                                    option.displayvalue
                                  }
                                  value={this.state.contactInit.contacttype}
                                  placeholder="Select Contact Type"
                                />
                              </div>
                              <div className="c-form-element">
                                <input
                                  type="text"
                                  name="contacttypevalue"
                                  placeholder="Enter details"
                                  value={
                                    this.state.contactInit.contacttypevalue
                                  }
                                  onChange={(event) =>
                                    this.handleChange(
                                      "contacttypevalue",
                                      event.target.value,
                                    )
                                  }
                                  onBlur={(e) =>
                                    this.handleblur(
                                      "contacttypevalue",
                                      e.target.value,
                                    )
                                  }
                                />
                                {this.state.isinvalidcontacttypevalue && (
                                  <p className="small-red-dark margin-bottom-8">
                                    Please enter details!
                                  </p>
                                )}
                              </div>
                              <p
                                className="normal-blue c-t-bold"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => this.addContact()}
                              >
                                Save contact
                              </p>
                            </div>
                          )}
                        </div>

                        <button
                          className="btn btn-primary-md-blue"
                          type="button"
                          style={{ marginTop: 40, marginBottom: 40 }}
                          onClick={this.onDocumentupload}
                        >
                          Proceed to document upload
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <div
              className="c-wrapper"
              style={{
                background: "rgb(249, 249, 249)",
                padding: "88px 0",
                textAlign: "center",
              }}
            >
              <p className="c-normal-dark">Powered by</p>
              <img src={sumhrlogo} />
            </div> */}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  SubscriptionData: state.SubscriptionInfo.SubscriptionData,
  prop: state.prop,
  login: state.login,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withSnackbar(PersonalDetails)));
