import React from "react";
import Date from "../../Material-ui/Date";
import initjson from "../Data/initjson";
import KEYS from "../KEYS";
// import { getUserDateFormat } from "../../../Utils/dates";

export default function DateComp(props) {
  let dateRef = null;
  // eslint-disable-next-line no-unused-vars
  const [focused, setFocused] = React.useState(false);
  const { field, updateData } = props;
  console.log('field: ', field);
  const { value = "", iserr = function () {}, placeholder = "", label = "", helperText = "", min = null, max = null, className = "", disabled = false } = field;
  console.log('helperText: ', helperText);

  
  function handleChange(event) {
    updateData({ id: field.id, value: event.value });
  }

  return (
    <div className={`${className}`}>
      <p className="input-label">{label}</p>
      <Date
        className={`width100 zindex-0`}
        variant="outlined"
        change={handleChange}
        placeholder={placeholder}
        value={value}
        {...props}
        error={iserr({ fielddata: field })}
        helperText={focused && helperText}
        // onFocus={() => setFocused(true)}
        // onBlur={() => setFocused(false)}
        min={min}
        max={max}
        disabled={disabled}
        onClick={() => {
          dateRef && dateRef.show();
        }}
        onFocus={() => setFocused(true)}
        ref={(ref) => {
          dateRef = ref;
        }}
      />
    </div>
  );
}

export const dateinputconverter = (field) => [
  {
    ...initjson,
    ...field,
    typeid: KEYS.Date,
  },
];
