import { withSnackbar } from "notistack";
import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import TypoError from "Module/LogIn/TypoError";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.snow.css";
import Configs from "Constants/Configs";
import CareerPage from "Module/Subscription/CareerPage/index";
import JobDescription from "Module/Subscription/CareerPage/JobDescription";
import PersonalDetails from "Module/Subscription/CareerPage/PersonalDetails";
import DocumentUpload from "Module/Subscription/CareerPage/DocumentUpload";
import "../Assets/Css/theme.css";
import If from "../Component/Common/If";
import Loader from "../Component/Loader";
import Pageloader from "../Component/Loader/page";
import MobileView from "../Component/MobileView";
import { getSubdomain } from "../Handlers/getters";
import Preview from "../Component/Preview";

const AccountsComp = lazy(() => import("./accounts"));
const SignupComp = lazy(() => import("./signup"));
const SubscriptionComp = lazy(() => import("./subscription"));
const SuperuserComp = lazy(() => import("./superuser"));
const C = (p) =>
  window.location.pathname === "/login" || window.location.pathname === "/" ? (
    <Suspense fallback={<Loader />}>{p.Child}</Suspense>
  ) : (
    <Suspense fallback={<Pageloader />}>{p.Child}</Suspense>
  );
const Accounts = (p) => <C Child={<AccountsComp {...p} />} />;
const Signup = (p) => <C Child={<SignupComp {...p} />} />;
const Subscription = (p) => <C Child={<SubscriptionComp {...p} />} />;
const Superuser = (p) => <C Child={<SuperuserComp {...p} />} />;
class WebView extends React.Component {
  state = {
    isDisconnected: !navigator.onLine,
    subdomain: getSubdomain(),
  };

  componentDidMount() {
    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        }).then(() => {
          this.setState({ isDisconnected: false }, () =>
            clearInterval(webPing),
          );
        });
        // .catch(() => this.setState({ isDisconnected: true }));
      }, 2000);
      return;
    }
    // return this.setState({ isDisconnected: true });
  };

  render() {
    const { subdomain } = this.state;
    const IsSubscriptionDomain =
      subdomain != "accounts" &&
      subdomain != "superuser" &&
      subdomain != "signup";
    if (
      window.location.pathname.includes("careerpage") &&
      Configs.MODE == "DEV" &&
      !IsSubscriptionDomain
    ) {
      window.Beacon("destroy");
    }
    return (
      <div
        className="root-display-desktop"
        // style={
        //   window.innerWidth < 768 ? { display: "none" } : { display: "block" }
        // }
      >
        {/* <If condition={this.state.isDisconnected}>
          <div>you are offline</div>
        </If> */}
        {/* <If condition={!this.state.isDisconnected}> */}
        <If condition={IsSubscriptionDomain}>
          {window.location.pathname.includes("career") ? (
            <Router>
              <Switch>
                <div className="layout-container width100">
                  <Route exact path="/career" component={CareerPage} />
                  <Route
                    path={`/career/job/:jobcode`}
                    component={JobDescription}
                    // search="?source=search-string"
                  />
                  {/* <Route
                      exact
                      path="/personaldetails"
                      component={PersonalDetails}
                    />
                    <Route
                      exact
                      path="/documentupload"
                      component={DocumentUpload}
                    /> */}
                </div>
              </Switch>
            </Router>
          ) : window.location.pathname.includes("/typo") ? (
            <TypoError {...this.props} {...this.state} />
          ) : (
            <Subscription />
          )}
        </If>
        <If condition={subdomain == "signup"}>
          <Signup />
        </If>
        <If condition={subdomain == "accounts"}>
          <Accounts />
        </If>
        <If condition={subdomain == "superuser"}>
          <Superuser />
        </If>
        {/* </If> */}
        <Preview />
      </div>
    );
  }
}
const MobileWebWrapper = () => (
  <div>
    {window.location.hostname.includes("signup") ? (
      <WebView />
    ) : (
      // <div className="sign-up-div-show">{window.innerWidth < 768 ? <MobileView /> : <WebView />}</div>
      <div>
        <div className="sign-up-div-show">
          <MobileView />
        </div>
        <div className="desktop-div">
          <WebView />
        </div>
      </div>
    )}
  </div>
);
// const MobileWebWrapper = () => (
//   <div>
//     <div
//       className={
//         window.innerWidth > 768 || window.location.hostname.includes("signup")
//           ? "display-block"
//           : "display-none"
//       }
//     >
//       <WebView />
//     </div>
//     <div
//       className={
//         window.location.hostname.includes("signup") || window.innerWidth > 768
//           ? "display-none"
//           : "display-block"
//       }
//     >
//       <MobileView />
//     </div>
//   </div>
// );
export default withRouter(withSnackbar(MobileWebWrapper));
