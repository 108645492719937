import React, { Component } from "react";
import Collapse from "@material-ui/core/Collapse";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CheckImg from "../../../../../Assets/Images/aaa.png";

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postData: this.props.postData,
      designationid: null,
      designationname: "",
      departmentids: null,
      issubdepartment: true,
      selectedDepartment: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
    this.updateDropdown = this.updateDropdown.bind(this);
  }

  componentDidMount() {
    const { allData, postData } = this.props;
    console.log('postData: ', postData);
    const deps = postData && postData.departmentids && !(Array.isArray(postData.departmentids)) ? postData.departmentids.split(",") : postData.departmentids;
    const selectedDepartment = allData && allData.Department && allData.Department.filter((unit) => deps && deps.findIndex((x) => x == unit.displayvalue) != -1);
    this.props.onRef({
      onCancel: this.props.onCancel,
      onNext: this.onsubmit,
      // nextButtonLabel: this.props.isentity ? "Next" : "Save",
    });

    this.setState({
      departmentids: deps,
      designationid: postData && postData.designationid,
      designationname: postData && postData.designationname,
      description: null,
      issubdepartment: postData.designationid ? !!deps : true,
      selectedDepartment,
    });
  }

  onChange(key, value) {
    switch (key) {
      case "issubdepartment": {
        if (value) {
          this.setState({
            [key]: value,
          });
        } else {
          this.setState({
            [key]: value,
            selectedDepartment: [],
            departmentids: null,
          });
        }
        break;
      }
      case "designationname": {
        this.setState({
          [key]: value.target.value,
        });
        break;
      }
      default:
        break;
    }
  }

  updateDropdown(key, selectedValues) {
    if (key == "departmentids") {
      this.setState({
        selectedDepartment: selectedValues,
        [key]: selectedValues && selectedValues.map((value) => value.displayvalue),
      });
    }
  }

  onsubmit() {
    const { designationname, designationid, departmentids, issubdepartment, postData } = this.state;
    if (postData) {
      postData.designationname = designationname;
      postData.designationid = designationid;
      postData.departmentids = departmentids;
      postData.description = null;
      this.setState({
        postData
      })
    }
    if (!designationname || designationname == "") {
      this.props.enqueueSnackbar("Please enter designation name", { variant: "error" });
      return;
    }
    else if (issubdepartment) {
      if (!departmentids || (departmentids && departmentids.length < 1)) {
        this.props.enqueueSnackbar("Please select department", { variant: "error" });
        return;
      }
    }

    const data = {
      designationname,
      designationid,
      departmentids,
      description: null,
    };
    // this.props.submit(data);
    this.props.onNextClick();
  }

  render() {
    const { issubdepartment } = this.state;
    const { allData } = this.props;
    return (
      <div>
        <div className="width50">
          <p className="form-sub-header">What would you like to call this Designation?</p>
          <div>
            <p className="form-input-label">Designation Name</p>
            <TextField
              id="outlined-dense"
              margin="dense"
              variant="outlined"
              style={{ width: "100%" }}
              placeholder="Enter designation name"
              onChange={(e) => this.onChange("designationname", e)}
              value={this.state.designationname}
              maxLength="100"
              className="form-text-input"
            />
          </div>
        </div>

        <div className="margin-top-20 width50">
          <p className="depedent-select-list-head ">Is this Job Title only a part of specific functional Department?</p>
          <div className="depedent-select-list" onClick={() => this.onChange("issubdepartment", true)} aria-expanded={issubdepartment}>
            <span>Yes</span>
            {issubdepartment ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
                <span className="not-selected" />
              )}
          </div>
          <div className="et-level2 width100 margin0">
            <Collapse in={issubdepartment} timeout={2} unmountOnExit className="padding20 overflow-initial">
              <p className="depedent-select-list-head">Department</p>
              <Select
                isMulti
                className="selectsearch"
                onChange={(e) => this.updateDropdown("departmentids", e)}
                options={allData && allData.Department}
                getOptionLabel={(option) => option.displayname}
                getOptionValue={(option) => option.displayvalue}
                value={this.state.selectedDepartment}
                placeholder="Select department"
              />
            </Collapse>
          </div>
          <div className="depedent-select-list" onClick={() => this.onChange("issubdepartment", false)} aria-expanded={!issubdepartment}>
            <span>No</span>
            {!issubdepartment ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
                <span className="not-selected" />
              )}
          </div>
        </div>

        <div className="margin-top-20" />
        {/* <Button onClick={this.onsubmit} variant="contained" color="primary" size="large">
          Save
        </Button> */}
      </div>
    );
  }
}
