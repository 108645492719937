import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";

export default function CreateComp(props) {
  const { text, heading, onCreate, imageSrc, permissions, subheading } = props;
  return (
    <Grid
      container
      className="empty-state-illustration"
      style={{ marginTop: 50 }}
    >
      <Grid container className="padding0 text-center">
        <div className="width100">
          {imageSrc && (
            <img src={imageSrc} style={{ width: 218, height: 218 }} />
          )}
          <div className="headline-h3 margin-bottom-8 margin-top-24 text-align-center">
            {heading}
          </div>
          <div className="normal-dark-secondary">{subheading}</div>
        </div>
      </Grid>
    </Grid>
  );
}

// CreateComp.propTypes = {
//   text: PropTypes.string.isRequired,
// };

CreateComp.defaultProps = {};
