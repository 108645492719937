import React, { Component } from "react";
import Collapse from "@material-ui/core/Collapse";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CheckImg from "../../../../../Assets/Images/aaa.png";

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentid: null,
      departmentname: "",
      businessunitids: null,
      departmentheadid: null,
      parentdepartmentid: null,
      isbusinessunit: false,
      issubdepartment: false,
      isdepartmenthead: false,
      selectedBusinessUnit: [],
      selectedDepartment: null,
      selectedDeprtmentHead: null,
      emailerror: null,
      olddepartmentheadid: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
    this.updateDropdown = this.updateDropdown.bind(this);
  }

  componentDidMount() {
    const { allData, postData } = this.props;

    const buids =
      postData &&
      postData.businessunitids &&
      postData.businessunitids.split(",");
    const selectedBusinessUnit =
      allData &&
      allData.BusinessUnit.filter(
        (unit) => buids && buids.findIndex((x) => x == unit.displayvalue) != -1,
      );

    const selectedDepartment =
      allData &&
      allData.Department.find(
        (dep) => postData && dep.displayvalue == postData.parentdepartmentid,
      );

    const selectedDeprtmentHead =
      allData &&
      allData.Employee.find(
        (dep) => postData && dep.displayvalue == postData.departmentheadid,
      );

    this.setState({
      businessunitids: buids,
      departmentheadid: postData && postData.departmentheadid,
      departmentid: postData && postData.departmentid,
      departmentname: postData && postData.departmentname,
      description: null,
      parentdepartmentid: postData && postData.parentdepartmentid,
      isbusinessunit: !!buids,
      issubdepartment:
        postData && postData.departmentid
          ? !!(postData && postData.parentdepartmentid)
          : false,
      isdepartmenthead: !!(postData && postData.departmentheadid),
      selectedBusinessUnit,
      selectedDepartment,
      selectedDeprtmentHead,
      olddepartmentheadid: postData && postData.olddepartmentheadid,
    });
  }

  onChange(key, value) {
    switch (key) {
      case "isbusinessunit": {
        if (value) {
          this.setState({
            [key]: value,
          });
        } else {
          this.setState({
            [key]: value,
            selectedBusinessUnit: [],
            businessunitids: null,
          });
        }
        break;
      }
      case "issubdepartment": {
        if (value) {
          this.setState({
            [key]: value,
          });
        } else {
          this.setState({
            [key]: value,
            selectedDepartment: [],
            parentdepartmentid: null,
          });
        }
        break;
      }
      case "isdepartmenthead": {
        if (value) {
          this.setState({
            [key]: value,
          });
        } else {
          this.setState({
            [key]: value,
            selectedDeprtmentHead: [],
            departmentheadid: null,
          });
        }
        break;
      }
      case "departmentname": {
        this.setState({
          [key]: value.target.value,
        });
        break;
      }
      default:
        break;
    }
  }

  updateDropdown(key, selectedValues) {
    if (key == "businessunitids") {
      this.setState({
        selectedBusinessUnit: selectedValues,
        [key]:
          selectedValues && selectedValues.map((value) => value.displayvalue),
      });
    } else if (key == "departmentheadid") {
      this.setState({
        selectedDeprtmentHead: selectedValues,
        [key]: selectedValues && selectedValues.displayvalue,
      });
    } else if (key == "parentdepartmentid") {
      this.setState({
        selectedDepartment: selectedValues,
        [key]: selectedValues && selectedValues.displayvalue,
      });
    }
  }

  onsubmit() {
    const {
      departmentname,
      departmentemailid,
      businessunitids,
      departmentheadid,
      parentdepartmentid,
      isbusinessunit,
      issubdepartment,
      isdepartmenthead,
      departmentid,
      olddepartmentheadid,
    } = this.state;

    if (!departmentname || departmentname == "") {
      this.props.enqueueSnackbar("Please enter department name", {
        variant: "error",
      });
      return;
    } else if (isbusinessunit) {
      if (!businessunitids || (businessunitids && businessunitids.length < 1)) {
        this.props.enqueueSnackbar("Please select business units", {
          variant: "error",
        });
        return;
      } else if (issubdepartment) {
        if (parentdepartmentid == null) {
          this.props.enqueueSnackbar("Please select department", {
            variant: "error",
          });
          return;
        } else if (isdepartmenthead) {
          if (!departmentheadid) {
            this.props.enqueueSnackbar("Please select department head", {
              variant: "error",
            });
            return;
          }
        }
      }
    }
    const data = {
      departmentname,
      departmentemailid,
      businessunitids,
      departmentheadid,
      parentdepartmentid,
      departmentid,
      olddepartmentheadid,
    };
    this.props.submit(data);
  }

  render() {
    const { isbusinessunit, issubdepartment, isdepartmenthead } = this.state;
    const { allData } = this.props;
    return (
      <div>
        <div className="width50">
          <p className="form-sub-header">
            What would you like to call this Department?
          </p>
          <div className="form-group padding-bottom-0">
            <p className="form-input-label">Department Name</p>
            <TextField
              id="outlined-dense"
              margin="dense"
              variant="outlined"
              style={{ width: "100%" }}
              placeholder="Enter department name"
              onChange={(e) => this.onChange("departmentname", e)}
              value={this.state.departmentname}
              maxLength="100"
              className="form-text-input"
            />
          </div>
        </div>

        <div className="margin-top-20 width50">
          <p className="depedent-select-list-head ">
            Is this Department only a part of a specific Business Unit?
          </p>
          <div
            className="depedent-select-list"
            onClick={() => this.onChange("isbusinessunit", true)}
            aria-expanded={isbusinessunit}
          >
            <span>Yes</span>
            {isbusinessunit ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
          <div className="et-level2 width100 margin0">
            <Collapse
              in={isbusinessunit}
              timeout={2}
              unmountOnExit
              className="padding20 overflow-initial"
            >
              <p className="depedent-select-list-head">Business Units</p>
              <Select
                className="selectsearch"
                isMulti
                onChange={(e) => this.updateDropdown("businessunitids", e)}
                options={allData && allData.BusinessUnit}
                getOptionLabel={(option) => option.displayname}
                getOptionValue={(option) => option.displayvalue}
                value={this.state.selectedBusinessUnit}
                placeholder="Select business unit"
              />
            </Collapse>
          </div>
          <div
            className="depedent-select-list"
            onClick={() => this.onChange("isbusinessunit", false)}
            aria-expanded={!isbusinessunit}
          >
            <span>No</span>
            {!isbusinessunit ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
        </div>
        <div className="margin-top-20 width50">
          <p className="depedent-select-list-head ">
            Do you want this Department to be a sub-department of an existing
            department?
          </p>
          <div
            className="depedent-select-list"
            onClick={() => this.onChange("issubdepartment", true)}
            aria-expanded={issubdepartment}
          >
            <span>Yes</span>
            {issubdepartment ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
          <div className="et-level2 width100 margin0">
            <Collapse
              in={issubdepartment}
              timeout={2}
              unmountOnExit
              className="padding20 overflow-initial"
            >
              <p className="depedent-select-list-head">Department</p>
              <Select
                className="selectsearch"
                onChange={(e) => this.updateDropdown("parentdepartmentid", e)}
                options={allData && allData.Department}
                getOptionLabel={(option) => option.displayname}
                getOptionValue={(option) => option.displayvalue}
                value={this.state.selectedDepartment}
                placeholder="Select department"
              />
            </Collapse>
          </div>
          <div
            className="depedent-select-list"
            onClick={() => this.onChange("issubdepartment", false)}
            aria-expanded={!issubdepartment}
          >
            <span>No</span>
            {!issubdepartment ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
        </div>
        <div className="margin-top-20 width50">
          <p className="depedent-select-list-head ">
            Do you want to assign a Department Head?
          </p>
          <div
            className="depedent-select-list"
            onClick={() => this.onChange("isdepartmenthead", true)}
            aria-expanded={isdepartmenthead}
          >
            <span>Yes</span>
            {isdepartmenthead ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
          <div className="et-level2 width100 margin0">
            <Collapse
              in={isdepartmenthead}
              timeout={2}
              unmountOnExit
              className="padding20 overflow-initial"
            >
              <p className="depedent-select-list-head">
                Assign Department Head
              </p>
              <Select
                className="selectsearch"
                onChange={(e) => this.updateDropdown("departmentheadid", e)}
                options={allData && allData.Employee}
                getOptionLabel={(option) => option.displayname}
                getOptionValue={(option) => option.displayvalue}
                value={this.state.selectedDeprtmentHead}
                // isDisabled={this.state.employeeaddressid}
                placeholder="Select department head"
              />
            </Collapse>
          </div>
          <div
            className="depedent-select-list"
            onClick={() => this.onChange("isdepartmenthead", false)}
            aria-expanded={!isdepartmenthead}
          >
            <span>No</span>
            {!isdepartmenthead ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
        </div>
        <div className="margin-top-20" />
        <Button
          onClick={this.onsubmit}
          variant="contained"
          color="primary"
          size="large"
        >
          Save
        </Button>
      </div>
    );
  }
}
