import get from "lodash/get";
import Cookies from "universal-cookie";
import { instanceMultipart } from "../Actions/AxiosInstance";

export const getOSName = () => navigator.appVersion;

export const isMacOS = () => navigator.appVersion.includes("Macintosh");

export const isIncognitoMode = () => Promise((resolve, reject) => {
    const fs = window.RequestFileSystem || window.webkitRequestFileSystem;
    if (!fs) reject("Check incognito failed");
    else fs(window.TEMPORARY, 100, () => resolve(false), () => resolve(true));
  });

export const getSubdomain = () => {
  const subdomain = get(window, "location.hostname", "").split(".");
  return subdomain[0];
};

export function getUrlExtension(url) {
  return url
    .split(/\#|\?/)[0]
    .split(".")
    .pop()
    .trim();
}

export function getFnameFromUrl(url) {
  return url.substring(url.lastIndexOf("/") + 1);
}

export async function getFileUrl({ file, fileurl, maxsize }) {
  let res = "";
  const fileAPIresp = await instanceMultipart.post(fileurl, file);
  res = get(fileAPIresp, "data.result[0].imagepath", "");
  return res;
}

export function getCookie(cname) {
  const cookies = new Cookies();
  return cookies.get(cname) || null;
}
