import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SearchSq from "Component/Material-ui/SearchSq";
import React from "react";
import "../../Assets/SCSS/components/Pageheader.scss";
import Tooltip from "@material-ui/core/Tooltip";
import BackArrow from "../../Assets/Icons/left-arrow.png";
import cancelIcon from "../../Assets/Svg/cancel_black.svg";

const DrawerHeader = ({
  closeDrawer,
  goBack,
  header,
  isbackarrow,
  onSave,
  onSearch,
  saveButtonLabel = "Save",
}) => (
  <div className="Drawer-header-wrapper">
    {isbackarrow ? (
      <span className="drawer-close-btn" onClick={closeDrawer}>
        <img src={BackArrow} onClick={goBack}></img>
      </span>
    ) : (
      <span className="drawer-close-btn" onClick={closeDrawer}>
        <img src={cancelIcon} onClick={closeDrawer}></img>
      </span>
    )}
    <Tooltip title={header} placement="top">
      <span
        className="drawer-header ellipsis"
        style={{ width: onSearch ? "70%" : "" }}
      >
        {header}
      </span>
    </Tooltip>
    {onSearch && (
      <div style={{ width: 250, float: "left" }}>
        <SearchSq placeholder="Search components" onChange={onSearch} />
      </div>
    )}
    {onSave && (
      <Button
        className="btn btn-primary-md-blue floatright"
        variant="contained"
        onClick={onSave}
      >
        {saveButtonLabel}
      </Button>
    )}
  </div>
);

export default DrawerHeader;
