import typeToReducer from "type-to-reducer";
import { ApiReducerType } from "../Constants";

const initState = {
  IsPending: false,
  IsFulfilled: false,
  IsError: false,
  Data: [],
};
const ApiReducer = {};
Object.keys(ApiReducerType).forEach((key) => {
  const actualKey = ApiReducerType[key];
  ApiReducer[actualKey] = typeToReducer(
    {
      [actualKey]: {
        PENDING: () => ({
          ...initState,
          IsPending: true,
        }),
        FULFILLED: (state, action) => ({
          ...state,
          IsFulfilled: true,
          IsPending: false,
          Data: action.payload.data.result,
        }),
        REJECTED: (state, action) => ({
          ...state,
          IsError: true,
          IsFulfilled: true,
          ErrorMessage: action.payload.response ? action.payload.response.data.error.message : action.payload.message,
        }),
      },
    },
    initState,
  );
});
export default ApiReducer;
