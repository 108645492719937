import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import SearchIcon from "@material-ui/icons/Search";
import "../../Assets/SCSS/components/Search.scss";

const SearchSq = (props) => (
  <Input
    className="search-square t-12"
    placeholder="Search policy"
    disableUnderline
    fullWidth
    variant="outlined"
    value={props.value}
    onChange={props.onChange}
    // style={{ height: 30 }}
    endAdornment={
      <InputAdornment position="start">
        <SearchIcon color="disabled" />
      </InputAdornment>
    }
    {...props}
  />
);

export default SearchSq;
