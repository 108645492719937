import { combineReducers } from "redux";
import typeToReducer from "type-to-reducer";
import ActionConstants from "../constants";

const initState = {
  IsFulfilled: false,
  IsError: false,
  list: [],
};

const APIAction = {};
Object.keys(ActionConstants).map((key) => {
  const actualKey = ActionConstants[key];
  APIAction[actualKey] = typeToReducer(
    {
      [actualKey]: {
        PENDING: () => ({ ...initState, IsPending: true }),
        FULFILLED: (state, action) => ({
          ...state,
          IsFulfilled: true,
          IsPending: false,
          list: action.payload.data.result,
        }),
        REJECTED: (state, action) => ({
          ...state,
          IsError: true,
          IsPending: false,
          ErrorMessage: action.payload.response ? action.payload.response.data.error.message : action.payload.message,
        }),
        UPDATE: (state) => state,
      },
    },
    initState,
  );
});

export default combineReducers(APIAction);
