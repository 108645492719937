import axios from "axios";
import get from "lodash/get";
import { backToHome } from "Handlers/setters";
import createstore from "../store";
import Configs from "../Constants/Configs";
import { FEATUREKEYS } from "../Constants/Featurekeys";

// const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://sumhrapiserver.sumhr.io:4000/api/' : process.env.NODE_ENV === 'staging' ? 'http://192.168.1.28:4000/api/' : 'http://192.168.1.7:4000/api/';
// const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.sumhr.io:3000/api/' : process.env.NODE_ENV === 'staging' ? 'https://api.sumhr.net:3000/api/' : 'https://api.sumhr.net:000/api/';
// const BASE_URL = "https://api.sumhr.net:3000/api/"; //this is for staging
// const BASE_URL = 'https://api.sumhr.io:3000/api/';//this is for production

const BASE_URL = Configs.API_URL;
export const GLOBAL_TOKEN =
  "Global eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzdW1IUiIsIm5hbWUiOiJzdW1IUi1KV1QiLCJqdGkiOiI1MTRhNTRiMi0xY2M1LTRkYzYtYWVmNC1lNmFhZmQ2MDEwOGIiLCJpYXQiOjE1MzcxNzI4MTMsImV4cCI6MTUzNzE3NjQxM30.kN0pHK0B-PchPACYdWHvN1U2XQgVN0bs3__7zRwc6h4";

let CALLAPI = true;

export const setAuthorizationToken = () => {
  const store = createstore.getState();
  const { AuthenticationAction, SubscriptionInfo } = store;

  const currenttime = Math.floor(Date.now() / 1000);
  const isExpired =
    get(AuthenticationAction, "login", "") &&
    get(AuthenticationAction, "login.expired_at", "") < currenttime;

  if (get(AuthenticationAction, "login.token", "") && isExpired && CALLAPI) {
    setRefreshToken(get(AuthenticationAction, "login.token", ""));
  }
  return (
    get(AuthenticationAction, "login.token", "") ||
    get(SubscriptionInfo, "SubscriptionData.token", "") ||
    GLOBAL_TOKEN
  );
};

const ConvertDataForBackend = (data) => {
  const resultData = data;
  const convert = (value) =>
    value === "" || value === [] || value === {} ? null : value;
  if (typeof data === "object" && data) {
    Object.keys(data).forEach((key) => {
      resultData[key] = convert(data[key]);
    });
  }
  if (resultData && resultData.featurekey) {
    const featureid = FEATUREKEYS[resultData.featurekey];
    const { AuthenticationAction } = createstore.getState();
    const feature = AuthenticationAction.login.features.find(
      (ftr) => ftr.featureid == featureid,
    );
    if (feature) {
      resultData.featureid = feature.featureid;
      resultData.actionid = feature.actionid;
      resultData.profilemenuactionid = feature.profilemenuactionid;
    }
    delete resultData.featurekey;
  }
  return resultData;
};

const ConvertHeadersForBackend = (headers) => {
  headers.Authorization = setAuthorizationToken();
  headers["Content-Type"] = "application/json";
  return headers;
};

export const instance = axios.create({
  baseURL: BASE_URL,
  transformRequest: [
    // eslint-disable-next-line no-unused-vars
    (data, headers) => {
      headers = ConvertHeadersForBackend(headers);
      return JSON.stringify(ConvertDataForBackend(data));
    },
  ],
});

export const careerpageinstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "content-type": "application/json",
    "x-sumhr-key": "37653334-9566-4acb-a391-6e47dc43a6a5",
  },
});

export const instanceMultipart = axios.create({
  baseURL: BASE_URL,
  transformRequest: [
    (data, headers) => {
      headers.Authorization = setAuthorizationToken();
      headers["Content-Type"] = "multipart/form-data";
      return data;
    },
  ],
});

export const extInstanceMultipart = axios.create({
  baseURL: BASE_URL,
  headers: {
    "content-type": "multipart/form-data",
    "x-sumhr-key": "37653334-9566-4acb-a391-6e47dc43a6a5",
  },
});

export const downloadFile = (url, name) => {
  let urlExt = url.split("/");
  urlExt = urlExt[urlExt.length - 1];
  instance
    .post("upload/download", { url }, { responseType: "blob" })
    .then((result) => {
      const newurl = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = newurl;
      link.setAttribute("download", name || urlExt);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
    });
};

const setRefreshToken = (token) => {
  CALLAPI = false;
  if (token) {
    const ConvertHeadersForBackend1 = (headers) => {
      headers.Authorization = GLOBAL_TOKEN;
      headers["Content-Type"] = "application/json";
      return headers;
    };
    const temptoken = axios.create({
      baseURL: BASE_URL,
      transformRequest: [
        // eslint-disable-next-line no-unused-vars
        (data, headers) => {
          headers = ConvertHeadersForBackend1(headers);
          return JSON.stringify(data);
        },
      ],
    });
    console.log("refresh");
    const store = createstore.getState();
    const { AuthenticationAction } = store;
    temptoken
      .post("refreshtoken", {
        logininfoid:
          AuthenticationAction.login && AuthenticationAction.login.logininfoid,
        refreshtoken:
          AuthenticationAction.login && AuthenticationAction.login.refreshtoken,
      })
      .then((resp) => {
        CALLAPI = true;
        console.log("AuthenticationAction1111: ", AuthenticationAction);
        createstore.dispatch({
          type: "LOGIN_UPDATED_WITH_ISFULFILLED",
          payload: {
            // ...AuthenticationAction,
            IsFulfilled: true,
            data: {
              ...AuthenticationAction.login,
              token: resp.data.result[0].token,
              expired_at: resp.data.result[0].expired_at,
            },
          },
        });
        // const URL = `${window.location.origin}/onboarding`;
        //         window.open(URL, "_self");
        window.location.reload();
      })
      .catch((error) => {
        CALLAPI = true;
        console.log("error: ", error);
        window.open("/login/session-expired", "_self");
        localStorage.clear();
      });
  }
};
