import { combineReducers } from "redux";
import AuthenticationAction from "../Actions/AuthenticationAction";
import LanguageAction from "../Actions/LanguageAction";
import SubscriptionInfo from "../Actions/SubscriptionInfo";
import { OnboardingReducer } from "../Module/Accounts/Onboarding/reducer";
import { businessConfigReducers } from "../Module/Subscription/business-configration";
import { ExitReasonsReducer } from "../Module/Subscription/ConfigMode/HRIS/Employee/ExitReasons/ExitReducer";
import AllReducer from "./commonReducer";
import ApiReducer from "./ApiReducer";
import EMPREQUESTCENTERLIST from "./EmployeeRequestCenterList";
import FilterReducer from "./FilterReducer";
import REQUESTCENTER from "./RequestCenterReducer";

export default combineReducers({
  ...ApiReducer,
  Onboarding: OnboardingReducer,
  AuthenticationAction,
  LanguageAction,
  SubscriptionInfo,
  REQUESTCENTER,
  FilterReducer,
  EMPREQUESTCENTERLIST,
  businessConfig: businessConfigReducers,
  ExitReasonsReducer,
  ...AllReducer,
});
