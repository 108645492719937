/* eslint-disable import/prefer-default-export */
import moment, { isDate } from "moment";
import { convertDateForBackend } from ".";

export function getUserDateFormat() {
  const format = localStorage.getItem("dateformat")
    ? localStorage.getItem("dateformat")
    : "YYYY-MM-DD HH";
  const dformat2 = format.split(" HH");
  return dformat2[0];
}

export function getUserDateTimeFormat() {
  const format = localStorage.getItem("dateformat")
    ? localStorage.getItem("dateformat")
    : "YYYY-MM-DD HH";
  return format;
}

export function getMuiUserDateFormat() {
  let res = getUserDateFormat();
  while (
    res.indexOf("Y") != -1 ||
    res.indexOf("m") != -1 ||
    res.indexOf("D") != -1
  ) {
    res = res.replace("Y", "y");
    res = res.replace("m", "M");
    res = res.replace("D", "d");
  }
  console.log("getMuiUserDateFormat res: ", res);
  return res;
}

export function splitDateTime({ datetime = new Date() }) {
  const date = moment(datetime).format("YYYY-MM-DD");
  const time = moment(datetime).format("HH:mm:ss");
  return { date, time };
}

export function mergeDateTime({ date = new Date(), time = new Date() }) {
  const d = moment(date).format("YYYY-MM-DD");
  const t = moment(time, "HH:mm:ss").format("HH:mm:ss");
  return `${d} ${t}`;
}

export function dayplusone(d) {
  return moment(d, "YYYY-MM-DD").add(1, "days").format();
}

export function convertToJSDateType(dt) {
  const BackendDate = dt && convertDateForBackend(dt).split("-");
  console.log("dt1: ", dt);
  console.log("dt2: ", convertDateForBackend(dt));
  console.log("dt3: ", new Date(convertDateForBackend(dt)).toUTCString());
  console.log("dt4: ", new Date("2021-03-18").toUTCString());
  console.log("dt5: ", new Date("2021-03-18T02:54:10Z").toString());
  return dt
    ? new Date(BackendDate[0], BackendDate[1] - 1, BackendDate[2])
    : null;
}

export function jsdayplusone(d = new Date()) {
  let res = d.setDate(d.getDate() + 1);
  console.log("res: ", res);
  res = new Date(res);
  console.log("res: ", res);
  return res || null;
}

export function jsaddDays({ date = new Date(), days }) {
  days = typeof days == "string" ? parseInt(days) : days;
  return date.setDate(date.getDate() + days);
}

export function dayplusoneasdate(d) {
  return moment(d).add(1, "days").format("YYYY-MM-DD");
}

export function dayminusone(d) {
  return moment(d).subtract(1, "days").format();
}

export function addDays({ date = moment(), days }) {
  return moment(date).add(days, "days").format();
}

export function subtractTime({ timearr }) {
  let totalDurations = timearr
    .slice(1)
    .reduce(
      (prev, cur) => moment.duration(prev).subtract(cur),
      moment.duration(timearr[0]),
    );
  totalDurations = moment
    .utc(totalDurations.asMilliseconds())
    .format("HH:mm:ss");
  return totalDurations;
}

export function totalTime({ timearr }) {
  let totalDurations = timearr
    .slice(1)
    .reduce(
      (prev, cur) => moment.duration(cur).add(prev),
      moment.duration(timearr[0]),
    );
  totalDurations = moment
    .utc(totalDurations.asMilliseconds())
    .format("HH:mm:ss");
  return totalDurations;
}

export function isIntimeSameBeforeOuttime({
  indate = moment(),
  outdate = moment(),
  intime,
  outtime,
}) {
  try {
    const indatetime = moment(
      `${moment(indate).format("YYYY-MM-DD")} ${moment(
        intime,
        "HH:mm:ss",
      ).format("HH:mm:ss")}`,
    );
    const outdatetime = moment(
      `${moment(outdate).format("YYYY-MM-DD")} ${moment(
        outtime,
        "HH:mm:ss",
      ).format("HH:mm:ss")}`,
    );
    const res = indatetime.isSameOrBefore(outdatetime);
    return res;
  } catch (err) {
    console.log("err in isIntimesameorBeforeOuttime,", err);
    return true;
  }
}

export function isIntimeBeforeOuttime({
  indate = moment(),
  outdate = moment(),
  intime,
  outtime,
}) {
  try {
    console.log("isIntimeBeforeOuttime", indate, outdate, intime, outtime);
    const indatetime = moment(
      `${moment(indate).format("YYYY-MM-DD")} ${moment(
        intime,
        "HH:mm:ss",
      ).format("HH:mm:ss")}`,
    );
    const outdatetime = moment(
      `${moment(outdate).format("YYYY-MM-DD")} ${moment(
        outtime,
        "HH:mm:ss",
      ).format("HH:mm:ss")}`,
    );
    const res = indatetime.isBefore(outdatetime);
    return res;
  } catch (err) {
    console.log("err in isIntimeBeforeOuttime,", err);
    return true;
  }
}

export function isIntimeSameOrBeforeOuttime({
  indate = moment(),
  outdate = moment(),
  intime,
  outtime,
}) {
  try {
    console.log("isIntimeBeforeOuttime", indate, outdate, intime, outtime);
    const indatetime = moment(
      `${moment(indate).format("YYYY-MM-DD")} ${moment(
        intime,
        "HH:mm:ss",
      ).format("HH:mm:ss")}`,
    );
    const outdatetime = moment(
      `${moment(outdate).format("YYYY-MM-DD")} ${moment(
        outtime,
        "HH:mm:ss",
      ).format("HH:mm:ss")}`,
    );
    const res = indatetime.isSameOrBefore(outdatetime);
    return res;
  } catch (err) {
    console.log("err in isIntimeBeforeOuttime,", err);
    return true;
  }
}

export function isIndatetimeBeforeOutdatetime({
  indatetime = moment(),
  outdatetime = moment(),
}) {
  try {
    const indatetime1 = moment.isMoment(indatetime)
      ? indatetime
      : moment(indatetime, "YYYY-MM-DD HH:mm:ss");
    const outdatetime1 = moment.isMoment(outdatetime)
      ? outdatetime
      : moment(outdatetime, "YYYY-MM-DD HH:mm:ss");
    const res = moment(indatetime1).isBefore(outdatetime1);
    console.log("res: ", res);
    return res;
  } catch (err) {
    console.log("err in isIndateBeforeOutdate,", err);
    return true;
  }
}

export function isIndatetimeSameOrBeforeOutdatetime({
  indatetime = moment(),
  outdatetime = moment(),
}) {
  try {
    const indatetime1 = moment.isMoment(indatetime)
      ? indatetime
      : moment(indatetime, "YYYY-MM-DD HH:mm:ss");
    const outdatetime1 = moment.isMoment(outdatetime)
      ? outdatetime
      : moment(outdatetime, "YYYY-MM-DD HH:mm:ss");
    const res = moment(indatetime1).isSameOrBefore(outdatetime1);
    return res;
  } catch (err) {
    console.log("err in isIndateBeforeOutdate,", err);
    return true;
  }
}

export function istoday(date) {
  return isIndateSameAsOutdate({ indate: date });
}

export function isIndateSameAsOutdate({
  indate = moment(),
  outdate = moment(),
}) {
  try {
    const indatetime = moment(
      `${moment(indate).format("YYYY-MM-DD")}`,
    ).format();
    const outdatetime = moment(
      `${moment(outdate).format("YYYY-MM-DD")}`,
    ).format();
    const res = moment(indatetime).isSame(outdatetime);
    return res;
  } catch (err) {
    console.log("err in isIndateSameAsOutdate,", err);
    return true;
  }
}

export function isIndateSameOrBeforeOutdate({
  indate = moment(),
  outdate = moment(),
}) {
  try {
    const indatetime = moment(
      `${moment(indate).format("YYYY-MM-DD")}`,
    ).format();
    const outdatetime = moment(
      `${moment(outdate).format("YYYY-MM-DD")}`,
    ).format();
    const res = moment(indatetime).isSameOrBefore(outdatetime);
    return res;
  } catch (err) {
    console.log("err in isIndateBeforeOutdate,", err);
    return true;
  }
}

export function isIndateBeforeOutdate({
  indate = moment(),
  outdate = moment(),
}) {
  try {
    const indatetime = moment(
      `${moment(indate).format("YYYY-MM-DD")}`,
    ).format();
    const outdatetime = moment(
      `${moment(outdate).format("YYYY-MM-DD")}`,
    ).format();
    const res = moment(indatetime).isBefore(outdatetime);
    return res;
  } catch (err) {
    console.log("err in isIndateBeforeOutdate,", err);
    return true;
  }
}

export function betweenDays({ startdate = moment(), enddate = moment() }) {
  enddate = moment.isMoment(enddate) ? enddate : moment(enddate);
  startdate = moment.isMoment(startdate) ? startdate : moment(startdate);
  const duration = moment.duration(enddate.diff(startdate));
  const res = duration.asDays();
  return parseInt(res);
}

export function betweenHrMin({
  indatetime = moment(),
  outdatetime = moment(),
}) {
  const duration = moment.duration(outdatetime.diff(indatetime));
  return { hr: duration.asHours(), min: duration.asMinutes() % 60 };
}

export function betweenHrs({ indatetime = moment(), outdatetime = moment() }) {
  const duration = moment.duration(outdatetime.diff(indatetime));
  return duration.asHours();
}

export function betweenMins({ indatetime = moment(), outdatetime = moment() }) {
  const duration = moment.duration(outdatetime.diff(indatetime));
  return duration.asMinutes();
}

export function getUserFormattedTimeFromAPIData(time) {
  const res = moment(time, "HH:mm:ss").format("hh:mm:ss A");
  return res && res != "Invalid date" ? res : "";
}

export function isTimeValid(timeinput) {
  // console.log("time: ", time);
  let res = null;
  if (timeinput && typeof timeinput == "string") {
    let time = timeinput.replace("a", "A");
    const obj = {
      A: [" a", "a", " A"],
      P: [" p", "p", " P"],
    };
    Object.keys(obj).forEach((ele) => {
      obj[ele].forEach((ele2) => {
        time = time.replace(ele2, ele);
      });
    });

    time = time.replace("A", " A");
    time = time.replace("P", " P");
    time = time.replace("m", "M");

    time = time.replace(" AM", "AM");
    time = time.replace("AM", " AM");
    time = time.replace(" PM", "PM");
    time = time.replace("PM", " PM");

    const formats = [
      "YYYY-MM-DD LT",
      "YYYY-MM-DD h:mm:ss A",
      "YYYY-MM-DD HH:mm:ss",
      "YYYY-MM-DD HH:mm",
    ];
    formats.forEach((x) => {
      // console.log("x: ", x);
      if (moment(`2000-01-01 ${time}`, x, true).isValid()) {
        // res = true;
        res = moment(`2000-01-01 ${time}`, x).format("HH:mm");
      }
    });
  }
  return res;
}

/**
 * YYYY 1998
 * MM 01
 * DD 01
 * Time depends on AM\PM
 * HH 12
 * MM
 * SS
 */
export const DateTimeConversion = (DateTime, DateTimeFormat) => {
  const d = new Date(DateTime);
  if (d == "Invalid Date") return "-";
  const DD = d.getDate();
  const MM = d.getMonth() + 1;
  const YYYY = d.getFullYear();
  let HH = d.getHours();
  HH = HH < 10 ? `0${HH}` : HH;
  let MI = d.getMinutes();
  if (MI < 10) {
    MI = `0${MI}`;
  }
  let SS = d.getSeconds();
  if (SS < 10) {
    SS = `0${SS}`;
  }
  const AMPM = ["AM", "PM"];
  switch (DateTimeFormat) {
    case "DD-MM-YYYY HH12:MI:SS AM":
      return `${DD}-${MM}-${YYYY} \t\n${
        HH > 12
          ? HH - 12 < 10
            ? `0${HH - 12}`
            : HH - 12
          : HH == "0" || HH == "00"
          ? "12"
          : HH
      }:${MI}:${SS}${HH < 12 ? AMPM[0] : AMPM[1]}`;
    case "YYYY/DD/MM HH12:MI:SS AM":
      return `${YYYY}/${DD}/${MM} \n${
        HH > 12
          ? HH - 12 < 10
            ? `0${HH - 12}`
            : HH - 12
          : HH == "0" || HH == "00"
          ? "12"
          : HH
      }:${MI}:${SS}${HH < 12 ? AMPM[0] : AMPM[1]}`;
    case "DD/MM/YYYY HH24:MI:SS":
      return `${DD}/${MM}/${YYYY} \n${HH}:${MI}:${SS}`;
    case "DD/MM/YYYY HH12:MI:SS AM":
      return `${DD}/${MM}/${YYYY} \n${
        HH > 12
          ? HH - 12 < 10
            ? `0${HH - 12}`
            : HH - 12
          : HH == "0" || HH == "00"
          ? "12"
          : HH
      }:${MI}:${SS}${HH < 12 ? AMPM[0] : AMPM[1]}`;
    case "DD MM YYYY HH24:MI:SS":
      return `${DD} /\n${MM} /\n${YYYY} \n${HH}:${MI}:${SS}`;
    case "YYYY-DD-MM HH12:MI:SS AM":
      return `${DD}-${MM}-${YYYY}${
        HH > 12
          ? HH - 12 < 10
            ? `0${HH - 12}`
            : HH - 12
          : HH == "0" || HH == "00"
          ? "12"
          : HH
      }:${MI}:${SS}${HH < 12 ? AMPM[0] : AMPM[1]}`;
    case "YYYY-DD-MM HH24:MI:SS":
      return `${YYYY}-${DD}-${MM} \n${HH}:${MI}:${SS}`;
    case "DD-MM-YYYY HH24:MI:SS":
      return `${DD}-${MM}-${YYYY} \n${HH}:${MI}:${SS}`;
    default:
      return false;
  }
};

export const DateConversion = (DateTime, DateFormat) => {
  const d = new Date(DateTime);
  if (d == "Invalid Date") return "-";
  const DD = d.getDate();
  const MM = d.getMonth() + 1;
  const YYYY = d.getFullYear();
  switch (DateFormat.trim()) {
    case "MMM":
      return `${DD}-${MM}-${YYYY}`;
    case "DD-MM-YYYY":
      return `${DD}-${MM}-${YYYY}`;
    case "DD-YYYY-MM":
      return `${DD}-${YYYY}-${MM}`;
    case "MM-DD-YYYY":
      return `${MM}-${DD}-${YYYY}`;
    case "MM-YYYY-DD":
      return `${MM}-${YYYY}-${DD}`;
    case "YYYY-DD-MM":
      return `${YYYY}-${DD}-${MM}`;
    case "YYYY-MM-DD":
      return `${YYYY}-${MM}-${DD}`;
    case "YYYY":
      return YYYY;
    case "DD/MM/YYYY":
      return `${DD}/${MM}/${YYYY}`;
    case "DD/YYYY/MM":
      return `${DD}/${YYYY}/${MM}`;
    case "MM/DD/YYYY":
      return `${MM}/${DD}/${YYYY}`;
    case "MM/YYYY/DD":
      return `${MM}/${YYYY}/${DD}`;
    case "YYYY/DD/MM":
      return `${YYYY}/${DD}/${MM}`;
    case "YYYY/MM/DD":
      return `${YYYY}/${MM}/${DD}`;
    default:
      return false;
  }
};

export const TimeConversion = (DateTime, DateTimeFormat) => {
  const d = new Date(`01-01-1970 ${DateTime}`);
  if (d == "Invalid Date") return "-";
  let HH = d.getHours();
  HH = HH < 10 ? `0${HH}` : HH;
  let MI = d.getMinutes();
  if (MI < 10) {
    MI = `0${MI}`;
  }
  let SS = d.getSeconds();
  if (SS < 10) {
    SS = `0${SS}`;
  }
  const AMPM = [" AM", " PM"];
  switch (DateTimeFormat) {
    case "HH12:MI:SS AM":
      return `${
        HH > 12
          ? HH - 12 < 10
            ? `0${HH - 12}`
            : HH - 12
          : HH == "0" || HH == "00"
          ? "12"
          : HH
      }:${MI}:${SS}${HH < 12 ? AMPM[0] : AMPM[1]}`;
    case "HH24:MI:SS":
      return `${HH}:${MI}:${SS}`;
    case "H12:MI:SS AM":
      return `${
        HH > 12
          ? HH - 12 < 10
            ? `0${HH - 12}`
            : HH - 12
          : HH == "0" || HH == "00"
          ? "12"
          : HH
      }:${MI}:${SS}${HH < 12 ? AMPM[0] : AMPM[1]}`;
    default:
      return false;
  }
};

export const getdateonly = (date) =>
  parseInt(moment(date, "YYYY-MM-DD").format("DD"));
export const getmonthonly = (date) =>
  parseInt(moment(date, "YYYY-MM-DD").format("MM"));
export const getyearonly = (date) =>
  parseInt(moment(date, "YYYY-MM-DD").format("YYYY"));
export const getmonthyearonly = (date = new Date()) =>
  moment(date, "YYYY-MM-DD").format("MMM YYYY");
export const mergedatemonth = ({ date, month, mindate }) => {
  let res =
    month && date
      ? new Date(`${moment().format("YYYY")}-${month}-${date}`)
      : "";

  if (
    !isIndateBeforeOutdate({ indate: mindate, outdate: res }) &&
    mindate &&
    res
  ) {
    res =
      month && date
        ? new Date(`${moment().add("year", 1).format("YYYY")}-${month}-${date}`)
        : "";
  }
  console.log("mergedatemonth res: ", res);
  return res;
};
export const mergemonthyear = ({ month, year }) =>
  month && year ? `${year}-${month}-01` : "";
