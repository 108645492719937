import { FEATUREKEYS } from "./Featurekeys";

// eslint-disable-next-line import/prefer-default-export
export const NAVIGATION = {
  normal: [
    {
      key: "hris",
      id: FEATUREKEYS.hris,
      featurename: "HRIS",
      expanded: true,
      value: [
        {
          key: "employeedata",
          id: FEATUREKEYS.employeedata,
          featurename: "Employee",
          expanded: true,
          value: [
            {
              key: "profile",
              id: FEATUREKEYS.profile,
              featurename: "Profile",
              route: "/profile",
            },
            {
              key: "personalProfilePrivacy",
              id: FEATUREKEYS.personalProfilePrivacy,
              featurename: "Profile privacy",
              route: "/personal_privacy",
            },
          ],
        },
        {
          key: "whitelistMobileDevice",
          id: FEATUREKEYS.whitelistMobileDevice,
          featurename: "Whitelist Mobile Device",
          route: "/comingsoon/hris/whitelistmobiledevice",
        },
        {
          key: "organizationChart",
          id: FEATUREKEYS.organizationChart,
          featurename: "Organization Chart",
          route: "/comingsoon/hris/orgchart",
        },
      ],
    },
    {
      key: "track",
      id: FEATUREKEYS.track,
      featurename: "Track",
      expanded: true,
      value: [
        {
          key: "attendance",
          id: FEATUREKEYS.attendance,
          featurename: "Attendance",
          expanded: true,
          value: [
            {
              key: "attendanceLogs",
              id: FEATUREKEYS.attendanceLogs,
              featurename: "Attendance logs",
              route: "/track/attendance/logs",
            },
            {
              key: "geoTracking",
              id: FEATUREKEYS.geoTracking,
              featurename: "Geo Tracking",
              route: "/comingsoon/track/attendance/geotracking",
            },
          ],
        },
        {
          key: "leave",
          id: FEATUREKEYS.leave,
          featurename: "Leave",
          expanded: true,
          value: [
            {
              key: "leaveBalanceAndHistory",
              id: FEATUREKEYS.leaveBalanceAndHistory,
              featurename: "Leave Balance & history",
              route: "/track/leave/leavelog",
            },
            {
              key: "holiday",
              id: FEATUREKEYS.holiday,
              featurename: "Holiday",
              route: "/track/leave/holidaylist",
            },
          ],
        },
        {
          key: "biometric",
          id: FEATUREKEYS.biometric,
          featurename: "Biometric",
          expanded: true,
          value: [
            {
              key: "biometricDevice",
              id: FEATUREKEYS.biometricDevice,
              featurename: "Biometric device",
              route: "/comingsoon/track/biometric/device",
            },
            // {
            //   key: "exportbiometricLogsData",
            //   id: FEATUREKEYS["exportbiometricLogsData"],
            //   featurename: "Export biometric device data",
            //   route: "/comingsoon"
            // },
            // {
            //   key: "exportbiometricMappingData",
            //   id: FEATUREKEYS["exportbiometricMappingData"],
            //   featurename: "Export biometric mapping data",
            //   route: "/comingsoon"
            // }
          ],
        },
      ],
    },
    {
      key: "payroll",
      id: FEATUREKEYS.payroll,
      featurename: "Payroll",
      expanded: true,
      value: [],
    },
    {
      key: "expense",
      id: FEATUREKEYS.expense,
      featurename: "Expense",
      expanded: true,
      value: [
        {
          key: "employeeExpense",
          id: FEATUREKEYS.adminDashboard,
          featurename: "Compensator dashboard",
          route: "/app/compensator/dashboard",
        },
        {
          key: "adminExpense",
          id: FEATUREKEYS.employeeExpense,
          featurename: "Expense",
          route: "/expense/dashboard",
        },
      ],
    },
    {
      key: "myDrive",
      id: FEATUREKEYS.myDrive,
      featurename: "Drive",
      expanded: true,
      value: [],
    },
    {
      key: "letterbox",
      id: FEATUREKEYS.letterbox,
      featurename: "Letter box",
      expanded: true,
      route: "config/origanise/letters",
    },
  ],
  config: [
    {
      key: "hris",
      id: FEATUREKEYS.hris,
      featurename: "HRIS",
      expanded: true,
      value: [
        {
          key: "companyData",
          id: FEATUREKEYS.companyData,
          featurename: "Company",
          expanded: true,
          value: [
            {
              key: "businessUnit",
              id: FEATUREKEYS.businessUnit,
              featurename: "Business Unit",
              route: "/config/hris/company/business_unit",
            },
            {
              key: "department",
              id: FEATUREKEYS.department,
              featurename: "Department",
              route: "/config/hris/company/department",
            },
            {
              key: "designation",
              id: FEATUREKEYS.designation,
              featurename: "Designation",
              route: "/config/hris/company/designation",
            },
            {
              key: "team",
              id: FEATUREKEYS.team,
              featurename: "Team",
              route: "/config/hris/company/team",
            },
            {
              key: "grade",
              id: FEATUREKEYS.grade,
              featurename: "Grade",
              route: "/config/hris/company/grade",
            },
          ],
        },
        {
          key: "employeedata",
          id: FEATUREKEYS.employeedata,
          featurename: "Employee",
          expanded: true,
          value: [
            {
              key: "employeeID",
              id: FEATUREKEYS.employeeID,
              featurename: "Employee ID",
              route: "/config/hris/employee/employee_id",
            },
            {
              key: "probation",
              id: FEATUREKEYS.probation,
              featurename: "Probation",
              route: "/config/hris/employee/probation",
            },
            {
              key: "approvalWorkflow",
              id: FEATUREKEYS.approvalWorkflow,
              featurename: "Approval Workflow",
              route: "/config/hris/employee/approval_workflow",
            },
            {
              key: "globalProfilePrivacy",
              id: FEATUREKEYS.globalProfilePrivacy,
              featurename: "Global Profile Privacy",
              route: "/config/hris/employee/default_privacy",
            },
            {
              key: "defaultPermission",
              id: FEATUREKEYS.defaultPermission,
              featurename: "Default Permission",
              route: "/config/hris/employee/default_permission",
            },
            {
              key: "permissionPolicy",
              id: FEATUREKEYS.permissionPolicy,
              featurename: "Permission Policy",
              route: "/config/hris/employee/permission_policy",
            },
            {
              key: "exitPolicy",
              id: FEATUREKEYS.exitPolicy,
              featurename: "Exit Policy",
              route: "/config/hris/employee/exit_policy",
            },
            {
              key: "exitReasons",
              id: FEATUREKEYS.exitReasons,
              featurename: "Exit Reason",
              route: "/config/hris/employee/exit_reason",
            },
            {
              key: "customData",
              id: FEATUREKEYS.customData,
              featurename: "Custom Data",
              route: "/config/hris/employee/custom_data",
            },
            {
              key: "commonAccess",
              id: FEATUREKEYS.commonAccess,
              featurename: "Common Access",
              route: "/config/hris/employee/common_access",
            },
          ],
        },
        {
          key: "accountManagement",
          id: FEATUREKEYS.accountManagement,
          featurename: "accountManagement",
          expanded: true,
          value: [
            {
              key: "globalDefault",
              id: FEATUREKEYS.globalDefault,
              featurename: "Global Default",
              route: "/config/hris/account_management/global_default",
            },
            {
              key: "interfaceBranding",
              id: FEATUREKEYS.interfaceBranding,
              featurename: "Interface Branding",
              route: "/config/hris/account_management/interface_branding",
            },
            {
              key: "emailBranding",
              id: FEATUREKEYS.emailBranding,
              featurename: "Email Branding",
              route: "/config/hris/account_management/email_branding",
            },
            {
              key: "companyOffices",
              id: FEATUREKEYS.companyOffices,
              featurename: "Company Offices",
              route: "/config/hris/account_management/company_offices",
            },
            {
              key: "incorporationDetails",
              id: FEATUREKEYS.incorporationDetails,
              featurename: "Incorporation Details",
              route: "/config/hris/account_management/incorporation_details",
            },
          ],
        },
      ],
    },
    {
      key: "track",
      id: FEATUREKEYS.track,
      featurename: "Track",
      expanded: true,
      value: [
        {
          key: "attendance",
          id: FEATUREKEYS.attendance,
          featurename: "Attendance",
          expanded: true,
          value: [
            {
              key: "shift",
              id: FEATUREKEYS.shift,
              featurename: "Shift",
              route: "/config/track/attendance/shift",
            },
            {
              key: "clockinMethod",
              id: FEATUREKEYS.clockinMethod,
              featurename: "Clock-In Method",
              route: "/config/track/attendance/clockin_method",
            },
            {
              key: "attendancePolicy",
              id: FEATUREKEYS.attendancePolicy,
              featurename: "Attendance Policy",
              route: "/config/track/attendance/policy",
            },
            {
              key: "extratime",
              id: FEATUREKEYS.extratime,
              featurename: "Extra Time",
              route: "/config/track/attendance/extra_time",
            },
            {
              key: "requestCycle",
              id: FEATUREKEYS.requestCycle,
              featurename: "Request Cycle",
              route: "/config/track/attendance/request_cycle",
            },
          ],
        },
        {
          key: "leave",
          id: FEATUREKEYS.leave,
          featurename: "Leave",
          expanded: true,
          value: [
            {
              key: "weeklyOff",
              id: FEATUREKEYS.weeklyOff,
              featurename: "Weekly Off",
              route: "/config/track/leave/weeklyoff",
            },
            {
              key: "leavePlan",
              id: FEATUREKEYS.leavePlan,
              featurename: "Plan",
              route: "/config/track/leave/type",
            },
            {
              key: "leavePolicy",
              id: FEATUREKEYS.leavePolicy,
              featurename: "Leave Policy",
              route: "/config/track/leave/policy",
            },
            {
              key: "holidayConfig",
              id: FEATUREKEYS.holidayConfig,
              featurename: "Holiday",
              route: "/config/track/leave/holiday",
            },
            {
              key: "transition",
              id: FEATUREKEYS.transition,
              featurename: "Transition",
              route: "/config/track/leave/transition",
            },
          ],
        },
      ],
    },
    {
      key: "pay",
      id: FEATUREKEYS.pay,
      featurename: "Pay",
      expanded: true,
      value: [
        {
          key: "expense",
          id: FEATUREKEYS.expense,
          featurename: "Expense",
          value: [
            // {
            //   key: "expenseType",
            //   id: FEATUREKEYS.expenseType,
            //   featurename: "Expense Type",
            //   route: "/config/expense/type",
            // },
            {
              key: "expenseCycle",
              id: FEATUREKEYS.expenseCycle,
              featurename: "Expense Cycle",
              route: "/config/pay/expense/expense_cycle",
            },
            {
              key: "expensecurrency",
              id: FEATUREKEYS.expensecurrency,
              featurename: "Currency conversion",
              route: "/config/pay/expense/currency_conversion",
            },
            {
              key: "expenseFuel",
              id: FEATUREKEYS.expenseFuel,
              featurename: "Fuel & Distance Units",
              route: "/config/pay/expense/fuelconfig",
            },
            {
              key: "compensator",
              id: FEATUREKEYS.compensator,
              featurename: "Compensator",
              route: "/config/pay/expense/compensator",
            },
            {
              key: "expensePolicy",
              id: FEATUREKEYS.expensePolicy,
              featurename: "Expense Policy",
              route: "/config/pay/expense/policy",
            },
          ],
        },
        {
          key: "payroll",
          id: FEATUREKEYS.payroll,
          featurename: "Payroll",
          value: [
            {
              key: "payrollmethod",
              id: FEATUREKEYS.payrollmethod,
              featurename: "Payroll Method",
              route: "/config/pay/payroll/method",
            },
            {
              key: "salarycycle",
              id: FEATUREKEYS.salarycycle,
              featurename: "Salary Cycle",
              route: "/config/pay/payroll/salary_cycle",
            },
            {
              key: "signatory",
              id: FEATUREKEYS.signatory,
              featurename: "Signatory & bank details",
              route: "/config/pay/payroll/details",
            },
            {
              key: "misc",
              id: FEATUREKEYS.misc,
              featurename: "Misc",
              route: "/config/pay/payroll/misc",
            },
            {
              key: "salarycomponent",
              id: FEATUREKEYS.salarycomponent,
              featurename: "Component",
              route: "/config/pay/payroll/salary_component",
            },
            {
              key: "salarystructure",
              id: FEATUREKEYS.salarystructure,
              featurename: "Salary Structure",
              route: "/config/pay/payroll/salary_structure",
            },
            {
              key: "configfbp",
              id: FEATUREKEYS.configfbp,
              featurename: "Flexible Benefit Plan",
              route: "/config/pay/payroll/fbp",
            },
            {
              key: "configtag",
              id: FEATUREKEYS.configtag,
              featurename: "Payroll Tag",
              route: "/config/pay/payroll/tag",
            },
            {
              key: "fnfconfig",
              id: FEATUREKEYS.fnfconfig,
              featurename: "Payroll FNF",
              route: "/config/pay/payroll/fnf",
            },
            {
              key: "extrapaymentpolicy",
              id: FEATUREKEYS.extrapaymentpolicy,
              featurename: "Extra time payment policy",
              route: "/config/pay/payroll/overtimepaymentpolicy",
            },
          ],
        },
      ],
    },
    {
      key: "organise",
      id: FEATUREKEYS.organise,
      featurename: "Organise",
      expanded: true,
      value: [
        {
          key: "letters",
          id: FEATUREKEYS.letters,
          featurename: "Hr letters",
          value: [
            {
              key: "pagelayout",
              id: FEATUREKEYS.pagelayout,
              featurename: "Page layout",
              route: "/config/organise/letter/page_layout",
            },
            {
              key: "templates",
              id: FEATUREKEYS.templates,
              featurename: "Template",
              route: "/config/organise/letter/template",
            },
            {
              key: "signatories",
              id: FEATUREKEYS.signatories,
              featurename: "Signatory",
              route: "/config/organise/letter/signatory",
            },
          ],
        },
      ],
    },
    {
      key: "resolve",
      id: FEATUREKEYS.resolve,
      featurename: "Resolve",
      expanded: true,
      value: [
        {
          key: "configChecklistRoot",
          id: FEATUREKEYS.configChecklistRoot,
          featurename: "Checklist",
          // route: "/config/resolve/checklist",
          value: [
            {
              key: "configChecklist",
              id: FEATUREKEYS.configChecklist,
              featurename: "Checklist creation",
              route: "/config/resolve/checklist",
            },
          ],
        },
        {
          key: "policyCenter",
          id: FEATUREKEYS.policyCenter,
          featurename: "Policy Center",
          // route: "/config/resolve/policycenter",
          value: [
            {
              key: "configPolicyCenter",
              id: FEATUREKEYS.configPolicyCenter,
              featurename: "Policy Center",
              route: "/config/resolve/policy_center",
            },
          ],
        },
      ],
    },
    {
      key: "perform",
      id: FEATUREKEYS.perform,
      featurename: "Perform",
      expanded: true,
      value: [
        {
          key: "degree",
          id: FEATUREKEYS.degree,
          featurename: "360 Review",
          value: [
            {
              key: "performcycle",
              id: FEATUREKEYS.performcycle,
              featurename: "Performance cycle",
              route: "/config/perform/360/cycle",
            },
            {
              key: "remainder",
              id: FEATUREKEYS.remainder,
              featurename: "Reminder",
              route: "/config/perform/360/reminder",
            },
            // {
            //   key: "ratingscale",
            //   id: FEATUREKEYS.ratingscale,
            //   featurename: "Rating scale",
            //   route: "/config/perform/360/rating_scale",
            // },
            {
              key: "competency",
              id: FEATUREKEYS.competency,
              featurename: "Competency repository",
              route: "/config/perform/360/competency_repository",
            },
            {
              key: "reviewstructure",
              id: FEATUREKEYS.reviewstructure,
              featurename: "Review structure",
              route: "/config/perform/360/structure",
            },
            {
              key: "reviewform",
              id: FEATUREKEYS.reviewform,
              featurename: "Review form",
              route: "/config/perform/360/form",
            },
          ],
        },
        {
          key: "okr",
          id: FEATUREKEYS.okr,
          featurename: "OKR & Goals",
          value: [
            {
              key: "okrconfig",
              id: FEATUREKEYS.okrconfig,
              featurename: "OKR config",
              route: "/config/perform/okr/misc",
            },
            {
              key: "Successunit",
              id: FEATUREKEYS.Successunit,
              featurename: "Successunit",
              route: "/config/perform/successunit",
            },
          ],
        },
      ],
    },
    {
      key: "recruit",
      id: FEATUREKEYS.recruit,
      featurename: "recruit",
      expanded: true,
      value: [
        {
          key: "ATS",
          id: FEATUREKEYS.ATS,
          featurename: "ats",
          expanded: true,
          value: [
            {
              key: "recruiters",
              id: FEATUREKEYS.recruiters,
              featurename: "Recruiters",
              route: "/config/recruit/recruiters",
            },
            {
              key: "careerPage",
              id: FEATUREKEYS.careerPage,
              featurename: "Career Page",
              route: "/config/recruit/career_page",
            },
            {
              key: "pipelineEmailTemplates",
              id: FEATUREKEYS.pipelineEmailTemplates,
              featurename: "Pipeline Email Templates",
              route: "/config/recruit/pipeline_email_templates",
            },
            {
              key: "pipeline",
              id: FEATUREKEYS.pipeline,
              featurename: "Pipeline",
              route: "/config/recruit/pipeline",
            },
            {
              key: "screeningQuestions",
              id: FEATUREKEYS.screeningQuestions,
              featurename: "Screening Questions",
              route: "/config/recruit/screening_questions",
            },
            {
              key: "jobTemplates",
              id: FEATUREKEYS.jobTemplates,
              featurename: "Job Templates",
              route: "/config/recruit/job_templates",
            },
            {
              key: "candidateSources",
              id: FEATUREKEYS.candidateSources,
              featurename: "Candidate Sources",
              route: "/config/recruit/candidate_sources",
            },
          ],
        },
      ],
    },
    {
      key: "hrops",
      id: FEATUREKEYS.hrops,
      featurename: "hrops",
      expanded: true,
      value: [
        {
          key: "asset",
          id: FEATUREKEYS.asset,
          featurename: "asset",
          expanded: true,
          value: [
            {
              key: "assetCategory",
              id: FEATUREKEYS.assetCategory,
              featurename: "assetCategory",
              route: "/config/hrops/asset/category",
            },
          ],
        },
        {
          key: "helpdesk",
          id: FEATUREKEYS.helpdesk,
          featurename: "helpdesk",
          expanded: true,
          value: [
            {
              key: "ticketCategory",
              id: FEATUREKEYS.ticketCategory,
              featurename: "ticketCategory",
              route: "/config/hrops/helpdesk/ticketcategory",
            },
            {
              key: "cannedResponses",
              id: FEATUREKEYS.cannedResponses,
              featurename: "cannedResponses",
              route: "/config/hrops/helpdesk/cannedresponses",
            },
          ],
        },
      ],
    },
  ],
};

export const EXACTROUTES = [
  "/storybook",
  "/live_data",
  "/planner",
  "/report_robo",
  "/profile",
  "/directory",
  "/help",
  "/leave",
  "/holiday",
  "/holiday/select_holiday",
  "/attendance",
  "/personal_privacy",
  "/invite_new_employee",
  "/reporting_hierarchy",
  "/request_center",
  "/biometric",
  "/expense",
  "/app/pay/expense",
  "/app/pay/expense/compensator",
  "/notification_center",
  "/policycenter",
  "/comingsoon",
  "/change_password",
  "/desk",
  "/calendar",
  "/app/hris/dashboard",
  "/drive",
  "/app/organise/drive",
  "/app/resolve/checklist",
  "/app/organise/letters",
  "/letter_box",
  "/app/pay/payroll/run",
  "/app/pay/payroll/",
  // "/app/perform/",
  "/okr",
  "/zero",
  "/360",
  "/myresults",
  "/pendingreviews",
  "/submittedreviews",
  "/app/perform/360/form",
  "/app/perform/360/employee",
  // "/app/recruit",

  "/app/careerpage",
  "/dashboard",
  "/requestcenter",
  "/operations",
  "/helpdesk",
  "/app/helpdesk",
  "/app/hrops/assets",
  "/productupdate",
  // "/subscriptionexpired",
  "/typo",
  "/app/perform/okr",
];

export const INCLUDEROUTES = [
  "/config",
  "/track",
  "/profile/",
  "/offercenter",
  "/notification_preferences",
  "/checklist",
  "/app/organise/hrletters",
  "/app/",
  "/payroll/",
  "/recruit/",
  // "/perform",
];
