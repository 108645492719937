import React from "react";
import CheckBox from "../../Material-ui/CheckBox";
import initjson from "../Data/initjson";
import KEYS from "../KEYS";

export default function TextComp(props) {
  // const [focused, setFocused] = React.useState(false);
  const { field, updateData } = props;
  const {
    value = false,
    iserr = function () {},
    placeholder = "",
    label = "",
    helperText = "",
    disabled = false,
    width = "80%",
    // width = width ? width : "80%",
    labelclass = "",
  } = field;

  function handleChange(event) {
    !disabled && updateData({ id: field.id, value: !value });
  }

  return (
    <div className="display-flex" style={{ alignItems: "center" }}>
      <CheckBox
        className="dynamic-form-checkbox"
        onChange={handleChange}
        placeholder={placeholder}
        error={iserr({ fielddata: field })}
        value={value}
        checked={value}
        helperText={helperText}
        disabled={disabled}
        {...props}
      />
      <p
        // className="margin-top-0 margin-left-8 cursour-pointer"
        className={`${
          labelclass || "input-label margin-top-4"
        } margin-left-8 cursour-pointer`}
        onClick={handleChange}
        style={{ width }}
      >
        {label}
      </p>
    </div>
  );
}

export const checkboxinputconverter = (field) => [
  {
    ...initjson,
    ...field,
    typeid: KEYS.CheckBox,
  },
];
