const common = {
  fontfamily: "Muli",
  fontWeight: "normal",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "normal",
};

const smallFont = {
  ...common,
  fontSize: "14px",
  color: "rgba(1,7,9,0.70)",
  textAlign: "left",
};

export default {
  avatar: {
    // marginRight: "8px",
  },
  countAvatar: {
    // height: "56px",
    // width: "56px",
    // background: "#fff",
    // color: "#166ab3",
    // border: "1px solid #d2d2d2",
    // fontSize: "15.2px",
  },
  actionBtnContainer: {
    // display: 'inline-block',
    // color: '#166ab3',
    // border: '1px solid #166ab3',
    // borderRadius: '50%',
    // height: '30px',
    // width: '30px',
    // cursor: 'pointer'
  },
  actionBtns: {
    height: "15px",
    width: "15px",
    paddingTop: "4px",
  },
  name: {
    ...common,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.5px",
    color: "rgba(1, 7, 9, 0.70)",
    textAlign: "left",
  },
  shiftname: {
    ...common,
    fontWeight: 500,
    letterSpacing: "0.5px",
    color: "rgba(1,7,9,0.70)",
    textAlign: "left",
    paddingTop: "0px !important",
    lineHeight: "14px",
  },
  gradeRankText: {
    ...common,
    fontSize: "21.2px",
    fontWeight: 500,
    letterSpacing: "0.7px",
    color: "rgba(1,7,9,0.70)",
    textAlign: "center",
  },
  customWidth: {
    maxWidth: 300,
    height: "auto",
    maxHeight: "200px !important",
    fontWeight: "normal",
  },
  date: smallFont,
  paddingTop7: {
    paddingTop: "7px",
  },
  paddingTop10: {
    paddingTop: "10px",
  },
  paddingTop0: {
    paddingTop: 0,
  },
  centerAlight: {
    textAlign: "center",
  },
  commonName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "90%",
  },
  iconAndText: {
    display: "inline-flex",
    verticalAlign: "middle",
  },
  tableSearch: {
    padding: "30px 0",
  },
  noOfResults: {
    ...common,
    fontSize: "14px",
    letterSpacing: "0.6px",
    color: "rgba(1, 7, 9, 0.35)",
    display: "inline",
  },
  sortBy: {
    ...smallFont,
    display: "inline",
  },
  search: {
    // maxWidth: '414px',
    // maxWidth: "150px",
    width: "90%",
    border: "1px solid #d9dadb",
    borderRadius: "4px",
    padding: "2px 10px",
  },
  listPadding: {
    paddingTop: "18px",
    paddingBottom: "18px",
  },
  iconInsideNameField: {
    marginTop: "-18px",
    display: "inline-flex",
    verticalAlign: "middle",
    height: "30px",
    width: "30px",
    marginLeft: "10px",
  },
  listHead: {
    background: "#edf5fd",
    height: "40px !important",
    borderRadius: 4,
  },
  flex: {
    display: "flex",
  },
  gradeRank: {
    flexGrow: 1,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  gradeName: {
    ...common,
    fontSize: "21.2px",
    fontWeight: 500,
    letterSpacing: "0.7px",
    textAlign: "center",
    color: "rgba(13, 13, 13, 0.6)",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  blueText: {
    color: "#166ab3",
  },
  drawer: {
    width: "30%",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "30%",
  },
};
