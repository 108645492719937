
import React from "react";
// import ContentLoader, { Facebook } from 'react-content-loader'

// const MyLoader = () => <ContentLoader />

// export default MyLoader;

import loader from "../../Assets/Gif/loader.gif";
import "./index.css";

const Loader = () => (
    <div>
      <img className="loader" src={loader} />
    </div>
  );
export default Loader;
