import React from "react";
import Radio from "../../Material-ui/Radio";
import initjson from "../Data/initjson";
import KEYS from "../KEYS";

export default function RadioComp(props) {
  // const [focused, setFocused] = React.useState(false);
  const { field, updateData } = props;
  const {
    iserr = () => {},
    placeholder = "",
    label = "",
    helperText = "",
    labelclass = "",
    iconclass = "",
    disabled = false,
  } = field;

  function handleChange() {
    !disabled &&
      updateData({ id: field.id, value: field.value ? true : false });
  }

  return (
    <div className="display-flex" style={{ alignItems: "center" }}>
      <span onClick={handleChange}>
        <Radio
          className={`dynamic-form-checkbox cursour-pointer`}
          placeholder={placeholder}
          error={iserr({ fielddata: field })}
          // value={false}
          // value={!value}
          // onChange={handleChange}
          checked={field.value}
          {...props}
        />
      </span>
      <span onClick={handleChange}>
        <p
          className={`${
            labelclass || "input-label margin-top-4"
          } margin-left-8 cursour-pointer`}
          style={{ width: "100%" }}
        >
          {label}
        </p>
      </span>
    </div>
  );
}

export const radioinputconverter = (field) => [
  {
    ...initjson,
    ...field,
    typeid: KEYS.Radio,
  },
];
