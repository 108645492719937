import React from "react";
import Integer from "../../Material-ui/Integer";
import initjson from "../Data/initjson";
import KEYS from "../KEYS";

export default function IntegerComp(props) {
  const [focused, setFocused] = React.useState(false);
  const { field, updateData } = props;
  const {
    value = "",
    iserr = () => {},
    placeholder = "",
    label = "",
    helperText = "enter valid number",
    className = "",
    disabled = false,
    min = 0,
    max = null,
  } = field;

  function handleChange(event) {
    let valuetemp = event.target.value;
    console.log('valuetemp: ', valuetemp);
    valuetemp = valuetemp ? parseInt(valuetemp.replace(".", "")) : "";
    if ((min != null ? valuetemp >= min : true) && (max ? valuetemp <= max : true)) {
      updateData({
        id: field.id,
        value: valuetemp ? Math.trunc(valuetemp) : valuetemp,
      });
    } else {
      updateData({ id: field.id, value: "" });
    }
  }

  const tempprops = { ...props };
  max && (tempprops.max = max);
  min && (tempprops.min = min);

  return (
    <div>
      <p className="input-label">{label}</p>
      <Integer
        {...tempprops}
        type="number"
        className={`width100 ${className}`}
        variant="outlined"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        error={iserr({ fielddata: field })}
        helperText={focused ? helperText : ""}
        disabled={disabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        step="1"
      />
    </div>
  );
}

export const integerinputconverter = (field) => [
  {
    ...initjson,
    ...field,
    typeid: KEYS.Integer,
  },
];
