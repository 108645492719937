import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import { careerpageinstance, instance } from "Actions/AxiosInstance";
import { getSubdomain } from "Handlers/getters";
import { get } from "lodash";
import { withSnackbar } from "notistack";
import React from "react";
import { getFormattedLogoLink } from "Utils";
// import { location } from "react-router-dom";
import queryString from "query-string";
import loader from "Assets/Gif/loader.gif";
import downArrow from "./images/arrow-down.svg";
import sumhrlogo from "./images/sumhr-logo.svg";
import PersonalDetails from "./PersonalDetails";
import "./style/style.css";

class JobDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobDetails: [],
      // jobdetails:
      //   this.props.history.location.state && this.props.history.location.state,
      jobdetails: [],
      jobtemplateid: null,
      ispersonaldetails: false,
      subscriptiondetails: {},
      isloading: true,
    };

    this.getJobDescription = this.getJobDescription.bind(this);
    this.onjobDescription = this.onjobDescription.bind(this);
  }

  componentDidMount() {
    window.Beacon("destroy");
    const sourcename = queryString.parse(this.props.location.search);
    this.setState({ sourcename });
    console.log(sourcename);
    const subDomain = getSubdomain();
    instance
      .get(`subscription/verifysubscription/${subDomain}`)
      .then((res) => {
        this.setState(
          {
            subscriptiondetails: res.data.result && res.data.result[0],
            typoperrorcode: false,
          },
          () => this.getJobDescription(),
        );
      })
      .catch((err) => {
        if (get(err, "response.status", "") == 404) {
          this.setState({ typoperrorcode: true });
        }
      });
    // const windowurl = window.location.pathname.split("/");
    // const jobuuid = windowurl[windowurl.length - 1];
    // console.log("jobuuid", jobuuid);

    // if (window.location.pathname.includes("career/joburl/")) {
    //   this.getJobDescription();
    //   console.log("1000");
    // }
  }

  // useQuery() {
  //   return new URLSearchParams(location().search);
  // }

  onjobDescription() {
    this.setState({
      ispersonaldetails: true,
      // isjobdescription: false,
      // jobdetails: item,
    });
  }

  getJobDescription = () => {
    const windowurl = window.location.pathname.split("/");
    const jobuuid = windowurl[windowurl.length - 1];
    careerpageinstance
      // .get(`recruit/getrequisitionjoblist/${uuid}/null`)
      .get(`recruit/getrequisitionjoblist/${jobuuid}`)
      .then((result) => {
        this.setState({ jobdetails: result.data.result[0], isloading: false });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
        this.setState({ isloading: false });
      });
  };

  getJobTemplate = () => {
    instance
      .get("recruit/getjobtemplatelist")
      .then((result) => {
        this.setState({ jobTemplates: result.data.result });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
      });
  };

  // getJobDetails() {
  //   const { jobtemplateid } = this.state;
  //   instance
  //     .get(`/recruit/getjobtemplatebyid/${jobtemplateid}`)
  //     .then((resp) => {
  //       // console.log(resp.data.result[0]);
  //       this.setState(
  //         { jobDetails: resp.data.result[0] },
  //         // () => {this.props.history.push(`${this.props.match.url}/create`);}
  //       );
  //     });
  // }

  render() {
    const { jobdetails, isloading } = this.state;
    if (window.location.pathname.includes("/career/job")) {
      window.Beacon("destroy");
    }
    // if (jobdetails && Object.values(jobdetails).length > 0) {
    return isloading ? (
      <img className="loader" src={loader} />
    ) : (
      <div style={{ background: "rgb(249, 249, 249)" }}>
        {this.state.ispersonaldetails ? (
          <PersonalDetails
            {...this.state}
            {...this.props}
            jobdetails={this.state.jobdetails}
          />
        ) : (
          <div>
            <div
              className="c-fluid-container"
              style={{
                backgroundColor: "#fff",
                width: "100%",
                padding: "32px 0px 0px 0px",
              }}
            >
              <div className="c-wrapper">
                <img
                  style={{
                    maxHeight: 100,
                    maxWidth: 200,
                    objectFit: "contain",
                  }}
                  src={getFormattedLogoLink(
                    this.state.subscriptiondetails.logo,
                  )}
                  alt="icon"
                />
              </div>
            </div>
            <div
              // className="c-fluid-container"
              style={{ backgroundColor: "#fff", width: "100%" }}
            >
              <div className="c-wrapper">
                <div className="c-MuiGrid-root c-fluid-container c-MuiGrid-container">
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-7">
                    <h1
                      style={{
                        color: "rgb(1, 7, 9)",
                        fontSize: 40,
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      {jobdetails &&
                        jobdetails.designationname &&
                        jobdetails.designationname}
                    </h1>
                    {jobdetails &&
                      jobdetails.location &&
                      jobdetails.location.location && (
                        <p
                          style={{
                            color: "rgb(84, 85, 86)",
                            fontSize: 24,
                            marginRight: 16,
                            fontWeight: "bold",
                            marginTop: 8,
                          }}
                        >
                          {jobdetails.location &&
                            jobdetails.location.location &&
                            jobdetails.location.location}{" "}
                          • {jobdetails.jobtype}
                        </p>
                      )}
                  </div>
                  <div
                    className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-5
                    "
                    style={{ textAlign: "right" }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <p
                        style={{
                          color: "rgb(30, 140, 237)",
                          fontSize: 24,
                          marginRight: 16,
                          fontWeight: "bold",
                        }}
                      >
                        Apply for this position
                      </p>
                      <a href="#applyposition">
                        <div className="c-go-to-bottom">
                          <img src={downArrow} />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="c-fluid-container"
              style={{
                background: "rgb(249, 249, 249)",
                padding: " 0px 0px 32px 0px",
              }}
            >
              <div className="c-wrapper">
                <div>
                  <div
                    className="c-MuiGrid-root c-fluid-container c-MuiGrid-container"
                    style={{ background: "rgb(249, 249, 249)" }}
                  >
                    <div
                      className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-8"
                      style={{ paddingRight: 32 }}
                    >
                      {/* <p className="c-normal-dark c-t-bold c-margin-bottom-216">
                  Job description
                </p> */}

                      <p className="c-normal-dark c-t-bold c-margin-bottom-16">
                        Job mission
                      </p>

                      <p className="c-small-dark-secondary c-margin-bottom-24">
                        {jobdetails && jobdetails.mission
                          ? jobdetails.mission
                          : "No job mission defined"}
                      </p>

                      <Grid container spacing={2}>
                        <Grid item sm={12} md={12}>
                          <div>
                            <p
                              className="c-normal-dark c-t-bold c-margin-bottom-16"
                              // style={{ lineHeight: "24px" }}
                            >
                              Role summary
                            </p>

                            {jobdetails && jobdetails.rolesummary ? (
                              <p className="c-small-dark-secondary c-margin-bottom-24">
                                {" "}
                                {jobdetails.rolesummary &&
                                  jobdetails.rolesummary}
                              </p>
                            ) : (
                              <p className="c-small-dark-secondary c-margin-bottom-24">
                                No role summary defined
                              </p>
                            )}
                          </div>

                          <p
                            className="c-normal-dark c-t-bold c-margin-bottom-16"
                            // style={{ lineHeight: "24px" }}
                          >
                            Responsibilities
                          </p>

                          {jobdetails && jobdetails.responsibility ? (
                            <p className="c-small-dark-secondary c-margin-bottom-24">
                              {" "}
                              {jobdetails.responsibility &&
                                jobdetails.responsibility.replace(
                                  /(<([^>]+)>)/gi,
                                  "",
                                )}{" "}
                            </p>
                          ) : (
                            <p className="c-small-dark-secondary c-margin-bottom-24">
                              No responsibilities defined
                            </p>
                          )}

                          <p className="c-normal-dark c-t-bold c-margin-bottom-16">
                            Skills
                          </p>
                          {jobdetails && jobdetails.skill ? (
                            jobdetails.skill.map((x) => (
                              <Chip
                                variant="outlined"
                                label={x}
                                className="margin-right-8"
                              />
                            ))
                          ) : (
                            <p className="c-small-dark-secondary c-margin-bottom-24">
                              No skills defined
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                    <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                      <p className="c-normal-dark c-t-bold c-margin-bottom-16">
                        Location
                      </p>
                      <p className="c-small-dark-secondary c-margin-bottom-24">
                        {jobdetails &&
                        jobdetails.location &&
                        jobdetails.location.location
                          ? jobdetails.location.location
                          : "-"}
                      </p>
                      <p className="c-normal-dark c-t-bold c-margin-bottom-16">
                        Job type
                      </p>
                      <p className="c-small-dark-secondary c-margin-bottom-24">
                        {jobdetails && jobdetails.jobtype}
                      </p>
                      <p className="c-normal-dark c-t-bold c-margin-bottom-16">
                        Experience
                      </p>
                      <p className="c-small-dark-secondary c-margin-bottom-24">
                        {jobdetails && jobdetails.minworkexperience} -{" "}
                        {jobdetails && jobdetails.maxworkexperience}
                      </p>
                    </div>
                  </div>
                  <div
                    className="c-MuiGrid-root c-MuiGrid-container"
                    id="applyposition"
                    style={{ margin: "64px 0" }}
                  >
                    <div
                      className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12 cursor-pointer"
                      style={{ textAlign: "center" }}
                      onClick={this.onjobDescription}
                    >
                      {/* <a
                  onClick={(e) => {
                    e.preventDefault();
                    // this.props.history.push("./fgh/1");
                    this.props.history.push("/personaldetails");
                  }}
                > */}
                      <p
                        style={{
                          color: "#fff",
                          fontSize: 20,

                          fontWeight: "bold",
                          textAlign: "center",
                          marginTop: 0,
                          background: "#1e8ced",
                          padding: "16px 32px",
                          borderRadius: 4,
                          display: "inline-block",
                        }}
                      >
                        Apply for this position
                      </p>
                      {/* </a> */}
                      {/* <p
                  style={{
                    color: "rgb(1, 7, 9)",
                    fontSize: 24,
                    marginBottom: 32,
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: 0,
                  }}
                >
                  Apply for this position
                </p>
                <div
                  style={{
                    background: "rgb(39, 116, 177)",
                    padding: 16,
                    width: 250,
                    display: "flex",
                    borderRadius: 4,
                    margin: "0px auto",
                    cursor: "pointer",
                  }}
                >
                  <img src={linkedinIcon} />
                  <p
                    className="c-normal-dark"
                    style={{
                      fontSize: 22,
                      color: "rgb(255, 255, 255)",
                      marginLeft: 16,
                    }}
                  >
                    Apply with LinkedIn
                  </p>
                </div>
                 <p
                  className="c-large-text-small-dark-secondary c-margin-top-8"
                  style={{ textAlign: "center" }}
                >
                  - or -
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ width: 250, marginRight: 8 }}>
                    <p className="c-input-label">Apply with phone number</p>
                    <div className="c-react-tel-input">
                      <input
                        className="c-form-control"
                        id="phone-form-control"
                        placeholder="+1 (702) 123-4567"
                        type="tel"
                        value="+91"
                      />
                    </div>
                  </div>
                  <a href="./personal-details.html">
                    <div
                      style={{
                        width: 44,
                        height: 44,
                        background: "rgb(30, 140, 237)",
                        borderRadius: 72,
                        position: "relative",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 7px 16px 0",
                        margin: "20px 16",
                      }}
                    >
                      <img src={checkIcon} className="c-vertical-center" />
                    </div>
                  </a>
                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              className="c-wrapper"
              style={{
                background: "rgb(249, 249, 249)",
                padding: "88px 0",
                textAlign: "center",
              }}
            >
              <p className="c-normal-dark">Powered by</p>
              <img src={sumhrlogo} />
            </div> */}
          </div>
        )}
      </div>
    );
    // } else {
    //   return "loading...";
    // }
  }
}

export default withSnackbar(JobDescription);
