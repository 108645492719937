import get from "lodash/get";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import KEYS from "./KEYS";
import { setDynamicForm, addDynamicForm } from "../../Actions/commonAction";
import TextComp from "./Fields/Text";
import TextAreaComp from "./Fields/Textarea";
import IntegerComp from "./Fields/Integer";
import FloatComp from "./Fields/Float";
import DateComp from "./Fields/Date";
import TimeComp from "./Fields/Time";
import DateTimeComp from "./Fields/DateTime";
import SelectComp from "./Fields/Select";
import MultiSelectComp from "./Fields/MultiSelect";
import CheckBoxComp from "./Fields/CheckBox";
import RadioComp from "./Fields/Radio";
import ImageInput from "./Fields/ImageInput";
import GroupSelect from "./Fields/GroupSelect";
import {
  showAllErrorsFunction,
  fetchMyFormFunction,
  deleteMyFormFunction,
  validateMyFormFunction,
  fetchAllFormsFunction,
} from "./Functions/getters";

/**
 * these are the functions to control dynamic form
 */
export const fetchMyForm = fetchMyFormFunction;
export const deleteMyForm = deleteMyFormFunction;
export const showAllErrors = showAllErrorsFunction;
export const validateMyForm = validateMyFormFunction;
export const fetchAllForms = fetchAllFormsFunction;

/**
 * @class Dynamic Form Details
 * @param { function } onChange - if this function present, using dynamic form for single field
 * @param {array} fields - this is array of fields, which needs to be rendered in our form
 * @param {string} formKey - this is unique identifier for our form
 * @param {function} fetchMyForm - you can access our form from anywhere by passing 'formKey' identifier
 * @param {function} deleteMyForm - you can delete the dynamic form uding this function
 */
class DynamicForm extends Component {
  constructor(props) {
    super(props);
    this.getField = this.getField.bind(this);
    this.updateData = this.updateData.bind(this);
  }

  componentDidMount() {
    const { fields, formKey, onChange } = this.props;
    if (!fetchMyForm(formKey) && !onChange) {
      // form doesn't present, so create new form
      this.props.addDynamicForm({ forms: [{ fields, formKey }] });
    }
  }

  updateData({ id, value }) {
    const { formKey, onChange } = this.props;
    if (onChange) {
      onChange(value);
    } else {
      const RESULT = get(this.props, "dynamicformReducer.forms", []).map((form) => ({
        ...form,
        fields:
          form.formKey === formKey
            ? form.fields.map((field) => ({
                ...field,
                [id]: value,
                value: field.id === id ? ( Array.isArray(value) && value.find(x => x.displayvalue == 0) && value.find(x => x.displayvalue == 0).displayvalue == 0 ? field.options.filter(x => x.displayvalue != 0) : value) : field.value,
              }))
            : form.fields,
      }));
      this.props.setDynamicForm({ forms: RESULT });
    }
  }

  componentWillUnmount() {
    const { formKey, onChange } = this.props;
    if (!onChange) deleteMyForm(formKey);
  }

  getField({ field, showAllErr }) {
    const PROPS = {
      field,
      updateData: this.updateData,
      showAllErr,
      onBlur: this.props.onBlur,
    };
    switch (field.typeid) {
      case KEYS.Text:
        return <TextComp {...PROPS}/>;
      case KEYS.TextArea:
        return <TextAreaComp {...PROPS} />;
      case KEYS.Integer:
        return <IntegerComp {...PROPS} />;
      case KEYS.Float:
        return <FloatComp {...PROPS} />;
      case KEYS.Date:
        return <DateComp {...PROPS} />;
      case KEYS.Time:
        return <TimeComp {...PROPS} />;
      case KEYS.DateTime:
        return <DateTimeComp {...PROPS} />;
      case KEYS.Select:
        return <SelectComp {...PROPS} />;
      case KEYS.CheckBox:
        return <CheckBoxComp {...PROPS} />;
      case KEYS.Radio:
        return <RadioComp {...PROPS} />;
      case KEYS.MultiSelect:
        return <MultiSelectComp {...PROPS} />;
      case KEYS.ImageInput:
        return <ImageInput {...PROPS} />;
      case KEYS.GroupSelect:
        return <GroupSelect {...PROPS} />;
      default:
        return null;
    }
  }

  render() {
    const { formKey, onChange, fields } = this.props;
    const form = onChange ? { fields } : fetchMyForm(formKey);
    return (
      <Grid container >
        {get(form, "fields", []).map((field) => (
          // <Grid className="margin-bottom-16" key="key" md="6" sm="6">
          //   <div className="width96">{this.getField(field)}</div>
          // </Grid>
          <Grid className="margin-bottom-16" key="key" md="12" sm="12">
            <div className="width100">
              {this.getField({ field, showAllErr: get(form, "shoAllErr", "") })}
            </div>
          </Grid>
        ))}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    dynamicformReducer: state.dynamicformReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setDynamicForm, addDynamicForm }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DynamicForm);
