import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";
import get from "lodash/get";
import React, { Component } from "react";
import Select from "react-select";
import CheckImg from "../../../../../Assets/Images/aaa.png";
import SectionHeaderExtraSmall from "../../../../../Component/Headers/sectionHeaderExtraSmall";
import { ErrorMessages } from "../../../../../Constants";
import { getSelectValue } from "../../../../../Utils";

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isbuopen: false,
      businessunitheadid: null,
    };
  }

  componentDidMount() {
    this.setState({
      isbuopen: this.props.postData.businessunitheadid,
      businessunitheadid: this.props.postData.businessunitheadid,
    });
    this.props.onRef({
      onCancel: this.props.onCancel,
      onNext: this._onsubmit,
      nextButtonLabel: "Save",
      // nextButtonLabel: this.props.isentity ? "Next" : "Save",
    });
  }

  _onChange = (key, value) => {
    const { postData } = this.props;
    postData[key] = value;
    if (key == "isbuopen") {
      if (value) {
        this.setState({
          [key]: value,
          businessunitheadid: postData.businessunitheadid,
        });
      } else {
        this.setState({
          [key]: value,
          businessunitheadid: null,
        });
        postData.businessunitheadid = null;
      }
    }
    delete postData.isbuopen;
    this.props.updatePostData(postData);
  };

  _onsubmit = () => {
    const { businessunitname } = this.props.postData;
    const { isentity } = this.props;
    if (!businessunitname) {
      this.props.enqueueSnackbar(ErrorMessages.E1300, {
        variant: "error",
      });
      return;
    } else if (this.state.isbuopen && !this.props.postData.businessunitheadid) {
      this.props.enqueueSnackbar(ErrorMessages.E1310, {
        variant: "error",
      });
      return;
    }
    !isentity && this.props._submit();
    // isentity && this.props.setStep(1);
  };

  render() {
    const {
      businessunitname,
      officeaddressid,
      businessunitheadid,
    } = this.props.postData;
    // const { isentity } = this.props;
    // console.log("isentity11: ", isentity);
    const { isbuopen } = this.state;
    return (
      <div>
        {/** numnof days */}
        <div className="form-group">
          <p className="depedent-select-list-head ">Business Unit Name</p>
          {/* <input
            type="text"
            className="width50"
            autoComplete="off"
            autoFocus
            placeholder="Workflow Name"
            onChange={(e) => this._onChange("businessunitname", e.target.value)}
            value={businessunitname}
            maxLength="40"
          /> */}
          <TextField
            id="outlined-dense"
            margin="dense"
            variant="outlined"
            autoComplete="off"
            style={{ width: "50%" }}
            placeholder="Enter Business unit name"
            onChange={(e) => this._onChange("businessunitname", e.target.value)}
            value={businessunitname}
            maxLength="100"
            className="form-text-input"
            autoFocus
          />
        </div>
        <div className="margin-bottom-20 width50">
          <p className="depedent-select-list-head">Location (Optional) </p>
          <Select
            className="selectsearch"
            onChange={(e) => this._onChange("officeaddressid", e.displayvalue)}
            options={get(this.props, "initData.OfficeAddress", [])}
            getOptionLabel={(option) => option.displayname}
            getOptionValue={(option) => option.displayvalue}
            value={getSelectValue(
              get(this.props, "initData.OfficeAddress", []),
              officeaddressid,
            )}
            placeholder="Select"
          />
        </div>
        <div className="margin-top-20 width50">
          <p className="depedent-select-list-head ">
            Do you want to assign a Business Unit Head? (Optional)
          </p>
          <div
            className="depedent-select-list"
            onClick={() => this._onChange("isbuopen", true)}
            aria-expanded={isbuopen}
          >
            <span>Yes</span>
            {isbuopen ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
          <div className="et-level2 width100 margin0">
            <Collapse
              in={isbuopen}
              timeout={2}
              unmountOnExit
              className="padding20 overflow-initial"
            >
              <p className="depedent-select-list-head">Business unit head</p>
              <Select
                className="selectsearch"
                onChange={(e) =>
                  this._onChange("businessunitheadid", e.displayvalue)
                }
                options={get(this.props, "initData.Employee", [])}
                getOptionLabel={(option) => option.displayname}
                getOptionValue={(option) => option.displayvalue}
                value={getSelectValue(
                  get(this.props, "initData.Employee", []),
                  businessunitheadid,
                )}
                placeholder="Select"
              />
            </Collapse>
          </div>
          <div
            className="depedent-select-list"
            onClick={() => this._onChange("isbuopen", false)}
            aria-expanded={!isbuopen}
          >
            <span>No</span>
            {!isbuopen ? (
              <span className="floatright">
                <img src={CheckImg} height="20" />
              </span>
            ) : (
              <span className="not-selected" />
            )}
          </div>
        </div>

        {/* {isentity && (
          <div className="margin-top-20">
            <SectionHeaderExtraSmall header="📌 This business unit will be a separate entity" />
          </div>
        )} */}
        <div className="margin-top-20" />
      </div>
    );
  }
}
