import get from "lodash/get";
import typeToReducer from "type-to-reducer";
import { ReducersTypes } from "../Constants";
import { instance } from "./AxiosInstance";

export const VerifySubscription = (data) => ({
  type: ReducersTypes.SUBSCRIPTIONINFO,
  payload: instance.get(`subscription/verifysubscription/${data}`),
});

export const SetSubscriptionData = (data) => ({
  type: `${ReducersTypes.SUBSCRIPTIONINFO}_FULFILLED`,
  payload: data,
});

export const updateSubscriptionInfo = (type, data) => ({
  type,
  payload: data,
});

const initialState = {
  IsFulfilled: false,
  IsError: false,
  SubscriptionData: {},
};

const SubscriptionInfo = typeToReducer(
  {
    [ReducersTypes.SUBSCRIPTIONINFO]: {
      PENDING: (state) => ({
        ...initialState,
        ...state,
        isPending: true,
      }),
      FULFILLED: (state, action) => {
        localStorage.setItem(
          "dateformat",
          get(action.payload, "data.result[0].dateformatname", "YYYY-MM-DD"),
        );
        return {
          ...state,
          IsFulfilled: true,
          SubscriptionData: {
            employeeid:
              get(state, "SubscriptionData.employeeid", "") ||
              get(action.payload, "data.result[0].employeeid", ""),
            ...action.payload.data.result[0],
          },
        };
      },
      UPDATED_WITH_FULLFILLED: (state, action) => {
        localStorage.setItem(
          "dateformat",
          get(action.payload, "data.dateformatname", "YYYY-MM-DD"),
        );
        return {
          ...state,
          IsFulfilled: action.payload.IsFulfilled,
          SubscriptionData: action.payload.data,
        };
      },
      UPDATED_FOR_EMPLOYEEID: (state, action) => ({
        ...state,
        IsFulfilled: action.payload.IsFulfilled,
        SubscriptionData: action.payload,
      }),
      REJECTED: (state, action) => ({
        ...state,
        IsError: true,
        ErrorMessage: action.payload.response
          ? get(action.payload, "response.data.error.message", "")
          : get(action.payload, "message", ""),
      }),
    },
  },
  initialState,
);

export default SubscriptionInfo;
