export const extraTime = [{
  extratimeid: null,
  isworkingbenefit: false,
  benefittypeid: 0,
  requiredhour: '00:00:00',
  maxearninghour: '00:00:00',
  creditcompoff: 0,
  maxearningcompoff: 0,
  isaccumulation: false,
  accumulationmonths: 0,
  isonweekoff: false,
  isonholiday: false,
  isonoptionalholiday: false,
  isconsiderbuffertime: false,
  buffertime: '00:00:00',
  isperhour: false,
}, {
  extratimeid: null,
  isworkingbenefit: false,
  benefittypeid: 0,
  requiredhour: '00:00:00',
  maxearninghour: '00:00:00',
  creditcompoff: 0,
  maxearningcompoff: 0,
  isaccumulation: false,
  accumulationmonths: 0,
  isonweekoff: true,
  isonholiday: true,
  isonoptionalholiday: true,
  isconsiderbuffertime: false,
  buffertime: '00:00:00',
  isperhour: false,
}];

export const extraTimeJson = {
  extratimepolicyid: null,
  extratimepolicyname: null,
  description: null,
  isetapprovalrequired: false,
  ispastdateallowed: false,
  isseparatebalance: false,
  ispayableapproverequired: false,
  iscompoffapproverequired: false,
  parentid: null,
  isdraft: true,
  extratime: extraTime,
  extratimecompoffpolicy: [{
    lapsetypeid: 0,
    lapsedays: 0,
    isattachment: false,
    attachmentdurationlimit: 0,
    attachmentnote: '',
    maxleave: 0,
    maxdurationtypeid: 0,
    consecutiveleave: 0,
    consecutivedurationtypeid: 0,
    allowedhalfday: false,
    isfurtureappallowed: false,
    minleavecountforapp: 0,
    notifyinadvanceday: 0,
    notifynotbeforeday: 0,
    ispastappallowed: false,
    apppastday: 0,

  }],
};
