import get from "lodash/get";
import React, { Component } from "react";

class InputValues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      defaultvalue: null,
      fixwid: 30,
      wid: null,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentWillMount() {
    this.setState({
      value: this.props.value && this.props.value.toString(),
      defaultvalue: this.props.min,
      width: 30 + (this.props.value && this.props.value.toString().length * 10),
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value != this.props.value) {
      this.setState({
        value: nextProps.value && nextProps.value.toString(),
        defaultvalue: nextProps.min,
        width: 30 + (nextProps.value && nextProps.value.toString().length * 10),
      });
    }
  }

  onInputChange(event) {
    const x = get(event, "target.value", null);
    const width1 = this.state.fixwid;
    const result = 20 * x.length;
    const new1 = result + width1;
    this.setState({ value: event.target.value, wid: new1 });
  }

  onBlur(event) {
    const x = get(event, "target.value", null);
    const { max, field, min } = this.props;
    const m = parseInt(max);
    const n = parseInt(min);
    const y = Math.floor(parseInt(x));
    if (y && y >= n && y <= m) {
      const wid = x.length * 20 + this.state.fixwid;
      this.setState({
        value: y,
        wid,
      });
      this.props.onChange(event, field, max && max);
    } else {
      event.target.value = this.state.defaultvalue;
      this.setState({
        value: this.state.defaultvalue,
        wid: 50,
      });
      const { field, max } = this.props;
      this.props.onChange(event, field, max && max);
    }
  }

  render() {
    const {
 min, max, value, width, step, title, disabled, pattern, onkeypress,
} = this.props;

    return (
      <span>
        <input
          className="seatlimit"
          style={{ width: `${this.state.wid}px` }}
          type="number"
          min={min}
          max={max && max}
          step={step && step}
          title={title && title}
          pattern={pattern && pattern}
          onKeyPress={onkeypress && onkeypress}
          onChange={this.onInputChange}
          onBlur={this.onBlur}
          disabled={disabled && disabled}
          value={this.state.value == null || this.state.value == "" ? this.state.defaultvalue : this.state.value}
        />
      </span>
    );
  }
}

export default InputValues;
