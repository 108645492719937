import { careerpageinstance, instance } from "Actions/AxiosInstance";
import loader from "Assets/Gif/loader.gif";
import empId from "Assets/Icons/EmployeeId/emp-id-16px.svg";
import NoRecordFoundImg from "Assets/Images/no-record.svg";
import empty from "Assets/Svg/emptycareerpage.svg";
import Axios from "axios";
import DefaultComp from "Component/DefaultComp/Defaultstatecomp";
import SearchSq from "Component/Material-ui/SearchSq";
import Configs from "Constants/Configs";
import { getSubdomain } from "Handlers/getters";
import { get } from "lodash";
import { withSnackbar } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { getFormattedImageLink, getFormattedLogoLink } from "Utils";
import arrowdown from "./images/arrow-down.svg";
import location from "./images/location-icon.svg";
import PersonalDetails from "./PersonalDetails";
import "./style/style.css";

class Career extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configData: [],
      searchValue: "",
      coverPhoto: [],
      backupList: [],
      aboutUs: [],
      isjobdescription: false,
      jobdetails: null,
      ourCulture: [],
      ourValues: [],
      overview: [],
      benefits: [],
      ispersonaldetails: false,
      teamStructure: [],
      officeLocation: [],
      aboutusimage1: null,
      aboutusimage2: null,
      aboutusimage3: null,
      ourcultureimage1: null,
      ourcultureimage2: null,
      ourcultureimage3: null,
      jobTemplates: [],
      loadjobs: false,
      pageloading: true,
      subscriptiondetails: {},
    };

    this.focusToPositions = this.focusToPositions.bind(this);
    this.getCareerConfig = this.getCareerConfig.bind(this);
    this.getJobTemplate = this.getJobTemplate.bind(this);
    this.getJobDescription = this.getJobDescription.bind(this);
    this.onCardclick = this.onCardclick.bind(this);
    this.onjobDescription = this.onjobDescription.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    // this.getData();

    const subDomain = getSubdomain();
    instance
      .get(`subscription/verifysubscription/${subDomain}`)
      .then((res) => {
        this.setState({
          subscriptiondetails: res.data.result && res.data.result[0],
          typoperrorcode: false,
        });
      })
      .catch((err) => {
        if (get(err, "response.status", "") == 404) {
          this.setState({ typoperrorcode: true });
        }
      });

    this.getCareerConfig();
    // this.getJobTemplate();
    // setTimeout(this.getJobTemplate(), 500);
    const {
      history: {
        location: { pathname = "" },
      },
    } = this.props;

    if (window.location.pathname.includes("/job/")) {
      this.getJobDescription();
    }

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
  }

  // handleSearch(e) {
  //   const { backupList, jobTemplates } = this.state;
  //   const filterList = jobTemplates.filter(
  //     (item) =>
  //       item.designationname
  //         .toLowerCase()
  //         .search(e.target.value.toLowerCase()) !== -1 ||
  //       (item.jobtype &&
  //         item.jobtype.toLowerCase().search(e.target.value.toLowerCase()) !==
  //           -1(
  //             item.location &&
  //               item.location.location &&
  //               item.location.location
  //                 .toLowerCase()
  //                 .search(e.target.value.toLowerCase()) !== -1,
  //           )),
  //   );
  //   this.setState({
  //     searchValue: e.target.value,
  //     jobTemplates: e.target.value ? filterList : backupList,
  //   });
  // }

  handleSearch(e) {
    const { backupList, jobTemplates } = this.state;
    const filterList = jobTemplates.filter(
      (item) =>
        item.designationname
          .toLowerCase()
          .search(e.target.value.toLowerCase()) !== -1 ||
        (item.jobtype &&
          item.jobtype.toLowerCase().search(e.target.value.toLowerCase()) !==
            -1) ||
        (item.location &&
          item.location.location &&
          item.location.location
            .toLowerCase()
            .search(e.target.value.toLowerCase()) !== -1),
    );
    this.setState({
      searchValue: e.target.value,
      jobTemplates: e.target.value ? filterList : backupList,
    });
  }

  getConfigData() {
    const coverPhoto =
      this.state.configData &&
      this.state.configData.filter((x) => x.sectiontypeid == 1);
    const aboutUs =
      this.state.configData &&
      this.state.configData.filter((x) => x.sectiontypeid == 2);
    const ourCulture =
      this.state.configData &&
      this.state.configData.filter((x) => x.sectiontypeid == 3);
    const ourValues =
      this.state.configData &&
      this.state.configData.filter((x) => x.sectiontypeid == 4);
    const overview =
      this.state.configData &&
      this.state.configData.filter((x) => x.sectiontypeid == 5);
    const benefits =
      this.state.configData &&
      this.state.configData.filter((x) => x.sectiontypeid == 6);
    const teamStructure =
      this.state.configData &&
      this.state.configData.filter((x) => x.sectiontypeid == 7);
    const officeLocation =
      this.state.configData &&
      this.state.configData.filter((x) => x.sectiontypeid == 8);
    const aboutusimage1 =
      aboutUs &&
      aboutUs[0] &&
      aboutUs[0].sectiondetail[0] &&
      aboutUs[0].sectiondetail[0].documentpath;
    const aboutusimage2 =
      aboutUs &&
      aboutUs[0] &&
      aboutUs[0].sectiondetail[1] &&
      aboutUs[0].sectiondetail[1].documentpath;
    const aboutusimage3 =
      aboutUs &&
      aboutUs[0] &&
      aboutUs[0].sectiondetail[2] &&
      aboutUs[0].sectiondetail[2].documentpath;
    const ourcultureimage1 =
      ourCulture &&
      ourCulture[0] &&
      ourCulture[0].sectiondetail[0] &&
      ourCulture[0].sectiondetail[0].documentpath;
    const ourcultureimage2 =
      ourCulture &&
      ourCulture[0] &&
      ourCulture[0].sectiondetail[1] &&
      ourCulture[0].sectiondetail[1].documentpath;
    const ourcultureimage3 =
      ourCulture &&
      ourCulture[0] &&
      ourCulture[0].sectiondetail[2] &&
      ourCulture[0].sectiondetail[2].documentpath;

    this.setState({
      coverPhoto,
      aboutUs,
      ourCulture,
      ourValues,
      overview,
      benefits,
      teamStructure,
      officeLocation,
      aboutusimage1,
      aboutusimage2,
      aboutusimage3,
      ourcultureimage1,
      ourcultureimage2,
      ourcultureimage3,
    });
  }

  getCareerConfigWithoutToken() {
    Axios({
      method: "get",
      url: `${Configs.API_URL}recruit/careerpage`,
      headers: {
        "Content-Type": "application/json",
        "x-sumhr-key": "a136d73d-c18d-497e-a648-82bb730727e8",
      },
    })
      .then((resp) => {
        this.setState(
          {
            configData: resp.data.result,
            pageloading: false,
          },
          () => {
            this.getConfigData();
            this.getJobTemplate();
          },
        );
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
        this.setState({ pageloading: false });
      });
  }

  getCareerConfig() {
    careerpageinstance
      .get(`recruit/careerpage`)
      .then((resp) => {
        const pageloading = resp.data.result.length ? false : true;
        this.setState(
          {
            configData: resp.data.result,
            pageloading,
          },
          () => {
            this.getConfigData();
            this.getJobTemplate();
          },
        );
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
        this.setState({ pageloading: false });
      });
  }

  onCardclick(item) {
    this.setState(
      {
        isjobdescription: true,
        ispersonaldetails: false,
        jobdetails: item,
      },
      () =>
        this.props.history.push({
          pathname: `${this.props.match.path}/job/${item.jobuuid}`,
          // search: `?source=${item.jobuuid}`,
          // state: item,
        }),
    );
  }

  onjobDescription() {
    this.setState({
      ispersonaldetails: true,
      isjobdescription: false,
      // jobdetails: item,
    });
  }

  getJobTemplate = () => {
    careerpageinstance
      .get("recruit/getrequisitionjoblist ")
      .then((result) => {
        this.setState({
          jobTemplates: result.data.result,
          loadjobs: true,
          pageloading: false,
          backupList: result.data.result,
        });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
      });
  };

  getJobDescription = () => {
    instance
      // .get(`recruit/getrequisitionjoblist/${uuid}/null`)
      .get(`recruit/getrequisitionjoblist/null/null`)
      .then((result) => {
        this.setState({ jobTemplates: result.data.result, loadjobs: true });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
      });
  };

  focusToPositions() {
    const tesNode = ReactDOM.findDOMNode(this.refs.positionref);
    window.scrollTo(0, tesNode.offsetTop);
    this.getJobTemplate();
  }

  render() {
    if (window.location.pathname.includes("/career")) {
      window.Beacon("destroy");
    }

    const {
      coverPhoto,
      aboutUs,
      ourCulture,
      ourValues,
      overview,
      benefits,
      teamStructure,
      officeLocation,
      aboutusimage1,
      aboutusimage2,
      aboutusimage3,
    } = this.state;
    return (
      <div>
        {this.state.pageloading ? (
          <img className="loader" src={loader} />
        ) : (this.state.configData && this.state.configData.length) ||
          (this.state.loadjobs && this.state.jobTemplates.length) ? (
          <div>
            {this.state.configData && this.state.configData.length > 0 && (
              <div>
                {this.state.isjobdescription == true ? (
                  // <JobDescription
                  //   {...this.state}
                  //   {...this.props}
                  //   jobdetails={this.state.jobdetails}
                  //   onjobDescription={this.onjobDescription}
                  // />
                  ""
                ) : this.state.ispersonaldetails == true ? (
                  <PersonalDetails
                    {...this.state}
                    {...this.props}
                    jobdetails={this.state.jobdetails}
                  />
                ) : (
                  <div>
                    {coverPhoto && coverPhoto.length > 0 && (
                      <div className="c-MuiGrid-root c-MuiGrid-container">
                        <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                          <div
                            className="c-hero-container"
                            style={{
                              backgroundImage: `url(${getFormattedImageLink(
                                coverPhoto &&
                                  coverPhoto[0] &&
                                  coverPhoto[0].sectiondetail[0].documentpath,
                              )})`,
                            }}
                          >
                            <div style={{ position: "absolute", top: 64 }}>
                              <img
                                style={{
                                  maxHeight: 100,
                                  maxWidth: 200,
                                  objectFit: "contain",
                                }}
                                src={getFormattedLogoLink(
                                  this.state.subscriptiondetails.logo,
                                )}
                                alt="icon"
                              />
                            </div>
                            <h1
                              style={{
                                color: "rgb(255, 255, 255)",
                                fontSize: 64,
                                width: "50%",
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {coverPhoto &&
                                coverPhoto[0] &&
                                coverPhoto[0].title}
                            </h1>
                            <div
                              style={{
                                width: "50%",
                                display: "inline-flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <p
                                style={{
                                  color: "rgb(255, 255, 255)",
                                  fontSize: 24,
                                  marginRight: 16,
                                  fontWeight: "bold",
                                }}
                              >
                                See open positions
                              </p>
                              <button
                                style={{
                                  border: "none",
                                  background: "transparent",
                                  cursor: "pointer",
                                }}
                                onClick={this.focusToPositions}
                              >
                                <div className="c-go-to-bottom">
                                  <img src={arrowdown} />
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div style={{ padding: 64 }}>
                      {/* ABOUT US */}
                      {aboutUs && aboutUs.length > 0 && (
                        <div
                          className="c-MuiGrid-root c-MuiGrid-container c-MuiGrid-spacing-xs-3"
                          style={{ marginBottom: 64 }}
                        >
                          <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-8">
                            <p className="c-small-dark-secondary c-margin-bottom-16">
                              About us
                            </p>
                            <h6 className="c-section-title">
                              {aboutUs && aboutUs[0] && aboutUs[0].title}
                            </h6>
                            <p
                              className="c-small-dark-secondary c-margin-bottom-16"
                              dangerouslySetInnerHTML={{
                                __html:
                                  aboutUs &&
                                  aboutUs[0] &&
                                  aboutUs[0].sectionbody,
                              }}
                            >
                              {/* {aboutUs && aboutUs[0] && aboutUs[0].sectionbody} */}
                            </p>
                          </div>
                          <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                            <div
                              className="c-text-center c-width100 c-position-rel"
                              style={{ height: 284 }}
                            >
                              <div>
                                {aboutusimage1 && (
                                  <img
                                    src={getFormattedImageLink(aboutusimage1)}
                                    height="200"
                                    width="200"
                                    style={{
                                      position: "absolute",
                                      border: "1px solid rgb(221, 221, 221)",
                                      zIndex: 0,
                                      background: "rgb(255, 255, 255)",
                                      top: 0,
                                      left: 0,
                                      display: "block",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                {aboutusimage2 && (
                                  <img
                                    src={getFormattedImageLink(aboutusimage2)}
                                    height="200"
                                    width="200"
                                    style={{
                                      position: "absolute",
                                      border: "1px solid rgb(221, 221, 221)",
                                      zIndex: 0,
                                      background: "rgb(255, 255, 255)",
                                      top: 32,
                                      left: 64,
                                      display: "block",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                {aboutusimage3 && (
                                  <img
                                    src={getFormattedImageLink(aboutusimage3)}
                                    height="200"
                                    width="200"
                                    style={{
                                      border: "1px solid rgb(221, 221, 221)",
                                      position: "absolute",
                                      zIndex: 2,
                                      background: "rgb(255, 255, 255)",
                                      top: 82,
                                      left: 128,
                                      display: "block",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {ourCulture && ourCulture.length > 0 && (
                        <div
                          className="c-MuiGrid-root c-MuiGrid-container c-MuiGrid-spacing-xs-3"
                          style={{ marginBottom: 64 }}
                        >
                          <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-8">
                            <p className="c-small-dark-secondary c-margin-bottom-16">
                              OUR CULTURE
                            </p>
                            <h6 className="c-section-title">
                              {" "}
                              {ourCulture &&
                                ourCulture[0] &&
                                ourCulture[0].title}
                            </h6>
                            <p
                              className="c-small-dark-secondary c-margin-bottom-16"
                              dangerouslySetInnerHTML={{
                                __html:
                                  ourCulture &&
                                  ourCulture[0] &&
                                  ourCulture[0].sectionbody,
                              }}
                            >
                              {/* {ourCulture &&
                                ourCulture[0] &&
                                ourCulture[0].sectionbody} */}
                            </p>
                          </div>
                          <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                            <div
                              className="c-text-center c-width100 c-position-rel"
                              style={{ height: 284 }}
                            >
                              <div>
                                {this.state.ourcultureimage1 && (
                                  <img
                                    src={getFormattedImageLink(
                                      this.state.ourcultureimage1,
                                    )}
                                    height="200"
                                    width="200"
                                    style={{
                                      position: "absolute",
                                      border: "1px solid rgb(221, 221, 221)",
                                      zIndex: 0,
                                      background: "rgb(255, 255, 255)",
                                      top: 0,
                                      left: 0,
                                      display: "block",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                {this.state.ourcultureimage2 && (
                                  <img
                                    src={getFormattedImageLink(
                                      this.state.ourcultureimage2,
                                    )}
                                    height="200"
                                    width="200"
                                    style={{
                                      position: "absolute",
                                      border: "1px solid rgb(221, 221, 221)",
                                      zIndex: 0,
                                      background: "rgb(255, 255, 255)",
                                      top: 32,
                                      left: 64,
                                      display: "block",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                {this.state.ourcultureimage3 && (
                                  <img
                                    src={getFormattedImageLink(
                                      this.state.ourcultureimage3,
                                    )}
                                    height="200"
                                    width="200"
                                    style={{
                                      border: "1px solid rgb(221, 221, 221)",
                                      position: "absolute",
                                      zIndex: 2,
                                      background: "rgb(255, 255, 255)",
                                      top: 82,
                                      left: 128,
                                      display: "block",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </div>
                            </div>

                            {/* <div>
                        <video width="320" height="240" controls>
                          <source src="movie.mp4" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div> */}
                          </div>
                        </div>
                      )}

                      {ourValues && ourValues.length > 0 && (
                        <div style={{ marginBottom: 64 }}>
                          <div
                            className="c-MuiGrid-root c-MuiGrid-container"
                            style={{ marginBottom: 24 }}
                          >
                            <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                              <p className="c-small-dark-secondary c-margin-bottom-16">
                                OUR VALUES
                              </p>
                              <h6 className="c-section-title">
                                {ourValues &&
                                  ourValues[0] &&
                                  ourValues[0].title}
                              </h6>
                            </div>
                          </div>
                          <div
                            className="c-MuiGrid-root c-thumbnails c-MuiGrid-container c-MuiGrid-spacing-xs-3"
                            style={{ justifyContent: "center" }}
                          >
                            {ourValues &&
                              ourValues[0] &&
                              ourValues[0].sectiondetail.length > 0 &&
                              ourValues[0].sectiondetail.map((item) => (
                                <div className="c-MuiGrid-root c-text-center c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-3">
                                  <div
                                    style={{
                                      height: 160,
                                      position: "relative",
                                    }}
                                  >
                                    <img
                                      src={getFormattedImageLink(
                                        item.documentpath,
                                      )}
                                      height="64"
                                      width="64"
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        left: 0,
                                        bottom: 0,
                                        margin: "auto",
                                      }}
                                    />
                                  </div>
                                  <p className="c-large-text-dark-secondary c-t-bold c-margin-bottom-32">
                                    {item.title}
                                  </p>
                                  <p className="c-normal-dark-secondary c-margin-bottom-16">
                                    {item.description}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                      {overview && overview.length > 0 && (
                        <div style={{ marginBottom: 64 }}>
                          <div
                            className="c-MuiGrid-root c-MuiGrid-container"
                            style={{ marginBottom: 24 }}
                          >
                            <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                              <p className="c-small-dark-secondary c-margin-bottom-16">
                                OVERVIEW
                              </p>
                              <h6 className="c-section-title">
                                {overview && overview[0] && overview[0].title}
                              </h6>
                            </div>
                          </div>
                          <div
                            className="c-MuiGrid-root c-thumbnails c-MuiGrid-container c-MuiGrid-spacing-xs-3"
                            style={{ justifyContent: "center" }}
                          >
                            {overview &&
                              overview[0] &&
                              overview[0].sectiondetail.length > 0 &&
                              overview[0].sectiondetail.map((item) => (
                                <div className="c-MuiGrid-root c-text-center c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-3">
                                  <h3
                                    className="c-t-bold c-margin-top-0"
                                    style={{
                                      color: "rgba(1, 7, 9, 0.7)",
                                      fontSize: 48,
                                      marginBottom: 8,
                                    }}
                                  >
                                    {item.title}
                                  </h3>
                                  <p className="c-normal-blue c-margin-bottom-32">
                                    {item.description}
                                    {String.fromCodePoint(
                                      parseInt(item.documentpath, 16),
                                    )}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                      {benefits && benefits.length > 0 && (
                        <div style={{ marginBottom: 64 }}>
                          <div
                            className="c-MuiGrid-root c-MuiGrid-container"
                            style={{ marginBottom: 24 }}
                          >
                            <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                              <p className="c-small-dark-secondary c-margin-bottom-16">
                                BENIFITS
                              </p>
                              <h6 className="c-section-title">
                                {benefits && benefits[0] && benefits[0].title}
                              </h6>
                            </div>
                          </div>
                          <div
                            className="c-MuiGrid-root c-thumbnails c-MuiGrid-container c-MuiGrid-spacing-xs-3"
                            style={{ justifyContent: "center" }}
                          >
                            {benefits &&
                              benefits[0] &&
                              benefits[0].sectiondetail.length > 0 &&
                              benefits[0].sectiondetail.map((item) => (
                                <div className="c-MuiGrid-root c-text-center c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-3">
                                  <div
                                    style={{
                                      height: 160,
                                      position: "relative",
                                    }}
                                  >
                                    <img
                                      src={getFormattedImageLink(
                                        item.documentpath,
                                      )}
                                      height="64"
                                      width="64"
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        left: 0,
                                        bottom: 0,
                                        margin: "auto",
                                      }}
                                    />
                                  </div>
                                  <p className="c-large-text-dark-secondary c-margin-bottom-32">
                                    {item.title}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}

                      {teamStructure && teamStructure.length > 0 && (
                        <div style={{ marginBottom: 64 }}>
                          <div
                            className="c-MuiGrid-root c-MuiGrid-container"
                            style={{ marginBottom: 24 }}
                          >
                            <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                              <p className="c-small-dark-secondary c-margin-bottom-16">
                                TEAM STRUCTURE
                              </p>
                              <h6 className="c-section-title">
                                {teamStructure &&
                                  teamStructure[0] &&
                                  teamStructure[0].title}
                              </h6>
                            </div>
                          </div>
                          <div
                            className="c-MuiGrid-root c-thumbnails c-MuiGrid-container c-MuiGrid-spacing-xs-3"
                            style={{ justifyContent: "center" }}
                          >
                            {teamStructure &&
                              teamStructure[0].sectiondetail &&
                              teamStructure[0].sectiondetail.map((item) => (
                                <div className="c-MuiGrid-root c-text-center c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-3">
                                  <h3
                                    className="c-t-bold c-margin-top-0"
                                    style={{
                                      color: "rgba(1, 7, 9, 0.7)",
                                      fontSize: 48,
                                      marginBottom: 8,
                                    }}
                                  >
                                    {item.title}
                                  </h3>
                                  <p className="c-normal-blue c-margin-bottom-32">
                                    {item.description}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}

                      {officeLocation && officeLocation.length > 0 && (
                        <div style={{ marginBottom: 64 }}>
                          <div
                            className="c-MuiGrid-root c-MuiGrid-container"
                            style={{ marginBottom: 24 }}
                          >
                            <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-12">
                              <p className="c-small-dark-secondary c-margin-bottom-16">
                                OFFICE LOCATIONS
                              </p>
                              <h6 className="c-section-title">
                                {officeLocation &&
                                  officeLocation[0] &&
                                  officeLocation[0].title}
                              </h6>
                            </div>
                          </div>
                          <div
                            className="c-MuiGrid-root c-thumbnails c-MuiGrid-container c-MuiGrid-spacing-xs-3"
                            style={{ justifyContent: "center" }}
                          >
                            {officeLocation &&
                              officeLocation[0] &&
                              officeLocation[0].sectiondetail.map((item) => (
                                <div className="c-MuiGrid-root c-text-center c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-3">
                                  <img
                                    src={getFormattedImageLink(
                                      item.documentpath,
                                    )}
                                    width="200"
                                    height="200"
                                    style={{
                                      objectFit: "contain",
                                      marginBottom: 8,
                                    }}
                                  />
                                  <p
                                    className="c-normal-blue c-t-bold"
                                    style={{ marginBottom: 8 }}
                                  >
                                    {item.title}
                                  </p>
                                  <p className="c-normal-dark-secondary">
                                    {item.officeaddress &&
                                      item.officeaddress.addressline1}
                                    <br></br>{" "}
                                    {item.officeaddress &&
                                      item.officeaddress.addressline2}
                                    {item.officeaddress &&
                                      item.officeaddress.addressline2 && (
                                        <br></br>
                                      )}
                                    {item.officeaddress &&
                                      item.officeaddress.cityname}
                                    ,{" "}
                                    {item.officeaddress &&
                                      item.officeaddress.pincode}{" "}
                                    <br></br>
                                    {item.officeaddress &&
                                      item.officeaddress.countryname}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {this.state.loadjobs && this.state.jobTemplates.length > 0 && (
              <div
                ref="positionref"
                style={{ margin: "0px auto", width: "80%" }}
                className={
                  this.state.configData && this.state.configData.length == 0
                    ? "margin-top-60"
                    : ""
                }
              >
                <div className="c-MuiGrid-root c-thumbnails c-MuiGrid-container c-MuiGrid-spacing-xs-3">
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-6">
                    <p
                      className="c-large-text-dark-secondary c-t-bold"
                      style={{ fontSize: 22 }}
                    >
                      Open Positions
                    </p>
                  </div>
                  <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-6">
                    <div
                    // className="c-search-box c-t-12"
                    >
                      <div>
                        {/* <input
                            className=""
                            placeholder="Search"
                            type="text"
                            value=""
                          /> */}
                        <SearchSq
                          placeholder="Search job"
                          value={this.state.searchValue}
                          onChange={this.handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="c-MuiGrid-root c-thumbnails c-MuiGrid-container c-MuiGrid-spacing-xs-3"
                  style={{
                    justifyContent: "flex-start",
                    marginTop: 16,
                  }}
                >
                  {this.state.jobTemplates.length == 0 ? (
                    <div className="position-rel" style={{ width: "100%" }}>
                      <DefaultComp
                        heading="No result found"
                        // subheading="Please configuration career page first"
                        imageSrc={NoRecordFoundImg}
                      />
                    </div>
                  ) : (
                    this.state.jobTemplates &&
                    this.state.jobTemplates.map((item) => (
                      <div
                        className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-6"
                        onClick={() => {
                          // e.preventDefault();
                          // // this.props.history.push("./fgh/1");
                          // this.props.history.push("./jobdescription/1");
                          this.onCardclick(item);
                        }}
                      >
                        <div className="c-rounded-rectangle-paper">
                          <p
                            className="c-normal-dark-secondary c-t-bold"
                            style={{ marginBottom: 8 }}
                          >
                            {item.designationname}
                          </p>
                          <div style={{ marginBottom: 8 }}>
                            <img
                              src={empId}
                              style={{
                                verticalAlign: "middle",
                                marginRight: 8,
                              }}
                            />
                            <p
                              className="c-small-dark-secondary"
                              style={{
                                display: "inline",
                                marginBottom: 8,
                              }}
                            >
                              {item.jobtype}
                            </p>
                          </div>
                          <div>
                            <img
                              src={location}
                              style={{
                                verticalAlign: "middle",
                                marginRight: 8,
                              }}
                            />
                            <p
                              className="c-small-dark-secondary"
                              style={{
                                display: "inline",
                                marginBottom: 8,
                              }}
                            >
                              {item.location && item.location.location}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="position-rel">
            <DefaultComp
              heading="You are yet to configure your company's career page."
              // subheading="Please configuration career page first"
              imageSrc={empty}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withSnackbar(Career);
