import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import ClearIcon from "../../Assets/Svg/clearIcon.svg";
import { ErrorMessages } from "../../Constants";
import {
  betweenDays,
  convertToJSDateType,
  getUserDateTimeFormat,
} from "../../Utils/dates";
import "./DateRangeCx.scss";

const CALENDERS_DATEFORMATS = {
  "DD-MM-YYYY HH12:MI:SS AM": "d/MM/yyyy",
  "DD-MM-YYYY HH24:MI:SS": "d/MM/yyyy",
  "DD-MM-YY HH12:MI:SS AM": "d/MM/yy",
  "DD-MM-YY HH24:MI:SS": "d/MM/yy",
  "DD-MMM-YYYY HH12:MI:SS AM": "d/MMM/yyyy",
  "DD-MMM-YYYY HH24:MI:SS": "d/MMM/yyyy",
  "MMM DD, YYYY HH12:MI:SS AM": "MMM dd, yyyy",
  "MMM DD, YYYY HH24:MI:SS": "MMM d, yyyy",
  "YYYY-MM-DD HH": "yyyy/MM/d",
};

const getthisdateformat = (depth) =>
  depth == "decade"
    ? "yyyy"
    : depth == "year"
    ? "MMM yyyy"
    : CALENDERS_DATEFORMATS[getUserDateTimeFormat()];

class DateRangeCx extends Component {
  state = {
    value: [null, null],
  };

  componentDidMount() {
    const {
      startdate = null,
      enddate = null,
      min = null,
      maxDays = null,
      max = null,
    } = this.props;
    const value = [
      convertToJSDateType(startdate),
      convertToJSDateType(enddate),
    ];
    this.setState({
      startdate,
      enddate,
      min,
      minDate: min && convertToJSDateType(min),
      maxDays,
      max,
      maxDate: max && convertToJSDateType(max),
      value,
    });
  }

  componentWillReceiveProps(props) {
    const {
      startdate = null,
      enddate = null,
      min = null,
      maxDays = null,
      max = null,
    } = props;
    const value = [
      convertToJSDateType(startdate),
      convertToJSDateType(enddate),
    ];
    this.setState({
      startdate,
      enddate,
      min,
      minDate: min && convertToJSDateType(min),
      maxDays,
      max,
      maxDate: max && convertToJSDateType(max),
      value,
    });
  }

  onChange = (date) => {
    const { maxDays } = this.props;
    if (
      maxDays &&
      date &&
      betweenDays({ startdate: moment(date[0]), enddate: moment(date[1]) }) >
        maxDays
    ) {
      this.props.enqueueSnackbar(ErrorMessages.E0080, { variant: "error" });
      return;
    }

    date = date || [null, null];
    this.props.onClose({
      startdate: date[0] || null,
      enddate: date[1] || null,
    });
  };

  render = () => {
    const { minDetail, maxDetail, view, depth, customFormat } = this.props;
    const { minDate, maxDate, value } = this.state;
    return (
      <div>
        <DateRangePicker
          // isOpen
          // format="d MM y"
          // format={getthisdatepickerformat()}
          format={customFormat || getthisdateformat(depth)}
          maxDate={maxDate}
          minDate={minDate}
          onChange={this.onChange}
          value={value}
          view={view || "month"}
          maxDetail={maxDetail || "month"} // "month", "year", "decade", "century"
          minDetail={minDetail || "decade"} // "month", "year", "decade", "century"
          dayAriaLabel="dd"
          dayPlaceholder="--"
          monthPlaceholder="--"
          yearPlaceholder="----"
          clearIcon={
            value[0] ? (
              <img
                src={ClearIcon}
                className="react-time-picker__clear-button__icon react-time-picker__button__icon"
              />
            ) : (
              ""
            )
          }
        />
      </div>
    );
  };
}

export default withSnackbar(DateRangeCx);
